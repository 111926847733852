import axios from 'axios'

 class EmpService  {
    fetchAllEmployeesListService() {
        return axios.get('http://localhost:8086/fetchEmployees');
    }

    addEmployeeService() {
        return axios.get('http://localhost:8086/addEmployee');
    }

    executeHelloWorldPathVariableService(name) {
        return axios.get(`http://localhost:8080/hello-world/path-variable/${name}`);
    }
}

export default new EmpService
