import axios from 'axios'

//const API_URL = 'http://localhost:8080/secured'

const API_URL = 'http://localhost:8080'
export const USER_NAME_SESSION_ATTRIBUTE_NAME = 'authenticatedUser';
export const USER_TOKEN = 'TOKEN';
export const THIRD_PARTY_LOGIN = "THRIDPARTY";
export const USER_VALUES='USER_VALUES';
class AuthenticationService {

    executeBasicAuthenticationService(username, password) {
        return axios.get(`${API_URL}/authenticate`,
            { headers: { authorization: this.createBasicAuthToken(username, password) } })
    }

    executeJwtAuthenticationService(username, password) {
        //console.log(username);
        return axios.post(`${API_URL}/authenticate`, {
            username,
            password
        })
    }

    

    createBasicAuthToken(username, password) {
        return 'Basic ' + window.btoa(username + ":" + password)
    }

    registerSuccessfulLogin(username, password) {
        //let basicAuthHeader = 'Basic ' +  window.btoa(username + ":" + password)
        ////console.log('registerSuccessfulLogin')
        sessionStorage.setItem(USER_NAME_SESSION_ATTRIBUTE_NAME, username)
               
        this.setupAxiosInterceptors(this.createBasicAuthToken(username, password))
    }

    registerSuccessfulLoginForJwt(username, token) {
        sessionStorage.setItem(USER_NAME_SESSION_ATTRIBUTE_NAME, username);
        sessionStorage.setItem(USER_TOKEN, token);
        this.setupAxiosInterceptors(this.createJWTToken(token))
    }

    createJWTToken(token) {
        //console.log("in createJWTToken::token value::",token)
        return 'Bearer ' + token
    }


    logout() {
        sessionStorage.removeItem(USER_NAME_SESSION_ATTRIBUTE_NAME);
        sessionStorage.removeItem(USER_TOKEN);
    }

    isUserLoggedIn() {
        let user = sessionStorage.getItem(USER_NAME_SESSION_ATTRIBUTE_NAME)
        if (user === null) return false
        return true
    }

    getLoggedInUserName() {
        let user = sessionStorage.getItem(USER_NAME_SESSION_ATTRIBUTE_NAME)
        if (user === null) return ''
        return user
    }
    getLoggedInUserToken() {
        let token = sessionStorage.getItem(USER_TOKEN)
        if (token === null) return ''
        return token
    }
    setupAxiosInterceptors(token) {
        axios.interceptors.request.use(
            (config) => {
                if (this.isUserLoggedIn()) {
                    config.headers.authorization = token
                }
                return config
            }
        )
    }

    executeJwtAnyService(url) {
        //console.log(url);
 

    }

    executeHelloWorldService() {
        var myHeaders = new Headers();
        myHeaders.append("Content-Transfer-Encoding", "application/json");
        myHeaders.append('Access-Control-Allow-Origin', '*');
        myHeaders.append('Access-Control-Allow-Credentials', 'true');
        myHeaders.append('Content-Type','application/json;charset=UTF-8');

        let tkn = this.getLoggedInUserToken();
        this.setupAxiosInterceptors(this.createJWTToken(tkn));
        return axios.post('http://localhost:8080/hello');
    
    }

    executestaticpassword(username, password) {
        //console.log("authenticateservice:username::"+username);
        //debugger;
    
        let hardcodedCred = {
            username: 'admin',
            password: 'admin@123'
        }
        if(username === hardcodedCred.username && password ===hardcodedCred.password){
            //console.log("login success");
            return true;
        }
        return false;
    }

      registerSuccessfulLoginThirdPartyForJwt(username, token,thirdpartyFlag) {
        sessionStorage.setItem(USER_NAME_SESSION_ATTRIBUTE_NAME, username);
        sessionStorage.setItem(USER_TOKEN, token);
        sessionStorage.setItem(THIRD_PARTY_LOGIN, thirdpartyFlag);
        this.setupAxiosInterceptors(this.createJWTToken(token))
    }

    thirdpartyLogout(){
        sessionStorage.removeItem(USER_NAME_SESSION_ATTRIBUTE_NAME);
        sessionStorage.removeItem(USER_TOKEN);
         sessionStorage.removeItem(THIRD_PARTY_LOGIN);
    }

    ClearFlagsforLogin(){
        sessionStorage.removeItem(USER_NAME_SESSION_ATTRIBUTE_NAME);
        sessionStorage.removeItem(THIRD_PARTY_LOGIN);
        sessionStorage.removeItem(USER_TOKEN);
        sessionStorage.removeItem(USER_VALUES);
        //sessionStorage.removeItem(USER_TOKEN);
    }
    saveUser(userobj){
        sessionStorage.setItem(USER_VALUES, JSON.stringify(userobj));
        
    }
    getUser(){
        var userObj = sessionStorage.getItem(USER_VALUES);
        if (userObj === null) return ''

        return userObj;
         
    }

}

export default new AuthenticationService()