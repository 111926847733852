import React from 'react';
import {HashRouter as Router,BrowserRouter ,Route,Switch} from 'react-router-dom';
import {Navbar,Nav,NavDropdown,Form,FormControl,Button} from 'react-bootstrap';
import { Redirect } from 'react-router';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCoffee } from '@fortawesome/free-solid-svg-icons';


import AboutUS from './bootstrapsupportfiles/AboutUs';
import ContactUs from './bootstrapsupportfiles/ContactUs';
import Home from './bootstrapsupportfiles/Home';
import HelloWorldBeanComponent from './component/HelloWorldBeanComponent';
import HelloWorldStringComponent from './component/HelloWorldStringComponent';
import FetchAllEmployees from './employee/FetchAllEmployees'
import AddEmployee from './employee/AddEmployee'
import LoginComponent from './login/Login';
import LogoutComponent from './login/LogoutComponent'
import AuthenticatedRoute from './AuthenticatedRoute'
import Dashboard from './bootstrapsupportfiles/Dashboard';
import Login from './login/Login';
import  ProtectedRoute  from "./Protected.Route";
import SocialFollowUp from "./SocialFollowUp";
import BrandDashboard from './bootstrapsupportfiles/BrandDashboard';
import AuthenticateService from './service/AuthenticateService';


class BootStrapNavBar extends React.Component{
   constructor(props){
       super(props);
        //console.log("bootstrap js -constructor props-",this.props);
       this. state={    
                    googleUserMailId: '', 
                    googleuserFistName: '', 
                    googleUserLastName: '', 
                    googleUserImage: ''
            };
         
      
    }
    componentDidMount = (props) => {

            document.addEventListener('contextmenu', (e) => {
                e.preventDefault();
            });

         this.setState({...this.props.state});
         // //console.log(this.props);
          setTimeout(function(){  
                this.props.history.push("/dashboard/logout");
            }.bind(this), (1000 *60*60));

          document.addEventListener('contextmenu', (e) => {
              e.preventDefault();
          });
    };
    render(){
       //console.log("render - bootstrap nav bar-",this.props);
     
      
        var userObj ={...this.props.state} ;
         var authObj = AuthenticateService.getUser();
        
        if( authObj ){
           // console.log("authservice user authObj ::"+authObj);
           //Object.assign(userObj,authObj); const case
         userObj=JSON.parse(authObj);
          //  console.log("authservice user userObj ::"+userObj);

        }
        let googleuserFistName = userObj.googleuserFistName;
        let googleUserLastName = userObj.googleUserLastName;
        let googleUserImage = userObj.googleUserImage;
         let usermailid = userObj.googleUserMailId;
        //console.log("bootstrap userObj-"+userObj.googleuserFistName);
        //console.log("bootstrap userObj-"+userObj.googleUserImage);
         //console.log("bootstrap userObj-"+userObj.googleUserLastName);
        //googleUserLastName
       // googleUserImage
        //googleuserFistName
       //console.log("render - bootstrap nav bar-usermailid-",usermailid);
      
        return(
            
             <div>
             
               {/* logged in as<h1 className="textLoggedinAs"> {usermailid} </h1 >*/}
           
                <div className="row">
                    <div className="col-md-12">
                       
                    
                           
                            <Navbar bg="bg-transparent" variant="light" expand="lg" >
                                 <Navbar.Brand href="#">&copy;<label  className="LoginMenutextbrand">Nirupa Naidu <br/>Enterprises</label> &nbsp;&nbsp;&nbsp;&copy;</Navbar.Brand>
                                 <Navbar.Toggle aria-controls="basic-navbar-nav" />
                                 <Navbar.Collapse id="basic-navbar-nav">
                                    <Nav className="mr-auto">                                        
                                         <Nav.Link href="#/dashboard/home" style={{color:'#a600ff',font: '1em'}}>Home</Nav.Link>
                                          <Nav.Link href="#/dashboard/autogallery" style={{color:'#a600ff',font: '1em'}}>Autogallery</Nav.Link>
                                        <Nav.Link href="#/dashboard/about-us" style={{color:'#a600ff',font: '1em'}}>About Us</Nav.Link>
                                        <Nav.Link href="#/dashboard/contact-us" style={{color:'#a600ff',font: '1em'}}>Contact Us</Nav.Link>                                   
                                    </Nav>
                                    <Nav className="mr-auto" >                                      
                                        <label className="userdtlsnavbar"> 
                                            <img src={googleUserImage} height="30px"/>
                                                {usermailid} 
                                                    <NavDropdown title="Logout" id="basic-nav-dropdown" style={{color:'#a600ff',font: '1em'}}>
                                                        <NavDropdown.Item href="#/dashboard/logout"  ><span  style={{color:'#a600ff',font: '1em'}}>Logout</span></NavDropdown.Item>                                             
                                                                                                  
                                                    </NavDropdown>
                                        </label> 
                                            
                                            
                                        
                                         <Nav.Link href="#"> 
                                            </Nav.Link>
                                    </Nav>                                  
                                    
                                    <Form inline>
                                         <FormControl type="text" placeholder="Search" className="mr-sm-2" />
                                         <Button variant="outline-success">Search</Button>
                                    </Form>
                                </Navbar.Collapse>
                             
                            </Navbar>
                       
                         
                        
               
                      
                        <br/>
                        <div>
                         <h4></h4>
                        </div>
                    
                         
                            <Switch>
                                     <Route exact path="/" 
                                        render={
                                            props => <LoginComponent {...props} updateGoogleUserdata={this.props.updateGoogleUserdata}/>
                                        } 
                                    />
                                <Route path="/logout" exact component={LogoutComponent} />  
                                {/*<AuthenticatedRoute  path="/dashboard/home" component={Home}/>*/}
                                 <AuthenticatedRoute exact path="/dashboard/home" 
                                        render={
                                            props => <Home {...props} state={this.state}/>
                                        } 
                                ></AuthenticatedRoute>
                                <AuthenticatedRoute exact path="/dashboard" 
                                        render={
                                            props => <Dashboard {...props} state={this.state}/>
                                        } 
                                ></AuthenticatedRoute>
                                   <AuthenticatedRoute exact path="/branddashboard" 
                                        render={
                                            props => <BrandDashboard {...props} state={this.state}/>
                                        } 
                                ></AuthenticatedRoute>

                                {/*window.location.href
                                <Redirect from="/old-path" to="/new-path" />
                                 
                                  <Route exact path="/display" render={ ()=>{window.location.href="./movie-title-home.html"} } />*/}
                                  <AuthenticatedRoute exact path="/display" render={() => {window.location.href="/republic/democratic/representative/commonwealth/constitutional/sovereignty/supremacy/predominance/federation/display/movie-title-home.html"}} />
                                  <AuthenticatedRoute exact path="/dashboard/autogallery" render={() => {window.location.href="/republic/democratic/representative/commonwealth/constitutional/sovereignty/supremacy/predominance/federation/display/movie-title-home-autogallery.html"}} />
                                
                                <AuthenticatedRoute  path="/dashboard/about-us" component={AboutUS}/> 
                                <AuthenticatedRoute path="/dashboard/contact-us"  component={ContactUs} />
                                <AuthenticatedRoute path="/dashboard/helloworld" exact component={HelloWorldStringComponent} />
                                <AuthenticatedRoute path="/dashboard/hello" exact component={HelloWorldStringComponent} />
                                <AuthenticatedRoute path="/dashboard/hello-world-string" component={HelloWorldStringComponent} />
                                <AuthenticatedRoute path="/dashboard/hello-world-bean" component={HelloWorldBeanComponent} />
                                <AuthenticatedRoute path="/dashboard/fetchEmpDetails" component={FetchAllEmployees}/>
                                <AuthenticatedRoute path="/dashboard/addEmployee" component={AddEmployee}/>
                                <AuthenticatedRoute path="/dashboard/logout" component={LogoutComponent}/>
                                
                            </Switch>
                           
                    </div>

                </div>
                <Nav className="navbar  navbar-light ">
                        <SocialFollowUp/>
                        {/*<Navbar.Brand href="#" style={{color:"white"}}> Copyright &copy; www.nirupanaidu.com .Thanks <FontAwesomeIcon icon={faCoffee} color="orange" /> </Navbar.Brand>*/}
                            
                            
                        </Nav>
        </div>
          
        );
    }

}

export default BootStrapNavBar;