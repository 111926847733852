import React, { Component } from 'react';
import {HashRouter as Router,BrowserRouter ,Route,Switch} from 'react-router-dom';
import AboutUS from './bootstrapsupportfiles/AboutUs';
import ContactUs from './bootstrapsupportfiles/ContactUs';
import Home from './bootstrapsupportfiles/Home';
import HelloWorldBeanComponent from './component/HelloWorldBeanComponent';
import HelloWorldStringComponent from './component/HelloWorldStringComponent';
import FetchAllEmployees from './employee/FetchAllEmployees'
import AddEmployee from './employee/AddEmployee'
import LoginComponent from './login/Login';
import LoginComponent2 from './login/Login2';
import LoginComponent3 from './login/Login3';
import LoginComponent4 from './login/Login4';
import LoginComponent5 from './login/Login5';
import LoginIndex from './login/Loginindex';
import LogoutComponent from './login/LogoutComponent'
import AuthenticatedRoute from './AuthenticatedRoute'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCoffee } from '@fortawesome/free-solid-svg-icons';
import Login from './login/Login';
import  ProtectedRoute  from "./Protected.Route";
import  BootStrapNavBar from "./BootstrapNavbar";
import HybridLogin from "./login/HybridLogin";
import CEODesk1 from './login/CEODesk1.js';
import CEODesk2 from './login/CEODesk2.js';
import CEODesk3 from './login/CEODesk3.js';
import CEODesk4 from './login/CEODesk4.js';
import CEODesk5 from './login/CEODesk5.js';
import CEODesk6 from './login/CEODesk6.js';
import CEODesk7 from './login/CEODesk7.js';
import LoginCompanyGlimpse from './login/LoginCompanyGlimpse.js';
import NewYear2023 from './wishes/NewYear2023.js';


class RoutesHome extends Component {
    constructor(props){
        super(props);
        this.state={
            googleUserMailId: '',
            googleuserFistName : '',
            googleUserLastName: '',
            googleUserImage: ''
        };
           this.updateGoogleUserdata=this.updateGoogleUserdata.bind(this);
    }
    
     updateGoogleUserdata (profileObj){
        //console.log('routeshome: currentUser:', profileObj);
        //const profileObj = googleresponse.profileObj;
        //console.log('routeshome: currentUser222:', profileObj.email);
        this.setState({ googleUserMailId: profileObj.email });
        this.setState({ googleuserFistName: profileObj.name });
        this.setState({ googleUserLastName: profileObj.givenName });
        this.setState({ googleUserImage: profileObj.imageUrl });
        //this.setState({ googleUserLastName: true });
       // this.setState({ googleUserImage: false });
        //console.//console.log("routeshome::stateobj::"+this.state);
    }
    render() {
        //console.log("hello world app component");
        return (                        
            <Switch>
                    {/*<Route path="/" exact component={LoginComponent} />  
                    <Route exact path="/login"  updateGoogleUserdata={this.updateGoogleUserdata}   component={LoginComponent} /> */}
                   {/* <Route exact path="/" 
                        render={
                            props => <CEODesk1 {...props} updateGoogleUserdata={this.updateGoogleUserdata}/>
                        } 
                    /> */}
                    <Route exact path="/" 
                        render={
                            props => <LoginIndex {...props} updateGoogleUserdata={this.updateGoogleUserdata}/>
                        } 
                    />
                    <Route exact path="/loginglimpse" 
                        render={
                            props => <LoginCompanyGlimpse {...props} updateGoogleUserdata={this.updateGoogleUserdata}/>
                        } 
                    />
                    <Route exact path="/loginindex" 
                        render={
                            props => <LoginIndex {...props} updateGoogleUserdata={this.updateGoogleUserdata}/>
                        } 
                    />
                      <Route exact path="/page1" 
                        render={
                            props => <LoginComponent3 {...props} updateGoogleUserdata={this.updateGoogleUserdata}/>
                        } 
                    />
                     <Route exact path="/page2" 
                        render={
                            props => <LoginComponent2 {...props} updateGoogleUserdata={this.updateGoogleUserdata}/>
                        } 
                    />
                   <Route exact path="/login" 
                        render={
                            props => <LoginComponent {...props} updateGoogleUserdata={this.updateGoogleUserdata}/>
                        } 
                    />
                    <Route exact path="/login2" 
                        render={
                            props => <LoginComponent2 {...props} updateGoogleUserdata={this.updateGoogleUserdata}/>
                        } 
                    />
                    <Route exact path="/login3" 
                        render={
                            props => <LoginComponent3 {...props} updateGoogleUserdata={this.updateGoogleUserdata}/>
                        } 
                    />
                     <Route exact path="/ceodesk1" 
                        render={
                            props => <CEODesk1 {...props} updateGoogleUserdata={this.updateGoogleUserdata}/>
                        } 
                    />
                    <Route exact path="/ceodesk2" 
                        render={
                            props => <CEODesk2 {...props} updateGoogleUserdata={this.updateGoogleUserdata}/>
                        } 
                    />
                    <Route exact path="/ceodesk3" 
                        render={
                            props => <CEODesk3 {...props} updateGoogleUserdata={this.updateGoogleUserdata}/>
                        } 
                    />
                    <Route exact path="/logincompanyglimpsy" 
                        render={
                            props => <LoginCompanyGlimpse {...props} updateGoogleUserdata={this.updateGoogleUserdata}/>
                        } 
                    />
                    <Route  
                         render={
                            props => <BootStrapNavBar {...props} state={this.state}/>
                        }  />  
                                        
                </Switch>                          
        )
    }
}

export default RoutesHome;