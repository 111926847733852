import React, { Component } from "react";
import { Carousel } from 'react-bootstrap';
import {Navbar,Nav,NavDropdown,Form,FormControl,Modal,Button} from 'react-bootstrap';

import PropTypes from "prop-types";
import Axios from 'axios';
import auth from "../Auth.js";
import { useState } from 'react';


import AuthenticationService from "../service/AuthenticateService";
import AuthenticateService from "../service/AuthenticateService";
import LoginModal from '../login/LoginModal';
import useWindowDimensions from '../useWindowDimensions'
import { GoogleLogin } from 'react-google-login';
import { refreshTokenSetup } from '../googleutils/refreshToken';
import LoginBackgroundImage from '../LoginBackgroundImage'; 
import desktopImage from '../images/test15.jpeg';
import mobileImage from '../images/test15.jpeg';
import SocialFollowUp from '../SocialFollowUp';


import "../css/login.css";
import "../css/book.css";
import "../css/poster.css";
import jpg15rm from "../images/test15-rm.jpeg"
import jpgtest from '../images/test.jpeg';
import jpg1 from '../images/test1.jpeg';
import jpg2 from '../images/test2.jpeg';
import jpg3 from '../images/test3.jpeg';
import jpg4 from '../images/test4.jpeg';
import jpg5 from '../images/test5.jpeg';
import jpg6 from '../images/test6.jpeg';
import jpg7 from '../images/test7.jpeg';
import jpg8 from '../images/test8.jpeg';
import jpg9 from '../images/test9.jpeg';
import jpg10 from '../images/test10.jpeg';
import jpg11 from '../images/test11.jpeg';
import jpg12 from '../images/test12.jpeg';
import jpg13 from '../images/test13.jpeg';
import jpg14 from '../images/test14.jpeg';
import jpg15 from '../images/test15.jpeg';
import jpg16 from '../images/test16.jpeg';
import jpg17 from '../images/test17.jpeg';
import jpg23 from '../images/test23-2.png';
import jpg23_2 from '../images/test23-2.png';
import jpg26 from '../images/test26.jpeg';
import jpg27 from '../images/test27.jpeg';
const gClientId = '989064674711-ihqe4b7sbvl8u3lu4f8jp68hvuo9hget.apps.googleusercontent.com';

export default class Login extends Component {

    constructor(props){
        super(props);
        //console.log("login 1st time props ::"+props);
        this.state={
            username:'',
            password:'',
            hasLoginFail:false,
            showSuccessMessage:false,
            modalshow: false,
            login_hide_show:false,
            dynamicheight:0,
            dynamicwidth:0,
            bookalbumdisplayflag:true
        }
        this.handleChange=this.handleChange.bind(this);
        this.loginClicked=this.loginClicked.bind(this);
        this.showModal = this.showModal.bind(this);
        this.hideModal = this.hideModal.bind(this);
        //this.handlgeGoogleSuccessSignIn = this.handlgeGoogleSuccessSignIn.bind();
        this.assigncustomername=this.assigncustomername.bind(this);
        this.assigncustomercredentials=this.assigncustomercredentials.bind(this);
        this.onSuccess=this.onSuccess.bind(this);
        this.loginbuttonhide = this.loginbuttonhide.bind(this);
        this.loginbuttonshow=this.loginbuttonshow.bind(this);
        this.closeBookAlbum=this.closeBookAlbum.bind(this);
        
    }

    componentDidMount = (props) => {
            // //console.log(this.props);
            //console.log("login component didmount:::");
            
              document.addEventListener('contextmenu', (e) => {
                e.preventDefault();
            });
        AuthenticateService.ClearFlagsforLogin();
        
          
        };
        closeBookAlbum(){
                this.setState({bookalbumdisplayflag : false});
        }
        loginbuttonhide(){
            this.setState({login_hide_show : false});
        }

        loginbuttonshow(){
            this.setState({login_hide_show : true});
        }

       onSuccess = (res) => {
          

           //console.log('Login Success: currentUser:', res.profileObj);
            //alert(`Logged in successfully welcome ${res.profileObj.name} 😍. \n `);
        
           // refreshTokenSetup(res);
            this.handlgeGoogleSuccessSignIn(res);
       };
       onFailure = (res) => {
        //console.log('Login failed: res:', res);
        //alert(`Failed to login. 😢` );
        
      };

        handleChange(event){
            // //console.log("handlechange-"+event.target.name+"handleclick-"+event.target.value);
            this.setState (
                {
                    [event.target.name]
                        : event.target.value
                }
            )
        }

        assigncustomername(value){
            // //console.log("handlechange-"+event.target.name+"handleclick-"+event.target.value);
            this.setState (
                {
                    username : value }
            )
        }
            assigncustomercredentials(value){
            // //console.log("handlechange-"+event.target.name+"handleclick-"+event.target.value);
            this.setState (
                {
                    password : value
                }
            )
        }
            

        handlgeGoogleSuccessSignIn(res){
            //console.log("login success");
            AuthenticationService.registerSuccessfulLoginThirdPartyForJwt(this.state.username, "45678987654312345afasfmad","google");
            
            this.setState({ showSuccessMessage: true });
            this.setState({ hasLoginFailed: false });
            //console.log("loginjs props::"+this.props.data);
                AuthenticateService.saveUser( this.formatUserobject(res.profileObj) );
            this.props.updateGoogleUserdata(res.profileObj);
            //this.props.history.push(`/dashboard/home`);  commented temporarly to display html in dashboard
                this.props.history.push(`/Dashboard`); 
            //this.props.history.push("/display")
        }

        formatUserobject(resProfObj){
            const saveUserOobj={
                            googleUserMailId: resProfObj.email,
                            googleuserFistName: resProfObj.name,
                            googleUserLastName: resProfObj.givenName,
                            googleUserImage: resProfObj.imageUrl 

            }
        return saveUserOobj;
        }

        showModal = () => {
        this.setState({ modalshow: true });
        };

        hideModal = () => {
        this.setState({ modalshow: false });
        };

        loginClicked(event){
        event.preventDefault();
        //console.log("in login clicked");
        //debugger;
        const usrname=this.state.username;
        const pwd=this.state.password;
        const myHeaders = new Headers();
        const auth = "Basic "+btoa(this.state.username+":"+this.state.password);
        myHeaders.append("Authorization", auth);
        const raw = "";
        const requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: raw,
            redirect: 'follow'
            };



        if( AuthenticationService
            .executestaticpassword(this.state.username, this.state.password)){
                //console.log("login success");
            AuthenticationService.registerSuccessfulLoginForJwt(this.state.username, "45678987654312345afasfmad");
            this.setState({ showSuccessMessage: true });
            this.setState({ hasLoginFailed: false });
            const userobj = {    
                                googleUserImage: 'testImage', 
                                googleUserMailId: 'user@nirupanaidu.com', 
                                googleuserFistName: 'userfirstname', 
                                googleUserLastName: 'userlastname'
                                }
            AuthenticateService.saveUser(userobj);
            this.props.updateGoogleUserdata(userobj);
            //this.props.history.push(`/dashboard/home`);  commented temporarly to display html in dashboard
            this.props.history.push(`/Dashboard`); 
            //this.props.history.push("/display");
            }

        }
    

  
    render() {
     
        //console.log("login js");
      const imageUrl = window.innerWidth >= 650 ? desktopImage : mobileImage;
      const  loginformClassName = this.state.login_hide_show ? "display-block" : "display-none";
      const  displaybookalbum = this.state.bookalbumdisplayflag ? "display-block" : "display-none";

     {/* including in login js itself instead of calling some other palce */}
    {/*dynamic height and width */}

  

   /* const testComponent = () => {
        const { height, width } = useWindowDimensions();
        this.setState({dynamicheight:{height}});
        this.setState({dynamicwidth:{width}});
     
        return (
            <div>
            width: {width} ~ height: {height}
            </div>
        );
    };

      var bkimageDivStyle={
                    width:this.state.dynamicheight+"px",
                    height:this.state.dynamicheight+"px",
                    backgroundImage: `url(${imageUrl})`

                };
    console.log("dynamicheight:"+this.state.dynamicheight);
       console.log("dynamicwidth:"+this.state.dynamicwidth)
  */


        return (
            <>
              
            <style>{`
            .line{
                background-color:white; 
                padding-left:20px; 
                padding-right:20px; 
                max-width:550px; 
                margin:auto; 
                border-radius:5px;
                padding-bottom:5px; 
                text-align:left; 
                margin-bottom:40px;
                width:35%;
            }
            .center {
                display: flex;
                justify-content: center;
                align-items: center;
            }
            
          `}</style>


        <section >
 
 <Carousel variant="outline-danger" autoPlay interval="15000"  indicators={true} fade={true}>

 <Carousel.Item  >
				  
                  <section >
                 
                       <Navbar bg="bg-transparent" variant="light" expand="lg" id="officebuilding">
                           
                       
                                            <Navbar.Brand href="#" style={{zindex:"1"}} >
                                            
                                          
                                           <div style={{display:"flex"}} >
                                          
                                            {/* <div id="circle"  >
                                                       <h1 style={{ padding: "1.5em"}}></h1>
                                                   </div> */}
                                                       {/* <div style={{width:"20%",zindex:"1"}} id="walkinglioncircle">
                                                       
                                                        <h1 style={{ padding: "5em"}}></h1>
                                                       
                                                       </div> */}
                                               
                                                       {/* <div id="officescreen-rupa" style={{zindex:"1"}} >
                                                       <h1 style={{ padding: "4em"}}></h1>
                                                             
                                                        </div> */}
                                                       
           
                                               </div>   
                                            </Navbar.Brand>
                                            {/* <Navbar.Toggle aria-controls="basic-navbar-nav" /> */}
                                            {/* <Navbar.Collapse id="basic-navbar-nav"> */}
                                            <Nav className="mr-auto "  style={{zindex:"90"}}>                                      
                                              
{/*                                                        
                                            <div id="humming-bird"  >
                                                                <h1 style={{ padding: "3em"}}></h1>
                                                                </div>        */}
                                                       
                                                    <Nav.Link href="#"> 
                                                       </Nav.Link>
                                               </Nav>                  
                                               <Nav className="mr-auto" > 
                                                                             
                                                          
                                                                
                                                       <div >    
                                                                <div style={{height:"10%",zindex:"1"}} id="officescreen-row">
            
                                                                    <Nav.Link href="#" onClick={this.loginbuttonshow.bind(this)} style={{color:'#a600ff',font: '1em', zindex: '5000'} }  className="LoginMenuLinksText ">  Login
                                                                        {/* <button type="submit" className="btn btn-primary btn-block"   className="LoginButton-availability"
                                                                                        style={{color:"blue"}} onClick={this.loginbuttonshow.bind(this)} >  
                                                                                        <b className="textloginbutton" id="offset">Login </b>
                                                                            </button>*/}
                                                                        </Nav.Link>
                                                                        <Nav.Link href="#" style={{color:'#a600ff',font: '1em'}}>Solutions</Nav.Link>
                                                                        <Nav.Link href="#" style={{color:'#a600ff',font: '1em'}}>Products</Nav.Link>
                                                                        <Nav.Link href="#" style={{color:'#a600ff',font: '1em'}}>Carees</Nav.Link>
                                                                        {/* <Nav.Link href="#" style={{color:'#a600ff',font: '1em'}}>Webinars</Nav.Link> 
                                                                        <Nav.Link href="#" style={{color:'#a600ff',font: '1em'}}>Events</Nav.Link>
                                                                        <Nav.Link href="#" style={{color:'#a600ff',font: '1em'}}>Newsroom</Nav.Link>  */}
                                                                        <Nav.Link href="#" style={{color:'#a600ff',font: '1em'}}>Global Contacts</Nav.Link>                                                                                   
                                                                        <Nav.Link href="#" onClick={this.loginbuttonshow.bind(this)} style={{color:'#a600ff',font: '1em', zindex: '5000'} }  className="LoginMenuLinksText ">  Login
                                                                        {/* <button type="submit" className="btn btn-primary btn-block"   className="LoginButton-availability"
                                                                                        style={{color:"blue"}} onClick={this.loginbuttonshow.bind(this)} >  
                                                                                        <b className="textloginbutton" id="offset">Login </b>
                                                                            </button>*/}
                                                                        </Nav.Link>  
                                                                             
                                                                    </div>
                                                                    <div style={{marginLeft:"30vw"}}> 
                                                                                <ul >
                                                                                <li >
                                                                            <a className='list-item' >
                                                                                <Nav.Link href="#" onClick={this.loginbuttonshow.bind(this)} style={{color:'white', zindex: '99'} }  className="LoginMenuLinksText LoginButton-availability">  
                                                                                        <i className='icon-th-large L'><span >Login</span></i>
                                                                                </Nav.Link>
                                                                            </a>
                                                                            </li>

                                                                            <li>
                                                                                
                                                                                <a className='list-item' >
                                                                                <Nav.Link href="#" style={{color:'white',font: '1em'}}>
                                                                                    <i className='icon-reorder'>Solutions</i>
                                                                                </Nav.Link>
                                                                                </a>
                                                                            </li>
                                    
                                                                            <li>
                                
                                                                                <a className='list-item' >
                                                                                <Nav.Link href="#" style={{color:'white',font: '1em'}}>
                                                                                    <i className='icon-reorder'>Products</i>
                                                                                </Nav.Link>
                                                                                </a>
                                                                            </li>
                                                                            
                                                                            <li>
                                
                                                                                <a className='list-item' >
                                                                                <Nav.Link href="#" style={{color:'white',font: '1em'}}>
                                                                                    <i className='icon-reorder'>Carees</i>
                                                                                </Nav.Link>
                                                                                </a>
                                                                            </li>
                                                                            
                                                                            <li>
                                
                                                                                <a className='list-item' >
                                                                                <Nav.Link href="#" style={{color:'white',font: '1em'}}>
                                                                                    <i className='icon-reorder'>Webinars</i>
                                                                                </Nav.Link>
                                                                                </a>
                                                                            </li>
                                                                            <li>
                                
                                                                                <a className='list-item' >
                                                                                <Nav.Link href="#" style={{color:'white',font: '1em'}}>
                                                                                        <i className='icon-reorder'>Events</i>
                                                                                </Nav.Link>
                                                                                </a>
                                                                            </li>
                                                                            <li>
                                
                                                                                <a className='list-item' >
                                                                                <Nav.Link href="#" style={{color:'white',font: '1em'}}>
                                                                                    <i className='icon-reorder'>Newsroom</i>
                                                                                </Nav.Link>
                                                                                </a>
                                                                            </li>
                                                                            <li>
                                
                                                                                <a className='list-item' >
                                                                                <Nav.Link href="#" style={{color:'white',font: '1em'}}>
                                                                                        <i className='icon-reorder'>Global Contacts</i>
                                                                                </Nav.Link>
                                                                                </a>
                                                                            </li>
                                                                                <li>
                                
                                                                                <a className='list-item' >
                                                                                <Nav.Link href="#" style={{color:'white',font: '1em'}}>
                                                                                    <i className='icon-reorder'>Travel</i>
                                                                                </Nav.Link>
                                                                                </a>
                                                                            </li>
                                
                                                                                <li>
                                
                                                                                <a className='list-item' >
                                                                                <Nav.Link href="#" style={{color:'white',font: '1em'}}>
                                                                                    <i className='icon-reorder'>Health</i>
                                                                                </Nav.Link>
                                                                                </a>
                                                                            </li>
                                                                            
                                                                                <li >
                                                                            <a className='list-item' >
                                                                                <Nav.Link href="#" onClick={this.loginbuttonshow.bind(this)} style={{color:'white', zindex: '99'} }  className="LoginMenuLinksText LoginButton-availability">  
                                                                                        <i className='icon-th-large L'><span >Login</span></i>
                                                                                </Nav.Link>
                                                                            </a>
                                                                            </li>
                                                                          
                                                                        </ul>  
                                                                        </div>  
                                                                          
                                                            </div>
           
                                                             

                                                       
                                               </Nav>
                                                               
                                               
                                               
                                           {/* </Navbar.Collapse> */}
                                        
                       </Navbar>

                        
                   </section>
                           
                               <Carousel.Caption>
                                  
                               </Carousel.Caption>
                               
                   </Carousel.Item>
                   <Carousel.Item  >
				  
   
                  <section >
                     
                       <Navbar bg="bg-transparent" variant="light" expand="lg" id="officebuilding3">
                       
                                            <Navbar.Brand href="#" style={{zindex:"1"}} >
                                            
                                          
                                           <div style={{display:"flex"}} >
                                          
                                            {/* <div id="circle"  >
                                                       <h1 style={{ padding: "1.5em"}}></h1>
                                                   </div> */}
                                                       {/* <div style={{width:"20%",zindex:"1"}} id="walkinglioncircle">
                                                       
                                                        <h1 style={{ padding: "5em"}}></h1>
                                                       
                                                       </div> */}
                                               
                                                       {/* <div id="officescreen-rupa" style={{zindex:"1"}} >
                                                       <h1 style={{ padding: "4em"}}></h1>
                                                             
                                                        </div> */}
                                                       
           
                                               </div>   
                                            </Navbar.Brand>
                                            {/* <Navbar.Toggle aria-controls="basic-navbar-nav" /> */}
                                            {/* <Navbar.Collapse id="basic-navbar-nav"> */}
                                            <Nav className="mr-auto "  style={{zindex:"90"}}>                                      
                                              
{/*                                                        
                                            <div id="humming-bird"  >
                                                                <h1 style={{ padding: "3em"}}></h1>
                                                                </div>        */}
                                                       
                                                    <Nav.Link href="#"> 
                                                       </Nav.Link>
                                               </Nav>                  
                                               <Nav className="mr-auto" > 
                                                                             
                                                          
                                                                
                                                       <div >    
                                                                <div style={{height:"10%",zindex:"1"}} id="officescreen-row">
            
                                                                    <Nav.Link href="#" onClick={this.loginbuttonshow.bind(this)} style={{color:'#a600ff',font: '1em', zindex: '5000'} }  className="LoginMenuLinksText ">  Login
                                                                        {/* <button type="submit" className="btn btn-primary btn-block"   className="LoginButton-availability"
                                                                                        style={{color:"blue"}} onClick={this.loginbuttonshow.bind(this)} >  
                                                                                        <b className="textloginbutton" id="offset">Login </b>
                                                                            </button>*/}
                                                                        </Nav.Link>
                                                                        <Nav.Link href="#" style={{color:'#a600ff',font: '1em'}}>Solutions</Nav.Link>
                                                                        <Nav.Link href="#" style={{color:'#a600ff',font: '1em'}}>Products</Nav.Link>
                                                                        <Nav.Link href="#" style={{color:'#a600ff',font: '1em'}}>Carees</Nav.Link>
                                                                        {/* <Nav.Link href="#" style={{color:'#a600ff',font: '1em'}}>Webinars</Nav.Link> 
                                                                        <Nav.Link href="#" style={{color:'#a600ff',font: '1em'}}>Events</Nav.Link>
                                                                        <Nav.Link href="#" style={{color:'#a600ff',font: '1em'}}>Newsroom</Nav.Link>  */}
                                                                        <Nav.Link href="#" style={{color:'#a600ff',font: '1em'}}>Global Contacts</Nav.Link>                                                                                   
                                                                        <Nav.Link href="#" onClick={this.loginbuttonshow.bind(this)} style={{color:'#a600ff',font: '1em', zindex: '5000'} }  className="LoginMenuLinksText ">  Login
                                                                        {/* <button type="submit" className="btn btn-primary btn-block"   className="LoginButton-availability"
                                                                                        style={{color:"blue"}} onClick={this.loginbuttonshow.bind(this)} >  
                                                                                        <b className="textloginbutton" id="offset">Login </b>
                                                                            </button>*/}
                                                                        </Nav.Link>  
                                                                             
                                                                    </div>
                                                                    <div style={{marginLeft:"30vw"}}> 
                                                                                <ul >
                                                                                <li >
                                                                            <a className='list-item' >
                                                                                <Nav.Link href="#" onClick={this.loginbuttonshow.bind(this)} style={{color:'white', zindex: '99'} }  className="LoginMenuLinksText LoginButton-availability">  
                                                                                        <i className='icon-th-large L'><span >Login</span></i>
                                                                                </Nav.Link>
                                                                            </a>
                                                                            </li>

                                                                            <li>
                                                                                
                                                                                <a className='list-item' >
                                                                                <Nav.Link href="#" style={{color:'white',font: '1em'}}>
                                                                                    <i className='icon-reorder'>Solutions</i>
                                                                                </Nav.Link>
                                                                                </a>
                                                                            </li>
                                    
                                                                            <li>
                                
                                                                                <a className='list-item' >
                                                                                <Nav.Link href="#" style={{color:'white',font: '1em'}}>
                                                                                    <i className='icon-reorder'>Products</i>
                                                                                </Nav.Link>
                                                                                </a>
                                                                            </li>
                                                                            
                                                                            <li>
                                
                                                                                <a className='list-item' >
                                                                                <Nav.Link href="#" style={{color:'white',font: '1em'}}>
                                                                                    <i className='icon-reorder'>Carees</i>
                                                                                </Nav.Link>
                                                                                </a>
                                                                            </li>
                                                                            
                                                                            <li>
                                
                                                                                <a className='list-item' >
                                                                                <Nav.Link href="#" style={{color:'white',font: '1em'}}>
                                                                                    <i className='icon-reorder'>Webinars</i>
                                                                                </Nav.Link>
                                                                                </a>
                                                                            </li>
                                                                            <li>
                                
                                                                                <a className='list-item' >
                                                                                <Nav.Link href="#" style={{color:'white',font: '1em'}}>
                                                                                        <i className='icon-reorder'>Events</i>
                                                                                </Nav.Link>
                                                                                </a>
                                                                            </li>
                                                                            <li>
                                
                                                                                <a className='list-item' >
                                                                                <Nav.Link href="#" style={{color:'white',font: '1em'}}>
                                                                                    <i className='icon-reorder'>Newsroom</i>
                                                                                </Nav.Link>
                                                                                </a>
                                                                            </li>
                                                                            <li>
                                
                                                                                <a className='list-item' >
                                                                                <Nav.Link href="#" style={{color:'white',font: '1em'}}>
                                                                                        <i className='icon-reorder'>Global Contacts</i>
                                                                                </Nav.Link>
                                                                                </a>
                                                                            </li>
                                                                                <li>
                                
                                                                                <a className='list-item' >
                                                                                <Nav.Link href="#" style={{color:'white',font: '1em'}}>
                                                                                    <i className='icon-reorder'>Travel</i>
                                                                                </Nav.Link>
                                                                                </a>
                                                                            </li>
                                
                                                                                <li>
                                
                                                                                <a className='list-item' >
                                                                                <Nav.Link href="#" style={{color:'white',font: '1em'}}>
                                                                                    <i className='icon-reorder'>Health</i>
                                                                                </Nav.Link>
                                                                                </a>
                                                                            </li>
                                                                            
                                                                                <li >
                                                                            <a className='list-item' >
                                                                                <Nav.Link href="#" onClick={this.loginbuttonshow.bind(this)} style={{color:'white', zindex: '99'} }  className="LoginMenuLinksText LoginButton-availability">  
                                                                                        <i className='icon-th-large L'><span >Login</span></i>
                                                                                </Nav.Link>
                                                                            </a>
                                                                            </li>
                                                                          
                                                                        </ul>  
                                                                        </div>  
                                                                          
                                                            </div>
           
                                                             

                                                       
                                               </Nav>
                                                               
                                               
                                               
                                           {/* </Navbar.Collapse> */}
                                        
                       </Navbar>

                        
                   </section>
                           
                               <Carousel.Caption>
                                  
                               </Carousel.Caption>
                               
                   </Carousel.Item>

              	 <Carousel.Item  >
				  
						   
                  <section>
                       <Navbar bg="bg-transparent" variant="light" expand="lg" id="officebuilding2">
                       
                                            <Navbar.Brand href="#" style={{zindex:"1"}} >
                                            
                                          
                                           <div style={{display:"flex"}} >
                                          
                                            {/* <div id="circle"  >
                                                       <h1 style={{ padding: "1.5em"}}></h1>
                                                   </div> */}
                                                       {/* <div style={{width:"20%",zindex:"1"}} id="walkinglioncircle">
                                                       
                                                        <h1 style={{ padding: "5em"}}></h1>
                                                       
                                                       </div> */}
                                               
                                                       {/* <div id="officescreen-rupa" style={{zindex:"1"}} >
                                                       <h1 style={{ padding: "4em"}}></h1>
                                                             
                                                        </div> */}
                                                       
           
                                               </div>   
                                            </Navbar.Brand>
                                            {/* <Navbar.Toggle aria-controls="basic-navbar-nav" /> */}
                                            {/* <Navbar.Collapse id="basic-navbar-nav"> */}
                                            <Nav className="mr-auto "  style={{zindex:"90"}}>                                      
                                              
                                                       
                                            {/* <div id="humming-bird"  >
                                                                <h1 style={{ padding: "3em"}}></h1>
                                                                </div>        */}
                                                       
                                                    <Nav.Link href="#"> 
                                                       </Nav.Link>
                                               </Nav>                  
                                               <Nav className="mr-auto" > 
                                                                             
                                                          
                                                                
                                                       <div >    
                                                                <div style={{height:"10%",zindex:"1"}} id="officescreen-row">
            
                                                                    <Nav.Link href="#" onClick={this.loginbuttonshow.bind(this)} style={{color:'#a600ff',font: '1em', zindex: '5000'} }  className="LoginMenuLinksText ">  Login
                                                                        {/* <button type="submit" className="btn btn-primary btn-block"   className="LoginButton-availability"
                                                                                        style={{color:"blue"}} onClick={this.loginbuttonshow.bind(this)} >  
                                                                                        <b className="textloginbutton" id="offset">Login </b>
                                                                            </button>*/}
                                                                        </Nav.Link>
                                                                        <Nav.Link href="#" style={{color:'#a600ff',font: '1em'}}>Solutions</Nav.Link>
                                                                        <Nav.Link href="#" style={{color:'#a600ff',font: '1em'}}>Products</Nav.Link>
                                                                        <Nav.Link href="#" style={{color:'#a600ff',font: '1em'}}>Carees</Nav.Link>
                                                                        {/* <Nav.Link href="#" style={{color:'#a600ff',font: '1em'}}>Webinars</Nav.Link> 
                                                                        <Nav.Link href="#" style={{color:'#a600ff',font: '1em'}}>Events</Nav.Link>
                                                                        <Nav.Link href="#" style={{color:'#a600ff',font: '1em'}}>Newsroom</Nav.Link>  */}
                                                                        <Nav.Link href="#" style={{color:'#a600ff',font: '1em'}}>Global Contacts</Nav.Link>                                                                                   
                                                                        <Nav.Link href="#" onClick={this.loginbuttonshow.bind(this)} style={{color:'#a600ff',font: '1em', zindex: '5000'} }  className="LoginMenuLinksText ">  Login
                                                                        {/* <button type="submit" className="btn btn-primary btn-block"   className="LoginButton-availability"
                                                                                        style={{color:"blue"}} onClick={this.loginbuttonshow.bind(this)} >  
                                                                                        <b className="textloginbutton" id="offset">Login </b>
                                                                            </button>*/}
                                                                        </Nav.Link>  
                                                                             
                                                                    </div>
                                                                    <div style={{marginLeft:"30vw"}}> 
                                                                                <ul >
                                                                                <li >
                                                                            <a className='list-item' >
                                                                                <Nav.Link href="#" onClick={this.loginbuttonshow.bind(this)} style={{color:'white', zindex: '99'} }  className="LoginMenuLinksText LoginButton-availability">  
                                                                                        <i className='icon-th-large L'><span >Login</span></i>
                                                                                </Nav.Link>
                                                                            </a>
                                                                            </li>

                                                                            <li>
                                                                                
                                                                                <a className='list-item' >
                                                                                <Nav.Link href="#" style={{color:'white',font: '1em'}}>
                                                                                    <i className='icon-reorder'>Solutions</i>
                                                                                </Nav.Link>
                                                                                </a>
                                                                            </li>
                                    
                                                                            <li>
                                
                                                                                <a className='list-item' >
                                                                                <Nav.Link href="#" style={{color:'white',font: '1em'}}>
                                                                                    <i className='icon-reorder'>Products</i>
                                                                                </Nav.Link>
                                                                                </a>
                                                                            </li>
                                                                            
                                                                            <li>
                                
                                                                                <a className='list-item' >
                                                                                <Nav.Link href="#" style={{color:'white',font: '1em'}}>
                                                                                    <i className='icon-reorder'>Carees</i>
                                                                                </Nav.Link>
                                                                                </a>
                                                                            </li>
                                                                            
                                                                            <li>
                                
                                                                                <a className='list-item' >
                                                                                <Nav.Link href="#" style={{color:'white',font: '1em'}}>
                                                                                    <i className='icon-reorder'>Webinars</i>
                                                                                </Nav.Link>
                                                                                </a>
                                                                            </li>
                                                                            <li>
                                
                                                                                <a className='list-item' >
                                                                                <Nav.Link href="#" style={{color:'white',font: '1em'}}>
                                                                                        <i className='icon-reorder'>Events</i>
                                                                                </Nav.Link>
                                                                                </a>
                                                                            </li>
                                                                            <li>
                                
                                                                                <a className='list-item' >
                                                                                <Nav.Link href="#" style={{color:'white',font: '1em'}}>
                                                                                    <i className='icon-reorder'>Newsroom</i>
                                                                                </Nav.Link>
                                                                                </a>
                                                                            </li>
                                                                            <li>
                                
                                                                                <a className='list-item' >
                                                                                <Nav.Link href="#" style={{color:'white',font: '1em'}}>
                                                                                        <i className='icon-reorder'>Global Contacts</i>
                                                                                </Nav.Link>
                                                                                </a>
                                                                            </li>
                                                                                <li>
                                
                                                                                <a className='list-item' >
                                                                                <Nav.Link href="#" style={{color:'white',font: '1em'}}>
                                                                                    <i className='icon-reorder'>Travel</i>
                                                                                </Nav.Link>
                                                                                </a>
                                                                            </li>
                                
                                                                                <li>
                                
                                                                                <a className='list-item' >
                                                                                <Nav.Link href="#" style={{color:'white',font: '1em'}}>
                                                                                    <i className='icon-reorder'>Health</i>
                                                                                </Nav.Link>
                                                                                </a>
                                                                            </li>
                                                                            
                                                                                <li >
                                                                            <a className='list-item' >
                                                                                <Nav.Link href="#" onClick={this.loginbuttonshow.bind(this)} style={{color:'white', zindex: '99'} }  className="LoginMenuLinksText LoginButton-availability">  
                                                                                        <i className='icon-th-large L'><span >Login</span></i>
                                                                                </Nav.Link>
                                                                            </a>
                                                                            </li>
                                                                           
                                                                        </ul>  
                                                                        </div>  
                                                                          
                                                            </div>
           
                                                           
                                                       
                                               </Nav>
                                                               
                                               
                                               
                                           {/* </Navbar.Collapse> */}
                                        
                       </Navbar>

                        
                   </section>
                           
                               <Carousel.Caption>
                                  
                               </Carousel.Caption>
                               
                   </Carousel.Item>
                   <Carousel.Item  >
					
                                    
                    <section>
                            <Navbar bg="bg-transparent" variant="light" expand="lg" id="officebuilding4">
                            
                                                <Navbar.Brand href="#" style={{zindex:"1"}} >
                                 
                                 {/*  &copy;
                                          <img
                                        style={{height: '10%',width: '10%'}}
                                            src={jpg23}
                                            alt="First slide"
                                         />
                              
                                 
                                    <label id="offsetbrand" className="LoginMenutextbrand  spinbrand">
                                        Nirupa Naidu <br/>Enterprises
                                    </label> &nbsp;&nbsp;&nbsp;
                               
                                &copy;
                                 
                                
                                <div className="flip-box">
                                    <div className="flip-box-inner">
                                        <div className="flip-box-front">
                                           <img
                                        style={{height: '70px%',width: '70px'}}
                                            src={jpg23}
                                            alt="First slide"
                                         />
                                        </div>
                                        <div className="flip-box-back">
                                        <h2>Together</h2>
                                        <p>We can do it!</p>
                                        </div>
                                    </div>
                                    </div>
                                 */}
                                {/* <div  id="walkinglioncircle" >
                                                    <h1 style={{ padding: "4em"}}></h1>
                                                                                        

                                                                                
                                  </div> */}
                                <div style={{display:"flex"}} >
                               
                                 {/* <div id="circle"  >
                                            <h1 style={{ padding: "1.5em"}}></h1>
                                        </div> */}
                                            {/* <div style={{width:"20%",zindex:"1"}} id="walkinglioncircle">
                                            
                                             <h1 style={{ padding: "5em"}}></h1>
                                            
                                            </div> */}
                                    
                                            {/* <div id="officebuilding4-rupa" style={{zindex:"1"}} >
                                            <h1 style={{ padding: "3em"}}></h1>
                                           
                                             </div> */}
                                            
                                        
                                    </div>   
                                 </Navbar.Brand>
                                 {/* <Navbar.Toggle aria-controls="basic-navbar-nav" /> */}
                                 {/* <Navbar.Collapse id="basic-navbar-nav"> */}
                                 <Nav className="mr-auto "  style={{zindex:"90"}}>                                      
                                   
                                 
                                             <ul >
                                          
                                            <li>
                                               
                                                <a className='list-item' >
                                                <Nav.Link href="#" style={{color:'white',font: '1em'}}>
                                                    <i className='icon-reorder'>Solutions</i>
                                                </Nav.Link>
                                                </a>
                                            </li>
    
                                            <li>

                                                <a className='list-item' >
                                                <Nav.Link href="#" style={{color:'white',font: '1em'}}>
                                                    <i className='icon-reorder'>Products</i>
                                                </Nav.Link>
                                                </a>
                                            </li>
                                            
                                            <li>

                                                <a className='list-item' >
                                                <Nav.Link href="#" style={{color:'white',font: '1em'}}>
                                                    <i className='icon-reorder'>Carees</i>
                                                </Nav.Link>
                                                </a>
                                            </li>
                                            
                                            <li>

                                                <a className='list-item' >
                                                <Nav.Link href="#" style={{color:'white',font: '1em'}}>
                                                    <i className='icon-reorder'>Webinars</i>
                                                </Nav.Link>
                                                </a>
                                            </li>
                                            <li>

                                                <a className='list-item' >
                                                <Nav.Link href="#" style={{color:'white',font: '1em'}}>
                                                     <i className='icon-reorder'>Events</i>
                                                </Nav.Link>
                                                </a>
                                            </li>
                                            <li>

                                                <a className='list-item' >
                                                <Nav.Link href="#" style={{color:'white',font: '1em'}}>
                                                    <i className='icon-reorder'>Newsroom</i>
                                                </Nav.Link>
                                                </a>
                                            </li>
                                            <li>

                                                <a className='list-item' >
                                                <Nav.Link href="#" style={{color:'white',font: '1em'}}>
                                                     <i className='icon-reorder'>Global Contacts</i>
                                                </Nav.Link>
                                                </a>
                                            </li>
                                               <li>

                                                <a className='list-item' >
                                                <Nav.Link href="#" style={{color:'white',font: '1em'}}>
                                                    <i className='icon-reorder'>Travel</i>
                                                </Nav.Link>
                                                </a>
                                            </li>

                                               <li>

                                                <a className='list-item' >
                                                <Nav.Link href="#" style={{color:'white',font: '1em'}}>
                                                    <i className='icon-reorder'>Health</i>
                                                </Nav.Link>
                                                </a>
                                            </li>
                                          
                                             <li >
                                            <a className='list-item' >
                                                <Nav.Link href="#" onClick={this.loginbuttonshow.bind(this)} style={{color:'white', zindex: '99'} }  className="LoginMenuLinksText LoginButton-availability">  
                                                        <i className='icon-th-large L'><span >Login</span></i>
                                                </Nav.Link>
                                            </a>
                                            </li>
                                            
                                        </ul>                     
                                            
                                         <Nav.Link href="#"> 
                                            </Nav.Link>
                                    </Nav>                  
                                    <Nav className="mr-auto" > 
                                                                  
                                               
                                                     <h1 style={{ padding: "2em"}}></h1>
                                                
                                            

                                           <Nav.Link href="#" onClick={this.loginbuttonshow.bind(this)} style={{color:'white',font: '1em', zindex: '5000'} }  className="LoginMenuLinksText ">  Login
                                                {/* <button type="submit" className="btn btn-primary btn-block"   className="LoginButton-availability"
                                                                style={{color:"blue"}} onClick={this.loginbuttonshow.bind(this)} >  
                                                                <b className="textloginbutton" id="offset">Login </b>
                                                    </button>*/}
                                                </Nav.Link>
                                                <Nav.Link href="#" style={{color:'white',font: '1em'}}>Solutions</Nav.Link>
                                                <Nav.Link href="#" style={{color:'white',font: '1em'}}>Products</Nav.Link>
                                                <Nav.Link href="#" style={{color:'white',font: '1em'}}>Carees</Nav.Link>
                                                {/* <Nav.Link href="#" style={{color:'#a600ff',font: '1em'}}>Webinars</Nav.Link> 
                                                <Nav.Link href="#" style={{color:'#a600ff',font: '1em'}}>Events</Nav.Link>
                                                <Nav.Link href="#" style={{color:'#a600ff',font: '1em'}}>Newsroom</Nav.Link>  */}
                                                <Nav.Link href="#" style={{color:'white',font: '1em'}}>Global Contacts</Nav.Link>                                                                                   
                                                <Nav.Link href="#" onClick={this.loginbuttonshow.bind(this)} style={{color:'white',font: '1em', zindex: '5000'} }  className="LoginMenuLinksText ">  Login
                                                {/* <button type="submit" className="btn btn-primary btn-block"   className="LoginButton-availability"
                                                                style={{color:"blue"}} onClick={this.loginbuttonshow.bind(this)} >  
                                                                <b className="textloginbutton" id="offset">Login </b>
                                                    </button>*/}
                                                </Nav.Link>  


                                                 <h1 style={{ padding: "5em"}}></h1>       
                                    </Nav>
                                                    
                                    
                                    
                                {/* </Navbar.Collapse> */}
                             
            </Navbar>
        </section>
					<Carousel.Caption>
                       
                    </Carousel.Caption>
                    
                </Carousel.Item>
                   
				  <Carousel.Item  >
				  
						   
                  <section>
                       <Navbar bg="bg-transparent" variant="light" expand="lg" id="officeenvironment">
                       
                                            <Navbar.Brand href="#" style={{zindex:"1"}} >
                                            
                                          
                                           <div style={{display:"flex"}} >
                                          
                                            {/* <div id="circle"  >
                                                       <h1 style={{ padding: "1.5em"}}></h1>
                                                   </div> */}
                                                       {/* <div style={{width:"20%",zindex:"1"}} id="walkinglioncircle">
                                                       
                                                        <h1 style={{ padding: "5em"}}></h1>
                                                       
                                                       </div> */}
                                               
                                                       {/* <div id="officescreen-rupa" style={{zindex:"1"}} >
                                                       <h1 style={{ padding: "4em"}}></h1>
                                                             
                                                        </div> */}
                                                       
           
                                               </div>   
                                            </Navbar.Brand>
                                            {/* <Navbar.Toggle aria-controls="basic-navbar-nav" /> */}
                                            {/* <Navbar.Collapse id="basic-navbar-nav"> */}
                                            <Nav className="mr-auto "  style={{zindex:"90"}}>                                      
                                              
                                                       
                                            <div id="humming-bird"  >
                                                                <h1 style={{ padding: "1em"}}></h1>
                                                                </div>       
                                                       
                                                    <Nav.Link href="#"> 
                                                       </Nav.Link>
                                               </Nav>                  
                                               <Nav className="mr-auto" > 
                                                                             
                                                          
                                                                
                                                       <div >    
                                                                <div style={{height:"50%",zindex:"1"}} id="officescreen-row">
            
                                                                    <Nav.Link href="#" onClick={this.loginbuttonshow.bind(this)} style={{color:'white',font: '1em', zindex: '5000'} }  className="LoginMenuLinksText ">  Login
                                                                        {/* <button type="submit" className="btn btn-primary btn-block"   className="LoginButton-availability"
                                                                                        style={{color:"blue"}} onClick={this.loginbuttonshow.bind(this)} >  
                                                                                        <b className="textloginbutton" id="offset">Login </b>
                                                                            </button>*/}
                                                                        </Nav.Link>
                                                                        <Nav.Link href="#" style={{color:'white',font: '1em'}}>Solutions</Nav.Link>
                                                                        <Nav.Link href="#" style={{color:'white',font: '1em'}}>Products</Nav.Link>
                                                                        <Nav.Link href="#" style={{color:'white',font: '1em'}}>Carees</Nav.Link>
                                                                        {/* <Nav.Link href="#" style={{color:'#a600ff',font: '1em'}}>Webinars</Nav.Link> 
                                                                        <Nav.Link href="#" style={{color:'#a600ff',font: '1em'}}>Events</Nav.Link>
                                                                        <Nav.Link href="#" style={{color:'#a600ff',font: '1em'}}>Newsroom</Nav.Link>  */}
                                                                        <Nav.Link href="#" style={{color:'white',font: '1em'}}>Global Contacts</Nav.Link>                                                                                   
                                                                        <Nav.Link href="#" onClick={this.loginbuttonshow.bind(this)} style={{color:'white',font: '1em', zindex: '5000'} }  className="LoginMenuLinksText ">  Login
                                                                        {/* <button type="submit" className="btn btn-primary btn-block"   className="LoginButton-availability"
                                                                                        style={{color:"blue"}} onClick={this.loginbuttonshow.bind(this)} >  
                                                                                        <b className="textloginbutton" id="offset">Login </b>
                                                                            </button>*/}
                                                                        </Nav.Link>  
                                                                             
                                                                    </div>
                                                                    <div style={{marginLeft:"30vw"}}> 
                                                                                <ul >
                                                                                <li >
                                                                            <a className='list-item' >
                                                                                <Nav.Link href="#" onClick={this.loginbuttonshow.bind(this)} style={{color:'white', zindex: '99'} }  className="LoginMenuLinksText LoginButton-availability">  
                                                                                        <i className='icon-th-large L'><span >Login</span></i>
                                                                                </Nav.Link>
                                                                            </a>
                                                                            </li>

                                                                            <li>
                                                                                
                                                                                <a className='list-item' >
                                                                                <Nav.Link href="#" style={{color:'white',font: '1em'}}>
                                                                                    <i className='icon-reorder'>Solutions</i>
                                                                                </Nav.Link>
                                                                                </a>
                                                                            </li>
                                    
                                                                            <li>
                                
                                                                                <a className='list-item' >
                                                                                <Nav.Link href="#" style={{color:'white',font: '1em'}}>
                                                                                    <i className='icon-reorder'>Products</i>
                                                                                </Nav.Link>
                                                                                </a>
                                                                            </li>
                                                                            
                                                                            <li>
                                
                                                                                <a className='list-item' >
                                                                                <Nav.Link href="#" style={{color:'white',font: '1em'}}>
                                                                                    <i className='icon-reorder'>Carees</i>
                                                                                </Nav.Link>
                                                                                </a>
                                                                            </li>
                                                                            
                                                                            <li>
                                
                                                                                <a className='list-item' >
                                                                                <Nav.Link href="#" style={{color:'white',font: '1em'}}>
                                                                                    <i className='icon-reorder'>Webinars</i>
                                                                                </Nav.Link>
                                                                                </a>
                                                                            </li>
                                                                            <li>
                                
                                                                                <a className='list-item' >
                                                                                <Nav.Link href="#" style={{color:'white',font: '1em'}}>
                                                                                        <i className='icon-reorder'>Events</i>
                                                                                </Nav.Link>
                                                                                </a>
                                                                            </li>
                                                                            <li>
                                
                                                                                <a className='list-item' >
                                                                                <Nav.Link href="#" style={{color:'white',font: '1em'}}>
                                                                                    <i className='icon-reorder'>Newsroom</i>
                                                                                </Nav.Link>
                                                                                </a>
                                                                            </li>
                                                                            <li>
                                
                                                                                <a className='list-item' >
                                                                                <Nav.Link href="#" style={{color:'white',font: '1em'}}>
                                                                                        <i className='icon-reorder'>Global Contacts</i>
                                                                                </Nav.Link>
                                                                                </a>
                                                                            </li>
                                                                                <li>
                                
                                                                                <a className='list-item' >
                                                                                <Nav.Link href="#" style={{color:'white',font: '1em'}}>
                                                                                    <i className='icon-reorder'>Travel</i>
                                                                                </Nav.Link>
                                                                                </a>
                                                                            </li>
                                
                                                                                <li>
                                
                                                                                <a className='list-item' >
                                                                                <Nav.Link href="#" style={{color:'white',font: '1em'}}>
                                                                                    <i className='icon-reorder'>Health</i>
                                                                                </Nav.Link>
                                                                                </a>
                                                                            </li>
                                                                            
                                                                                <li >
                                                                            <a className='list-item' >
                                                                                <Nav.Link href="#" onClick={this.loginbuttonshow.bind(this)} style={{color:'white', zindex: '99'} }  className="LoginMenuLinksText LoginButton-availability">  
                                                                                        <i className='icon-th-large L'><span >Login</span></i>
                                                                                </Nav.Link>
                                                                            </a>
                                                                            </li>
                                                                            <h1 style={{ padding: "2em"}}></h1> 
                                                                        </ul>  
                                                                        </div>  
                                                                          
                                                            </div>
           
                                                          

                                                       
                                               </Nav>
                                                               
                                               
                                               
                                           {/* </Navbar.Collapse> */}
                                        
                       </Navbar>

                        
                   </section>
                           
                               <Carousel.Caption>
                                  
                               </Carousel.Caption>
                               
                   </Carousel.Item>
                  

             

                <Carousel.Item  >
					
                                    
                    <section>
                            <Navbar bg="bg-transparent" variant="light" expand="lg" id="sittingLion">
                            
                                                <Navbar.Brand href="#" style={{zindex:"1"}} >
                                 
                                 {/*  &copy;
                                          <img
                                        style={{height: '10%',width: '10%'}}
                                            src={jpg23}
                                            alt="First slide"
                                         />
                              
                                 
                                    <label id="offsetbrand" className="LoginMenutextbrand  spinbrand">
                                        Nirupa Naidu <br/>Enterprises
                                    </label> &nbsp;&nbsp;&nbsp;
                               
                                &copy;
                                 
                                
                                <div className="flip-box">
                                    <div className="flip-box-inner">
                                        <div className="flip-box-front">
                                           <img
                                        style={{height: '70px%',width: '70px'}}
                                            src={jpg23}
                                            alt="First slide"
                                         />
                                        </div>
                                        <div className="flip-box-back">
                                        <h2>Together</h2>
                                        <p>We can do it!</p>
                                        </div>
                                    </div>
                                    </div>
                                 */}
                                {/* <div  id="walkinglioncircle" >
                                                    <h1 style={{ padding: "4em"}}></h1>
                                                                                        

                                                                                
                                  </div> */}
                                <div style={{display:"flex"}} >
                               
                                 {/* <div id="circle"  >
                                            <h1 style={{ padding: "1.5em"}}></h1>
                                        </div> */}
                                            <div style={{width:"20%",zindex:"1"}} id="walkinglioncircle">
                                            
                                             <h1 style={{ padding: "5em"}}></h1>
                                            
                                            </div>
                                    
                                            <div id="circle-rupa" style={{zindex:"1"}} >
                                             
                                           <div id="humming-bird"  >
                                             <h1 style={{ padding: "1em"}}></h1>
                                             </div>
                                             </div>
                                            

                                    </div>   
                                 </Navbar.Brand>
                                 {/* <Navbar.Toggle aria-controls="basic-navbar-nav" /> */}
                                 {/* <Navbar.Collapse id="basic-navbar-nav"> */}
                                 <Nav className="mr-auto "  style={{zindex:"90"}}>                                      
                                   
                                            
                                             <ul >
                                          
                                            <li>
                                               
                                                <a className='list-item' >
                                                <Nav.Link href="#" style={{color:'white',font: '1em'}}>
                                                    <i className='icon-reorder'>Solutions</i>
                                                </Nav.Link>
                                                </a>
                                            </li>
    
                                            <li>

                                                <a className='list-item' >
                                                <Nav.Link href="#" style={{color:'white',font: '1em'}}>
                                                    <i className='icon-reorder'>Products</i>
                                                </Nav.Link>
                                                </a>
                                            </li>
                                            
                                            <li>

                                                <a className='list-item' >
                                                <Nav.Link href="#" style={{color:'white',font: '1em'}}>
                                                    <i className='icon-reorder'>Carees</i>
                                                </Nav.Link>
                                                </a>
                                            </li>
                                            
                                            <li>

                                                <a className='list-item' >
                                                <Nav.Link href="#" style={{color:'white',font: '1em'}}>
                                                    <i className='icon-reorder'>Webinars</i>
                                                </Nav.Link>
                                                </a>
                                            </li>
                                            <li>

                                                <a className='list-item' >
                                                <Nav.Link href="#" style={{color:'white',font: '1em'}}>
                                                     <i className='icon-reorder'>Events</i>
                                                </Nav.Link>
                                                </a>
                                            </li>
                                            <li>

                                                <a className='list-item' >
                                                <Nav.Link href="#" style={{color:'white',font: '1em'}}>
                                                    <i className='icon-reorder'>Newsroom</i>
                                                </Nav.Link>
                                                </a>
                                            </li>
                                            <li>

                                                <a className='list-item' >
                                                <Nav.Link href="#" style={{color:'white',font: '1em'}}>
                                                     <i className='icon-reorder'>Global Contacts</i>
                                                </Nav.Link>
                                                </a>
                                            </li>
                                               <li>

                                                <a className='list-item' >
                                                <Nav.Link href="#" style={{color:'white',font: '1em'}}>
                                                    <i className='icon-reorder'>Travel</i>
                                                </Nav.Link>
                                                </a>
                                            </li>

                                               <li>

                                                <a className='list-item' >
                                                <Nav.Link href="#" style={{color:'white',font: '1em'}}>
                                                    <i className='icon-reorder'>Health</i>
                                                </Nav.Link>
                                                </a>
                                            </li>
                                          
                                             <li >
                                            <a className='list-item' >
                                                <Nav.Link href="#" onClick={this.loginbuttonshow.bind(this)} style={{color:'white', zindex: '99'} }  className="LoginMenuLinksText LoginButton-availability">  
                                                        <i className='icon-th-large L'><span >Login</span></i>
                                                </Nav.Link>
                                            </a>
                                            </li>
                                            
                                        </ul>                     
                                            
                                         <Nav.Link href="#"> 
                                            </Nav.Link>
                                    </Nav>                  
                                    <Nav className="mr-auto" > 
                                                                  
                                               
                                                     <h1 style={{ padding: "2em"}}></h1>
                                                
                                            

                                           <Nav.Link href="#" onClick={this.loginbuttonshow.bind(this)} style={{color:'white',font: '1em', zindex: '5000'} }  className="LoginMenuLinksText ">  Login
                                                {/* <button type="submit" className="btn btn-primary btn-block"   className="LoginButton-availability"
                                                                style={{color:"blue"}} onClick={this.loginbuttonshow.bind(this)} >  
                                                                <b className="textloginbutton" id="offset">Login </b>
                                                    </button>*/}
                                                </Nav.Link>
                                                <Nav.Link href="#" style={{color:'white',font: '1em'}}>Solutions</Nav.Link>
                                                <Nav.Link href="#" style={{color:'white',font: '1em'}}>Products</Nav.Link>
                                                <Nav.Link href="#" style={{color:'white',font: '1em'}}>Carees</Nav.Link>
                                                {/* <Nav.Link href="#" style={{color:'#a600ff',font: '1em'}}>Webinars</Nav.Link> 
                                                <Nav.Link href="#" style={{color:'#a600ff',font: '1em'}}>Events</Nav.Link>
                                                <Nav.Link href="#" style={{color:'#a600ff',font: '1em'}}>Newsroom</Nav.Link>  */}
                                                <Nav.Link href="#" style={{color:'white',font: '1em'}}>Global Contacts</Nav.Link>                                                                                   
                                                <Nav.Link href="#" onClick={this.loginbuttonshow.bind(this)} style={{color:'white',font: '1em', zindex: '5000'} }  className="LoginMenuLinksText ">  Login
                                                {/* <button type="submit" className="btn btn-primary btn-block"   className="LoginButton-availability"
                                                                style={{color:"blue"}} onClick={this.loginbuttonshow.bind(this)} >  
                                                                <b className="textloginbutton" id="offset">Login </b>
                                                    </button>*/}
                                                </Nav.Link>  


                                                    
                                    </Nav>
                                                    
                                    
                                    
                                {/* </Navbar.Collapse> */}
                             
            </Navbar>
        </section>
					<Carousel.Caption>
                       
                    </Carousel.Caption>
                    
                </Carousel.Item>
				
				  <Carousel.Item  >
				  
						   
       <section>
            <Navbar bg="bg-transparent" variant="light" expand="lg" id="sittingLion2">
            
                                 <Navbar.Brand href="#" style={{zindex:"1"}} >
                                 
                               
                                <div style={{display:"flex"}} >
                               
                                 {/* <div id="circle"  >
                                            <h1 style={{ padding: "1.5em"}}></h1>
                                        </div> */}
                                            <div style={{width:"20%",zindex:"1"}} id="walkinglioncircle">
                                            
                                             <h1 style={{ padding: "5em"}}></h1>
                                            
                                            </div>
                                    
                                            <div id="circle-rupa" style={{zindex:"1"}} >
                                             
                                           <div id="humming-bird"  >
                                             <h1 style={{ padding: "1em"}}></h1>
                                             </div>
                                             </div>
                                            

                                    </div>   
                                 </Navbar.Brand>
                                 {/* <Navbar.Toggle aria-controls="basic-navbar-nav" /> */}
                                 {/* <Navbar.Collapse id="basic-navbar-nav"> */}
                                 <Nav className="mr-auto "  style={{zindex:"90"}}>                                      
                                   
                                            
                                             <ul >
                                          
                                            <li>
                                               
                                                <a className='list-item' >
                                                <Nav.Link href="#" style={{color:'white',font: '1em'}}>
                                                    <i className='icon-reorder'>Solutions</i>
                                                </Nav.Link>
                                                </a>
                                            </li>
    
                                            <li>

                                                <a className='list-item' >
                                                <Nav.Link href="#" style={{color:'white',font: '1em'}}>
                                                    <i className='icon-reorder'>Products</i>
                                                </Nav.Link>
                                                </a>
                                            </li>
                                            
                                            <li>

                                                <a className='list-item' >
                                                <Nav.Link href="#" style={{color:'white',font: '1em'}}>
                                                    <i className='icon-reorder'>Carees</i>
                                                </Nav.Link>
                                                </a>
                                            </li>
                                            
                                            <li>

                                                <a className='list-item' >
                                                <Nav.Link href="#" style={{color:'white',font: '1em'}}>
                                                    <i className='icon-reorder'>Webinars</i>
                                                </Nav.Link>
                                                </a>
                                            </li>
                                            <li>

                                                <a className='list-item' >
                                                <Nav.Link href="#" style={{color:'white',font: '1em'}}>
                                                     <i className='icon-reorder'>Events</i>
                                                </Nav.Link>
                                                </a>
                                            </li>
                                            <li>

                                                <a className='list-item' >
                                                <Nav.Link href="#" style={{color:'white',font: '1em'}}>
                                                    <i className='icon-reorder'>Newsroom</i>
                                                </Nav.Link>
                                                </a>
                                            </li>
                                            <li>

                                                <a className='list-item' >
                                                <Nav.Link href="#" style={{color:'white',font: '1em'}}>
                                                     <i className='icon-reorder'>Global Contacts</i>
                                                </Nav.Link>
                                                </a>
                                            </li>
                                               <li>

                                                <a className='list-item' >
                                                <Nav.Link href="#" style={{color:'white',font: '1em'}}>
                                                    <i className='icon-reorder'>Travel</i>
                                                </Nav.Link>
                                                </a>
                                            </li>

                                               <li>

                                                <a className='list-item' >
                                                <Nav.Link href="#" style={{color:'white',font: '1em'}}>
                                                    <i className='icon-reorder'>Health</i>
                                                </Nav.Link>
                                                </a>
                                            </li>
                                          
                                             <li >
                                            <a className='list-item' >
                                                <Nav.Link href="#" onClick={this.loginbuttonshow.bind(this)} style={{color:'white', zindex: '99'} }  className="LoginMenuLinksText LoginButton-availability">  
                                                        <i className='icon-th-large L'><span >Login</span></i>
                                                </Nav.Link>
                                            </a>
                                            </li>
                                            
                                        </ul>                     
                                            
                                         <Nav.Link href="#"> 
                                            </Nav.Link>
                                    </Nav>                  
                                    <Nav className="mr-auto" > 
                                                                  
                                               
                                                     <h1 style={{ padding: "2em"}}></h1>
                                                
                                            

                                           <Nav.Link href="#" onClick={this.loginbuttonshow.bind(this)} style={{color:'white',font: '1em', zindex: '5000'} }  className="LoginMenuLinksText ">  Login
                                                {/* <button type="submit" className="btn btn-primary btn-block"   className="LoginButton-availability"
                                                                style={{color:"blue"}} onClick={this.loginbuttonshow.bind(this)} >  
                                                                <b className="textloginbutton" id="offset">Login </b>
                                                    </button>*/}
                                                </Nav.Link>
                                                <Nav.Link href="#" style={{color:'white',font: '1em'}}>Solutions</Nav.Link>
                                                <Nav.Link href="#" style={{color:'white',font: '1em'}}>Products</Nav.Link>
                                                <Nav.Link href="#" style={{color:'white',font: '1em'}}>Carees</Nav.Link>
                                                {/* <Nav.Link href="#" style={{color:'#a600ff',font: '1em'}}>Webinars</Nav.Link> 
                                                <Nav.Link href="#" style={{color:'#a600ff',font: '1em'}}>Events</Nav.Link>
                                                <Nav.Link href="#" style={{color:'#a600ff',font: '1em'}}>Newsroom</Nav.Link>  */}
                                                <Nav.Link href="#" style={{color:'white',font: '1em'}}>Global Contacts</Nav.Link>                                                                                   
                                                <Nav.Link href="#" onClick={this.loginbuttonshow.bind(this)} style={{color:'white',font: '1em', zindex: '5000'} }  className="LoginMenuLinksText ">  Login
                                                {/* <button type="submit" className="btn btn-primary btn-block"   className="LoginButton-availability"
                                                                style={{color:"blue"}} onClick={this.loginbuttonshow.bind(this)} >  
                                                                <b className="textloginbutton" id="offset">Login </b>
                                                    </button>*/}
                                                </Nav.Link>  


                                                    
                                    </Nav>
                                                    
                                    
                                    
                                {/* </Navbar.Collapse> */}
                             
            </Navbar>
        </section>
				
				    <Carousel.Caption>
                       
                    </Carousel.Caption>
                    
        </Carousel.Item>
		<Carousel.Item  >
				  
						   
       <section>
            <Navbar bg="bg-transparent" variant="light" expand="lg" id="sittingLion3">
            
                                 <Navbar.Brand href="#" style={{zindex:"1"}} >
                                 
                               
                                <div style={{display:"flex"}} >
                               
                                 {/* <div id="circle"  >
                                            <h1 style={{ padding: "1.5em"}}></h1>
                                        </div> */}
                                            <div style={{width:"20%",zindex:"1"}} >
                                            
                                             <h1 style={{ padding: "5em"}}></h1>
                                            
                                            </div>
                                    
                                            <div id="circle-rupa" style={{zindex:"1"}} >
                                             
                                           <div id="humming-bird"  >
                                             <h1 style={{ padding: "1em"}}></h1>
                                             </div>
                                             </div>
                                            

                                    </div>   
                                 </Navbar.Brand>
                                 {/* <Navbar.Toggle aria-controls="basic-navbar-nav" /> */}
                                 {/* <Navbar.Collapse id="basic-navbar-nav"> */}
                                 <Nav className="mr-auto "  style={{zindex:"90"}}>                                      
                                   
                                            
                                             <ul >
                                          
                                            <li>
                                               
                                                <a className='list-item' >
                                                <Nav.Link href="#" style={{color:'white',font: '1em'}}>
                                                    <i className='icon-reorder'>Solutions</i>
                                                </Nav.Link>
                                                </a>
                                            </li>
    
                                            <li>

                                                <a className='list-item' >
                                                <Nav.Link href="#" style={{color:'white',font: '1em'}}>
                                                    <i className='icon-reorder'>Products</i>
                                                </Nav.Link>
                                                </a>
                                            </li>
                                            
                                            <li>

                                                <a className='list-item' >
                                                <Nav.Link href="#" style={{color:'white',font: '1em'}}>
                                                    <i className='icon-reorder'>Carees</i>
                                                </Nav.Link>
                                                </a>
                                            </li>
                                            
                                            <li>

                                                <a className='list-item' >
                                                <Nav.Link href="#" style={{color:'white',font: '1em'}}>
                                                    <i className='icon-reorder'>Webinars</i>
                                                </Nav.Link>
                                                </a>
                                            </li>
                                            <li>

                                                <a className='list-item' >
                                                <Nav.Link href="#" style={{color:'white',font: '1em'}}>
                                                     <i className='icon-reorder'>Events</i>
                                                </Nav.Link>
                                                </a>
                                            </li>
                                            <li>

                                                <a className='list-item' >
                                                <Nav.Link href="#" style={{color:'white',font: '1em'}}>
                                                    <i className='icon-reorder'>Newsroom</i>
                                                </Nav.Link>
                                                </a>
                                            </li>
                                            <li>

                                                <a className='list-item' >
                                                <Nav.Link href="#" style={{color:'white',font: '1em'}}>
                                                     <i className='icon-reorder'>Global Contacts</i>
                                                </Nav.Link>
                                                </a>
                                            </li>
                                               <li>

                                                <a className='list-item' >
                                                <Nav.Link href="#" style={{color:'white',font: '1em'}}>
                                                    <i className='icon-reorder'>Travel</i>
                                                </Nav.Link>
                                                </a>
                                            </li>

                                               <li>

                                                <a className='list-item' >
                                                <Nav.Link href="#" style={{color:'white',font: '1em'}}>
                                                    <i className='icon-reorder'>Health</i>
                                                </Nav.Link>
                                                </a>
                                            </li>
                                          
                                             <li >
                                            <a className='list-item' >
                                                <Nav.Link href="#" onClick={this.loginbuttonshow.bind(this)} style={{color:'white', zindex: '99'} }  className="LoginMenuLinksText LoginButton-availability">  
                                                        <i className='icon-th-large L'><span >Login</span></i>
                                                </Nav.Link>
                                            </a>
                                            </li>
                                            
                                        </ul>                     
                                            
                                         <Nav.Link href="#"> 
                                            </Nav.Link>
                                    </Nav>                  
                                    <Nav className="mr-auto" > 
                                                                  
                                               
                                                     <h1 style={{ padding: "2em"}}></h1>
                                                
                                            

                                           <Nav.Link href="#" onClick={this.loginbuttonshow.bind(this)} style={{color:'white',font: '1em', zindex: '5000'} }  className="LoginMenuLinksText ">  Login
                                                {/* <button type="submit" className="btn btn-primary btn-block"   className="LoginButton-availability"
                                                                style={{color:"blue"}} onClick={this.loginbuttonshow.bind(this)} >  
                                                                <b className="textloginbutton" id="offset">Login </b>
                                                    </button>*/}
                                                </Nav.Link>
                                                <Nav.Link href="#" style={{color:'white',font: '1em'}}>Solutions</Nav.Link>
                                                <Nav.Link href="#" style={{color:'white',font: '1em'}}>Products</Nav.Link>
                                                <Nav.Link href="#" style={{color:'white',font: '1em'}}>Carees</Nav.Link>
                                                {/* <Nav.Link href="#" style={{color:'#a600ff',font: '1em'}}>Webinars</Nav.Link> 
                                                <Nav.Link href="#" style={{color:'#a600ff',font: '1em'}}>Events</Nav.Link>
                                                <Nav.Link href="#" style={{color:'#a600ff',font: '1em'}}>Newsroom</Nav.Link>  */}
                                                <Nav.Link href="#" style={{color:'white',font: '1em'}}>Global Contacts</Nav.Link>                                                                                   
                                                <Nav.Link href="#" onClick={this.loginbuttonshow.bind(this)} style={{color:'white',font: '1em', zindex: '5000'} }  className="LoginMenuLinksText ">  Login
                                                {/* <button type="submit" className="btn btn-primary btn-block"   className="LoginButton-availability"
                                                                style={{color:"blue"}} onClick={this.loginbuttonshow.bind(this)} >  
                                                                <b className="textloginbutton" id="offset">Login </b>
                                                    </button>*/}
                                                </Nav.Link>  


                                                 <h1 style={{ padding: "5em"}}></h1>       
                                    </Nav>
                                                    
                                    
                                    
                                {/* </Navbar.Collapse> */}
                           
            </Navbar>

        </section>
				
				    <Carousel.Caption>
                       
                    </Carousel.Caption>
                    
        </Carousel.Item>

         <Carousel.Item  >
					
                                    
                    <section >
                            <Navbar bg="bg-transparent" variant="light" expand="lg" id="lastSection">
                            
                                                <Navbar.Brand href="#" style={{zindex:"1"}} >
                                 
                                 {/*  &copy;
                                          <img
                                        style={{height: '10%',width: '10%'}}
                                            src={jpg23}
                                            alt="First slide"
                                         />
                              
                                 
                                    <label id="offsetbrand" className="LoginMenutextbrand  spinbrand">
                                        Nirupa Naidu <br/>Enterprises
                                    </label> &nbsp;&nbsp;&nbsp;
                               
                                &copy;
                                 
                                
                                <div className="flip-box">
                                    <div className="flip-box-inner">
                                        <div className="flip-box-front">
                                           <img
                                        style={{height: '70px%',width: '70px'}}
                                            src={jpg23}
                                            alt="First slide"
                                         />
                                        </div>
                                        <div className="flip-box-back">
                                        <h2>Together</h2>
                                        <p>We can do it!</p>
                                        </div>
                                    </div>
                                    </div>
                                 */}
                                {/* <div  id="walkinglioncircle" >
                                                    <h1 style={{ padding: "4em"}}></h1>
                                                                                        

                                                                                
                                  </div> */}
                                <div style={{display:"flex"}} >
                               
                                 {/* <div id="circle"  >
                                            <h1 style={{ padding: "1.5em"}}></h1>
                                        </div> */}
                                            
                                    
                                          
                                            

                                    </div>   
                                 </Navbar.Brand>
                                 {/* <Navbar.Toggle aria-controls="basic-navbar-nav" /> */}
                                 {/* <Navbar.Collapse id="basic-navbar-nav"> */}
                                 <Nav className="mr-auto "  style={{zindex:"90"}}>    
                                 <div style={{width:"20%",zindex:"1"}} id="walkinglioncircle">
                                            
                                             <h1 style={{ padding: "5em"}}></h1>
                                            
                                            </div>                                  
                                   
                                            
                                             <ul >
                                          
                                            <li>
                                               
                                                <a className='list-item' >
                                                <Nav.Link href="#" style={{color:'white',font: '1em'}}>
                                                    <i className='icon-reorder'>Solutions</i>
                                                </Nav.Link>
                                                </a>
                                            </li>
    
                                            <li>

                                                <a className='list-item' >
                                                <Nav.Link href="#" style={{color:'white',font: '1em'}}>
                                                    <i className='icon-reorder'>Products</i>
                                                </Nav.Link>
                                                </a>
                                            </li>
                                            
                                            <li>

                                                <a className='list-item' >
                                                <Nav.Link href="#" style={{color:'white',font: '1em'}}>
                                                    <i className='icon-reorder'>Carees</i>
                                                </Nav.Link>
                                                </a>
                                            </li>
                                            
                                            <li>

                                                <a className='list-item' >
                                                <Nav.Link href="#" style={{color:'white',font: '1em'}}>
                                                    <i className='icon-reorder'>Webinars</i>
                                                </Nav.Link>
                                                </a>
                                            </li>
                                            <li>

                                                <a className='list-item' >
                                                <Nav.Link href="#" style={{color:'white',font: '1em'}}>
                                                     <i className='icon-reorder'>Events</i>
                                                </Nav.Link>
                                                </a>
                                            </li>
                                            <li>

                                                <a className='list-item' >
                                                <Nav.Link href="#" style={{color:'white',font: '1em'}}>
                                                    <i className='icon-reorder'>Newsroom</i>
                                                </Nav.Link>
                                                </a>
                                            </li>
                                            <li>

                                                <a className='list-item' >
                                                <Nav.Link href="#" style={{color:'white',font: '1em'}}>
                                                     <i className='icon-reorder'>Global Contacts</i>
                                                </Nav.Link>
                                                </a>
                                            </li>
                                               <li>

                                                <a className='list-item' >
                                                <Nav.Link href="#" style={{color:'white',font: '1em'}}>
                                                    <i className='icon-reorder'>Travel</i>
                                                </Nav.Link>
                                                </a>
                                            </li>

                                               <li>

                                                <a className='list-item' >
                                                <Nav.Link href="#" style={{color:'white',font: '1em'}}>
                                                    <i className='icon-reorder'>Health</i>
                                                </Nav.Link>
                                                </a>
                                            </li>
                                          
                                             <li >
                                            <a className='list-item' >
                                                <Nav.Link href="#" onClick={this.loginbuttonshow.bind(this)} style={{color:'white', zindex: '99'} }  className="LoginMenuLinksText LoginButton-availability">  
                                                        <i className='icon-th-large L'><span >Login</span></i>
                                                </Nav.Link>
                                            </a>
                                            </li>
                                            
                                        </ul>                     
                                       
                                         <Nav.Link href="#"> 
                                         
                                            </Nav.Link>
                                    </Nav>                  
                                    <Nav className="mr-auto" > 
                                                         
                                               
                                                     <h1 style={{ padding: "2em"}}>

                                                     <div id="humming-bird2"  >
                                             <h1 style={{ padding: "0.1em"}}></h1>
                                             </div> 
                                                     </h1>
                                                
                                            

                                           <Nav.Link href="#" onClick={this.loginbuttonshow.bind(this)} style={{color:'white',font: '1em', zindex: '5000'} }  className="LoginMenuLinksText ">  Login
                                                {/* <button type="submit" className="btn btn-primary btn-block"   className="LoginButton-availability"
                                                                style={{color:"blue"}} onClick={this.loginbuttonshow.bind(this)} >  
                                                                <b className="textloginbutton" id="offset">Login </b>
                                                    </button>*/}
                                                </Nav.Link>
                                                <Nav.Link href="#" style={{color:'#a600ff',font: '1em'}}>Solutions</Nav.Link>
                                                <Nav.Link href="#" style={{color:'#a600ff',font: '1em'}}>Products</Nav.Link>
                                                <Nav.Link href="#" style={{color:'#a600ff',font: '1em'}}>Carees</Nav.Link>
                                                {/* <Nav.Link href="#" style={{color:'#a600ff',font: '1em'}}>Webinars</Nav.Link> 
                                                <Nav.Link href="#" style={{color:'#a600ff',font: '1em'}}>Events</Nav.Link>
                                                <Nav.Link href="#" style={{color:'#a600ff',font: '1em'}}>Newsroom</Nav.Link>  */}
                                                <Nav.Link href="#" style={{color:'#a600ff',font: '1em'}}>Global Contacts</Nav.Link>                                                                                   
                                                <Nav.Link href="#" onClick={this.loginbuttonshow.bind(this)} style={{color:'#a600ff',font: '1em', zindex: '5000'} }  className="LoginMenuLinksText ">  Login
                                                {/* <button type="submit" className="btn btn-primary btn-block"   className="LoginButton-availability"
                                                                style={{color:"blue"}} onClick={this.loginbuttonshow.bind(this)} >  
                                                                <b className="textloginbutton" id="offset">Login </b>
                                                    </button>*/}
                                                </Nav.Link>  

                                                         
                                    </Nav>
                                                    
                                    
                                    
                                {/* </Navbar.Collapse> */}
                                            
            </Navbar>
           
        </section>
					<Carousel.Caption>
                       
                    </Carousel.Caption>
                    
                </Carousel.Item>		
				
</Carousel>

 </section>

             <h1 style={{ padding: "1em"}}></h1>
          
             
 
 
  <section>
  {/* <div style={{width: "100%"}}>
        <div style={{width: "50%", float: "left"}}> 
            Left Div 
        </div>
        <div style={{margin: "50%"}}> 
            Right Div
        </div>
    </div> */}
       <button type="submit" className="btn  btn-block"  
                style={{color:"#a600ff",marginLeft:"15em",width:"100px"}} onClick={this.loginbuttonshow.bind(this)} >  
                <b className="textloginbutton" id="offset">Login </b>
</button>
    <div style={{ marginLeft: "25em"}}>
    <h1 ></h1>

                                                </div>
</section>
 
 <section >
 
 <Carousel variant="outline-danger" autoPlay interval="25000"  indicators={true} fade={true}>
 <Carousel.Item  >
					  <section id="postersection3">
                            <div className="postercuteimage" >
                                                
                            <div ></div>    
                            </div>
                            </section>
				                 <Carousel.Caption>
                       
                            </Carousel.Caption>
                    
                </Carousel.Item>
                    
                {/* <Carousel.Item  >
						 <section id="postersection">
        	
							<div class="postercontainer">
							  
								<div class="hero"><img class="ballack" src={jpg23} 
                                                    style={{height: '100%',width: '70%'}}
                                                    alt="Poster of the day"/>
									<p class="quote"><span>Hollywood</span><span>magazine</span><span>cafe </span>
									<span>&nbsp;</span>
									<span>&nbsp;</span><span>poster the day</span><span>of the </span><span> day</span></p>
								</div>
                                <div className="cutecontainer-text-block-right">
											
                                            <p class="hollywoodtext" ><span className="LoginMenutextbrand" style={{color:'red'}} id="offset">WELCOME TO NIRUPANAIDU CITY</span> </p>
                                           {/* <p > <span style={{color:'cyan'}}>
                                                                    &#34;awesome hollywood debut&#34;
                                                                </span>
                                                                    <br/>
                                                                <span style={{color:'pink'}}>— hollywood magazine cafe</span></p>*/}
                                        {/* </div>
							</div>

      
 
						</section>
				     <Carousel.Caption>
                       
                    </Carousel.Caption>
                    
                </Carousel.Item> */} */}
				
				  <Carousel.Item  >
				  <section id="postersection4">
					  <section id="booksection">

						<div className="imgLoader"></div>

						<div className="container">

						<h1 className="title">
							hollywood<br/>magazine cafe
                            
						</h1>
                        
						<div className="credit">
						<span style={{font:'font: 700 40px/1 "Arvo", sans-serif',color:'orange'}}> * &copy; all image rights are reserved www.nirupanaidu.com</span>
						</div>

						<div className="book">
							<div className="gap"></div>
							<div className="pages">
							<div className="page"></div>
							<div className="page"></div>
							<div className="page"></div>
							<div className="page"></div>
							<div className="page"></div>
							<div className="page"></div>
							</div>
							<div className="flips">
							<div className="flip flip1">
								<div className="flip flip2">
								<div className="flip flip3">
									<div className="flip flip4">
									<div className="flip flip5">
										<div className="flip flip6">
										<div className="flip flip7"></div>
										</div>
									</div>
									</div>
								</div>
								</div>
							</div>
							</div>
						</div>
                        <div className="cutecontainer-text-block-right">
											
                                            <p class="hollywoodtext" ><span className="LoginMenutextbrand" style={{color:'red'}} id="offset">NIRUPANAIDU CITY</span> </p>
                                           {/* <p > <span style={{color:'cyan'}}>
                                                                    &#34;awesome hollywood debut&#34;
                                                                </span>
                                                                    <br/>
                                                                <span style={{color:'pink'}}>— hollywood magazine cafe</span></p>*/}
                                        </div>
						</div>
                       
                        
					</section>
				</section >
				     <Carousel.Caption>
                       
                    </Carousel.Caption>
                    
                </Carousel.Item>
				
				  <Carousel.Item  >
						      <section id="postersection2">
									<div className="cutecontainer">
										<div className="alignleftclass">
												 <div id="hero-8" > 
													<img
												style={{height: '50%',width: '30%'}}
													src={jpg23_2}
													alt="First slide"
													/>
                                       
												 </div>

											</div>
                                            <div className="cutecontainer-text-block">
											
                                            <p class="hollywoodtext"><span> &#34;awesome hollywood debut&#34;<br/><span style={{color:'pink'}}>— hollywood magazine cafe</span></span> </p>
                                           {/* <p > <span style={{color:'cyan'}}>
                                                                    &#34;awesome hollywood debut&#34;
                                                                </span>
                                                                    <br/>
                                                                <span style={{color:'pink'}}>— hollywood magazine cafe</span></p>*/}
                                        </div>
                                            
											<div className="cutecontainer-text-block-right">
											
												<p class="hollywoodtext" ><span className="LoginMenutextbrand" style={{color:'red'}} id="offset">WELCOME TO NIRUPANAIDU CITY</span> </p>
											   {/* <p > <span style={{color:'cyan'}}>
																		&#34;awesome hollywood debut&#34;
																	</span>
																		<br/>
																	<span style={{color:'pink'}}>— hollywood magazine cafe</span></p>*/}
											</div>


									</div>
									 <h1 style={{ padding: "1em"}}></h1>
								 {/** 
									 <div className="alignleftclass">
										<img
										style={{height: '100%',width: '100%'}}
											src={jpg23}
											alt="First slide"
											/>
											<div className="hero-imagepostertext">
												
											</div>
									 </div>
									  <div class="parallexcontainer">
										  <label className="loginMoving-text">
																<span style={{color:'cyan'}}>
																		&#34;awesome hollywood debut&#34;
																	</span>
																		<br/>
																	<span style={{color:'pink'}}>— hollywood magazine cafe</span>
														</label>
											   
														</div>
									*/}
									
						</section>
				
				     <Carousel.Caption>
                       
                    </Carousel.Caption>
                    
                </Carousel.Item>
				
        
				
</Carousel>
 <h1 style={{ padding: "1em"}}></h1>
</section>
    <h1 style={{ padding: "1em"}}></h1>

           <Carousel variant="outline-danger" autoPlay interval="35000"  indicators={true} fade={true}>
              
                    
                <Carousel.Item  >
                           
                        <section id="carousel-hero-1" className="shakingsection">
                         
                            <div class="pyro">
                                    <div class="before"></div>
                                    <div class="after"></div>
                                </div>
                                <div class="parallexcontainer">
                                    <h1 class="hollywoodtext">
                                        <label className="loginMoving-text">
                                        <span style={{color:'cyan'}}>
                                                &#34;There is nothing impossible to they who will try.&#34;
                                            </span>
                                                <br/>
                                            <span style={{color:'pink'}}>— Alexander the Great</span>
                                        </label>
                                    </h1>
                                </div>
                     
                        </section>
                                  <Carousel.Caption>
                       
                    </Carousel.Caption>
                    
                </Carousel.Item>
                    
                <Carousel.Item  >
                   
                        <section id="carousel-hero-2" className="shakingsection">
                                <div class="pyro">
                                    <div class="before">
                                
                                    </div>
                                    <div class="after"></div>
                                 <label className="loginMoving-text">
                                        <span style={{color:'cyan'}}>
                                             &#34;You define your own life. Don’t let other people write your script..&#34;
                                        </span>
                                        <br/>
                                        <span style={{color:'pink'}}>— Oprah Winfrey</span>
                                    </label>
                                </div>
                                <div class="parallexcontainer">
                                
                                </div>
                                
                        </section>
                    <Carousel.Caption>
                      
                              
                    </Carousel.Caption>
                </Carousel.Item>
                    
                <Carousel.Item  >
                            <section id="carousel-hero-3" className="shakingsection">
                               <label className="loginMoving-text">
                                        <span style={{color:'cyan'}}>
                                             &#34;No matter what people tell you, words and ideas can change the world.&#34;
                                        </span>
                                        <br/>
                                        <span style={{color:'pink'}}>— Robin Williams </span>
                                    </label>
                                <div class="pyro">
                                    <div class="before"></div>
                                    <div class="after"></div>
                                    
                                </div>
                                <div class="parallexcontainer">                                  
                                  
                                </div>
                                
                            </section>
                         <Carousel.Caption>
                     
                         </Carousel.Caption>
                    </Carousel.Item>
              
                    {/*<Carousel.Item  >
                    
                            <section id="carousel-hero-4" className="login4thsection">
                               <label className="loginMoving-text">
                                     <span style={{color:'cyan'}}>
                                             &#34;No matter what people tell you, words and ideas can change the world.&#34;
                                        </span>
                                        <br/>
                                        <span style={{color:'pink'}}>— Robin Williams </span>
                                        
                                    </label>
                                <div class="pyro">
                                    <div class="before"></div>
                                    <div class="after"></div>
                                    
                                </div>
                                <div class="parallexcontainer">                                  
                                  
                                </div>
                                
                            </section>
                            <Carousel.Caption>
                            
                            </Carousel.Caption>
                </Carousel.Item>*/}
            </Carousel>
  
        {/*<section class="normal-scroll">
            <div class="parallexcontainer">
                 Content section 4
            </div>
        </section>
          <Navbar bg="bg-transparent" variant="light" expand="lg" >
             <Navbar.Brand href="#">&nbsp;<label id="offsetbrand" className="LoginMenutextbrand">&nbsp; <br/></label> &nbsp;&nbsp;&nbsp;</Navbar.Brand>
                   
          </Navbar>*/}
      <h1 style={{ padding: "1em"}}></h1>
 
     <section >
            <div class="parallexcontainer">
                <h1 class="hollywoodtext">
                    <label className="loginMoving-text">
                        <span style={{color:'coral'}}>&#34;The power of imagination makes us infinite.&#34;</span>
                            <br/><span style={{color:'azure'}}>—John Muir </span>
                    </label>
                </h1>
            </div>
                
                  <h1 style={{ padding: "2em"}}></h1>
        </section>
         
         
          
            <h1 style={{ padding: "3em"}}></h1>
        {/* 
    parallex actual pic
        <section id="hero-test" className="normalsection">
               <div class="pyro">
                <div class="before"></div>
                <div class="after"></div>
                </div>
            <div class="parallexcontainer">
               <h1 className="loginMoving-quotes-text"></h1>
            </div>
        </section> */}
        

      
        <section id="forestsection">
 
                      <h1 style={{ padding: "3em"}}></h1>                                 
             <Carousel variant="outline-danger" autoPlay interval="30000"  indicators={true} fade={true}>
                  <Carousel.Item  >
					  <section>
                            <div className="cat-bed3" >
                                                
                            <div ></div>    
                            </div>
                            </section>
				                 <Carousel.Caption>
                       
                            </Carousel.Caption>
                    
                     </Carousel.Item>
                    
                    <Carousel.Item  >
				        <section>
                              <div className="cat-bed" >
                                            <svg version="1.1" 
                                                xmlns="http://www.w3.org/2000/svg"
                                                xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="300px" height="500px" viewBox="0 0 300 300" enable-background="new 0 0 300 300" xmlSpace="preserve">
                                                <defs>
                                                <pattern id="img-pattern" patternUnits="userSpaceOnUse" width="800" height="800">
                                       
                                                    </pattern>    
                                                
                                                    <path id="circlePath" d=" M 150, 150 m -60, 0 a 60,60 0 0,1 120,0 a 60,60 0 0,1 -120,0 ">
                                                  
                                                   </path>
                                                </defs>
                                                
                                                
                                                <circle cx="150" cy="100" r="75" fill="none">
                                             
                                                </circle>
                                                <g>
                                                             
                                                    <use xlinkHref="#circlePath" fill="none"/>
                                                    <text fill="white">
                                                        <textPath xlinkHref="#circlePath">&copy;*NirupaNaidu.com*&copy;&nbsp;&nbsp;&nbsp;NNirupaNaidu.com*&copy;</textPath>
                                                    </text>
                                                </g>
                                              
                                            </svg>
                                 <div className="walking"></div>    
                            </div>
                        </section>
				        <Carousel.Caption>
                       
                        </Carousel.Caption>
                    
                    </Carousel.Item>						
            
				    < Carousel.Item  >
					    <section>
                            <div className="cat-bed2" >
                                   
                                <div className="walking"></div>    
                            </div>
                        </section>
				        <Carousel.Caption>
                       
                         </Carousel.Caption>
                    
                     </Carousel.Item>
				
				
    </Carousel>
        {/* <button type="submit" className="btn btn-primary btn-block"  
                                                                style={{color:"blue",width:"100px", marginLeft: "25em"}} onClick={this.loginbuttonshow.bind(this)} >  
                                                                <b className="textloginbutton" id="offset">Login </b>
                                                    </button> */}

    
        </section>

     
      <section >  
              <div>
             <div className={loginformClassName}>
           
                         <div className="bkimage-content">
                        
                            <div className="auth-inner ">
                                   <div className="form-group ">
                                        
                                           <label >
                                              <GoogleLogin
                                                  clientId={gClientId}
                                                  buttonText="Log in with Google"
                                                  onSuccess={this.onSuccess}
                                                  onFailure={this.onFailure}
                                                  cookiePolicy={'single_host_origin'}
                                                  style={{ marginTop: '100px' }}
                                                  isSignedIn={false}
                                                />
                                          </label>
                                            
                                   </div>
                                   <h3 >OR</h3>
                                  <form onSubmit={this.loginClicked}>
                                        <h3 className="textSignIn">Sign In</h3>

                                        <div className="form-group">
                                            <label>User Name</label>
                                            <input autoFocus type="text" name="username"   value={this.state.username} onChange={this.handleChange}  className="form-control" placeholder="Enter user Name"  />
                                        </div>

                                        <div className="form-group">
                                            <label>Password</label>
                                            <input type="password" name="password" value={this.state.password} onChange={this.handleChange} className="form-control" placeholder="Enter password" />
                                        </div>

                                        <div className="form-group">
                                            <div className="custom-control custom-checkbox">
                                                <input type="checkbox" className="custom-control-input" id="customCheck1" />
                                                <label className="custom-control-label" htmlFor="customCheck1">Remember me</label>
                                            </div>
                                        </div>

                                        <button type="submit" className="btn btn-primary btn-block" >Submit</button>
                                        <p className="forgot-password text-right">
                                            Forgot <a href="#">password?</a>
                                        </p>
                                  </form>
                                 
                                           
                                      <button type="submit" className="btn btn-primary btn-block"  
                                     onClick={this.loginbuttonhide.bind(this)} >  
                                    <b className="closeloginformbutton" id="offset">close </b></button>  
                                        
                            
                            </div>
                    </div>
                </div>
                 
        </div>        
        <div id="officescreen-coloumn">
                       <Navbar bg="bg-transparent" variant="light" expand="lg" >
                       
                                            <Navbar.Brand href="#" style={{zindex:"1"}} >
                                    
                                            </Navbar.Brand>
                                            {/* <Navbar.Toggle aria-controls="basic-navbar-nav" /> */}
                                            {/* <Navbar.Collapse id="basic-navbar-nav"> */}
                                            <Nav className="mr-auto "  style={{zindex:"90"}}>                                      
                                              
                                                       
                                          
                                                    <Nav.Link href="#"> 
                                                       </Nav.Link>
                                               </Nav>                  
                                               <Nav className="mr-auto" > 
                                                                             
                                                          
                                                                
                                                     
                                                                    <Nav.Link href="#" onClick={this.loginbuttonshow.bind(this)} style={{color:'a600ff',font: '1em', zindex: '5000'} }  className="LoginMenuLinksText ">  Login
                                                                        {/* <button type="submit" className="btn btn-primary btn-block"   className="LoginButton-availability"
                                                                                        style={{color:"blue"}} onClick={this.loginbuttonshow.bind(this)} >  
                                                                                        <b className="textloginbutton" id="offset">Login </b>
                                                                            </button>*/}
                                                                        </Nav.Link>
                                                                        <Nav.Link href="#" style={{color:'a600ff',font: '1em'}}>Solutions</Nav.Link>
                                                                        <Nav.Link href="#" style={{color:'a600ff',font: '1em'}}>Products</Nav.Link>
                                                                        <Nav.Link href="#" style={{color:'a600ff',font: '1em'}}>Carees</Nav.Link>
                                                                        {/* <Nav.Link href="#" style={{color:'#a600ff',font: '1em'}}>Webinars</Nav.Link> 
                                                                        <Nav.Link href="#" style={{color:'#a600ff',font: '1em'}}>Events</Nav.Link>
                                                                        <Nav.Link href="#" style={{color:'#a600ff',font: '1em'}}>Newsroom</Nav.Link>  */}
                                                                        <Nav.Link href="#" style={{color:'a600ff',font: '1em'}}>Global Contacts</Nav.Link>                                                                                   
                                                                        <Nav.Link href="#" onClick={this.loginbuttonshow.bind(this)} style={{color:'a600ff',font: '1em', zindex: '5000'} }  className="LoginMenuLinksText ">  Login
                                                                        {/* <button type="submit" className="btn btn-primary btn-block"   className="LoginButton-availability"
                                                                                        style={{color:"blue"}} onClick={this.loginbuttonshow.bind(this)} >  
                                                                                        <b className="textloginbutton" id="offset">Login </b>
                                                                            </button>*/}
                                                                        </Nav.Link>  
                                                                             
                                                                   
                         

                                                       
                                               </Nav>
                                                               
                                               
                                               
                                           {/* </Navbar.Collapse> */}
                                        
                       </Navbar>
</div>

        </section>
       {/*<section>
        <div className="hero-text ">  
            <b className="textcopyright"> &copy;www.nirupanaidu.com </b> 

        </div>
       </section>*/}

<section>


                       <SocialFollowUp/>
                   </section>
                   
        </>
        );
    }
}

