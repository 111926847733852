import React, { Component } from 'react';


import { ProtectedRoute } from "./Protected.Route";
import RoutesHome from './RoutesHome';
import GLogin from './GLogin';
import ContextMenu from './ContextMenu';
import SimpleSilkSlider from './SimpleSilkSlider';
import ReactSilkLogin from './ReactSilkLogin';
import LoginSilk from './LoginSilk';


class App extends Component {
  constructor(props) {
    super(props);

    //console.log("appjs-",this.props);
}

  render() {
    
    return (

     
        <>
        {/* remove right click ContextMenu */}
        <ContextMenu/>
        
        <RoutesHome/>
       
       {/*<LoginSilk/>*/}
      
       </>
   

     /* <div className="App">
     <BootStrapNavBar/>
     
      </div>*/
    );
  }
}
/*<div className="auth-wrapper">
        <div className="auth-inner">
          
        </div>
      </div>*/
export default App;
