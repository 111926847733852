import React from "react";
import ReactDOM from "react-dom";
import Slider from "react-slick";

import mobileImage from './images/test15.jpeg';
import jpgtest from './images/test.jpeg';
import jpg1 from './images/test1.jpeg';
import jpg2 from './images/test2.jpeg';
import jpg3 from './images/test3.jpeg';
import jpg4 from './images/test4.jpeg';
import jpg5 from './images/test5.jpeg';
import jpg6 from './images/test6.jpeg';
import jpg7 from './images/test7.jpeg';
import jpg8 from './images/test8.jpeg';
import jpg9 from './images/test9.jpeg';
import jpg10 from './images/test10.jpeg';
import jpg11 from './images/test11.jpeg';
import jpg12 from './images/test12.jpeg';
import jpg13 from './images/test13.jpeg';
import jpg14 from './images/test14.jpeg';
import jpg15 from './images/test15.jpeg';
import jpg16 from './images/test16.jpeg';
import jpg17 from './images/test17.jpeg';

class SimpleSilkSlider extends React.Component {
  render() {
    var settings = {
      dots: true
    };
    return (
      <div className="silkcontainer">
        <Slider {...settings}>
          <div>
            <img src={mobileImage} />
          </div>
          <div>
            <img src={jpg10} />
          </div>
          <div>
            <img src={jpg11} />
          </div>
          <div>
            <img src={jpg12} />
          </div>
        </Slider>
      </div>
    );
  }
}
export default SimpleSilkSlider;


