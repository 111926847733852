import React from 'react';
import { useState } from 'react';
import { GoogleLogin } from 'react-google-login';

// refresh token
import { refreshTokenSetup } from './googleutils/refreshToken';
//const clientId =
 // '707788443358-u05p46nssla3l8tmn58tpo9r5sommgks.apps.googleusercontent.com';
const gClientId = '989064674711-ihqe4b7sbvl8u3lu4f8jp68hvuo9hget.apps.googleusercontent.com';
function GLogin() {

  const [loginData, setLoginData] = useState(
    localStorage.getItem('loginData')
      ? JSON.parse(localStorage.getItem('loginData'))
      : null
  );

    const onSuccess = (res) => {
    //console.log('Login Success: currentUser:', res.profileObj);
    alert(
      `Logged in successfully welcome ${res.profileObj.name} 😍. \n `
    );
    
    refreshTokenSetup(res);
    this.props.history.push(`/dashboard/home`);
  };

  const onFailure = (res) => {
    //console.log('Login failed: res:', res);
    alert(
      `Failed to login. 😢`
    );
  };
  
    const handleLogin = async (googleData) => {
    const res = await fetch('/api/google-login', {
      method: 'POST',
      body: JSON.stringify({
        token: googleData.tokenId,
      }),
      headers: {
        'Content-Type': 'application/json',
      },
    });

    const data = await res.json();
    setLoginData(data);
    localStorage.setItem('loginData', JSON.stringify(data));
  };

  const handleLogout = () => {
    localStorage.removeItem('loginData');
    setLoginData(null);
  };

  return (
    <div>
    {loginData ? (
            <div>
              <h3>You logged in as {loginData.email}</h3>
              <button onClick={handleLogout}>Logout</button>
            </div>
          ) : (
            <GoogleLogin
              clientId={gClientId}
              buttonText="Login"
              onSuccess={onSuccess}
              onFailure={onFailure}
              cookiePolicy={'single_host_origin'}
              style={{ marginTop: '100px' }}
              isSignedIn={true}
            />
            )
      }
    </div>
  );
}

export default GLogin;