import React, { Component } from "react";
import AuthenticateService from "../service/AuthenticateService";
import PropTypes from "prop-types";
import Axios from 'axios';
import AuthenticationService from '../service/AuthenticateService';
import auth from "../Auth.js";
import { useState } from 'react';
import { GoogleLogin } from 'react-google-login';
import { refreshTokenSetup } from '../googleutils/refreshToken';
import LoginBackgroundImage from '../LoginBackgroundImage'; 
import desktopImage from '../images/test15.jpeg';
import mobileImage from '../images/test15.jpeg';
import SocialFollowUp from '../SocialFollowUp';



import jpgtest from '../images/test.jpeg';
import jpg1 from '../images/test1.jpeg';
import jpg2 from '../images/test2.jpeg';
import jpg3 from '../images/test3.jpeg';
import jpg4 from '../images/test4.jpeg';
import jpg5 from '../images/test5.jpeg';
import jpg6 from '../images/test6.jpeg';
import jpg7 from '../images/test7.jpeg';
import jpg8 from '../images/test8.jpeg';
import jpg9 from '../images/test9.jpeg';
import jpg10 from '../images/test10.jpeg';
import jpg11 from '../images/test11.jpeg';
import jpg12 from '../images/test12.jpeg';
import jpg13 from '../images/test13.jpeg';
import jpg14 from '../images/test14.jpeg';
import jpg15 from '../images/test15.jpeg';
import jpg16 from '../images/test16.jpeg';
import jpg17 from '../images/test17.jpeg';

const gClientId = '989064674711-ihqe4b7sbvl8u3lu4f8jp68hvuo9hget.apps.googleusercontent.com';

export default class HybridLogin extends Component {

    constructor(props){
        super(props);
        //console.log("login 1st time props ::"+props);
        this.state={
            username:'',
            password:'',
            hasLoginFail:false,
            showSuccessMessage:false

        }
        this.handleChange=this.handleChange.bind(this);
        this.loginClicked=this.loginClicked.bind(this);
        //this.handlgeGoogleSuccessSignIn = this.handlgeGoogleSuccessSignIn.bind();

    }

    componentDidMount = (props) => {
         // //console.log(this.props);
          document.addEventListener('contextmenu', (e) => {
              e.preventDefault();
          });
      };



  onChangeInput = e => {
    this.setState({
      [e.target.name]: parseInt(e.target.value, 10) || 0
    });
  };


    handleChange(event){
       // //console.log("handlechange-"+event.target.name+"handleclick-"+event.target.value);
        this.setState (
            {
                [event.target.name]
                    : event.target.value
            }
        )
    }
    handlgeGoogleSuccessSignIn(res){
        //console.log("login success");
        AuthenticationService.registerSuccessfulLoginThirdPartyForJwt(this.state.username, "45678987654312345afasfmad","google");
        
        this.setState({ showSuccessMessage: true });
        this.setState({ hasLoginFailed: false });
        //console.log("loginjs props::"+this.props.data);
        this.props.updateGoogleUserdata(res.profileObj);
        this.props.history.push(`/dashboard/home`);
    

    }
   

    loginClicked(event){
        event.preventDefault();
        //console.log("in login clicked");
        //debugger;
        const usrname=this.state.username;
        const pwd=this.state.password;
        const myHeaders = new Headers();
        const auth = "Basic "+btoa(this.state.username+":"+this.state.password);
        myHeaders.append("Authorization", auth);
        const raw = "";
        const requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: raw,
            redirect: 'follow'
          };



        if( AuthenticationService
         .executestaticpassword(this.state.username, this.state.password)){
             //console.log("login success");
            AuthenticationService.registerSuccessfulLoginForJwt(this.state.username, "45678987654312345afasfmad");
            this.setState({ showSuccessMessage: true });
            this.setState({ hasLoginFailed: false });
            const userobj = {    
                              imageUrl: 'testImage', 
                              email: 'user@nirupanaidu.com', 
                              name: 'userfirstname', 
                              givenName: 'userlastname'
                              }
            this.props.updateGoogleUserdata(userobj);
            this.props.history.push(`/dashboard/home`);
         }
         //.then((response) => {
         //    AuthenticationService.registerSuccessfulLoginForJwt(this.state.username, "45678987654312345afasfmad")
         //    this.setState({ showSuccessMessage: true });
         //    this.setState({ hasLoginFailed: false });
             //this.props.history.push(`/home`);
        // }).catch(() => {
         //    this.setState({ showSuccessMessage: false })
         //    this.setState({ hasLoginFailed: true })
        // })
          
        // Axios.post('http://localhost:8080/secured/all', { 
           
        //     method: 'post', 
        //    headers: {
               
        //       'Content-Type': 'application/x-www-form-urlencoded',
        //      'Cache-Control': 'no-store',
        //      'Authorization' : 'Bearer'+btoa(this.state.username+":"+this.state.password)
        //     } 

              
        //   }).then((response) =>{
        //       //debugger;
        //       //console.log(response);
             
        //      //console.log(this.props.history);
             
        //       if (response.status == "200") {
        //         //console.log(this.state);
        //        this.setState({ hasLoginFail:true,showSuccessMessage:true});
        //        //this.registerSuccessfulLogin(this.username,this.password);
        //        //debugger;
        //        //const location=`${window.location.origin}`;
        //        let queryParams = this.props.history.location.search; 
        //        //console.log('queryParams:'+queryParams);
        //        this.props.history.location.search=null;
        //        let newQueryParams = this.props.history.location.search; 
        //        //console.log('newQueryParams:'+newQueryParams);
        //       // const url = location+"/app";
        //       // this.props.history.location.pathname =url;
        //        // this.props.history.push("/app");

        //        var newurl = window.location.protocol + "//" + window.location.host + window.location.pathname + 'app';
        //        //console.log("new url-loginjs-",newurl);
             

        //        this.props.history.push({
        //         pathname: '/app',
        //         search: ''
        //       })

        //        this.props.history.push("/app");
               
        //     }
        //     }).catch(
        //         this.setState({hasLoginFail:false,showSuccessMessage:false})
        // )
      
   //this.props.history.push("/home");
    }
    

  
    render() {
                    let slides = [
                        <img  src={jpg15} alt="1" />,
                        <img  src={jpg16}  alt="2" />  ,
                        <img  src={jpg6}  alt="3" />  ,
                        <img  src={jpg7}  alt="4" />  ,
                        <img src={jpg8}  alt="5" />   ];
                    
        const onSuccess = (res) => {
           //console.log('Login Success: currentUser:', res.profileObj);
            alert(
            `Logged in successfully welcome ${res.profileObj.name} 😍. \n `
            );
        
           // refreshTokenSetup(res);
            this.handlgeGoogleSuccessSignIn(res);
       };
      const onFailure = (res) => {
        //console.log('Login failed: res:', res);
        alert(
          `Failed to login. 😢`
        );
        
      };
        //console.log("login js");
      const imageUrl = window.innerWidth >= 650 ? desktopImage : mobileImage;

        return (
            <>
           
            <style>{`
            .line{
                background-color:white; 
                padding-left:20px; 
                padding-right:20px; 
                max-width:550px; 
                margin:auto; 
                border-radius:5px;
                padding-bottom:5px; 
                text-align:left; 
                margin-bottom:40px;
                width:35%;
            }
            .center {
                display: flex;
                justify-content: center;
                align-items: center;
            }
            
          `}</style>
            <div>&nbsp;&nbsp;&nbsp;&nbsp;</div>           
                     {/*<div className="bkimage" style={{backgroundImage: `url(${imageUrl})` }}>
                        <div className="bkimage-content">
                            
                            <div className="auth-inner ">
                                   <div className="form-group ">
                                        
                                           <label >
                                              <GoogleLogin
                                                  clientId={gClientId}
                                                  buttonText="Log in with Google"
                                                  onSuccess={onSuccess}
                                                  onFailure={onFailure}
                                                  cookiePolicy={'single_host_origin'}
                                                  style={{ marginTop: '100px' }}
                                                  isSignedIn={true}
                                                />
                                          </label>
                                            
                                   </div>
                                   <h3 >OR</h3>
                                  <form onSubmit={this.loginClicked}>
                                        <h3 className="textSignIn">Sign In</h3>

                                        <div className="form-group">
                                            <label>User Name</label>
                                            <input type="text" name="username" value={this.state.username} onChange={this.handleChange}  className="form-control" placeholder="Enter user Name"  />
                                        </div>

                                        <div className="form-group">
                                            <label>Password</label>
                                            <input type="password" name="password" value={this.state.password} onChange={this.handleChange} className="form-control" placeholder="Enter password" />
                                        </div>

                                        <div className="form-group">
                                            <div className="custom-control custom-checkbox">
                                                <input type="checkbox" className="custom-control-input" id="customCheck1" />
                                                <label className="custom-control-label" htmlFor="customCheck1">Remember me</label>
                                            </div>
                                        </div>

                                        <button type="submit" className="btn btn-primary btn-block" >Submit</button>
                                        <p className="forgot-password text-right">
                                            Forgot <a href="#">password?</a>
                                        </p>
                                  </form>
                                 
                                        
                                        
                            
                            </div>
                        </div>
                    </div>*/}
                 {/* 3d carousel start*/}
                 {/* 3d carousel end */}
                 
            
                <SocialFollowUp/>       
                        
  
        </>
        );
    }
}

