import React, { Component } from "react";
import { Carousel } from 'react-bootstrap';
import {Navbar,Nav,NavDropdown,Form,FormControl,Modal,Button} from 'react-bootstrap';

import PropTypes from "prop-types";
import Axios from 'axios';
import auth from "../Auth.js";
import { useState } from 'react';


import AuthenticationService from "../service/AuthenticateService";
import AuthenticateService from "../service/AuthenticateService";
import LoginModal from '../login/LoginModal';
import useWindowDimensions from '../useWindowDimensions'
import { GoogleLogin } from 'react-google-login';
import { refreshTokenSetup } from '../googleutils/refreshToken';
import LoginBackgroundImage from '../LoginBackgroundImage'; 
import desktopImage from '../images/test15.jpeg';
import mobileImage from '../images/test15.jpeg';
import SocialFollowUp from '../SocialFollowUp';
import CommentBox from '../comments/CommentBox.js';
import NiruChatBot from '../chatbot/NiruChatBot.js';
import SimpleChatBot from '../chatbot/SimpleForm.js';



import "../css/login.css";
import "../css/book.css";
import "../css/poster.css";
import jpg15rm from "../images/test15-rm.jpeg"
import jpgtest from '../images/test.jpeg';
import jpg1 from '../images/test1.jpeg';
import jpg2 from '../images/test2.jpeg';
import jpg3 from '../images/test3.jpeg';
import jpg4 from '../images/test4.jpeg';
import jpg5 from '../images/test5.jpeg';
import jpg6 from '../images/test6.jpeg';
import jpg7 from '../images/test7.jpeg';
import jpg8 from '../images/test8.jpeg';
import jpg9 from '../images/test9.jpeg';
import jpg10 from '../images/test10.jpeg';
import jpg11 from '../images/test11.jpeg';
import jpg12 from '../images/test12.jpeg';
import jpg13 from '../images/test13.jpeg';
import jpg14 from '../images/test14.jpeg';
import jpg15 from '../images/test15.jpeg';
import jpg16 from '../images/test16.jpeg';
import jpg17 from '../images/test17.jpeg';
import jpg23 from '../images/test23-2.png';
import jpg23_2 from '../images/test23-2.png';
import jpg26 from '../images/test26.jpeg';
import jpg27 from '../images/test27.jpeg';


export default class CEODesk6 extends Component {

  constructor(props){
        super(props);
        this.state = { time: new Date() }; // initialise the state
        this.ceoDeskClicked=this.ceoDeskClicked.bind(this);
        this.loginClicked=this.loginClicked.bind(this);

        
    }

    componentDidMount = (props) => {
            // //console.log(this.props);
            //console.log("login component didmount:::");           
              document.addEventListener('contextmenu', (e) => {
                e.preventDefault();
            });
        AuthenticateService.ClearFlagsforLogin();
        this.update = setInterval(() => {
            this.setState({ time: new Date() });
        }, 1 * 1000); // every 1 seconds
        this.timer = setInterval(function(){  this.props.history.push(`/login3`); }.bind(this), 180000);
          
        };
     

    ceoDeskClicked(event){
        event.preventDefault();
     
        this.props.history.push(`/login3`);       

    }
     loginClicked(event){
        event.preventDefault();
     
        this.props.history.push(`/login`);       

    }
    
     componentWillUnmount() {
        clearInterval(this.timer);
        clearInterval(this.update);
      }

    render() {
  
        return (
                <>
                
                    <style>{`
                    .line{
                        background-color:white; 
                        padding-left:20px; 
                        padding-right:20px; 
                        max-width:550px; 
                        margin:auto; 
                        border-radius:5px;
                        padding-bottom:5px; 
                        text-align:left; 
                        margin-bottom:40px;
                        width:35%;
                    }
                    .center {
                        display: flex;
                        justify-content: center;
                        align-items: center;
                    }
                    ul{
                        display: flex;
                        justify-content: center;
                    }
                   ul > li{
                       
                        display:inline-block;
                        padding: 0px 40px;
                        first-child{padding-left:0;}
                        last-child{padding-right:0;}
                    }


                `}</style>


                <section >
                    
                    <Carousel variant="outline-danger" autoPlay interval="15000"  indicators={true} fade={true}>
                        <Carousel.Item  >				  
                            <section >    
                                         
                                <Navbar bg="bg-transparent" variant="light" expand="lg" id="loginIndex1">                                                               
                                        <h1 className="welcometext" style={{color:"gold"}}> 
                                         <p>{this.state.time.toLocaleDateString('en-US',{ year: 'numeric', month: 'long', day: 'numeric' })} </p>
                                          <p>{this.state.time.toLocaleTimeString()} </p>
                                       </h1>
                                </Navbar>                        
                            </section>                          
                            <Carousel.Caption> 
                            
                              <div style={{height:"10%",zindex:"1"}} id="officescreen-coloumn">
                                        <label id="offsetbrand" className="LoginMenutextbrand  spinbrand">
                                             <span style={{fontSize: "25px"}}> Nirupa Naidu <br/>Enterprises</span>
                                        </label>  
                                    </div>                                   
                            </Carousel.Caption>
                                    
                        </Carousel.Item>
                        <Carousel.Item  >		  
                            <section >                     
                                <Navbar bg="bg-transparent" variant="light" expand="lg" id="loginIndex2"> 
                                     <h1 className="welcometext" style={{color:"gold"}}> 
                                         <p>{this.state.time.toLocaleDateString('en-US',{ year: 'numeric', month: 'long', day: 'numeric' })} </p>
                                          <p>{this.state.time.toLocaleTimeString()} </p>
                                       </h1>                        
                                </Navbar>
                            </section>
                            <Carousel.Caption>
                              <div style={{height:"10%",zindex:"1"}} id="officescreen-coloumn">
                                        <label id="offsetbrand" className="LoginMenutextbrand  spinbrand">
                                             <span style={{fontSize: "25px"}}> Nirupa Naidu <br/>Enterprises</span>
                                        </label>  
                                    </div>  
                            </Carousel.Caption>                               
                        </Carousel.Item>

                        <Carousel.Item  >
                            <section>
                            <Navbar bg="bg-transparent" variant="light" expand="lg" id="loginIndex3">  
                                    <h1 className="welcometext" style={{color:"gold"}}> 
                                         <p>{this.state.time.toLocaleDateString('en-US',{ year: 'numeric', month: 'long', day: 'numeric' })} </p>
                                          <p>{this.state.time.toLocaleTimeString()} </p>
                                       </h1>
                            </Navbar>
                        </section>
                                
                            <Carousel.Caption>
                                  <div style={{height:"10%",zindex:"1"}} id="officescreen-coloumn">
                                        <label id="offsetbrand" className="LoginMenutextbrand  spinbrand">
                                             <span style={{fontSize: "25px"}}> Nirupa Naidu <br/>Enterprises</span>
                                        </label>  
                                    </div>  
                            </Carousel.Caption>
                                    
                        </Carousel.Item>
                        <Carousel.Item  >
                            <section>
                                    <Navbar bg="bg-transparent" variant="light" expand="lg" id="loginIndex4">
                                      <h1 className="welcometext" style={{color:"gold"}}> 
                                         <p>{this.state.time.toLocaleDateString('en-US',{ year: 'numeric', month: 'long', day: 'numeric' })} </p>
                                          <p>{this.state.time.toLocaleTimeString()} </p>
                                       </h1>
                                    </Navbar>
                            </section>
                            <Carousel.Caption>
                           
                              <div style={{height:"10%",zindex:"1"}} id="officescreen-coloumn">
                                        <label id="offsetbrand" className="LoginMenutextbrand  spinbrand">
                                             <span style={{fontSize: "25px"}}> Nirupa Naidu <br/>Enterprises</span>
                                        </label>  
                                    </div>  
                                   
                            </Carousel.Caption>
                        </Carousel.Item>>
                        
                        
                    </Carousel>

                </section>

               
               
                <section>
                  <SimpleChatBot 
                        
                    />  
                 
                                     <ul >
                                       <li >
                                        <a className='list-item' >
                                            <Nav.Link href="#" onClick={this.ceoDeskClicked.bind(this)} >
                                                    <i className='icon-reorder'><span style={{color:'white',fontFamily: 'grapenuts'}}>CEODESK</span></i>
                                            </Nav.Link>
                                            </a>
                                            {/* <a  className='list-item'>
                                            <Nav.Link href="#" onClick={this.ceoDeskClicked.bind(this)}  style={{color:'white', zindex: '99',font: '1em',text:'left'} }  className="LoginMenuLinksText LoginButton-availability">  
                                                 <i className='icon-reorder'><span></span></i>
                                            </Nav.Link>
                                            </a> */}
                                        </li>
                                     
                                        <li >
                                            <a  className='list-item'>
                                            <Nav.Link href="#" onClick={this.loginClicked.bind(this)}  style={{color:'white', zindex: '99'} }  className="LoginMenuLinksText LoginButton-availability">  
                                                <i className='icon-th-large L'><span >Login</span></i>
                                            </Nav.Link>
                                            </a>
                                        </li>
                                        
  
                                     </ul>  
                                      
                              
                    {/* <NiruChatBot {...this.props} />  */}
                     
                  
                    <CommentBox  {...this.props} />
                </section>
                <h1 style={{ padding: "1em"}}></h1>
                <section>
                    <SocialFollowUp/>
                </section>
                    
            </>
        );
    }
}

