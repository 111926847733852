import React, { Component } from 'react'
import AuthenticateService from '../service/AuthenticateService';
import { GoogleLogout } from 'react-google-login';
import "../css/logout.css"
export const THIRD_PARTY_LOGIN = "THRIDPARTY";
const gClientId = '989064674711-ihqe4b7sbvl8u3lu4f8jp68hvuo9hget.apps.googleusercontent.com';
class LogoutComponent extends Component {

    constructor(props){
        super(props);
        this.handleLogout=this.handleLogout.bind(this);
        //this.isThirdPartyUser = this.isThirdPartyUser.bind();
    }

    componentDidMount = (props) => {
        //console.log(this.props);
      };

    handleLogout(e) {
        AuthenticateService.logout();
        this.props.history.push("/");
    }
    isThirdPartyUser() {
        let thirparty = sessionStorage.getItem(THIRD_PARTY_LOGIN);
        //console.log("logout thirdparty::"+thirparty);
        if (thirparty === null) return false
        return true
    }

    thirdpartyLogout(){
     
         AuthenticateService.thirdpartyLogout();
        this.props.history.push("/");
    }
 
    render() {
        
           const logoutthirdparty = () => {
                ////console.log('Logout made successfully');
               // alert('Logout made successfully ✌');

                this.thirdpartyLogout();
            };
            
        return (
            <>
           { this.isThirdPartyUser() ? 
                    (
                      
                         <div>
                         <h2> <label className="logoutlabel">Thank You for Using Our Application. <br/> please use below logout button to exit from this site
                         </label>
                         <label className="logoutlabel2">Note: please use separate window logout from your google accout.</label>
                         </h2>
                            <label className="textSignIn"><GoogleLogout
                                clientId={gClientId}
                                buttonText="Logout with Google from this site"
                                onLogoutSuccess={logoutthirdparty}
                            >
                            </GoogleLogout> </label>
                                <div>
                            <p>&nbsp;</p>
                            <p>&nbsp;</p>
                            <p>&nbsp;</p>
                            <p>&nbsp;</p>
                            <p>&nbsp;</p>
                        </div>
                        </div>
                       
                    ) : (
                          <div>
                        <h1>thanks.. 1 click to logout.. </h1>
                        <div className="container">
                            <h2>Thank You for Using Our Application.</h2>
                            <button  onClick={this.handleLogout}>   <h3 className="textSignIn">Logout</h3>  </button>

                        </div>
                        
                        <div>
                            <p>&nbsp;</p>
                            <p>&nbsp;</p>
                            <p>&nbsp;</p>
                            <p>&nbsp;</p>
                            <p>&nbsp;</p>
                        </div>
                        </div>

                    ) }

            
             
                
            </>
        )
    }
}

export default LogoutComponent