import React, { Component } from "react";
import { Carousel } from 'react-bootstrap';
import {Navbar,Nav,NavDropdown,Form,FormControl,Modal,Button} from 'react-bootstrap';

import PropTypes from "prop-types";
import Axios from 'axios';
import auth from "../Auth.js";
import { useState } from 'react';


import AuthenticationService from "../service/AuthenticateService";
import AuthenticateService from "../service/AuthenticateService";
import LoginModal from '../login/LoginModal';
import useWindowDimensions from '../useWindowDimensions'
import { GoogleLogin } from 'react-google-login';
import { refreshTokenSetup } from '../googleutils/refreshToken';
import LoginBackgroundImage from '../LoginBackgroundImage'; 
import desktopImage from '../images/test15.jpeg';
import mobileImage from '../images/test15.jpeg';
import SocialFollowUp from '../SocialFollowUp';


import "../css/login.css";
import "../css/book.css";
import "../css/poster.css";
import jpg15rm from "../images/test15-rm.jpeg"
import jpgtest from '../images/test.jpeg';
import jpg1 from '../images/test1.jpeg';
import jpg2 from '../images/test2.jpeg';
import jpg3 from '../images/test3.jpeg';
import jpg4 from '../images/test4.jpeg';
import jpg5 from '../images/test5.jpeg';
import jpg6 from '../images/test6.jpeg';
import jpg7 from '../images/test7.jpeg';
import jpg8 from '../images/test8.jpeg';
import jpg9 from '../images/test9.jpeg';
import jpg10 from '../images/test10.jpeg';
import jpg11 from '../images/test11.jpeg';
import jpg12 from '../images/test12.jpeg';
import jpg13 from '../images/test13.jpeg';
import jpg14 from '../images/test14.jpeg';
import jpg15 from '../images/test15.jpeg';
import jpg16 from '../images/test16.jpeg';
import jpg17 from '../images/test17.jpeg';
import jpg23 from '../images/test23-2.png';
import jpg23_2 from '../images/test23-2.png';
import jpg26 from '../images/test26.jpeg';
import jpg27 from '../images/test27.jpeg';

const gClientId = '989064674711-ihqe4b7sbvl8u3lu4f8jp68hvuo9hget.apps.googleusercontent.com';

export default class Login4 extends Component {

    constructor(props){
        super(props);
        //console.log("login 1st time props ::"+props);
        this.state={
            username:'',
            password:'',
            hasLoginFail:false,
            showSuccessMessage:false,
            modalshow: false,
            login_hide_show:false,
            dynamicheight:0,
            dynamicwidth:0,
            bookalbumdisplayflag:true
        }
        this.handleChange=this.handleChange.bind(this);
        this.loginClicked=this.loginClicked.bind(this);
        this.showModal = this.showModal.bind(this);
        this.hideModal = this.hideModal.bind(this);
        //this.handlgeGoogleSuccessSignIn = this.handlgeGoogleSuccessSignIn.bind();
        this.assigncustomername=this.assigncustomername.bind(this);
        this.assigncustomercredentials=this.assigncustomercredentials.bind(this);
        this.onSuccess=this.onSuccess.bind(this);
        this.loginbuttonhide = this.loginbuttonhide.bind(this);
        this.loginbuttonshow=this.loginbuttonshow.bind(this);
        this.closeBookAlbum=this.closeBookAlbum.bind(this);
        
    }

    componentDidMount = (props) => {
            // //console.log(this.props);
            //console.log("login component didmount:::");
            
              document.addEventListener('contextmenu', (e) => {
                e.preventDefault();
            });
        AuthenticateService.ClearFlagsforLogin();
        
          
        };
        closeBookAlbum(){
                this.setState({bookalbumdisplayflag : false});
        }
        loginbuttonhide(){
            this.setState({login_hide_show : false});
        }

        loginbuttonshow(){
            this.setState({login_hide_show : true});
        }

       onSuccess = (res) => {
          

           //console.log('Login Success: currentUser:', res.profileObj);
            //alert(`Logged in successfully welcome ${res.profileObj.name} 😍. \n `);
        
           // refreshTokenSetup(res);
            this.handlgeGoogleSuccessSignIn(res);
       };
       onFailure = (res) => {
        //console.log('Login failed: res:', res);
        //alert(`Failed to login. 😢` );
        
      };

        handleChange(event){
            // //console.log("handlechange-"+event.target.name+"handleclick-"+event.target.value);
            this.setState (
                {
                    [event.target.name]
                        : event.target.value
                }
            )
        }

        assigncustomername(value){
            // //console.log("handlechange-"+event.target.name+"handleclick-"+event.target.value);
            this.setState (
                {
                    username : value }
            )
        }
            assigncustomercredentials(value){
            // //console.log("handlechange-"+event.target.name+"handleclick-"+event.target.value);
            this.setState (
                {
                    password : value
                }
            )
        }
            

        handlgeGoogleSuccessSignIn(res){
            //console.log("login success");
            AuthenticationService.registerSuccessfulLoginThirdPartyForJwt(this.state.username, "45678987654312345afasfmad","google");
            
            this.setState({ showSuccessMessage: true });
            this.setState({ hasLoginFailed: false });
            //console.log("loginjs props::"+this.props.data);
                AuthenticateService.saveUser( this.formatUserobject(res.profileObj) );
            this.props.updateGoogleUserdata(res.profileObj);
            //this.props.history.push(`/dashboard/home`);  commented temporarly to display html in dashboard
                this.props.history.push(`/Dashboard`); 
            //this.props.history.push("/display")
        }

        formatUserobject(resProfObj){
            const saveUserOobj={
                            googleUserMailId: resProfObj.email,
                            googleuserFistName: resProfObj.name,
                            googleUserLastName: resProfObj.givenName,
                            googleUserImage: resProfObj.imageUrl 

            }
        return saveUserOobj;
        }

        showModal = () => {
        this.setState({ modalshow: true });
        };

        hideModal = () => {
        this.setState({ modalshow: false });
        };

        loginClicked(event){
        event.preventDefault();
     
        this.props.history.push(`/page1`);       

        }
    

  
    render() {
     
        //console.log("login js");
      const imageUrl = window.innerWidth >= 650 ? desktopImage : mobileImage;
      const  loginformClassName = this.state.login_hide_show ? "display-block" : "display-none";
      const  displaybookalbum = this.state.bookalbumdisplayflag ? "display-block" : "display-none";

     {/* including in login js itself instead of calling some other palce */}
    {/*dynamic height and width */}

  

   /* const testComponent = () => {
        const { height, width } = useWindowDimensions();
        this.setState({dynamicheight:{height}});
        this.setState({dynamicwidth:{width}});
     
        return (
            <div>
            width: {width} ~ height: {height}
            </div>
        );
    };

      var bkimageDivStyle={
                    width:this.state.dynamicheight+"px",
                    height:this.state.dynamicheight+"px",
                    backgroundImage: `url(${imageUrl})`

                };
    console.log("dynamicheight:"+this.state.dynamicheight);
       console.log("dynamicwidth:"+this.state.dynamicwidth)
  */


        return (
            <>
            
            <style>{`
            .line{
                background-color:white; 
                padding-left:20px; 
                padding-right:20px; 
                max-width:550px; 
                margin:auto; 
                border-radius:5px;
                padding-bottom:5px; 
                text-align:left; 
                margin-bottom:40px;
                width:35%;
            }
            .center {
                display: flex;
                justify-content: center;
                align-items: center;
            }
            :root {
                --duration: 40s;
                --delay: 10s;
              }
              
              html {
                background-color: transparent;
                // animation: shrink var(--duration) var(--delay) linear infinite;
                // -webkit-animation: shrink var(--duration) var(--delay) linear infinite;
              }
              
              body {
                position: relative;
                margin: 0 auto;
                padding: 0 1rem;
                width: 100vw;
                height: 100vh;
                overflow: hidden;
                
                transform-style: preserve-3d;
                perspective: 25rem;
              }

              @media only screen and (max-device-width: 480px) {
                /* styles for mobile browsers smaller than 480px; (iPhone) */
                .footer-image {
                  position: fixed;
                  left: 0;
                  bottom:-50px;
                  width: 100%;
               
                }
             }
             @media only screen and (device-width: 768px) {
                /* default iPad screens */
             }
             /* different techniques for iPad screening */
             @media only screen and (min-device-width: 481px) and (max-device-width: 1024px) and (orientation:portrait) {
               /* For portrait layouts only */
             }
         
             @media only screen and (min-device-width: 481px) and (max-device-width: 1024px) and (orientation:landscape) {
               /* For landscape layouts only */
             }



              @media  screen and (max-width: 820px) {
                .footer-image {
                  position: fixed;
                  left: 0;
                  bottom:0;
                  width: 100%;
               
                }
             
              }
            
              .footer-image {
                position: fixed;
                left: 0;
                width: 100%;
                height: inherit;
                bottom: 10px;
              }

            img {
                --duration: 40s;
                --name: float-up-1;
                position: absolute;
               bottom:0;
                left: 0;
                width: 100vw;
                transform: translateZ(200vh);
                animation: var(--name) var(--duration) linear infinite;
                -webkit-animation: var(--name) var(--duration) linear infinite;
               
                z-index: 1;
              }
              img:nth-child(1) {
                animation-delay: 0s;
                -webkit-delay:  0s;
              }
     
             
              img:nth-child(1) {
                --name: float-up-1;
                --duration: calc(30s * 1);
                left: 0vw;
                z-index: -1;
              }
      
             
              @keyframes float-up-3 {
                from {
                  transform: translateY(200vh) translateZ(-50vh);
                }
                to {
                  transform: translateY(10vh) translateZ(-50vh);
                }
              }
              @-webkit-keyframes float-up-3 {
                from {
                  transform: translateY(200vh) translateZ(-50vh);
                }
                to {
                  transform: translateY(-200vh) translateZ(-50vh);
                }
              }
              @keyframes float-up-2 {
                from {
                  transform: translateY(150vh) translateZ(-25vh);
                }
                to {
                  transform: translateY(-150vh) translateZ(-25vh);
                }
              }
              @-webkit-keyframes float-up-2 {
                from {
                  transform: translateY(150vh) translateZ(-25vh);
                }
                to {
                  transform: translateY(-150vh) translateZ(-25vh);
                }
              }
              @keyframes float-up-1 {
                0% { transform: translateY(0vh);   }

              30% {transform: translateY(-2vh);}
              70% { transform: translateY(-2vh);  }
              100% { transform: translateY(-2vh) }                       
              }
              @-webkit-keyframes float-up-1 {
                0% { transform: translateY(0vh);   }

              30% {transform: translateY(-2vh);}
              70% { transform: translateY(-2vh);  }
              100% { transform: translateY(-2vh) }       
              }
              @keyframes spin {
                10%, 100% {
                  transform: rotateY(360deg);
                }
              }
              @-webkit-@keyframes spin {
                10%, 100% {
                  transform: rotateY(360deg);
                }
              }
              @keyframes shrink {
                0% {
                  transform: scale(1) translateX(0);
                }
                2.5% {
                  transform: scale(0.75) translateX(5%);
                }
                7.5% {
                  transform: scale(0.75) translateX(-5%);
                }
                10%, 100% {
                  transform: scale(1);
                }
              }
              @-webkit-keyframes shrink {
                0% {
                  transform: scale(1) translateX(0);
                }
                2.5% {
                  transform: scale(0.75) translateX(5%);
                }
                7.5% {
                  transform: scale(0.75) translateX(-5%);
                }
                10%, 100% {
                  transform: scale(1);
                }
              }
              .footer {
                position: fixed;
                left: 0;
                bottom: 0;
                width: 100%;
                background-color: white;
                color: white;
                text-align: center;
              }
              
              div.sticky {
                position: -webkit-sticky;
                position: sticky;
                top: 0;
                background-color: white;
                padding: 50px;
                font-size: 20px;
              }
         
      div {
        transform-style: preserve-3d;
      }
      
      #websitelogo .text {
        position: absolute;
        font-size: 4rem;
      
        line-height: 4rem;
        opacity: 0;
        font-family: "Anton", sans-serif;
        transform: translate(-50%, -50%);
        mix-blend-mode: screen;

        z-index: 99;
      }
      #websitelogo .text:nth-child(1) {
        color: #fd3203;
        -webkit-clip-path: polygon(0% 0%, 0% 50%, 10% 50%);
                clip-path: polygon(0% 0%, 0% 50%, 10% 50%);
        transform-origin: 18% -35%;
        -webkit-animation: fly1 5000ms 0ms cubic-bezier(0.36, 0.1, 0.16, 1) infinite alternate;
                animation: fly1 5000ms 0ms cubic-bezier(0.36, 0.1, 0.16, 1) infinite alternate;
      }
      @-webkit-keyframes fly1 {
        0% {
          opacity: 0;
          transform: translate(-50%, -50%) rotateX(-490deg) rotateY(420deg) rotateZ(227deg) translateZ(-464px);
        }
        10% {
          opacity: 0;
          transform: translate(-50%, -50%) rotateX(-490deg) rotateY(420deg) rotateZ(227deg) translateZ(-464px);
        }
        90% {
          opacity: 1;
          transform: translate(-50%, -50%) rotate(0deg) rotateY(0deg) rotateZ(0deg) translateZ(0px);
        }
        100% {
          opacity: 1;
          transform: translate(-50%, -50%) rotate(0deg) rotateY(0deg) rotateZ(0deg) translateZ(0px);
        }
      }
      @keyframes fly1 {
        0% {
          opacity: 0;
          transform: translate(-50%, -50%) rotateX(-490deg) rotateY(420deg) rotateZ(227deg) translateZ(-464px);
        }
        10% {
          opacity: 0;
          transform: translate(-50%, -50%) rotateX(-490deg) rotateY(420deg) rotateZ(227deg) translateZ(-464px);
        }
        90% {
          opacity: 1;
          transform: translate(-50%, -50%) rotate(0deg) rotateY(0deg) rotateZ(0deg) translateZ(0px);
        }
        100% {
          opacity: 1;
          transform: translate(-50%, -50%) rotate(0deg) rotateY(0deg) rotateZ(0deg) translateZ(0px);
        }
      }
      #websitelogo .text:nth-child(2) {
        color: #fb3106;
        -webkit-clip-path: polygon(0% 0%, 10% 0%, 10% 50%);
                clip-path: polygon(0% 0%, 10% 0%, 10% 50%);
        transform-origin: 30% 37%;
        -webkit-animation: fly2 5000ms 40ms cubic-bezier(0.36, 0.1, 0.16, 1) infinite alternate;
                animation: fly2 5000ms 40ms cubic-bezier(0.36, 0.1, 0.16, 1) infinite alternate;
      }
      @-webkit-keyframes fly2 {
        0% {
          opacity: 0;
          transform: translate(-50%, -50%) rotateX(-273deg) rotateY(15deg) rotateZ(-277deg) translateZ(-528px);
        }
        10% {
          opacity: 0;
          transform: translate(-50%, -50%) rotateX(-273deg) rotateY(15deg) rotateZ(-277deg) translateZ(-528px);
        }
        90% {
          opacity: 1;
          transform: translate(-50%, -50%) rotate(0deg) rotateY(0deg) rotateZ(0deg) translateZ(0px);
        }
        100% {
          opacity: 1;
          transform: translate(-50%, -50%) rotate(0deg) rotateY(0deg) rotateZ(0deg) translateZ(0px);
        }
      }
      @keyframes fly2 {
        0% {
          opacity: 0;
          transform: translate(-50%, -50%) rotateX(-273deg) rotateY(15deg) rotateZ(-277deg) translateZ(-528px);
        }
        10% {
          opacity: 0;
          transform: translate(-50%, -50%) rotateX(-273deg) rotateY(15deg) rotateZ(-277deg) translateZ(-528px);
        }
        90% {
          opacity: 1;
          transform: translate(-50%, -50%) rotate(0deg) rotateY(0deg) rotateZ(0deg) translateZ(0px);
        }
        100% {
          opacity: 1;
          transform: translate(-50%, -50%) rotate(0deg) rotateY(0deg) rotateZ(0deg) translateZ(0px);
        }
      }
      #websitelogo .text:nth-child(3) {
        color: #f93109;
        -webkit-clip-path: polygon(10% 0%, 10% 50%, 20% 50%);
                clip-path: polygon(10% 0%, 10% 50%, 20% 50%);
        transform-origin: 12% 31%;
        -webkit-animation: fly3 5000ms 80ms cubic-bezier(0.36, 0.1, 0.16, 1) infinite alternate;
                animation: fly3 5000ms 80ms cubic-bezier(0.36, 0.1, 0.16, 1) infinite alternate;
      }
      @-webkit-keyframes fly3 {
        0% {
          opacity: 0;
          transform: translate(-50%, -50%) rotateX(473deg) rotateY(-5deg) rotateZ(300deg) translateZ(206px);
        }
        10% {
          opacity: 0;
          transform: translate(-50%, -50%) rotateX(473deg) rotateY(-5deg) rotateZ(300deg) translateZ(206px);
        }
        90% {
          opacity: 1;
          transform: translate(-50%, -50%) rotate(0deg) rotateY(0deg) rotateZ(0deg) translateZ(0px);
        }
        100% {
          opacity: 1;
          transform: translate(-50%, -50%) rotate(0deg) rotateY(0deg) rotateZ(0deg) translateZ(0px);
        }
      }
      @keyframes fly3 {
        0% {
          opacity: 0;
          transform: translate(-50%, -50%) rotateX(473deg) rotateY(-5deg) rotateZ(300deg) translateZ(206px);
        }
        10% {
          opacity: 0;
          transform: translate(-50%, -50%) rotateX(473deg) rotateY(-5deg) rotateZ(300deg) translateZ(206px);
        }
        90% {
          opacity: 1;
          transform: translate(-50%, -50%) rotate(0deg) rotateY(0deg) rotateZ(0deg) translateZ(0px);
        }
        100% {
          opacity: 1;
          transform: translate(-50%, -50%) rotate(0deg) rotateY(0deg) rotateZ(0deg) translateZ(0px);
        }
      }
      #websitelogo .text:nth-child(4) {
        color: #f7300c;
        -webkit-clip-path: polygon(10% 0%, 20% 0%, 20% 50%);
                clip-path: polygon(10% 0%, 20% 0%, 20% 50%);
        transform-origin: -7% -19%;
        -webkit-animation: fly4 5000ms 120ms cubic-bezier(0.36, 0.1, 0.16, 1) infinite alternate;
                animation: fly4 5000ms 120ms cubic-bezier(0.36, 0.1, 0.16, 1) infinite alternate;
      }
      @-webkit-keyframes fly4 {
        0% {
          opacity: 0;
          transform: translate(-50%, -50%) rotateX(-332deg) rotateY(-460deg) rotateZ(232deg) translateZ(-1803px);
        }
        10% {
          opacity: 0;
          transform: translate(-50%, -50%) rotateX(-332deg) rotateY(-460deg) rotateZ(232deg) translateZ(-1803px);
        }
        90% {
          opacity: 1;
          transform: translate(-50%, -50%) rotate(0deg) rotateY(0deg) rotateZ(0deg) translateZ(0px);
        }
        100% {
          opacity: 1;
          transform: translate(-50%, -50%) rotate(0deg) rotateY(0deg) rotateZ(0deg) translateZ(0px);
        }
      }
      @keyframes fly4 {
        0% {
          opacity: 0;
          transform: translate(-50%, -50%) rotateX(-332deg) rotateY(-460deg) rotateZ(232deg) translateZ(-1803px);
        }
        10% {
          opacity: 0;
          transform: translate(-50%, -50%) rotateX(-332deg) rotateY(-460deg) rotateZ(232deg) translateZ(-1803px);
        }
        90% {
          opacity: 1;
          transform: translate(-50%, -50%) rotate(0deg) rotateY(0deg) rotateZ(0deg) translateZ(0px);
        }
        100% {
          opacity: 1;
          transform: translate(-50%, -50%) rotate(0deg) rotateY(0deg) rotateZ(0deg) translateZ(0px);
        }
      }
      #websitelogo .text:nth-child(5) {
        color: #f5300f;
        -webkit-clip-path: polygon(20% 0%, 20% 50%, 30% 50%);
                clip-path: polygon(20% 0%, 20% 50%, 30% 50%);
        transform-origin: 57% 29%;
        -webkit-animation: fly5 5000ms 160ms cubic-bezier(0.36, 0.1, 0.16, 1) infinite alternate;
                animation: fly5 5000ms 160ms cubic-bezier(0.36, 0.1, 0.16, 1) infinite alternate;
      }
      @-webkit-keyframes fly5 {
        0% {
          opacity: 0;
          transform: translate(-50%, -50%) rotateX(64deg) rotateY(-347deg) rotateZ(59deg) translateZ(182px);
        }
        10% {
          opacity: 0;
          transform: translate(-50%, -50%) rotateX(64deg) rotateY(-347deg) rotateZ(59deg) translateZ(182px);
        }
        90% {
          opacity: 1;
          transform: translate(-50%, -50%) rotate(0deg) rotateY(0deg) rotateZ(0deg) translateZ(0px);
        }
        100% {
          opacity: 1;
          transform: translate(-50%, -50%) rotate(0deg) rotateY(0deg) rotateZ(0deg) translateZ(0px);
        }
      }
      @keyframes fly5 {
        0% {
          opacity: 0;
          transform: translate(-50%, -50%) rotateX(64deg) rotateY(-347deg) rotateZ(59deg) translateZ(182px);
        }
        10% {
          opacity: 0;
          transform: translate(-50%, -50%) rotateX(64deg) rotateY(-347deg) rotateZ(59deg) translateZ(182px);
        }
        90% {
          opacity: 1;
          transform: translate(-50%, -50%) rotate(0deg) rotateY(0deg) rotateZ(0deg) translateZ(0px);
        }
        100% {
          opacity: 1;
          transform: translate(-50%, -50%) rotate(0deg) rotateY(0deg) rotateZ(0deg) translateZ(0px);
        }
      }
      #websitelogo .text:nth-child(6) {
        color: #f32f12;
        -webkit-clip-path: polygon(20% 0%, 30% 0%, 30% 50%);
                clip-path: polygon(20% 0%, 30% 0%, 30% 50%);
        transform-origin: 16% 23%;
        -webkit-animation: fly6 5000ms 200ms cubic-bezier(0.36, 0.1, 0.16, 1) infinite alternate;
                animation: fly6 5000ms 200ms cubic-bezier(0.36, 0.1, 0.16, 1) infinite alternate;
      }
      @-webkit-keyframes fly6 {
        0% {
          opacity: 0;
          transform: translate(-50%, -50%) rotateX(307deg) rotateY(332deg) rotateZ(164deg) translateZ(-497px);
        }
        10% {
          opacity: 0;
          transform: translate(-50%, -50%) rotateX(307deg) rotateY(332deg) rotateZ(164deg) translateZ(-497px);
        }
        90% {
          opacity: 1;
          transform: translate(-50%, -50%) rotate(0deg) rotateY(0deg) rotateZ(0deg) translateZ(0px);
        }
        100% {
          opacity: 1;
          transform: translate(-50%, -50%) rotate(0deg) rotateY(0deg) rotateZ(0deg) translateZ(0px);
        }
      }
      @keyframes fly6 {
        0% {
          opacity: 0;
          transform: translate(-50%, -50%) rotateX(307deg) rotateY(332deg) rotateZ(164deg) translateZ(-497px);
        }
        10% {
          opacity: 0;
          transform: translate(-50%, -50%) rotateX(307deg) rotateY(332deg) rotateZ(164deg) translateZ(-497px);
        }
        90% {
          opacity: 1;
          transform: translate(-50%, -50%) rotate(0deg) rotateY(0deg) rotateZ(0deg) translateZ(0px);
        }
        100% {
          opacity: 1;
          transform: translate(-50%, -50%) rotate(0deg) rotateY(0deg) rotateZ(0deg) translateZ(0px);
        }
      }
      #websitelogo .text:nth-child(7) {
        color: #f12f15;
        -webkit-clip-path: polygon(30% 0%, 30% 50%, 40% 50%);
                clip-path: polygon(30% 0%, 30% 50%, 40% 50%);
        transform-origin: -1% -44%;
        -webkit-animation: fly7 5000ms 240ms cubic-bezier(0.36, 0.1, 0.16, 1) infinite alternate;
                animation: fly7 5000ms 240ms cubic-bezier(0.36, 0.1, 0.16, 1) infinite alternate;
      }
      @-webkit-keyframes fly7 {
        0% {
          opacity: 0;
          transform: translate(-50%, -50%) rotateX(158deg) rotateY(108deg) rotateZ(-381deg) translateZ(-342px);
        }
        10% {
          opacity: 0;
          transform: translate(-50%, -50%) rotateX(158deg) rotateY(108deg) rotateZ(-381deg) translateZ(-342px);
        }
        90% {
          opacity: 1;
          transform: translate(-50%, -50%) rotate(0deg) rotateY(0deg) rotateZ(0deg) translateZ(0px);
        }
        100% {
          opacity: 1;
          transform: translate(-50%, -50%) rotate(0deg) rotateY(0deg) rotateZ(0deg) translateZ(0px);
        }
      }
      @keyframes fly7 {
        0% {
          opacity: 0;
          transform: translate(-50%, -50%) rotateX(158deg) rotateY(108deg) rotateZ(-381deg) translateZ(-342px);
        }
        10% {
          opacity: 0;
          transform: translate(-50%, -50%) rotateX(158deg) rotateY(108deg) rotateZ(-381deg) translateZ(-342px);
        }
        90% {
          opacity: 1;
          transform: translate(-50%, -50%) rotate(0deg) rotateY(0deg) rotateZ(0deg) translateZ(0px);
        }
        100% {
          opacity: 1;
          transform: translate(-50%, -50%) rotate(0deg) rotateY(0deg) rotateZ(0deg) translateZ(0px);
        }
      }
      #websitelogo .text:nth-child(8) {
        color: #ef2e18;
        -webkit-clip-path: polygon(30% 0%, 40% 0%, 40% 50%);
                clip-path: polygon(30% 0%, 40% 0%, 40% 50%);
        transform-origin: 80% 26%;
        -webkit-animation: fly8 5000ms 280ms cubic-bezier(0.36, 0.1, 0.16, 1) infinite alternate;
                animation: fly8 5000ms 280ms cubic-bezier(0.36, 0.1, 0.16, 1) infinite alternate;
      }
      @-webkit-keyframes fly8 {
        0% {
          opacity: 0;
          transform: translate(-50%, -50%) rotateX(411deg) rotateY(-79deg) rotateZ(-403deg) translateZ(127px);
        }
        10% {
          opacity: 0;
          transform: translate(-50%, -50%) rotateX(411deg) rotateY(-79deg) rotateZ(-403deg) translateZ(127px);
        }
        90% {
          opacity: 1;
          transform: translate(-50%, -50%) rotate(0deg) rotateY(0deg) rotateZ(0deg) translateZ(0px);
        }
        100% {
          opacity: 1;
          transform: translate(-50%, -50%) rotate(0deg) rotateY(0deg) rotateZ(0deg) translateZ(0px);
        }
      }
      @keyframes fly8 {
        0% {
          opacity: 0;
          transform: translate(-50%, -50%) rotateX(411deg) rotateY(-79deg) rotateZ(-403deg) translateZ(127px);
        }
        10% {
          opacity: 0;
          transform: translate(-50%, -50%) rotateX(411deg) rotateY(-79deg) rotateZ(-403deg) translateZ(127px);
        }
        90% {
          opacity: 1;
          transform: translate(-50%, -50%) rotate(0deg) rotateY(0deg) rotateZ(0deg) translateZ(0px);
        }
        100% {
          opacity: 1;
          transform: translate(-50%, -50%) rotate(0deg) rotateY(0deg) rotateZ(0deg) translateZ(0px);
        }
      }
      #websitelogo .text:nth-child(9) {
        color: #ed2e1b;
        -webkit-clip-path: polygon(40% 0%, 40% 50%, 50% 50%);
                clip-path: polygon(40% 0%, 40% 50%, 50% 50%);
        transform-origin: 32% -41%;
        -webkit-animation: fly9 5000ms 320ms cubic-bezier(0.36, 0.1, 0.16, 1) infinite alternate;
                animation: fly9 5000ms 320ms cubic-bezier(0.36, 0.1, 0.16, 1) infinite alternate;
      }
      @-webkit-keyframes fly9 {
        0% {
          opacity: 0;
          transform: translate(-50%, -50%) rotateX(-248deg) rotateY(-493deg) rotateZ(127deg) translateZ(197px);
        }
        10% {
          opacity: 0;
          transform: translate(-50%, -50%) rotateX(-248deg) rotateY(-493deg) rotateZ(127deg) translateZ(197px);
        }
        90% {
          opacity: 1;
          transform: translate(-50%, -50%) rotate(0deg) rotateY(0deg) rotateZ(0deg) translateZ(0px);
        }
        100% {
          opacity: 1;
          transform: translate(-50%, -50%) rotate(0deg) rotateY(0deg) rotateZ(0deg) translateZ(0px);
        }
      }
      @keyframes fly9 {
        0% {
          opacity: 0;
          transform: translate(-50%, -50%) rotateX(-248deg) rotateY(-493deg) rotateZ(127deg) translateZ(197px);
        }
        10% {
          opacity: 0;
          transform: translate(-50%, -50%) rotateX(-248deg) rotateY(-493deg) rotateZ(127deg) translateZ(197px);
        }
        90% {
          opacity: 1;
          transform: translate(-50%, -50%) rotate(0deg) rotateY(0deg) rotateZ(0deg) translateZ(0px);
        }
        100% {
          opacity: 1;
          transform: translate(-50%, -50%) rotate(0deg) rotateY(0deg) rotateZ(0deg) translateZ(0px);
        }
      }
      #websitelogo .text:nth-child(10) {
        color: #eb2d1e;
        -webkit-clip-path: polygon(40% 0%, 50% 0%, 50% 50%);
                clip-path: polygon(40% 0%, 50% 0%, 50% 50%);
        transform-origin: 4% 50%;
        -webkit-animation: fly10 5000ms 360ms cubic-bezier(0.36, 0.1, 0.16, 1) infinite alternate;
                animation: fly10 5000ms 360ms cubic-bezier(0.36, 0.1, 0.16, 1) infinite alternate;
      }
      @-webkit-keyframes fly10 {
        0% {
          opacity: 0;
          transform: translate(-50%, -50%) rotateX(-57deg) rotateY(106deg) rotateZ(-431deg) translateZ(-1426px);
        }
        10% {
          opacity: 0;
          transform: translate(-50%, -50%) rotateX(-57deg) rotateY(106deg) rotateZ(-431deg) translateZ(-1426px);
        }
        90% {
          opacity: 1;
          transform: translate(-50%, -50%) rotate(0deg) rotateY(0deg) rotateZ(0deg) translateZ(0px);
        }
        100% {
          opacity: 1;
          transform: translate(-50%, -50%) rotate(0deg) rotateY(0deg) rotateZ(0deg) translateZ(0px);
        }
      }
      @keyframes fly10 {
        0% {
          opacity: 0;
          transform: translate(-50%, -50%) rotateX(-57deg) rotateY(106deg) rotateZ(-431deg) translateZ(-1426px);
        }
        10% {
          opacity: 0;
          transform: translate(-50%, -50%) rotateX(-57deg) rotateY(106deg) rotateZ(-431deg) translateZ(-1426px);
        }
        90% {
          opacity: 1;
          transform: translate(-50%, -50%) rotate(0deg) rotateY(0deg) rotateZ(0deg) translateZ(0px);
        }
        100% {
          opacity: 1;
          transform: translate(-50%, -50%) rotate(0deg) rotateY(0deg) rotateZ(0deg) translateZ(0px);
        }
      }
      #websitelogo .text:nth-child(11) {
        color: #e92d21;
        -webkit-clip-path: polygon(50% 0%, 50% 50%, 60% 50%);
                clip-path: polygon(50% 0%, 50% 50%, 60% 50%);
        transform-origin: 57% 41%;
        -webkit-animation: fly11 5000ms 400ms cubic-bezier(0.36, 0.1, 0.16, 1) infinite alternate;
                animation: fly11 5000ms 400ms cubic-bezier(0.36, 0.1, 0.16, 1) infinite alternate;
      }
      @-webkit-keyframes fly11 {
        0% {
          opacity: 0;
          transform: translate(-50%, -50%) rotateX(-289deg) rotateY(183deg) rotateZ(297deg) translateZ(-1187px);
        }
        10% {
          opacity: 0;
          transform: translate(-50%, -50%) rotateX(-289deg) rotateY(183deg) rotateZ(297deg) translateZ(-1187px);
        }
        90% {
          opacity: 1;
          transform: translate(-50%, -50%) rotate(0deg) rotateY(0deg) rotateZ(0deg) translateZ(0px);
        }
        100% {
          opacity: 1;
          transform: translate(-50%, -50%) rotate(0deg) rotateY(0deg) rotateZ(0deg) translateZ(0px);
        }
      }
      @keyframes fly11 {
        0% {
          opacity: 0;
          transform: translate(-50%, -50%) rotateX(-289deg) rotateY(183deg) rotateZ(297deg) translateZ(-1187px);
        }
        10% {
          opacity: 0;
          transform: translate(-50%, -50%) rotateX(-289deg) rotateY(183deg) rotateZ(297deg) translateZ(-1187px);
        }
        90% {
          opacity: 1;
          transform: translate(-50%, -50%) rotate(0deg) rotateY(0deg) rotateZ(0deg) translateZ(0px);
        }
        100% {
          opacity: 1;
          transform: translate(-50%, -50%) rotate(0deg) rotateY(0deg) rotateZ(0deg) translateZ(0px);
        }
      }
      #websitelogo .text:nth-child(12) {
        color: #e72c24;
        -webkit-clip-path: polygon(50% 0%, 60% 0%, 60% 50%);
                clip-path: polygon(50% 0%, 60% 0%, 60% 50%);
        transform-origin: 58% 35%;
        -webkit-animation: fly12 5000ms 440ms cubic-bezier(0.36, 0.1, 0.16, 1) infinite alternate;
                animation: fly12 5000ms 440ms cubic-bezier(0.36, 0.1, 0.16, 1) infinite alternate;
      }
      @-webkit-keyframes fly12 {
        0% {
          opacity: 0;
          transform: translate(-50%, -50%) rotateX(467deg) rotateY(108deg) rotateZ(-310deg) translateZ(-490px);
        }
        10% {
          opacity: 0;
          transform: translate(-50%, -50%) rotateX(467deg) rotateY(108deg) rotateZ(-310deg) translateZ(-490px);
        }
        90% {
          opacity: 1;
          transform: translate(-50%, -50%) rotate(0deg) rotateY(0deg) rotateZ(0deg) translateZ(0px);
        }
        100% {
          opacity: 1;
          transform: translate(-50%, -50%) rotate(0deg) rotateY(0deg) rotateZ(0deg) translateZ(0px);
        }
      }
      @keyframes fly12 {
        0% {
          opacity: 0;
          transform: translate(-50%, -50%) rotateX(467deg) rotateY(108deg) rotateZ(-310deg) translateZ(-490px);
        }
        10% {
          opacity: 0;
          transform: translate(-50%, -50%) rotateX(467deg) rotateY(108deg) rotateZ(-310deg) translateZ(-490px);
        }
        90% {
          opacity: 1;
          transform: translate(-50%, -50%) rotate(0deg) rotateY(0deg) rotateZ(0deg) translateZ(0px);
        }
        100% {
          opacity: 1;
          transform: translate(-50%, -50%) rotate(0deg) rotateY(0deg) rotateZ(0deg) translateZ(0px);
        }
      }
      #websitelogo .text:nth-child(13) {
        color: #e52c27;
        -webkit-clip-path: polygon(60% 0%, 60% 50%, 70% 50%);
                clip-path: polygon(60% 0%, 60% 50%, 70% 50%);
        transform-origin: 44% -49%;
        -webkit-animation: fly13 5000ms 480ms cubic-bezier(0.36, 0.1, 0.16, 1) infinite alternate;
                animation: fly13 5000ms 480ms cubic-bezier(0.36, 0.1, 0.16, 1) infinite alternate;
      }
      @-webkit-keyframes fly13 {
        0% {
          opacity: 0;
          transform: translate(-50%, -50%) rotateX(-463deg) rotateY(130deg) rotateZ(-8deg) translateZ(-84px);
        }
        10% {
          opacity: 0;
          transform: translate(-50%, -50%) rotateX(-463deg) rotateY(130deg) rotateZ(-8deg) translateZ(-84px);
        }
        90% {
          opacity: 1;
          transform: translate(-50%, -50%) rotate(0deg) rotateY(0deg) rotateZ(0deg) translateZ(0px);
        }
        100% {
          opacity: 1;
          transform: translate(-50%, -50%) rotate(0deg) rotateY(0deg) rotateZ(0deg) translateZ(0px);
        }
      }
      @keyframes fly13 {
        0% {
          opacity: 0;
          transform: translate(-50%, -50%) rotateX(-463deg) rotateY(130deg) rotateZ(-8deg) translateZ(-84px);
        }
        10% {
          opacity: 0;
          transform: translate(-50%, -50%) rotateX(-463deg) rotateY(130deg) rotateZ(-8deg) translateZ(-84px);
        }
        90% {
          opacity: 1;
          transform: translate(-50%, -50%) rotate(0deg) rotateY(0deg) rotateZ(0deg) translateZ(0px);
        }
        100% {
          opacity: 1;
          transform: translate(-50%, -50%) rotate(0deg) rotateY(0deg) rotateZ(0deg) translateZ(0px);
        }
      }
      #websitelogo .text:nth-child(14) {
        color: #e32b2a;
        -webkit-clip-path: polygon(60% 0%, 70% 0%, 70% 50%);
                clip-path: polygon(60% 0%, 70% 0%, 70% 50%);
        transform-origin: 103% 11%;
        -webkit-animation: fly14 5000ms 520ms cubic-bezier(0.36, 0.1, 0.16, 1) infinite alternate;
                animation: fly14 5000ms 520ms cubic-bezier(0.36, 0.1, 0.16, 1) infinite alternate;
      }
      @-webkit-keyframes fly14 {
        0% {
          opacity: 0;
          transform: translate(-50%, -50%) rotateX(412deg) rotateY(-498deg) rotateZ(17deg) translateZ(-1120px);
        }
        10% {
          opacity: 0;
          transform: translate(-50%, -50%) rotateX(412deg) rotateY(-498deg) rotateZ(17deg) translateZ(-1120px);
        }
        90% {
          opacity: 1;
          transform: translate(-50%, -50%) rotate(0deg) rotateY(0deg) rotateZ(0deg) translateZ(0px);
        }
        100% {
          opacity: 1;
          transform: translate(-50%, -50%) rotate(0deg) rotateY(0deg) rotateZ(0deg) translateZ(0px);
        }
      }
      @keyframes fly14 {
        0% {
          opacity: 0;
          transform: translate(-50%, -50%) rotateX(412deg) rotateY(-498deg) rotateZ(17deg) translateZ(-1120px);
        }
        10% {
          opacity: 0;
          transform: translate(-50%, -50%) rotateX(412deg) rotateY(-498deg) rotateZ(17deg) translateZ(-1120px);
        }
        90% {
          opacity: 1;
          transform: translate(-50%, -50%) rotate(0deg) rotateY(0deg) rotateZ(0deg) translateZ(0px);
        }
        100% {
          opacity: 1;
          transform: translate(-50%, -50%) rotate(0deg) rotateY(0deg) rotateZ(0deg) translateZ(0px);
        }
      }
      #websitelogo .text:nth-child(15) {
        color: #e12b2d;
        -webkit-clip-path: polygon(70% 0%, 70% 50%, 80% 50%);
                clip-path: polygon(70% 0%, 70% 50%, 80% 50%);
        transform-origin: 88% -33%;
        -webkit-animation: fly15 5000ms 560ms cubic-bezier(0.36, 0.1, 0.16, 1) infinite alternate;
                animation: fly15 5000ms 560ms cubic-bezier(0.36, 0.1, 0.16, 1) infinite alternate;
      }
      @-webkit-keyframes fly15 {
        0% {
          opacity: 0;
          transform: translate(-50%, -50%) rotateX(-302deg) rotateY(-143deg) rotateZ(-174deg) translateZ(-903px);
        }
        10% {
          opacity: 0;
          transform: translate(-50%, -50%) rotateX(-302deg) rotateY(-143deg) rotateZ(-174deg) translateZ(-903px);
        }
        90% {
          opacity: 1;
          transform: translate(-50%, -50%) rotate(0deg) rotateY(0deg) rotateZ(0deg) translateZ(0px);
        }
        100% {
          opacity: 1;
          transform: translate(-50%, -50%) rotate(0deg) rotateY(0deg) rotateZ(0deg) translateZ(0px);
        }
      }
      @keyframes fly15 {
        0% {
          opacity: 0;
          transform: translate(-50%, -50%) rotateX(-302deg) rotateY(-143deg) rotateZ(-174deg) translateZ(-903px);
        }
        10% {
          opacity: 0;
          transform: translate(-50%, -50%) rotateX(-302deg) rotateY(-143deg) rotateZ(-174deg) translateZ(-903px);
        }
        90% {
          opacity: 1;
          transform: translate(-50%, -50%) rotate(0deg) rotateY(0deg) rotateZ(0deg) translateZ(0px);
        }
        100% {
          opacity: 1;
          transform: translate(-50%, -50%) rotate(0deg) rotateY(0deg) rotateZ(0deg) translateZ(0px);
        }
      }
      #websitelogo .text:nth-child(16) {
        color: #df2a30;
        -webkit-clip-path: polygon(70% 0%, 80% 0%, 80% 50%);
                clip-path: polygon(70% 0%, 80% 0%, 80% 50%);
        transform-origin: 21% 32%;
        -webkit-animation: fly16 5000ms 600ms cubic-bezier(0.36, 0.1, 0.16, 1) infinite alternate;
                animation: fly16 5000ms 600ms cubic-bezier(0.36, 0.1, 0.16, 1) infinite alternate;
      }
      @-webkit-keyframes fly16 {
        0% {
          opacity: 0;
          transform: translate(-50%, -50%) rotateX(440deg) rotateY(330deg) rotateZ(86deg) translateZ(-723px);
        }
        10% {
          opacity: 0;
          transform: translate(-50%, -50%) rotateX(440deg) rotateY(330deg) rotateZ(86deg) translateZ(-723px);
        }
        90% {
          opacity: 1;
          transform: translate(-50%, -50%) rotate(0deg) rotateY(0deg) rotateZ(0deg) translateZ(0px);
        }
        100% {
          opacity: 1;
          transform: translate(-50%, -50%) rotate(0deg) rotateY(0deg) rotateZ(0deg) translateZ(0px);
        }
      }
      @keyframes fly16 {
        0% {
          opacity: 0;
          transform: translate(-50%, -50%) rotateX(440deg) rotateY(330deg) rotateZ(86deg) translateZ(-723px);
        }
        10% {
          opacity: 0;
          transform: translate(-50%, -50%) rotateX(440deg) rotateY(330deg) rotateZ(86deg) translateZ(-723px);
        }
        90% {
          opacity: 1;
          transform: translate(-50%, -50%) rotate(0deg) rotateY(0deg) rotateZ(0deg) translateZ(0px);
        }
        100% {
          opacity: 1;
          transform: translate(-50%, -50%) rotate(0deg) rotateY(0deg) rotateZ(0deg) translateZ(0px);
        }
      }
      #websitelogo .text:nth-child(17) {
        color: #dd2a33;
        -webkit-clip-path: polygon(80% 0%, 80% 50%, 90% 50%);
                clip-path: polygon(80% 0%, 80% 50%, 90% 50%);
        transform-origin: 111% -13%;
        -webkit-animation: fly17 5000ms 640ms cubic-bezier(0.36, 0.1, 0.16, 1) infinite alternate;
                animation: fly17 5000ms 640ms cubic-bezier(0.36, 0.1, 0.16, 1) infinite alternate;
      }
      @-webkit-keyframes fly17 {
        0% {
          opacity: 0;
          transform: translate(-50%, -50%) rotateX(-252deg) rotateY(425deg) rotateZ(-94deg) translateZ(-202px);
        }
        10% {
          opacity: 0;
          transform: translate(-50%, -50%) rotateX(-252deg) rotateY(425deg) rotateZ(-94deg) translateZ(-202px);
        }
        90% {
          opacity: 1;
          transform: translate(-50%, -50%) rotate(0deg) rotateY(0deg) rotateZ(0deg) translateZ(0px);
        }
        100% {
          opacity: 1;
          transform: translate(-50%, -50%) rotate(0deg) rotateY(0deg) rotateZ(0deg) translateZ(0px);
        }
      }
      @keyframes fly17 {
        0% {
          opacity: 0;
          transform: translate(-50%, -50%) rotateX(-252deg) rotateY(425deg) rotateZ(-94deg) translateZ(-202px);
        }
        10% {
          opacity: 0;
          transform: translate(-50%, -50%) rotateX(-252deg) rotateY(425deg) rotateZ(-94deg) translateZ(-202px);
        }
        90% {
          opacity: 1;
          transform: translate(-50%, -50%) rotate(0deg) rotateY(0deg) rotateZ(0deg) translateZ(0px);
        }
        100% {
          opacity: 1;
          transform: translate(-50%, -50%) rotate(0deg) rotateY(0deg) rotateZ(0deg) translateZ(0px);
        }
      }
      #websitelogo .text:nth-child(18) {
        color: #db2936;
        -webkit-clip-path: polygon(80% 0%, 90% 0%, 90% 50%);
                clip-path: polygon(80% 0%, 90% 0%, 90% 50%);
        transform-origin: 31% 39%;
        -webkit-animation: fly18 5000ms 680ms cubic-bezier(0.36, 0.1, 0.16, 1) infinite alternate;
                animation: fly18 5000ms 680ms cubic-bezier(0.36, 0.1, 0.16, 1) infinite alternate;
      }
      @-webkit-keyframes fly18 {
        0% {
          opacity: 0;
          transform: translate(-50%, -50%) rotateX(161deg) rotateY(-229deg) rotateZ(70deg) translateZ(70px);
        }
        10% {
          opacity: 0;
          transform: translate(-50%, -50%) rotateX(161deg) rotateY(-229deg) rotateZ(70deg) translateZ(70px);
        }
        90% {
          opacity: 1;
          transform: translate(-50%, -50%) rotate(0deg) rotateY(0deg) rotateZ(0deg) translateZ(0px);
        }
        100% {
          opacity: 1;
          transform: translate(-50%, -50%) rotate(0deg) rotateY(0deg) rotateZ(0deg) translateZ(0px);
        }
      }
      @keyframes fly18 {
        0% {
          opacity: 0;
          transform: translate(-50%, -50%) rotateX(161deg) rotateY(-229deg) rotateZ(70deg) translateZ(70px);
        }
        10% {
          opacity: 0;
          transform: translate(-50%, -50%) rotateX(161deg) rotateY(-229deg) rotateZ(70deg) translateZ(70px);
        }
        90% {
          opacity: 1;
          transform: translate(-50%, -50%) rotate(0deg) rotateY(0deg) rotateZ(0deg) translateZ(0px);
        }
        100% {
          opacity: 1;
          transform: translate(-50%, -50%) rotate(0deg) rotateY(0deg) rotateZ(0deg) translateZ(0px);
        }
      }
      #websitelogo .text:nth-child(19) {
        color: #d92939;
        -webkit-clip-path: polygon(90% 0%, 90% 50%, 100% 50%);
                clip-path: polygon(90% 0%, 90% 50%, 100% 50%);
        transform-origin: 101% 43%;
        -webkit-animation: fly19 5000ms 720ms cubic-bezier(0.36, 0.1, 0.16, 1) infinite alternate;
                animation: fly19 5000ms 720ms cubic-bezier(0.36, 0.1, 0.16, 1) infinite alternate;
      }
      @-webkit-keyframes fly19 {
        0% {
          opacity: 0;
          transform: translate(-50%, -50%) rotateX(55deg) rotateY(422deg) rotateZ(-180deg) translateZ(-1864px);
        }
        10% {
          opacity: 0;
          transform: translate(-50%, -50%) rotateX(55deg) rotateY(422deg) rotateZ(-180deg) translateZ(-1864px);
        }
        90% {
          opacity: 1;
          transform: translate(-50%, -50%) rotate(0deg) rotateY(0deg) rotateZ(0deg) translateZ(0px);
        }
        100% {
          opacity: 1;
          transform: translate(-50%, -50%) rotate(0deg) rotateY(0deg) rotateZ(0deg) translateZ(0px);
        }
      }
      @keyframes fly19 {
        0% {
          opacity: 0;
          transform: translate(-50%, -50%) rotateX(55deg) rotateY(422deg) rotateZ(-180deg) translateZ(-1864px);
        }
        10% {
          opacity: 0;
          transform: translate(-50%, -50%) rotateX(55deg) rotateY(422deg) rotateZ(-180deg) translateZ(-1864px);
        }
        90% {
          opacity: 1;
          transform: translate(-50%, -50%) rotate(0deg) rotateY(0deg) rotateZ(0deg) translateZ(0px);
        }
        100% {
          opacity: 1;
          transform: translate(-50%, -50%) rotate(0deg) rotateY(0deg) rotateZ(0deg) translateZ(0px);
        }
      }
      #websitelogo .text:nth-child(20) {
        color: #d7283c;
        -webkit-clip-path: polygon(90% 0%, 100% 0%, 100% 50%);
                clip-path: polygon(90% 0%, 100% 0%, 100% 50%);
        transform-origin: 104% 48%;
        -webkit-animation: fly20 5000ms 760ms cubic-bezier(0.36, 0.1, 0.16, 1) infinite alternate;
                animation: fly20 5000ms 760ms cubic-bezier(0.36, 0.1, 0.16, 1) infinite alternate;
      }
      @-webkit-keyframes fly20 {
        0% {
          opacity: 0;
          transform: translate(-50%, -50%) rotateX(398deg) rotateY(140deg) rotateZ(-333deg) translateZ(-1007px);
        }
        10% {
          opacity: 0;
          transform: translate(-50%, -50%) rotateX(398deg) rotateY(140deg) rotateZ(-333deg) translateZ(-1007px);
        }
        90% {
          opacity: 1;
          transform: translate(-50%, -50%) rotate(0deg) rotateY(0deg) rotateZ(0deg) translateZ(0px);
        }
        100% {
          opacity: 1;
          transform: translate(-50%, -50%) rotate(0deg) rotateY(0deg) rotateZ(0deg) translateZ(0px);
        }
      }
      @keyframes fly20 {
        0% {
          opacity: 0;
          transform: translate(-50%, -50%) rotateX(398deg) rotateY(140deg) rotateZ(-333deg) translateZ(-1007px);
        }
        10% {
          opacity: 0;
          transform: translate(-50%, -50%) rotateX(398deg) rotateY(140deg) rotateZ(-333deg) translateZ(-1007px);
        }
        90% {
          opacity: 1;
          transform: translate(-50%, -50%) rotate(0deg) rotateY(0deg) rotateZ(0deg) translateZ(0px);
        }
        100% {
          opacity: 1;
          transform: translate(-50%, -50%) rotate(0deg) rotateY(0deg) rotateZ(0deg) translateZ(0px);
        }
      }
      #websitelogo .text:nth-child(21) {
        color: #d5283f;
        -webkit-clip-path: polygon(0% 50%, 0% 100%, 10% 100%);
                clip-path: polygon(0% 50%, 0% 100%, 10% 100%);
        transform-origin: 1% 72%;
        -webkit-animation: fly21 5000ms 800ms cubic-bezier(0.36, 0.1, 0.16, 1) infinite alternate;
                animation: fly21 5000ms 800ms cubic-bezier(0.36, 0.1, 0.16, 1) infinite alternate;
      }
      @-webkit-keyframes fly21 {
        0% {
          opacity: 0;
          transform: translate(-50%, -50%) rotateX(-284deg) rotateY(466deg) rotateZ(-159deg) translateZ(-248px);
        }
        10% {
          opacity: 0;
          transform: translate(-50%, -50%) rotateX(-284deg) rotateY(466deg) rotateZ(-159deg) translateZ(-248px);
        }
        90% {
          opacity: 1;
          transform: translate(-50%, -50%) rotate(0deg) rotateY(0deg) rotateZ(0deg) translateZ(0px);
        }
        100% {
          opacity: 1;
          transform: translate(-50%, -50%) rotate(0deg) rotateY(0deg) rotateZ(0deg) translateZ(0px);
        }
      }
      @keyframes fly21 {
        0% {
          opacity: 0;
          transform: translate(-50%, -50%) rotateX(-284deg) rotateY(466deg) rotateZ(-159deg) translateZ(-248px);
        }
        10% {
          opacity: 0;
          transform: translate(-50%, -50%) rotateX(-284deg) rotateY(466deg) rotateZ(-159deg) translateZ(-248px);
        }
        90% {
          opacity: 1;
          transform: translate(-50%, -50%) rotate(0deg) rotateY(0deg) rotateZ(0deg) translateZ(0px);
        }
        100% {
          opacity: 1;
          transform: translate(-50%, -50%) rotate(0deg) rotateY(0deg) rotateZ(0deg) translateZ(0px);
        }
      }
      #websitelogo .text:nth-child(22) {
        color: #d32742;
        -webkit-clip-path: polygon(0% 50%, 10% 50%, 10% 100%);
                clip-path: polygon(0% 50%, 10% 50%, 10% 100%);
        transform-origin: -41% 99%;
        -webkit-animation: fly22 5000ms 840ms cubic-bezier(0.36, 0.1, 0.16, 1) infinite alternate;
                animation: fly22 5000ms 840ms cubic-bezier(0.36, 0.1, 0.16, 1) infinite alternate;
      }
      @-webkit-keyframes fly22 {
        0% {
          opacity: 0;
          transform: translate(-50%, -50%) rotateX(-176deg) rotateY(-156deg) rotateZ(48deg) translateZ(-1138px);
        }
        10% {
          opacity: 0;
          transform: translate(-50%, -50%) rotateX(-176deg) rotateY(-156deg) rotateZ(48deg) translateZ(-1138px);
        }
        90% {
          opacity: 1;
          transform: translate(-50%, -50%) rotate(0deg) rotateY(0deg) rotateZ(0deg) translateZ(0px);
        }
        100% {
          opacity: 1;
          transform: translate(-50%, -50%) rotate(0deg) rotateY(0deg) rotateZ(0deg) translateZ(0px);
        }
      }
      @keyframes fly22 {
        0% {
          opacity: 0;
          transform: translate(-50%, -50%) rotateX(-176deg) rotateY(-156deg) rotateZ(48deg) translateZ(-1138px);
        }
        10% {
          opacity: 0;
          transform: translate(-50%, -50%) rotateX(-176deg) rotateY(-156deg) rotateZ(48deg) translateZ(-1138px);
        }
        90% {
          opacity: 1;
          transform: translate(-50%, -50%) rotate(0deg) rotateY(0deg) rotateZ(0deg) translateZ(0px);
        }
        100% {
          opacity: 1;
          transform: translate(-50%, -50%) rotate(0deg) rotateY(0deg) rotateZ(0deg) translateZ(0px);
        }
      }
      #websitelogo .text:nth-child(23) {
        color: #d12745;
        -webkit-clip-path: polygon(10% 50%, 10% 100%, 20% 100%);
                clip-path: polygon(10% 50%, 10% 100%, 20% 100%);
        transform-origin: -32% 45%;
        -webkit-animation: fly23 5000ms 880ms cubic-bezier(0.36, 0.1, 0.16, 1) infinite alternate;
                animation: fly23 5000ms 880ms cubic-bezier(0.36, 0.1, 0.16, 1) infinite alternate;
      }
      @-webkit-keyframes fly23 {
        0% {
          opacity: 0;
          transform: translate(-50%, -50%) rotateX(395deg) rotateY(426deg) rotateZ(-131deg) translateZ(-1236px);
        }
        10% {
          opacity: 0;
          transform: translate(-50%, -50%) rotateX(395deg) rotateY(426deg) rotateZ(-131deg) translateZ(-1236px);
        }
        90% {
          opacity: 1;
          transform: translate(-50%, -50%) rotate(0deg) rotateY(0deg) rotateZ(0deg) translateZ(0px);
        }
        100% {
          opacity: 1;
          transform: translate(-50%, -50%) rotate(0deg) rotateY(0deg) rotateZ(0deg) translateZ(0px);
        }
      }
      @keyframes fly23 {
        0% {
          opacity: 0;
          transform: translate(-50%, -50%) rotateX(395deg) rotateY(426deg) rotateZ(-131deg) translateZ(-1236px);
        }
        10% {
          opacity: 0;
          transform: translate(-50%, -50%) rotateX(395deg) rotateY(426deg) rotateZ(-131deg) translateZ(-1236px);
        }
        90% {
          opacity: 1;
          transform: translate(-50%, -50%) rotate(0deg) rotateY(0deg) rotateZ(0deg) translateZ(0px);
        }
        100% {
          opacity: 1;
          transform: translate(-50%, -50%) rotate(0deg) rotateY(0deg) rotateZ(0deg) translateZ(0px);
        }
      }
      #websitelogo .text:nth-child(24) {
        color: #cf2648;
        -webkit-clip-path: polygon(10% 50%, 20% 50%, 20% 100%);
                clip-path: polygon(10% 50%, 20% 50%, 20% 100%);
        transform-origin: -17% 65%;
        -webkit-animation: fly24 5000ms 920ms cubic-bezier(0.36, 0.1, 0.16, 1) infinite alternate;
                animation: fly24 5000ms 920ms cubic-bezier(0.36, 0.1, 0.16, 1) infinite alternate;
      }
      @-webkit-keyframes fly24 {
        0% {
          opacity: 0;
          transform: translate(-50%, -50%) rotateX(-469deg) rotateY(-67deg) rotateZ(-89deg) translateZ(-2412px);
        }
        10% {
          opacity: 0;
          transform: translate(-50%, -50%) rotateX(-469deg) rotateY(-67deg) rotateZ(-89deg) translateZ(-2412px);
        }
        90% {
          opacity: 1;
          transform: translate(-50%, -50%) rotate(0deg) rotateY(0deg) rotateZ(0deg) translateZ(0px);
        }
        100% {
          opacity: 1;
          transform: translate(-50%, -50%) rotate(0deg) rotateY(0deg) rotateZ(0deg) translateZ(0px);
        }
      }
      @keyframes fly24 {
        0% {
          opacity: 0;
          transform: translate(-50%, -50%) rotateX(-469deg) rotateY(-67deg) rotateZ(-89deg) translateZ(-2412px);
        }
        10% {
          opacity: 0;
          transform: translate(-50%, -50%) rotateX(-469deg) rotateY(-67deg) rotateZ(-89deg) translateZ(-2412px);
        }
        90% {
          opacity: 1;
          transform: translate(-50%, -50%) rotate(0deg) rotateY(0deg) rotateZ(0deg) translateZ(0px);
        }
        100% {
          opacity: 1;
          transform: translate(-50%, -50%) rotate(0deg) rotateY(0deg) rotateZ(0deg) translateZ(0px);
        }
      }
      #websitelogo .text:nth-child(25) {
        color: #cd264b;
        -webkit-clip-path: polygon(20% 50%, 20% 100%, 30% 100%);
                clip-path: polygon(20% 50%, 20% 100%, 30% 100%);
        transform-origin: 5% 64%;
        -webkit-animation: fly25 5000ms 960ms cubic-bezier(0.36, 0.1, 0.16, 1) infinite alternate;
                animation: fly25 5000ms 960ms cubic-bezier(0.36, 0.1, 0.16, 1) infinite alternate;
      }
      @-webkit-keyframes fly25 {
        0% {
          opacity: 0;
          transform: translate(-50%, -50%) rotateX(-109deg) rotateY(-144deg) rotateZ(-216deg) translateZ(388px);
        }
        10% {
          opacity: 0;
          transform: translate(-50%, -50%) rotateX(-109deg) rotateY(-144deg) rotateZ(-216deg) translateZ(388px);
        }
        90% {
          opacity: 1;
          transform: translate(-50%, -50%) rotate(0deg) rotateY(0deg) rotateZ(0deg) translateZ(0px);
        }
        100% {
          opacity: 1;
          transform: translate(-50%, -50%) rotate(0deg) rotateY(0deg) rotateZ(0deg) translateZ(0px);
        }
      }
      @keyframes fly25 {
        0% {
          opacity: 0;
          transform: translate(-50%, -50%) rotateX(-109deg) rotateY(-144deg) rotateZ(-216deg) translateZ(388px);
        }
        10% {
          opacity: 0;
          transform: translate(-50%, -50%) rotateX(-109deg) rotateY(-144deg) rotateZ(-216deg) translateZ(388px);
        }
        90% {
          opacity: 1;
          transform: translate(-50%, -50%) rotate(0deg) rotateY(0deg) rotateZ(0deg) translateZ(0px);
        }
        100% {
          opacity: 1;
          transform: translate(-50%, -50%) rotate(0deg) rotateY(0deg) rotateZ(0deg) translateZ(0px);
        }
      }
      #websitelogo .text:nth-child(26) {
        color: #cb254e;
        -webkit-clip-path: polygon(20% 50%, 30% 50%, 30% 100%);
                clip-path: polygon(20% 50%, 30% 50%, 30% 100%);
        transform-origin: 69% 63%;
        -webkit-animation: fly26 5000ms 1000ms cubic-bezier(0.36, 0.1, 0.16, 1) infinite alternate;
                animation: fly26 5000ms 1000ms cubic-bezier(0.36, 0.1, 0.16, 1) infinite alternate;
      }
      @-webkit-keyframes fly26 {
        0% {
          opacity: 0;
          transform: translate(-50%, -50%) rotateX(-276deg) rotateY(103deg) rotateZ(98deg) translateZ(-790px);
        }
        10% {
          opacity: 0;
          transform: translate(-50%, -50%) rotateX(-276deg) rotateY(103deg) rotateZ(98deg) translateZ(-790px);
        }
        90% {
          opacity: 1;
          transform: translate(-50%, -50%) rotate(0deg) rotateY(0deg) rotateZ(0deg) translateZ(0px);
        }
        100% {
          opacity: 1;
          transform: translate(-50%, -50%) rotate(0deg) rotateY(0deg) rotateZ(0deg) translateZ(0px);
        }
      }
      @keyframes fly26 {
        0% {
          opacity: 0;
          transform: translate(-50%, -50%) rotateX(-276deg) rotateY(103deg) rotateZ(98deg) translateZ(-790px);
        }
        10% {
          opacity: 0;
          transform: translate(-50%, -50%) rotateX(-276deg) rotateY(103deg) rotateZ(98deg) translateZ(-790px);
        }
        90% {
          opacity: 1;
          transform: translate(-50%, -50%) rotate(0deg) rotateY(0deg) rotateZ(0deg) translateZ(0px);
        }
        100% {
          opacity: 1;
          transform: translate(-50%, -50%) rotate(0deg) rotateY(0deg) rotateZ(0deg) translateZ(0px);
        }
      }
      #websitelogo .text:nth-child(27) {
        color: #c92551;
        -webkit-clip-path: polygon(30% 50%, 30% 100%, 40% 100%);
                clip-path: polygon(30% 50%, 30% 100%, 40% 100%);
        transform-origin: 47% 71%;
        -webkit-animation: fly27 5000ms 1040ms cubic-bezier(0.36, 0.1, 0.16, 1) infinite alternate;
                animation: fly27 5000ms 1040ms cubic-bezier(0.36, 0.1, 0.16, 1) infinite alternate;
      }
      @-webkit-keyframes fly27 {
        0% {
          opacity: 0;
          transform: translate(-50%, -50%) rotateX(-46deg) rotateY(240deg) rotateZ(153deg) translateZ(-2045px);
        }
        10% {
          opacity: 0;
          transform: translate(-50%, -50%) rotateX(-46deg) rotateY(240deg) rotateZ(153deg) translateZ(-2045px);
        }
        90% {
          opacity: 1;
          transform: translate(-50%, -50%) rotate(0deg) rotateY(0deg) rotateZ(0deg) translateZ(0px);
        }
        100% {
          opacity: 1;
          transform: translate(-50%, -50%) rotate(0deg) rotateY(0deg) rotateZ(0deg) translateZ(0px);
        }
      }
      @keyframes fly27 {
        0% {
          opacity: 0;
          transform: translate(-50%, -50%) rotateX(-46deg) rotateY(240deg) rotateZ(153deg) translateZ(-2045px);
        }
        10% {
          opacity: 0;
          transform: translate(-50%, -50%) rotateX(-46deg) rotateY(240deg) rotateZ(153deg) translateZ(-2045px);
        }
        90% {
          opacity: 1;
          transform: translate(-50%, -50%) rotate(0deg) rotateY(0deg) rotateZ(0deg) translateZ(0px);
        }
        100% {
          opacity: 1;
          transform: translate(-50%, -50%) rotate(0deg) rotateY(0deg) rotateZ(0deg) translateZ(0px);
        }
      }
      #websitelogo .text:nth-child(28) {
        color: #c72454;
        -webkit-clip-path: polygon(30% 50%, 40% 50%, 40% 100%);
                clip-path: polygon(30% 50%, 40% 50%, 40% 100%);
        transform-origin: 71% 39%;
        -webkit-animation: fly28 5000ms 1080ms cubic-bezier(0.36, 0.1, 0.16, 1) infinite alternate;
                animation: fly28 5000ms 1080ms cubic-bezier(0.36, 0.1, 0.16, 1) infinite alternate;
      }
      @-webkit-keyframes fly28 {
        0% {
          opacity: 0;
          transform: translate(-50%, -50%) rotateX(-484deg) rotateY(328deg) rotateZ(20deg) translateZ(-661px);
        }
        10% {
          opacity: 0;
          transform: translate(-50%, -50%) rotateX(-484deg) rotateY(328deg) rotateZ(20deg) translateZ(-661px);
        }
        90% {
          opacity: 1;
          transform: translate(-50%, -50%) rotate(0deg) rotateY(0deg) rotateZ(0deg) translateZ(0px);
        }
        100% {
          opacity: 1;
          transform: translate(-50%, -50%) rotate(0deg) rotateY(0deg) rotateZ(0deg) translateZ(0px);
        }
      }
      @keyframes fly28 {
        0% {
          opacity: 0;
          transform: translate(-50%, -50%) rotateX(-484deg) rotateY(328deg) rotateZ(20deg) translateZ(-661px);
        }
        10% {
          opacity: 0;
          transform: translate(-50%, -50%) rotateX(-484deg) rotateY(328deg) rotateZ(20deg) translateZ(-661px);
        }
        90% {
          opacity: 1;
          transform: translate(-50%, -50%) rotate(0deg) rotateY(0deg) rotateZ(0deg) translateZ(0px);
        }
        100% {
          opacity: 1;
          transform: translate(-50%, -50%) rotate(0deg) rotateY(0deg) rotateZ(0deg) translateZ(0px);
        }
      }
      #websitelogo .text:nth-child(29) {
        color: #c52457;
        -webkit-clip-path: polygon(40% 50%, 40% 100%, 50% 100%);
                clip-path: polygon(40% 50%, 40% 100%, 50% 100%);
        transform-origin: 51% 6%;
        -webkit-animation: fly29 5000ms 1120ms cubic-bezier(0.36, 0.1, 0.16, 1) infinite alternate;
                animation: fly29 5000ms 1120ms cubic-bezier(0.36, 0.1, 0.16, 1) infinite alternate;
      }
      @-webkit-keyframes fly29 {
        0% {
          opacity: 0;
          transform: translate(-50%, -50%) rotateX(-50deg) rotateY(-412deg) rotateZ(286deg) translateZ(-901px);
        }
        10% {
          opacity: 0;
          transform: translate(-50%, -50%) rotateX(-50deg) rotateY(-412deg) rotateZ(286deg) translateZ(-901px);
        }
        90% {
          opacity: 1;
          transform: translate(-50%, -50%) rotate(0deg) rotateY(0deg) rotateZ(0deg) translateZ(0px);
        }
        100% {
          opacity: 1;
          transform: translate(-50%, -50%) rotate(0deg) rotateY(0deg) rotateZ(0deg) translateZ(0px);
        }
      }
      @keyframes fly29 {
        0% {
          opacity: 0;
          transform: translate(-50%, -50%) rotateX(-50deg) rotateY(-412deg) rotateZ(286deg) translateZ(-901px);
        }
        10% {
          opacity: 0;
          transform: translate(-50%, -50%) rotateX(-50deg) rotateY(-412deg) rotateZ(286deg) translateZ(-901px);
        }
        90% {
          opacity: 1;
          transform: translate(-50%, -50%) rotate(0deg) rotateY(0deg) rotateZ(0deg) translateZ(0px);
        }
        100% {
          opacity: 1;
          transform: translate(-50%, -50%) rotate(0deg) rotateY(0deg) rotateZ(0deg) translateZ(0px);
        }
      }
      #websitelogo .text:nth-child(30) {
        color: #c3235a;
        -webkit-clip-path: polygon(40% 50%, 50% 50%, 50% 100%);
                clip-path: polygon(40% 50%, 50% 50%, 50% 100%);
        transform-origin: 68% 71%;
        -webkit-animation: fly30 5000ms 1160ms cubic-bezier(0.36, 0.1, 0.16, 1) infinite alternate;
                animation: fly30 5000ms 1160ms cubic-bezier(0.36, 0.1, 0.16, 1) infinite alternate;
      }
      @-webkit-keyframes fly30 {
        0% {
          opacity: 0;
          transform: translate(-50%, -50%) rotateX(-396deg) rotateY(-360deg) rotateZ(457deg) translateZ(-90px);
        }
        10% {
          opacity: 0;
          transform: translate(-50%, -50%) rotateX(-396deg) rotateY(-360deg) rotateZ(457deg) translateZ(-90px);
        }
        90% {
          opacity: 1;
          transform: translate(-50%, -50%) rotate(0deg) rotateY(0deg) rotateZ(0deg) translateZ(0px);
        }
        100% {
          opacity: 1;
          transform: translate(-50%, -50%) rotate(0deg) rotateY(0deg) rotateZ(0deg) translateZ(0px);
        }
      }
      @keyframes fly30 {
        0% {
          opacity: 0;
          transform: translate(-50%, -50%) rotateX(-396deg) rotateY(-360deg) rotateZ(457deg) translateZ(-90px);
        }
        10% {
          opacity: 0;
          transform: translate(-50%, -50%) rotateX(-396deg) rotateY(-360deg) rotateZ(457deg) translateZ(-90px);
        }
        90% {
          opacity: 1;
          transform: translate(-50%, -50%) rotate(0deg) rotateY(0deg) rotateZ(0deg) translateZ(0px);
        }
        100% {
          opacity: 1;
          transform: translate(-50%, -50%) rotate(0deg) rotateY(0deg) rotateZ(0deg) translateZ(0px);
        }
      }
      #websitelogo .text:nth-child(31) {
        color: #c1235d;
        -webkit-clip-path: polygon(50% 50%, 50% 100%, 60% 100%);
                clip-path: polygon(50% 50%, 50% 100%, 60% 100%);
        transform-origin: 64% 29%;
        -webkit-animation: fly31 5000ms 1200ms cubic-bezier(0.36, 0.1, 0.16, 1) infinite alternate;
                animation: fly31 5000ms 1200ms cubic-bezier(0.36, 0.1, 0.16, 1) infinite alternate;
      }
      @-webkit-keyframes fly31 {
        0% {
          opacity: 0;
          transform: translate(-50%, -50%) rotateX(152deg) rotateY(214deg) rotateZ(-136deg) translateZ(-1704px);
        }
        10% {
          opacity: 0;
          transform: translate(-50%, -50%) rotateX(152deg) rotateY(214deg) rotateZ(-136deg) translateZ(-1704px);
        }
        90% {
          opacity: 1;
          transform: translate(-50%, -50%) rotate(0deg) rotateY(0deg) rotateZ(0deg) translateZ(0px);
        }
        100% {
          opacity: 1;
          transform: translate(-50%, -50%) rotate(0deg) rotateY(0deg) rotateZ(0deg) translateZ(0px);
        }
      }
      @keyframes fly31 {
        0% {
          opacity: 0;
          transform: translate(-50%, -50%) rotateX(152deg) rotateY(214deg) rotateZ(-136deg) translateZ(-1704px);
        }
        10% {
          opacity: 0;
          transform: translate(-50%, -50%) rotateX(152deg) rotateY(214deg) rotateZ(-136deg) translateZ(-1704px);
        }
        90% {
          opacity: 1;
          transform: translate(-50%, -50%) rotate(0deg) rotateY(0deg) rotateZ(0deg) translateZ(0px);
        }
        100% {
          opacity: 1;
          transform: translate(-50%, -50%) rotate(0deg) rotateY(0deg) rotateZ(0deg) translateZ(0px);
        }
      }
      #websitelogo .text:nth-child(32) {
        color: #bf2260;
        -webkit-clip-path: polygon(50% 50%, 60% 50%, 60% 100%);
                clip-path: polygon(50% 50%, 60% 50%, 60% 100%);
        transform-origin: 10% 85%;
        -webkit-animation: fly32 5000ms 1240ms cubic-bezier(0.36, 0.1, 0.16, 1) infinite alternate;
                animation: fly32 5000ms 1240ms cubic-bezier(0.36, 0.1, 0.16, 1) infinite alternate;
      }
      @-webkit-keyframes fly32 {
        0% {
          opacity: 0;
          transform: translate(-50%, -50%) rotateX(165deg) rotateY(-225deg) rotateZ(317deg) translateZ(-2311px);
        }
        10% {
          opacity: 0;
          transform: translate(-50%, -50%) rotateX(165deg) rotateY(-225deg) rotateZ(317deg) translateZ(-2311px);
        }
        90% {
          opacity: 1;
          transform: translate(-50%, -50%) rotate(0deg) rotateY(0deg) rotateZ(0deg) translateZ(0px);
        }
        100% {
          opacity: 1;
          transform: translate(-50%, -50%) rotate(0deg) rotateY(0deg) rotateZ(0deg) translateZ(0px);
        }
      }
      @keyframes fly32 {
        0% {
          opacity: 0;
          transform: translate(-50%, -50%) rotateX(165deg) rotateY(-225deg) rotateZ(317deg) translateZ(-2311px);
        }
        10% {
          opacity: 0;
          transform: translate(-50%, -50%) rotateX(165deg) rotateY(-225deg) rotateZ(317deg) translateZ(-2311px);
        }
        90% {
          opacity: 1;
          transform: translate(-50%, -50%) rotate(0deg) rotateY(0deg) rotateZ(0deg) translateZ(0px);
        }
        100% {
          opacity: 1;
          transform: translate(-50%, -50%) rotate(0deg) rotateY(0deg) rotateZ(0deg) translateZ(0px);
        }
      }
      #websitelogo .text:nth-child(33) {
        color: #bd2263;
        -webkit-clip-path: polygon(60% 50%, 60% 100%, 70% 100%);
                clip-path: polygon(60% 50%, 60% 100%, 70% 100%);
        transform-origin: 34% 95%;
        -webkit-animation: fly33 5000ms 1280ms cubic-bezier(0.36, 0.1, 0.16, 1) infinite alternate;
                animation: fly33 5000ms 1280ms cubic-bezier(0.36, 0.1, 0.16, 1) infinite alternate;
      }
      @-webkit-keyframes fly33 {
        0% {
          opacity: 0;
          transform: translate(-50%, -50%) rotateX(36deg) rotateY(-9deg) rotateZ(162deg) translateZ(-1309px);
        }
        10% {
          opacity: 0;
          transform: translate(-50%, -50%) rotateX(36deg) rotateY(-9deg) rotateZ(162deg) translateZ(-1309px);
        }
        90% {
          opacity: 1;
          transform: translate(-50%, -50%) rotate(0deg) rotateY(0deg) rotateZ(0deg) translateZ(0px);
        }
        100% {
          opacity: 1;
          transform: translate(-50%, -50%) rotate(0deg) rotateY(0deg) rotateZ(0deg) translateZ(0px);
        }
      }
      @keyframes fly33 {
        0% {
          opacity: 0;
          transform: translate(-50%, -50%) rotateX(36deg) rotateY(-9deg) rotateZ(162deg) translateZ(-1309px);
        }
        10% {
          opacity: 0;
          transform: translate(-50%, -50%) rotateX(36deg) rotateY(-9deg) rotateZ(162deg) translateZ(-1309px);
        }
        90% {
          opacity: 1;
          transform: translate(-50%, -50%) rotate(0deg) rotateY(0deg) rotateZ(0deg) translateZ(0px);
        }
        100% {
          opacity: 1;
          transform: translate(-50%, -50%) rotate(0deg) rotateY(0deg) rotateZ(0deg) translateZ(0px);
        }
      }
      #websitelogo .text:nth-child(34) {
        color: #bb2166;
        -webkit-clip-path: polygon(60% 50%, 70% 50%, 70% 100%);
                clip-path: polygon(60% 50%, 70% 50%, 70% 100%);
        transform-origin: 18% 27%;
        -webkit-animation: fly34 5000ms 1320ms cubic-bezier(0.36, 0.1, 0.16, 1) infinite alternate;
                animation: fly34 5000ms 1320ms cubic-bezier(0.36, 0.1, 0.16, 1) infinite alternate;
      }
      @-webkit-keyframes fly34 {
        0% {
          opacity: 0;
          transform: translate(-50%, -50%) rotateX(-106deg) rotateY(132deg) rotateZ(-171deg) translateZ(-1590px);
        }
        10% {
          opacity: 0;
          transform: translate(-50%, -50%) rotateX(-106deg) rotateY(132deg) rotateZ(-171deg) translateZ(-1590px);
        }
        90% {
          opacity: 1;
          transform: translate(-50%, -50%) rotate(0deg) rotateY(0deg) rotateZ(0deg) translateZ(0px);
        }
        100% {
          opacity: 1;
          transform: translate(-50%, -50%) rotate(0deg) rotateY(0deg) rotateZ(0deg) translateZ(0px);
        }
      }
      @keyframes fly34 {
        0% {
          opacity: 0;
          transform: translate(-50%, -50%) rotateX(-106deg) rotateY(132deg) rotateZ(-171deg) translateZ(-1590px);
        }
        10% {
          opacity: 0;
          transform: translate(-50%, -50%) rotateX(-106deg) rotateY(132deg) rotateZ(-171deg) translateZ(-1590px);
        }
        90% {
          opacity: 1;
          transform: translate(-50%, -50%) rotate(0deg) rotateY(0deg) rotateZ(0deg) translateZ(0px);
        }
        100% {
          opacity: 1;
          transform: translate(-50%, -50%) rotate(0deg) rotateY(0deg) rotateZ(0deg) translateZ(0px);
        }
      }
      #websitelogo .text:nth-child(35) {
        color: #b92169;
        -webkit-clip-path: polygon(70% 50%, 70% 100%, 80% 100%);
                clip-path: polygon(70% 50%, 70% 100%, 80% 100%);
        transform-origin: 104% 62%;
        -webkit-animation: fly35 5000ms 1360ms cubic-bezier(0.36, 0.1, 0.16, 1) infinite alternate;
                animation: fly35 5000ms 1360ms cubic-bezier(0.36, 0.1, 0.16, 1) infinite alternate;
      }
      @-webkit-keyframes fly35 {
        0% {
          opacity: 0;
          transform: translate(-50%, -50%) rotateX(-302deg) rotateY(72deg) rotateZ(-388deg) translateZ(-1775px);
        }
        10% {
          opacity: 0;
          transform: translate(-50%, -50%) rotateX(-302deg) rotateY(72deg) rotateZ(-388deg) translateZ(-1775px);
        }
        90% {
          opacity: 1;
          transform: translate(-50%, -50%) rotate(0deg) rotateY(0deg) rotateZ(0deg) translateZ(0px);
        }
        100% {
          opacity: 1;
          transform: translate(-50%, -50%) rotate(0deg) rotateY(0deg) rotateZ(0deg) translateZ(0px);
        }
      }
      @keyframes fly35 {
        0% {
          opacity: 0;
          transform: translate(-50%, -50%) rotateX(-302deg) rotateY(72deg) rotateZ(-388deg) translateZ(-1775px);
        }
        10% {
          opacity: 0;
          transform: translate(-50%, -50%) rotateX(-302deg) rotateY(72deg) rotateZ(-388deg) translateZ(-1775px);
        }
        90% {
          opacity: 1;
          transform: translate(-50%, -50%) rotate(0deg) rotateY(0deg) rotateZ(0deg) translateZ(0px);
        }
        100% {
          opacity: 1;
          transform: translate(-50%, -50%) rotate(0deg) rotateY(0deg) rotateZ(0deg) translateZ(0px);
        }
      }
      #websitelogo .text:nth-child(36) {
        color: #b7206c;
        -webkit-clip-path: polygon(70% 50%, 80% 50%, 80% 100%);
                clip-path: polygon(70% 50%, 80% 50%, 80% 100%);
        transform-origin: 73% 64%;
        -webkit-animation: fly36 5000ms 1400ms cubic-bezier(0.36, 0.1, 0.16, 1) infinite alternate;
                animation: fly36 5000ms 1400ms cubic-bezier(0.36, 0.1, 0.16, 1) infinite alternate;
      }
      @-webkit-keyframes fly36 {
        0% {
          opacity: 0;
          transform: translate(-50%, -50%) rotateX(447deg) rotateY(474deg) rotateZ(306deg) translateZ(-2364px);
        }
        10% {
          opacity: 0;
          transform: translate(-50%, -50%) rotateX(447deg) rotateY(474deg) rotateZ(306deg) translateZ(-2364px);
        }
        90% {
          opacity: 1;
          transform: translate(-50%, -50%) rotate(0deg) rotateY(0deg) rotateZ(0deg) translateZ(0px);
        }
        100% {
          opacity: 1;
          transform: translate(-50%, -50%) rotate(0deg) rotateY(0deg) rotateZ(0deg) translateZ(0px);
        }
      }
      @keyframes fly36 {
        0% {
          opacity: 0;
          transform: translate(-50%, -50%) rotateX(447deg) rotateY(474deg) rotateZ(306deg) translateZ(-2364px);
        }
        10% {
          opacity: 0;
          transform: translate(-50%, -50%) rotateX(447deg) rotateY(474deg) rotateZ(306deg) translateZ(-2364px);
        }
        90% {
          opacity: 1;
          transform: translate(-50%, -50%) rotate(0deg) rotateY(0deg) rotateZ(0deg) translateZ(0px);
        }
        100% {
          opacity: 1;
          transform: translate(-50%, -50%) rotate(0deg) rotateY(0deg) rotateZ(0deg) translateZ(0px);
        }
      }
      #websitelogo .text:nth-child(37) {
        color: #b5206f;
        -webkit-clip-path: polygon(80% 50%, 80% 100%, 90% 100%);
                clip-path: polygon(80% 50%, 80% 100%, 90% 100%);
        transform-origin: 87% 47%;
        -webkit-animation: fly37 5000ms 1440ms cubic-bezier(0.36, 0.1, 0.16, 1) infinite alternate;
                animation: fly37 5000ms 1440ms cubic-bezier(0.36, 0.1, 0.16, 1) infinite alternate;
      }
      @-webkit-keyframes fly37 {
        0% {
          opacity: 0;
          transform: translate(-50%, -50%) rotateX(-383deg) rotateY(463deg) rotateZ(468deg) translateZ(-614px);
        }
        10% {
          opacity: 0;
          transform: translate(-50%, -50%) rotateX(-383deg) rotateY(463deg) rotateZ(468deg) translateZ(-614px);
        }
        90% {
          opacity: 1;
          transform: translate(-50%, -50%) rotate(0deg) rotateY(0deg) rotateZ(0deg) translateZ(0px);
        }
        100% {
          opacity: 1;
          transform: translate(-50%, -50%) rotate(0deg) rotateY(0deg) rotateZ(0deg) translateZ(0px);
        }
      }
      @keyframes fly37 {
        0% {
          opacity: 0;
          transform: translate(-50%, -50%) rotateX(-383deg) rotateY(463deg) rotateZ(468deg) translateZ(-614px);
        }
        10% {
          opacity: 0;
          transform: translate(-50%, -50%) rotateX(-383deg) rotateY(463deg) rotateZ(468deg) translateZ(-614px);
        }
        90% {
          opacity: 1;
          transform: translate(-50%, -50%) rotate(0deg) rotateY(0deg) rotateZ(0deg) translateZ(0px);
        }
        100% {
          opacity: 1;
          transform: translate(-50%, -50%) rotate(0deg) rotateY(0deg) rotateZ(0deg) translateZ(0px);
        }
      }
      #websitelogo .text:nth-child(38) {
        color: #b31f72;
        -webkit-clip-path: polygon(80% 50%, 90% 50%, 90% 100%);
                clip-path: polygon(80% 50%, 90% 50%, 90% 100%);
        transform-origin: 103% 3%;
        -webkit-animation: fly38 5000ms 1480ms cubic-bezier(0.36, 0.1, 0.16, 1) infinite alternate;
                animation: fly38 5000ms 1480ms cubic-bezier(0.36, 0.1, 0.16, 1) infinite alternate;
      }
      @-webkit-keyframes fly38 {
        0% {
          opacity: 0;
          transform: translate(-50%, -50%) rotateX(495deg) rotateY(60deg) rotateZ(-442deg) translateZ(-588px);
        }
        10% {
          opacity: 0;
          transform: translate(-50%, -50%) rotateX(495deg) rotateY(60deg) rotateZ(-442deg) translateZ(-588px);
        }
        90% {
          opacity: 1;
          transform: translate(-50%, -50%) rotate(0deg) rotateY(0deg) rotateZ(0deg) translateZ(0px);
        }
        100% {
          opacity: 1;
          transform: translate(-50%, -50%) rotate(0deg) rotateY(0deg) rotateZ(0deg) translateZ(0px);
        }
      }
      @keyframes fly38 {
        0% {
          opacity: 0;
          transform: translate(-50%, -50%) rotateX(495deg) rotateY(60deg) rotateZ(-442deg) translateZ(-588px);
        }
        10% {
          opacity: 0;
          transform: translate(-50%, -50%) rotateX(495deg) rotateY(60deg) rotateZ(-442deg) translateZ(-588px);
        }
        90% {
          opacity: 1;
          transform: translate(-50%, -50%) rotate(0deg) rotateY(0deg) rotateZ(0deg) translateZ(0px);
        }
        100% {
          opacity: 1;
          transform: translate(-50%, -50%) rotate(0deg) rotateY(0deg) rotateZ(0deg) translateZ(0px);
        }
      }
      #websitelogo .text:nth-child(39) {
        color: #b11f75;
        -webkit-clip-path: polygon(90% 50%, 90% 100%, 100% 100%);
                clip-path: polygon(90% 50%, 90% 100%, 100% 100%);
        transform-origin: 71% 59%;
        -webkit-animation: fly39 5000ms 1520ms cubic-bezier(0.36, 0.1, 0.16, 1) infinite alternate;
                animation: fly39 5000ms 1520ms cubic-bezier(0.36, 0.1, 0.16, 1) infinite alternate;
      }
      @-webkit-keyframes fly39 {
        0% {
          opacity: 0;
          transform: translate(-50%, -50%) rotateX(-409deg) rotateY(-44deg) rotateZ(140deg) translateZ(-219px);
        }
        10% {
          opacity: 0;
          transform: translate(-50%, -50%) rotateX(-409deg) rotateY(-44deg) rotateZ(140deg) translateZ(-219px);
        }
        90% {
          opacity: 1;
          transform: translate(-50%, -50%) rotate(0deg) rotateY(0deg) rotateZ(0deg) translateZ(0px);
        }
        100% {
          opacity: 1;
          transform: translate(-50%, -50%) rotate(0deg) rotateY(0deg) rotateZ(0deg) translateZ(0px);
        }
      }
      @keyframes fly39 {
        0% {
          opacity: 0;
          transform: translate(-50%, -50%) rotateX(-409deg) rotateY(-44deg) rotateZ(140deg) translateZ(-219px);
        }
        10% {
          opacity: 0;
          transform: translate(-50%, -50%) rotateX(-409deg) rotateY(-44deg) rotateZ(140deg) translateZ(-219px);
        }
        90% {
          opacity: 1;
          transform: translate(-50%, -50%) rotate(0deg) rotateY(0deg) rotateZ(0deg) translateZ(0px);
        }
        100% {
          opacity: 1;
          transform: translate(-50%, -50%) rotate(0deg) rotateY(0deg) rotateZ(0deg) translateZ(0px);
        }
      }
      #websitelogo .text:nth-child(40) {
        color: #af1e78;
        -webkit-clip-path: polygon(90% 50%, 100% 50%, 100% 100%);
                clip-path: polygon(90% 50%, 100% 50%, 100% 100%);
        transform-origin: 137% 31%;
        -webkit-animation: fly40 5000ms 1560ms cubic-bezier(0.36, 0.1, 0.16, 1) infinite alternate;
                animation: fly40 5000ms 1560ms cubic-bezier(0.36, 0.1, 0.16, 1) infinite alternate;
      }
      @-webkit-keyframes fly40 {
        0% {
          opacity: 0;
          transform: translate(-50%, -50%) rotateX(-383deg) rotateY(-93deg) rotateZ(-372deg) translateZ(25px);
        }
        10% {
          opacity: 0;
          transform: translate(-50%, -50%) rotateX(-383deg) rotateY(-93deg) rotateZ(-372deg) translateZ(25px);
        }
        90% {
          opacity: 1;
          transform: translate(-50%, -50%) rotate(0deg) rotateY(0deg) rotateZ(0deg) translateZ(0px);
        }
        100% {
          opacity: 1;
          transform: translate(-50%, -50%) rotate(0deg) rotateY(0deg) rotateZ(0deg) translateZ(0px);
        }
      }
      @keyframes fly40 {
        0% {
          opacity: 0;
          transform: translate(-50%, -50%) rotateX(-383deg) rotateY(-93deg) rotateZ(-372deg) translateZ(25px);
        }
        10% {
          opacity: 0;
          transform: translate(-50%, -50%) rotateX(-383deg) rotateY(-93deg) rotateZ(-372deg) translateZ(25px);
        }
        90% {
          opacity: 1;
          transform: translate(-50%, -50%) rotate(0deg) rotateY(0deg) rotateZ(0deg) translateZ(0px);
        }
        100% {
          opacity: 1;
          transform: translate(-50%, -50%) rotate(0deg) rotateY(0deg) rotateZ(0deg) translateZ(0px);
        }
      }
      #websitelogo .text:nth-child(41) {
        color: #ad1e7b;
        -webkit-clip-path: polygon(0% 100%, 0% 150%, 10% 150%);
                clip-path: polygon(0% 100%, 0% 150%, 10% 150%);
        transform-origin: -39% 149%;
        -webkit-animation: fly41 5000ms 1600ms cubic-bezier(0.36, 0.1, 0.16, 1) infinite alternate;
                animation: fly41 5000ms 1600ms cubic-bezier(0.36, 0.1, 0.16, 1) infinite alternate;
      }
      @-webkit-keyframes fly41 {
        0% {
          opacity: 0;
          transform: translate(-50%, -50%) rotateX(176deg) rotateY(258deg) rotateZ(20deg) translateZ(-1367px);
        }
        10% {
          opacity: 0;
          transform: translate(-50%, -50%) rotateX(176deg) rotateY(258deg) rotateZ(20deg) translateZ(-1367px);
        }
        90% {
          opacity: 1;
          transform: translate(-50%, -50%) rotate(0deg) rotateY(0deg) rotateZ(0deg) translateZ(0px);
        }
        100% {
          opacity: 1;
          transform: translate(-50%, -50%) rotate(0deg) rotateY(0deg) rotateZ(0deg) translateZ(0px);
        }
      }
      @keyframes fly41 {
        0% {
          opacity: 0;
          transform: translate(-50%, -50%) rotateX(176deg) rotateY(258deg) rotateZ(20deg) translateZ(-1367px);
        }
        10% {
          opacity: 0;
          transform: translate(-50%, -50%) rotateX(176deg) rotateY(258deg) rotateZ(20deg) translateZ(-1367px);
        }
        90% {
          opacity: 1;
          transform: translate(-50%, -50%) rotate(0deg) rotateY(0deg) rotateZ(0deg) translateZ(0px);
        }
        100% {
          opacity: 1;
          transform: translate(-50%, -50%) rotate(0deg) rotateY(0deg) rotateZ(0deg) translateZ(0px);
        }
      }
      #websitelogo .text:nth-child(42) {
        color: #ab1d7e;
        -webkit-clip-path: polygon(0% 100%, 10% 100%, 10% 150%);
                clip-path: polygon(0% 100%, 10% 100%, 10% 150%);
        transform-origin: -37% 74%;
        -webkit-animation: fly42 5000ms 1640ms cubic-bezier(0.36, 0.1, 0.16, 1) infinite alternate;
                animation: fly42 5000ms 1640ms cubic-bezier(0.36, 0.1, 0.16, 1) infinite alternate;
      }
      @-webkit-keyframes fly42 {
        0% {
          opacity: 0;
          transform: translate(-50%, -50%) rotateX(-348deg) rotateY(278deg) rotateZ(309deg) translateZ(-1231px);
        }
        10% {
          opacity: 0;
          transform: translate(-50%, -50%) rotateX(-348deg) rotateY(278deg) rotateZ(309deg) translateZ(-1231px);
        }
        90% {
          opacity: 1;
          transform: translate(-50%, -50%) rotate(0deg) rotateY(0deg) rotateZ(0deg) translateZ(0px);
        }
        100% {
          opacity: 1;
          transform: translate(-50%, -50%) rotate(0deg) rotateY(0deg) rotateZ(0deg) translateZ(0px);
        }
      }
      @keyframes fly42 {
        0% {
          opacity: 0;
          transform: translate(-50%, -50%) rotateX(-348deg) rotateY(278deg) rotateZ(309deg) translateZ(-1231px);
        }
        10% {
          opacity: 0;
          transform: translate(-50%, -50%) rotateX(-348deg) rotateY(278deg) rotateZ(309deg) translateZ(-1231px);
        }
        90% {
          opacity: 1;
          transform: translate(-50%, -50%) rotate(0deg) rotateY(0deg) rotateZ(0deg) translateZ(0px);
        }
        100% {
          opacity: 1;
          transform: translate(-50%, -50%) rotate(0deg) rotateY(0deg) rotateZ(0deg) translateZ(0px);
        }
      }
      #websitelogo .text:nth-child(43) {
        color: #a91d81;
        -webkit-clip-path: polygon(10% 100%, 10% 150%, 20% 150%);
                clip-path: polygon(10% 100%, 10% 150%, 20% 150%);
        transform-origin: 59% 115%;
        -webkit-animation: fly43 5000ms 1680ms cubic-bezier(0.36, 0.1, 0.16, 1) infinite alternate;
                animation: fly43 5000ms 1680ms cubic-bezier(0.36, 0.1, 0.16, 1) infinite alternate;
      }
      @-webkit-keyframes fly43 {
        0% {
          opacity: 0;
          transform: translate(-50%, -50%) rotateX(21deg) rotateY(-189deg) rotateZ(-463deg) translateZ(-1388px);
        }
        10% {
          opacity: 0;
          transform: translate(-50%, -50%) rotateX(21deg) rotateY(-189deg) rotateZ(-463deg) translateZ(-1388px);
        }
        90% {
          opacity: 1;
          transform: translate(-50%, -50%) rotate(0deg) rotateY(0deg) rotateZ(0deg) translateZ(0px);
        }
        100% {
          opacity: 1;
          transform: translate(-50%, -50%) rotate(0deg) rotateY(0deg) rotateZ(0deg) translateZ(0px);
        }
      }
      @keyframes fly43 {
        0% {
          opacity: 0;
          transform: translate(-50%, -50%) rotateX(21deg) rotateY(-189deg) rotateZ(-463deg) translateZ(-1388px);
        }
        10% {
          opacity: 0;
          transform: translate(-50%, -50%) rotateX(21deg) rotateY(-189deg) rotateZ(-463deg) translateZ(-1388px);
        }
        90% {
          opacity: 1;
          transform: translate(-50%, -50%) rotate(0deg) rotateY(0deg) rotateZ(0deg) translateZ(0px);
        }
        100% {
          opacity: 1;
          transform: translate(-50%, -50%) rotate(0deg) rotateY(0deg) rotateZ(0deg) translateZ(0px);
        }
      }
      #websitelogo .text:nth-child(44) {
        color: #a71c84;
        -webkit-clip-path: polygon(10% 100%, 20% 100%, 20% 150%);
                clip-path: polygon(10% 100%, 20% 100%, 20% 150%);
        transform-origin: 35% 148%;
        -webkit-animation: fly44 5000ms 1720ms cubic-bezier(0.36, 0.1, 0.16, 1) infinite alternate;
                animation: fly44 5000ms 1720ms cubic-bezier(0.36, 0.1, 0.16, 1) infinite alternate;
      }
      @-webkit-keyframes fly44 {
        0% {
          opacity: 0;
          transform: translate(-50%, -50%) rotateX(-303deg) rotateY(-153deg) rotateZ(495deg) translateZ(-2383px);
        }
        10% {
          opacity: 0;
          transform: translate(-50%, -50%) rotateX(-303deg) rotateY(-153deg) rotateZ(495deg) translateZ(-2383px);
        }
        90% {
          opacity: 1;
          transform: translate(-50%, -50%) rotate(0deg) rotateY(0deg) rotateZ(0deg) translateZ(0px);
        }
        100% {
          opacity: 1;
          transform: translate(-50%, -50%) rotate(0deg) rotateY(0deg) rotateZ(0deg) translateZ(0px);
        }
      }
      @keyframes fly44 {
        0% {
          opacity: 0;
          transform: translate(-50%, -50%) rotateX(-303deg) rotateY(-153deg) rotateZ(495deg) translateZ(-2383px);
        }
        10% {
          opacity: 0;
          transform: translate(-50%, -50%) rotateX(-303deg) rotateY(-153deg) rotateZ(495deg) translateZ(-2383px);
        }
        90% {
          opacity: 1;
          transform: translate(-50%, -50%) rotate(0deg) rotateY(0deg) rotateZ(0deg) translateZ(0px);
        }
        100% {
          opacity: 1;
          transform: translate(-50%, -50%) rotate(0deg) rotateY(0deg) rotateZ(0deg) translateZ(0px);
        }
      }
      #websitelogo .text:nth-child(45) {
        color: #a51c87;
        -webkit-clip-path: polygon(20% 100%, 20% 150%, 30% 150%);
                clip-path: polygon(20% 100%, 20% 150%, 30% 150%);
        transform-origin: 51% 108%;
        -webkit-animation: fly45 5000ms 1760ms cubic-bezier(0.36, 0.1, 0.16, 1) infinite alternate;
                animation: fly45 5000ms 1760ms cubic-bezier(0.36, 0.1, 0.16, 1) infinite alternate;
      }
      @-webkit-keyframes fly45 {
        0% {
          opacity: 0;
          transform: translate(-50%, -50%) rotateX(156deg) rotateY(-264deg) rotateZ(-410deg) translateZ(-1765px);
        }
        10% {
          opacity: 0;
          transform: translate(-50%, -50%) rotateX(156deg) rotateY(-264deg) rotateZ(-410deg) translateZ(-1765px);
        }
        90% {
          opacity: 1;
          transform: translate(-50%, -50%) rotate(0deg) rotateY(0deg) rotateZ(0deg) translateZ(0px);
        }
        100% {
          opacity: 1;
          transform: translate(-50%, -50%) rotate(0deg) rotateY(0deg) rotateZ(0deg) translateZ(0px);
        }
      }
      @keyframes fly45 {
        0% {
          opacity: 0;
          transform: translate(-50%, -50%) rotateX(156deg) rotateY(-264deg) rotateZ(-410deg) translateZ(-1765px);
        }
        10% {
          opacity: 0;
          transform: translate(-50%, -50%) rotateX(156deg) rotateY(-264deg) rotateZ(-410deg) translateZ(-1765px);
        }
        90% {
          opacity: 1;
          transform: translate(-50%, -50%) rotate(0deg) rotateY(0deg) rotateZ(0deg) translateZ(0px);
        }
        100% {
          opacity: 1;
          transform: translate(-50%, -50%) rotate(0deg) rotateY(0deg) rotateZ(0deg) translateZ(0px);
        }
      }
      #websitelogo .text:nth-child(46) {
        color: #a31b8a;
        -webkit-clip-path: polygon(20% 100%, 30% 100%, 30% 150%);
                clip-path: polygon(20% 100%, 30% 100%, 30% 150%);
        transform-origin: 50% 135%;
        -webkit-animation: fly46 5000ms 1800ms cubic-bezier(0.36, 0.1, 0.16, 1) infinite alternate;
                animation: fly46 5000ms 1800ms cubic-bezier(0.36, 0.1, 0.16, 1) infinite alternate;
      }
      @-webkit-keyframes fly46 {
        0% {
          opacity: 0;
          transform: translate(-50%, -50%) rotateX(-174deg) rotateY(-127deg) rotateZ(-214deg) translateZ(-1673px);
        }
        10% {
          opacity: 0;
          transform: translate(-50%, -50%) rotateX(-174deg) rotateY(-127deg) rotateZ(-214deg) translateZ(-1673px);
        }
        90% {
          opacity: 1;
          transform: translate(-50%, -50%) rotate(0deg) rotateY(0deg) rotateZ(0deg) translateZ(0px);
        }
        100% {
          opacity: 1;
          transform: translate(-50%, -50%) rotate(0deg) rotateY(0deg) rotateZ(0deg) translateZ(0px);
        }
      }
      @keyframes fly46 {
        0% {
          opacity: 0;
          transform: translate(-50%, -50%) rotateX(-174deg) rotateY(-127deg) rotateZ(-214deg) translateZ(-1673px);
        }
        10% {
          opacity: 0;
          transform: translate(-50%, -50%) rotateX(-174deg) rotateY(-127deg) rotateZ(-214deg) translateZ(-1673px);
        }
        90% {
          opacity: 1;
          transform: translate(-50%, -50%) rotate(0deg) rotateY(0deg) rotateZ(0deg) translateZ(0px);
        }
        100% {
          opacity: 1;
          transform: translate(-50%, -50%) rotate(0deg) rotateY(0deg) rotateZ(0deg) translateZ(0px);
        }
      }
      #websitelogo .text:nth-child(47) {
        color: #a11b8d;
        -webkit-clip-path: polygon(30% 100%, 30% 150%, 40% 150%);
                clip-path: polygon(30% 100%, 30% 150%, 40% 150%);
        transform-origin: 73% 128%;
        -webkit-animation: fly47 5000ms 1840ms cubic-bezier(0.36, 0.1, 0.16, 1) infinite alternate;
                animation: fly47 5000ms 1840ms cubic-bezier(0.36, 0.1, 0.16, 1) infinite alternate;
      }
      @-webkit-keyframes fly47 {
        0% {
          opacity: 0;
          transform: translate(-50%, -50%) rotateX(488deg) rotateY(248deg) rotateZ(-93deg) translateZ(-2250px);
        }
        10% {
          opacity: 0;
          transform: translate(-50%, -50%) rotateX(488deg) rotateY(248deg) rotateZ(-93deg) translateZ(-2250px);
        }
        90% {
          opacity: 1;
          transform: translate(-50%, -50%) rotate(0deg) rotateY(0deg) rotateZ(0deg) translateZ(0px);
        }
        100% {
          opacity: 1;
          transform: translate(-50%, -50%) rotate(0deg) rotateY(0deg) rotateZ(0deg) translateZ(0px);
        }
      }
      @keyframes fly47 {
        0% {
          opacity: 0;
          transform: translate(-50%, -50%) rotateX(488deg) rotateY(248deg) rotateZ(-93deg) translateZ(-2250px);
        }
        10% {
          opacity: 0;
          transform: translate(-50%, -50%) rotateX(488deg) rotateY(248deg) rotateZ(-93deg) translateZ(-2250px);
        }
        90% {
          opacity: 1;
          transform: translate(-50%, -50%) rotate(0deg) rotateY(0deg) rotateZ(0deg) translateZ(0px);
        }
        100% {
          opacity: 1;
          transform: translate(-50%, -50%) rotate(0deg) rotateY(0deg) rotateZ(0deg) translateZ(0px);
        }
      }
      #websitelogo .text:nth-child(48) {
        color: #9f1a90;
        -webkit-clip-path: polygon(30% 100%, 40% 100%, 40% 150%);
                clip-path: polygon(30% 100%, 40% 100%, 40% 150%);
        transform-origin: 61% 119%;
        -webkit-animation: fly48 5000ms 1880ms cubic-bezier(0.36, 0.1, 0.16, 1) infinite alternate;
                animation: fly48 5000ms 1880ms cubic-bezier(0.36, 0.1, 0.16, 1) infinite alternate;
      }
      @-webkit-keyframes fly48 {
        0% {
          opacity: 0;
          transform: translate(-50%, -50%) rotateX(48deg) rotateY(3deg) rotateZ(-209deg) translateZ(-954px);
        }
        10% {
          opacity: 0;
          transform: translate(-50%, -50%) rotateX(48deg) rotateY(3deg) rotateZ(-209deg) translateZ(-954px);
        }
        90% {
          opacity: 1;
          transform: translate(-50%, -50%) rotate(0deg) rotateY(0deg) rotateZ(0deg) translateZ(0px);
        }
        100% {
          opacity: 1;
          transform: translate(-50%, -50%) rotate(0deg) rotateY(0deg) rotateZ(0deg) translateZ(0px);
        }
      }
      @keyframes fly48 {
        0% {
          opacity: 0;
          transform: translate(-50%, -50%) rotateX(48deg) rotateY(3deg) rotateZ(-209deg) translateZ(-954px);
        }
        10% {
          opacity: 0;
          transform: translate(-50%, -50%) rotateX(48deg) rotateY(3deg) rotateZ(-209deg) translateZ(-954px);
        }
        90% {
          opacity: 1;
          transform: translate(-50%, -50%) rotate(0deg) rotateY(0deg) rotateZ(0deg) translateZ(0px);
        }
        100% {
          opacity: 1;
          transform: translate(-50%, -50%) rotate(0deg) rotateY(0deg) rotateZ(0deg) translateZ(0px);
        }
      }
      #websitelogo .text:nth-child(49) {
        color: #9d1a93;
        -webkit-clip-path: polygon(40% 100%, 40% 150%, 50% 150%);
                clip-path: polygon(40% 100%, 40% 150%, 50% 150%);
        transform-origin: 37% 140%;
        -webkit-animation: fly49 5000ms 1920ms cubic-bezier(0.36, 0.1, 0.16, 1) infinite alternate;
                animation: fly49 5000ms 1920ms cubic-bezier(0.36, 0.1, 0.16, 1) infinite alternate;
      }
      @-webkit-keyframes fly49 {
        0% {
          opacity: 0;
          transform: translate(-50%, -50%) rotateX(129deg) rotateY(-246deg) rotateZ(-393deg) translateZ(-920px);
        }
        10% {
          opacity: 0;
          transform: translate(-50%, -50%) rotateX(129deg) rotateY(-246deg) rotateZ(-393deg) translateZ(-920px);
        }
        90% {
          opacity: 1;
          transform: translate(-50%, -50%) rotate(0deg) rotateY(0deg) rotateZ(0deg) translateZ(0px);
        }
        100% {
          opacity: 1;
          transform: translate(-50%, -50%) rotate(0deg) rotateY(0deg) rotateZ(0deg) translateZ(0px);
        }
      }
      @keyframes fly49 {
        0% {
          opacity: 0;
          transform: translate(-50%, -50%) rotateX(129deg) rotateY(-246deg) rotateZ(-393deg) translateZ(-920px);
        }
        10% {
          opacity: 0;
          transform: translate(-50%, -50%) rotateX(129deg) rotateY(-246deg) rotateZ(-393deg) translateZ(-920px);
        }
        90% {
          opacity: 1;
          transform: translate(-50%, -50%) rotate(0deg) rotateY(0deg) rotateZ(0deg) translateZ(0px);
        }
        100% {
          opacity: 1;
          transform: translate(-50%, -50%) rotate(0deg) rotateY(0deg) rotateZ(0deg) translateZ(0px);
        }
      }
      #websitelogo .text:nth-child(50) {
        color: #9b1996;
        -webkit-clip-path: polygon(40% 100%, 50% 100%, 50% 150%);
                clip-path: polygon(40% 100%, 50% 100%, 50% 150%);
        transform-origin: 48% 55%;
        -webkit-animation: fly50 5000ms 1960ms cubic-bezier(0.36, 0.1, 0.16, 1) infinite alternate;
                animation: fly50 5000ms 1960ms cubic-bezier(0.36, 0.1, 0.16, 1) infinite alternate;
      }
      @-webkit-keyframes fly50 {
        0% {
          opacity: 0;
          transform: translate(-50%, -50%) rotateX(369deg) rotateY(-390deg) rotateZ(308deg) translateZ(-953px);
        }
        10% {
          opacity: 0;
          transform: translate(-50%, -50%) rotateX(369deg) rotateY(-390deg) rotateZ(308deg) translateZ(-953px);
        }
        90% {
          opacity: 1;
          transform: translate(-50%, -50%) rotate(0deg) rotateY(0deg) rotateZ(0deg) translateZ(0px);
        }
        100% {
          opacity: 1;
          transform: translate(-50%, -50%) rotate(0deg) rotateY(0deg) rotateZ(0deg) translateZ(0px);
        }
      }
      @keyframes fly50 {
        0% {
          opacity: 0;
          transform: translate(-50%, -50%) rotateX(369deg) rotateY(-390deg) rotateZ(308deg) translateZ(-953px);
        }
        10% {
          opacity: 0;
          transform: translate(-50%, -50%) rotateX(369deg) rotateY(-390deg) rotateZ(308deg) translateZ(-953px);
        }
        90% {
          opacity: 1;
          transform: translate(-50%, -50%) rotate(0deg) rotateY(0deg) rotateZ(0deg) translateZ(0px);
        }
        100% {
          opacity: 1;
          transform: translate(-50%, -50%) rotate(0deg) rotateY(0deg) rotateZ(0deg) translateZ(0px);
        }
      }
      #websitelogo .text:nth-child(51) {
        color: #991999;
        -webkit-clip-path: polygon(50% 100%, 50% 150%, 60% 150%);
                clip-path: polygon(50% 100%, 50% 150%, 60% 150%);
        transform-origin: 1% 82%;
        -webkit-animation: fly51 5000ms 2000ms cubic-bezier(0.36, 0.1, 0.16, 1) infinite alternate;
                animation: fly51 5000ms 2000ms cubic-bezier(0.36, 0.1, 0.16, 1) infinite alternate;
      }
      @-webkit-keyframes fly51 {
        0% {
          opacity: 0;
          transform: translate(-50%, -50%) rotateX(264deg) rotateY(-332deg) rotateZ(-486deg) translateZ(251px);
        }
        10% {
          opacity: 0;
          transform: translate(-50%, -50%) rotateX(264deg) rotateY(-332deg) rotateZ(-486deg) translateZ(251px);
        }
        90% {
          opacity: 1;
          transform: translate(-50%, -50%) rotate(0deg) rotateY(0deg) rotateZ(0deg) translateZ(0px);
        }
        100% {
          opacity: 1;
          transform: translate(-50%, -50%) rotate(0deg) rotateY(0deg) rotateZ(0deg) translateZ(0px);
        }
      }
      @keyframes fly51 {
        0% {
          opacity: 0;
          transform: translate(-50%, -50%) rotateX(264deg) rotateY(-332deg) rotateZ(-486deg) translateZ(251px);
        }
        10% {
          opacity: 0;
          transform: translate(-50%, -50%) rotateX(264deg) rotateY(-332deg) rotateZ(-486deg) translateZ(251px);
        }
        90% {
          opacity: 1;
          transform: translate(-50%, -50%) rotate(0deg) rotateY(0deg) rotateZ(0deg) translateZ(0px);
        }
        100% {
          opacity: 1;
          transform: translate(-50%, -50%) rotate(0deg) rotateY(0deg) rotateZ(0deg) translateZ(0px);
        }
      }
      #websitelogo .text:nth-child(52) {
        color: #97189c;
        -webkit-clip-path: polygon(50% 100%, 60% 100%, 60% 150%);
                clip-path: polygon(50% 100%, 60% 100%, 60% 150%);
        transform-origin: 23% 79%;
        -webkit-animation: fly52 5000ms 2040ms cubic-bezier(0.36, 0.1, 0.16, 1) infinite alternate;
                animation: fly52 5000ms 2040ms cubic-bezier(0.36, 0.1, 0.16, 1) infinite alternate;
      }
      @-webkit-keyframes fly52 {
        0% {
          opacity: 0;
          transform: translate(-50%, -50%) rotateX(249deg) rotateY(-17deg) rotateZ(-173deg) translateZ(-1270px);
        }
        10% {
          opacity: 0;
          transform: translate(-50%, -50%) rotateX(249deg) rotateY(-17deg) rotateZ(-173deg) translateZ(-1270px);
        }
        90% {
          opacity: 1;
          transform: translate(-50%, -50%) rotate(0deg) rotateY(0deg) rotateZ(0deg) translateZ(0px);
        }
        100% {
          opacity: 1;
          transform: translate(-50%, -50%) rotate(0deg) rotateY(0deg) rotateZ(0deg) translateZ(0px);
        }
      }
      @keyframes fly52 {
        0% {
          opacity: 0;
          transform: translate(-50%, -50%) rotateX(249deg) rotateY(-17deg) rotateZ(-173deg) translateZ(-1270px);
        }
        10% {
          opacity: 0;
          transform: translate(-50%, -50%) rotateX(249deg) rotateY(-17deg) rotateZ(-173deg) translateZ(-1270px);
        }
        90% {
          opacity: 1;
          transform: translate(-50%, -50%) rotate(0deg) rotateY(0deg) rotateZ(0deg) translateZ(0px);
        }
        100% {
          opacity: 1;
          transform: translate(-50%, -50%) rotate(0deg) rotateY(0deg) rotateZ(0deg) translateZ(0px);
        }
      }
      #websitelogo .text:nth-child(53) {
        color: #95189f;
        -webkit-clip-path: polygon(60% 100%, 60% 150%, 70% 150%);
                clip-path: polygon(60% 100%, 60% 150%, 70% 150%);
        transform-origin: 44% 79%;
        -webkit-animation: fly53 5000ms 2080ms cubic-bezier(0.36, 0.1, 0.16, 1) infinite alternate;
                animation: fly53 5000ms 2080ms cubic-bezier(0.36, 0.1, 0.16, 1) infinite alternate;
      }
      @-webkit-keyframes fly53 {
        0% {
          opacity: 0;
          transform: translate(-50%, -50%) rotateX(-61deg) rotateY(325deg) rotateZ(206deg) translateZ(-334px);
        }
        10% {
          opacity: 0;
          transform: translate(-50%, -50%) rotateX(-61deg) rotateY(325deg) rotateZ(206deg) translateZ(-334px);
        }
        90% {
          opacity: 1;
          transform: translate(-50%, -50%) rotate(0deg) rotateY(0deg) rotateZ(0deg) translateZ(0px);
        }
        100% {
          opacity: 1;
          transform: translate(-50%, -50%) rotate(0deg) rotateY(0deg) rotateZ(0deg) translateZ(0px);
        }
      }
      @keyframes fly53 {
        0% {
          opacity: 0;
          transform: translate(-50%, -50%) rotateX(-61deg) rotateY(325deg) rotateZ(206deg) translateZ(-334px);
        }
        10% {
          opacity: 0;
          transform: translate(-50%, -50%) rotateX(-61deg) rotateY(325deg) rotateZ(206deg) translateZ(-334px);
        }
        90% {
          opacity: 1;
          transform: translate(-50%, -50%) rotate(0deg) rotateY(0deg) rotateZ(0deg) translateZ(0px);
        }
        100% {
          opacity: 1;
          transform: translate(-50%, -50%) rotate(0deg) rotateY(0deg) rotateZ(0deg) translateZ(0px);
        }
      }
      #websitelogo .text:nth-child(54) {
        color: #9317a2;
        -webkit-clip-path: polygon(60% 100%, 70% 100%, 70% 150%);
                clip-path: polygon(60% 100%, 70% 100%, 70% 150%);
        transform-origin: 107% 125%;
        -webkit-animation: fly54 5000ms 2120ms cubic-bezier(0.36, 0.1, 0.16, 1) infinite alternate;
                animation: fly54 5000ms 2120ms cubic-bezier(0.36, 0.1, 0.16, 1) infinite alternate;
      }
      @-webkit-keyframes fly54 {
        0% {
          opacity: 0;
          transform: translate(-50%, -50%) rotateX(279deg) rotateY(308deg) rotateZ(329deg) translateZ(-2229px);
        }
        10% {
          opacity: 0;
          transform: translate(-50%, -50%) rotateX(279deg) rotateY(308deg) rotateZ(329deg) translateZ(-2229px);
        }
        90% {
          opacity: 1;
          transform: translate(-50%, -50%) rotate(0deg) rotateY(0deg) rotateZ(0deg) translateZ(0px);
        }
        100% {
          opacity: 1;
          transform: translate(-50%, -50%) rotate(0deg) rotateY(0deg) rotateZ(0deg) translateZ(0px);
        }
      }
      @keyframes fly54 {
        0% {
          opacity: 0;
          transform: translate(-50%, -50%) rotateX(279deg) rotateY(308deg) rotateZ(329deg) translateZ(-2229px);
        }
        10% {
          opacity: 0;
          transform: translate(-50%, -50%) rotateX(279deg) rotateY(308deg) rotateZ(329deg) translateZ(-2229px);
        }
        90% {
          opacity: 1;
          transform: translate(-50%, -50%) rotate(0deg) rotateY(0deg) rotateZ(0deg) translateZ(0px);
        }
        100% {
          opacity: 1;
          transform: translate(-50%, -50%) rotate(0deg) rotateY(0deg) rotateZ(0deg) translateZ(0px);
        }
      }
      #websitelogo .text:nth-child(55) {
        color: #9117a5;
        -webkit-clip-path: polygon(70% 100%, 70% 150%, 80% 150%);
                clip-path: polygon(70% 100%, 70% 150%, 80% 150%);
        transform-origin: 49% 55%;
        -webkit-animation: fly55 5000ms 2160ms cubic-bezier(0.36, 0.1, 0.16, 1) infinite alternate;
                animation: fly55 5000ms 2160ms cubic-bezier(0.36, 0.1, 0.16, 1) infinite alternate;
      }
      @-webkit-keyframes fly55 {
        0% {
          opacity: 0;
          transform: translate(-50%, -50%) rotateX(230deg) rotateY(77deg) rotateZ(190deg) translateZ(-339px);
        }
        10% {
          opacity: 0;
          transform: translate(-50%, -50%) rotateX(230deg) rotateY(77deg) rotateZ(190deg) translateZ(-339px);
        }
        90% {
          opacity: 1;
          transform: translate(-50%, -50%) rotate(0deg) rotateY(0deg) rotateZ(0deg) translateZ(0px);
        }
        100% {
          opacity: 1;
          transform: translate(-50%, -50%) rotate(0deg) rotateY(0deg) rotateZ(0deg) translateZ(0px);
        }
      }
      @keyframes fly55 {
        0% {
          opacity: 0;
          transform: translate(-50%, -50%) rotateX(230deg) rotateY(77deg) rotateZ(190deg) translateZ(-339px);
        }
        10% {
          opacity: 0;
          transform: translate(-50%, -50%) rotateX(230deg) rotateY(77deg) rotateZ(190deg) translateZ(-339px);
        }
        90% {
          opacity: 1;
          transform: translate(-50%, -50%) rotate(0deg) rotateY(0deg) rotateZ(0deg) translateZ(0px);
        }
        100% {
          opacity: 1;
          transform: translate(-50%, -50%) rotate(0deg) rotateY(0deg) rotateZ(0deg) translateZ(0px);
        }
      }
      #websitelogo .text:nth-child(56) {
        color: #8f16a8;
        -webkit-clip-path: polygon(70% 100%, 80% 100%, 80% 150%);
                clip-path: polygon(70% 100%, 80% 100%, 80% 150%);
        transform-origin: 46% 89%;
        -webkit-animation: fly56 5000ms 2200ms cubic-bezier(0.36, 0.1, 0.16, 1) infinite alternate;
                animation: fly56 5000ms 2200ms cubic-bezier(0.36, 0.1, 0.16, 1) infinite alternate;
      }
      @-webkit-keyframes fly56 {
        0% {
          opacity: 0;
          transform: translate(-50%, -50%) rotateX(278deg) rotateY(473deg) rotateZ(173deg) translateZ(310px);
        }
        10% {
          opacity: 0;
          transform: translate(-50%, -50%) rotateX(278deg) rotateY(473deg) rotateZ(173deg) translateZ(310px);
        }
        90% {
          opacity: 1;
          transform: translate(-50%, -50%) rotate(0deg) rotateY(0deg) rotateZ(0deg) translateZ(0px);
        }
        100% {
          opacity: 1;
          transform: translate(-50%, -50%) rotate(0deg) rotateY(0deg) rotateZ(0deg) translateZ(0px);
        }
      }
      @keyframes fly56 {
        0% {
          opacity: 0;
          transform: translate(-50%, -50%) rotateX(278deg) rotateY(473deg) rotateZ(173deg) translateZ(310px);
        }
        10% {
          opacity: 0;
          transform: translate(-50%, -50%) rotateX(278deg) rotateY(473deg) rotateZ(173deg) translateZ(310px);
        }
        90% {
          opacity: 1;
          transform: translate(-50%, -50%) rotate(0deg) rotateY(0deg) rotateZ(0deg) translateZ(0px);
        }
        100% {
          opacity: 1;
          transform: translate(-50%, -50%) rotate(0deg) rotateY(0deg) rotateZ(0deg) translateZ(0px);
        }
      }
      #websitelogo .text:nth-child(57) {
        color: #8d16ab;
        -webkit-clip-path: polygon(80% 100%, 80% 150%, 90% 150%);
                clip-path: polygon(80% 100%, 80% 150%, 90% 150%);
        transform-origin: 31% 132%;
        -webkit-animation: fly57 5000ms 2240ms cubic-bezier(0.36, 0.1, 0.16, 1) infinite alternate;
                animation: fly57 5000ms 2240ms cubic-bezier(0.36, 0.1, 0.16, 1) infinite alternate;
      }
      @-webkit-keyframes fly57 {
        0% {
          opacity: 0;
          transform: translate(-50%, -50%) rotateX(210deg) rotateY(-309deg) rotateZ(441deg) translateZ(-2079px);
        }
        10% {
          opacity: 0;
          transform: translate(-50%, -50%) rotateX(210deg) rotateY(-309deg) rotateZ(441deg) translateZ(-2079px);
        }
        90% {
          opacity: 1;
          transform: translate(-50%, -50%) rotate(0deg) rotateY(0deg) rotateZ(0deg) translateZ(0px);
        }
        100% {
          opacity: 1;
          transform: translate(-50%, -50%) rotate(0deg) rotateY(0deg) rotateZ(0deg) translateZ(0px);
        }
      }
      @keyframes fly57 {
        0% {
          opacity: 0;
          transform: translate(-50%, -50%) rotateX(210deg) rotateY(-309deg) rotateZ(441deg) translateZ(-2079px);
        }
        10% {
          opacity: 0;
          transform: translate(-50%, -50%) rotateX(210deg) rotateY(-309deg) rotateZ(441deg) translateZ(-2079px);
        }
        90% {
          opacity: 1;
          transform: translate(-50%, -50%) rotate(0deg) rotateY(0deg) rotateZ(0deg) translateZ(0px);
        }
        100% {
          opacity: 1;
          transform: translate(-50%, -50%) rotate(0deg) rotateY(0deg) rotateZ(0deg) translateZ(0px);
        }
      }
      #websitelogo .text:nth-child(58) {
        color: #8b15ae;
        -webkit-clip-path: polygon(80% 100%, 90% 100%, 90% 150%);
                clip-path: polygon(80% 100%, 90% 100%, 90% 150%);
        transform-origin: 57% 147%;
        -webkit-animation: fly58 5000ms 2280ms cubic-bezier(0.36, 0.1, 0.16, 1) infinite alternate;
                animation: fly58 5000ms 2280ms cubic-bezier(0.36, 0.1, 0.16, 1) infinite alternate;
      }
      @-webkit-keyframes fly58 {
        0% {
          opacity: 0;
          transform: translate(-50%, -50%) rotateX(-314deg) rotateY(-192deg) rotateZ(155deg) translateZ(-174px);
        }
        10% {
          opacity: 0;
          transform: translate(-50%, -50%) rotateX(-314deg) rotateY(-192deg) rotateZ(155deg) translateZ(-174px);
        }
        90% {
          opacity: 1;
          transform: translate(-50%, -50%) rotate(0deg) rotateY(0deg) rotateZ(0deg) translateZ(0px);
        }
        100% {
          opacity: 1;
          transform: translate(-50%, -50%) rotate(0deg) rotateY(0deg) rotateZ(0deg) translateZ(0px);
        }
      }
      @keyframes fly58 {
        0% {
          opacity: 0;
          transform: translate(-50%, -50%) rotateX(-314deg) rotateY(-192deg) rotateZ(155deg) translateZ(-174px);
        }
        10% {
          opacity: 0;
          transform: translate(-50%, -50%) rotateX(-314deg) rotateY(-192deg) rotateZ(155deg) translateZ(-174px);
        }
        90% {
          opacity: 1;
          transform: translate(-50%, -50%) rotate(0deg) rotateY(0deg) rotateZ(0deg) translateZ(0px);
        }
        100% {
          opacity: 1;
          transform: translate(-50%, -50%) rotate(0deg) rotateY(0deg) rotateZ(0deg) translateZ(0px);
        }
      }
      #websitelogo .text:nth-child(59) {
        color: #8915b1;
        -webkit-clip-path: polygon(90% 100%, 90% 150%, 100% 150%);
                clip-path: polygon(90% 100%, 90% 150%, 100% 150%);
        transform-origin: 140% 138%;
        -webkit-animation: fly59 5000ms 2320ms cubic-bezier(0.36, 0.1, 0.16, 1) infinite alternate;
                animation: fly59 5000ms 2320ms cubic-bezier(0.36, 0.1, 0.16, 1) infinite alternate;
      }
      @-webkit-keyframes fly59 {
        0% {
          opacity: 0;
          transform: translate(-50%, -50%) rotateX(-215deg) rotateY(-255deg) rotateZ(-34deg) translateZ(-941px);
        }
        10% {
          opacity: 0;
          transform: translate(-50%, -50%) rotateX(-215deg) rotateY(-255deg) rotateZ(-34deg) translateZ(-941px);
        }
        90% {
          opacity: 1;
          transform: translate(-50%, -50%) rotate(0deg) rotateY(0deg) rotateZ(0deg) translateZ(0px);
        }
        100% {
          opacity: 1;
          transform: translate(-50%, -50%) rotate(0deg) rotateY(0deg) rotateZ(0deg) translateZ(0px);
        }
      }
      @keyframes fly59 {
        0% {
          opacity: 0;
          transform: translate(-50%, -50%) rotateX(-215deg) rotateY(-255deg) rotateZ(-34deg) translateZ(-941px);
        }
        10% {
          opacity: 0;
          transform: translate(-50%, -50%) rotateX(-215deg) rotateY(-255deg) rotateZ(-34deg) translateZ(-941px);
        }
        90% {
          opacity: 1;
          transform: translate(-50%, -50%) rotate(0deg) rotateY(0deg) rotateZ(0deg) translateZ(0px);
        }
        100% {
          opacity: 1;
          transform: translate(-50%, -50%) rotate(0deg) rotateY(0deg) rotateZ(0deg) translateZ(0px);
        }
      }
      #websitelogo .text:nth-child(60) {
        color: #8714b4;
        -webkit-clip-path: polygon(90% 100%, 100% 100%, 100% 150%);
                clip-path: polygon(90% 100%, 100% 100%, 100% 150%);
        transform-origin: 50% 105%;
        -webkit-animation: fly60 5000ms 2360ms cubic-bezier(0.36, 0.1, 0.16, 1) infinite alternate;
                animation: fly60 5000ms 2360ms cubic-bezier(0.36, 0.1, 0.16, 1) infinite alternate;
      }
      @-webkit-keyframes fly60 {
        0% {
          opacity: 0;
          transform: translate(-50%, -50%) rotateX(209deg) rotateY(48deg) rotateZ(418deg) translateZ(299px);
        }
        10% {
          opacity: 0;
          transform: translate(-50%, -50%) rotateX(209deg) rotateY(48deg) rotateZ(418deg) translateZ(299px);
        }
        90% {
          opacity: 1;
          transform: translate(-50%, -50%) rotate(0deg) rotateY(0deg) rotateZ(0deg) translateZ(0px);
        }
        100% {
          opacity: 1;
          transform: translate(-50%, -50%) rotate(0deg) rotateY(0deg) rotateZ(0deg) translateZ(0px);
        }
      }
      @keyframes fly60 {
        0% {
          opacity: 0;
          transform: translate(-50%, -50%) rotateX(209deg) rotateY(48deg) rotateZ(418deg) translateZ(299px);
        }
        10% {
          opacity: 0;
          transform: translate(-50%, -50%) rotateX(209deg) rotateY(48deg) rotateZ(418deg) translateZ(299px);
        }
        90% {
          opacity: 1;
          transform: translate(-50%, -50%) rotate(0deg) rotateY(0deg) rotateZ(0deg) translateZ(0px);
        }
        100% {
          opacity: 1;
          transform: translate(-50%, -50%) rotate(0deg) rotateY(0deg) rotateZ(0deg) translateZ(0px);
        }
      }
      #websitelogo .text:nth-child(61) {
        color: #8514b7;
        -webkit-clip-path: polygon(0% 150%, 0% 200%, 10% 200%);
                clip-path: polygon(0% 150%, 0% 200%, 10% 200%);
        transform-origin: 10% 170%;
        -webkit-animation: fly61 5000ms 2400ms cubic-bezier(0.36, 0.1, 0.16, 1) infinite alternate;
                animation: fly61 5000ms 2400ms cubic-bezier(0.36, 0.1, 0.16, 1) infinite alternate;
      }
      @-webkit-keyframes fly61 {
        0% {
          opacity: 0;
          transform: translate(-50%, -50%) rotateX(-487deg) rotateY(-53deg) rotateZ(173deg) translateZ(-655px);
        }
        10% {
          opacity: 0;
          transform: translate(-50%, -50%) rotateX(-487deg) rotateY(-53deg) rotateZ(173deg) translateZ(-655px);
        }
        90% {
          opacity: 1;
          transform: translate(-50%, -50%) rotate(0deg) rotateY(0deg) rotateZ(0deg) translateZ(0px);
        }
        100% {
          opacity: 1;
          transform: translate(-50%, -50%) rotate(0deg) rotateY(0deg) rotateZ(0deg) translateZ(0px);
        }
      }
      @keyframes fly61 {
        0% {
          opacity: 0;
          transform: translate(-50%, -50%) rotateX(-487deg) rotateY(-53deg) rotateZ(173deg) translateZ(-655px);
        }
        10% {
          opacity: 0;
          transform: translate(-50%, -50%) rotateX(-487deg) rotateY(-53deg) rotateZ(173deg) translateZ(-655px);
        }
        90% {
          opacity: 1;
          transform: translate(-50%, -50%) rotate(0deg) rotateY(0deg) rotateZ(0deg) translateZ(0px);
        }
        100% {
          opacity: 1;
          transform: translate(-50%, -50%) rotate(0deg) rotateY(0deg) rotateZ(0deg) translateZ(0px);
        }
      }
      #websitelogo .text:nth-child(62) {
        color: #8313ba;
        -webkit-clip-path: polygon(0% 150%, 10% 150%, 10% 200%);
                clip-path: polygon(0% 150%, 10% 150%, 10% 200%);
        transform-origin: -16% 155%;
        -webkit-animation: fly62 5000ms 2440ms cubic-bezier(0.36, 0.1, 0.16, 1) infinite alternate;
                animation: fly62 5000ms 2440ms cubic-bezier(0.36, 0.1, 0.16, 1) infinite alternate;
      }
      @-webkit-keyframes fly62 {
        0% {
          opacity: 0;
          transform: translate(-50%, -50%) rotateX(-162deg) rotateY(191deg) rotateZ(55deg) translateZ(-1878px);
        }
        10% {
          opacity: 0;
          transform: translate(-50%, -50%) rotateX(-162deg) rotateY(191deg) rotateZ(55deg) translateZ(-1878px);
        }
        90% {
          opacity: 1;
          transform: translate(-50%, -50%) rotate(0deg) rotateY(0deg) rotateZ(0deg) translateZ(0px);
        }
        100% {
          opacity: 1;
          transform: translate(-50%, -50%) rotate(0deg) rotateY(0deg) rotateZ(0deg) translateZ(0px);
        }
      }
      @keyframes fly62 {
        0% {
          opacity: 0;
          transform: translate(-50%, -50%) rotateX(-162deg) rotateY(191deg) rotateZ(55deg) translateZ(-1878px);
        }
        10% {
          opacity: 0;
          transform: translate(-50%, -50%) rotateX(-162deg) rotateY(191deg) rotateZ(55deg) translateZ(-1878px);
        }
        90% {
          opacity: 1;
          transform: translate(-50%, -50%) rotate(0deg) rotateY(0deg) rotateZ(0deg) translateZ(0px);
        }
        100% {
          opacity: 1;
          transform: translate(-50%, -50%) rotate(0deg) rotateY(0deg) rotateZ(0deg) translateZ(0px);
        }
      }
      #websitelogo .text:nth-child(63) {
        color: #8113bd;
        -webkit-clip-path: polygon(10% 150%, 10% 200%, 20% 200%);
                clip-path: polygon(10% 150%, 10% 200%, 20% 200%);
        transform-origin: -10% 172%;
        -webkit-animation: fly63 5000ms 2480ms cubic-bezier(0.36, 0.1, 0.16, 1) infinite alternate;
                animation: fly63 5000ms 2480ms cubic-bezier(0.36, 0.1, 0.16, 1) infinite alternate;
      }
      @-webkit-keyframes fly63 {
        0% {
          opacity: 0;
          transform: translate(-50%, -50%) rotateX(442deg) rotateY(423deg) rotateZ(161deg) translateZ(225px);
        }
        10% {
          opacity: 0;
          transform: translate(-50%, -50%) rotateX(442deg) rotateY(423deg) rotateZ(161deg) translateZ(225px);
        }
        90% {
          opacity: 1;
          transform: translate(-50%, -50%) rotate(0deg) rotateY(0deg) rotateZ(0deg) translateZ(0px);
        }
        100% {
          opacity: 1;
          transform: translate(-50%, -50%) rotate(0deg) rotateY(0deg) rotateZ(0deg) translateZ(0px);
        }
      }
      @keyframes fly63 {
        0% {
          opacity: 0;
          transform: translate(-50%, -50%) rotateX(442deg) rotateY(423deg) rotateZ(161deg) translateZ(225px);
        }
        10% {
          opacity: 0;
          transform: translate(-50%, -50%) rotateX(442deg) rotateY(423deg) rotateZ(161deg) translateZ(225px);
        }
        90% {
          opacity: 1;
          transform: translate(-50%, -50%) rotate(0deg) rotateY(0deg) rotateZ(0deg) translateZ(0px);
        }
        100% {
          opacity: 1;
          transform: translate(-50%, -50%) rotate(0deg) rotateY(0deg) rotateZ(0deg) translateZ(0px);
        }
      }
      #websitelogo .text:nth-child(64) {
        color: #7f12c0;
        -webkit-clip-path: polygon(10% 150%, 20% 150%, 20% 200%);
                clip-path: polygon(10% 150%, 20% 150%, 20% 200%);
        transform-origin: 44% 112%;
        -webkit-animation: fly64 5000ms 2520ms cubic-bezier(0.36, 0.1, 0.16, 1) infinite alternate;
                animation: fly64 5000ms 2520ms cubic-bezier(0.36, 0.1, 0.16, 1) infinite alternate;
      }
      @-webkit-keyframes fly64 {
        0% {
          opacity: 0;
          transform: translate(-50%, -50%) rotateX(91deg) rotateY(-85deg) rotateZ(-455deg) translateZ(-1034px);
        }
        10% {
          opacity: 0;
          transform: translate(-50%, -50%) rotateX(91deg) rotateY(-85deg) rotateZ(-455deg) translateZ(-1034px);
        }
        90% {
          opacity: 1;
          transform: translate(-50%, -50%) rotate(0deg) rotateY(0deg) rotateZ(0deg) translateZ(0px);
        }
        100% {
          opacity: 1;
          transform: translate(-50%, -50%) rotate(0deg) rotateY(0deg) rotateZ(0deg) translateZ(0px);
        }
      }
      @keyframes fly64 {
        0% {
          opacity: 0;
          transform: translate(-50%, -50%) rotateX(91deg) rotateY(-85deg) rotateZ(-455deg) translateZ(-1034px);
        }
        10% {
          opacity: 0;
          transform: translate(-50%, -50%) rotateX(91deg) rotateY(-85deg) rotateZ(-455deg) translateZ(-1034px);
        }
        90% {
          opacity: 1;
          transform: translate(-50%, -50%) rotate(0deg) rotateY(0deg) rotateZ(0deg) translateZ(0px);
        }
        100% {
          opacity: 1;
          transform: translate(-50%, -50%) rotate(0deg) rotateY(0deg) rotateZ(0deg) translateZ(0px);
        }
      }
      #websitelogo .text:nth-child(65) {
        color: #7d12c3;
        -webkit-clip-path: polygon(20% 150%, 20% 200%, 30% 200%);
                clip-path: polygon(20% 150%, 20% 200%, 30% 200%);
        transform-origin: 56% 142%;
        -webkit-animation: fly65 5000ms 2560ms cubic-bezier(0.36, 0.1, 0.16, 1) infinite alternate;
                animation: fly65 5000ms 2560ms cubic-bezier(0.36, 0.1, 0.16, 1) infinite alternate;
      }
      @-webkit-keyframes fly65 {
        0% {
          opacity: 0;
          transform: translate(-50%, -50%) rotateX(225deg) rotateY(19deg) rotateZ(274deg) translateZ(-142px);
        }
        10% {
          opacity: 0;
          transform: translate(-50%, -50%) rotateX(225deg) rotateY(19deg) rotateZ(274deg) translateZ(-142px);
        }
        90% {
          opacity: 1;
          transform: translate(-50%, -50%) rotate(0deg) rotateY(0deg) rotateZ(0deg) translateZ(0px);
        }
        100% {
          opacity: 1;
          transform: translate(-50%, -50%) rotate(0deg) rotateY(0deg) rotateZ(0deg) translateZ(0px);
        }
      }
      @keyframes fly65 {
        0% {
          opacity: 0;
          transform: translate(-50%, -50%) rotateX(225deg) rotateY(19deg) rotateZ(274deg) translateZ(-142px);
        }
        10% {
          opacity: 0;
          transform: translate(-50%, -50%) rotateX(225deg) rotateY(19deg) rotateZ(274deg) translateZ(-142px);
        }
        90% {
          opacity: 1;
          transform: translate(-50%, -50%) rotate(0deg) rotateY(0deg) rotateZ(0deg) translateZ(0px);
        }
        100% {
          opacity: 1;
          transform: translate(-50%, -50%) rotate(0deg) rotateY(0deg) rotateZ(0deg) translateZ(0px);
        }
      }
      #websitelogo .text:nth-child(66) {
        color: #7b11c6;
        -webkit-clip-path: polygon(20% 150%, 30% 150%, 30% 200%);
                clip-path: polygon(20% 150%, 30% 150%, 30% 200%);
        transform-origin: 68% 173%;
        -webkit-animation: fly66 5000ms 2600ms cubic-bezier(0.36, 0.1, 0.16, 1) infinite alternate;
                animation: fly66 5000ms 2600ms cubic-bezier(0.36, 0.1, 0.16, 1) infinite alternate;
      }
      @-webkit-keyframes fly66 {
        0% {
          opacity: 0;
          transform: translate(-50%, -50%) rotateX(-164deg) rotateY(206deg) rotateZ(-419deg) translateZ(-384px);
        }
        10% {
          opacity: 0;
          transform: translate(-50%, -50%) rotateX(-164deg) rotateY(206deg) rotateZ(-419deg) translateZ(-384px);
        }
        90% {
          opacity: 1;
          transform: translate(-50%, -50%) rotate(0deg) rotateY(0deg) rotateZ(0deg) translateZ(0px);
        }
        100% {
          opacity: 1;
          transform: translate(-50%, -50%) rotate(0deg) rotateY(0deg) rotateZ(0deg) translateZ(0px);
        }
      }
      @keyframes fly66 {
        0% {
          opacity: 0;
          transform: translate(-50%, -50%) rotateX(-164deg) rotateY(206deg) rotateZ(-419deg) translateZ(-384px);
        }
        10% {
          opacity: 0;
          transform: translate(-50%, -50%) rotateX(-164deg) rotateY(206deg) rotateZ(-419deg) translateZ(-384px);
        }
        90% {
          opacity: 1;
          transform: translate(-50%, -50%) rotate(0deg) rotateY(0deg) rotateZ(0deg) translateZ(0px);
        }
        100% {
          opacity: 1;
          transform: translate(-50%, -50%) rotate(0deg) rotateY(0deg) rotateZ(0deg) translateZ(0px);
        }
      }
      #websitelogo .text:nth-child(67) {
        color: #7911c9;
        -webkit-clip-path: polygon(30% 150%, 30% 200%, 40% 200%);
                clip-path: polygon(30% 150%, 30% 200%, 40% 200%);
        transform-origin: -12% 150%;
        -webkit-animation: fly67 5000ms 2640ms cubic-bezier(0.36, 0.1, 0.16, 1) infinite alternate;
                animation: fly67 5000ms 2640ms cubic-bezier(0.36, 0.1, 0.16, 1) infinite alternate;
      }
      @-webkit-keyframes fly67 {
        0% {
          opacity: 0;
          transform: translate(-50%, -50%) rotateX(271deg) rotateY(-165deg) rotateZ(-30deg) translateZ(-1612px);
        }
        10% {
          opacity: 0;
          transform: translate(-50%, -50%) rotateX(271deg) rotateY(-165deg) rotateZ(-30deg) translateZ(-1612px);
        }
        90% {
          opacity: 1;
          transform: translate(-50%, -50%) rotate(0deg) rotateY(0deg) rotateZ(0deg) translateZ(0px);
        }
        100% {
          opacity: 1;
          transform: translate(-50%, -50%) rotate(0deg) rotateY(0deg) rotateZ(0deg) translateZ(0px);
        }
      }
      @keyframes fly67 {
        0% {
          opacity: 0;
          transform: translate(-50%, -50%) rotateX(271deg) rotateY(-165deg) rotateZ(-30deg) translateZ(-1612px);
        }
        10% {
          opacity: 0;
          transform: translate(-50%, -50%) rotateX(271deg) rotateY(-165deg) rotateZ(-30deg) translateZ(-1612px);
        }
        90% {
          opacity: 1;
          transform: translate(-50%, -50%) rotate(0deg) rotateY(0deg) rotateZ(0deg) translateZ(0px);
        }
        100% {
          opacity: 1;
          transform: translate(-50%, -50%) rotate(0deg) rotateY(0deg) rotateZ(0deg) translateZ(0px);
        }
      }
      #websitelogo .text:nth-child(68) {
        color: #7710cc;
        -webkit-clip-path: polygon(30% 150%, 40% 150%, 40% 200%);
                clip-path: polygon(30% 150%, 40% 150%, 40% 200%);
        transform-origin: 43% 197%;
        -webkit-animation: fly68 5000ms 2680ms cubic-bezier(0.36, 0.1, 0.16, 1) infinite alternate;
                animation: fly68 5000ms 2680ms cubic-bezier(0.36, 0.1, 0.16, 1) infinite alternate;
      }
      @-webkit-keyframes fly68 {
        0% {
          opacity: 0;
          transform: translate(-50%, -50%) rotateX(-456deg) rotateY(-426deg) rotateZ(191deg) translateZ(-1234px);
        }
        10% {
          opacity: 0;
          transform: translate(-50%, -50%) rotateX(-456deg) rotateY(-426deg) rotateZ(191deg) translateZ(-1234px);
        }
        90% {
          opacity: 1;
          transform: translate(-50%, -50%) rotate(0deg) rotateY(0deg) rotateZ(0deg) translateZ(0px);
        }
        100% {
          opacity: 1;
          transform: translate(-50%, -50%) rotate(0deg) rotateY(0deg) rotateZ(0deg) translateZ(0px);
        }
      }
      @keyframes fly68 {
        0% {
          opacity: 0;
          transform: translate(-50%, -50%) rotateX(-456deg) rotateY(-426deg) rotateZ(191deg) translateZ(-1234px);
        }
        10% {
          opacity: 0;
          transform: translate(-50%, -50%) rotateX(-456deg) rotateY(-426deg) rotateZ(191deg) translateZ(-1234px);
        }
        90% {
          opacity: 1;
          transform: translate(-50%, -50%) rotate(0deg) rotateY(0deg) rotateZ(0deg) translateZ(0px);
        }
        100% {
          opacity: 1;
          transform: translate(-50%, -50%) rotate(0deg) rotateY(0deg) rotateZ(0deg) translateZ(0px);
        }
      }
      #websitelogo .text:nth-child(69) {
        color: #7510cf;
        -webkit-clip-path: polygon(40% 150%, 40% 200%, 50% 200%);
                clip-path: polygon(40% 150%, 40% 200%, 50% 200%);
        transform-origin: 15% 141%;
        -webkit-animation: fly69 5000ms 2720ms cubic-bezier(0.36, 0.1, 0.16, 1) infinite alternate;
                animation: fly69 5000ms 2720ms cubic-bezier(0.36, 0.1, 0.16, 1) infinite alternate;
      }
      @-webkit-keyframes fly69 {
        0% {
          opacity: 0;
          transform: translate(-50%, -50%) rotateX(24deg) rotateY(-54deg) rotateZ(-444deg) translateZ(-1099px);
        }
        10% {
          opacity: 0;
          transform: translate(-50%, -50%) rotateX(24deg) rotateY(-54deg) rotateZ(-444deg) translateZ(-1099px);
        }
        90% {
          opacity: 1;
          transform: translate(-50%, -50%) rotate(0deg) rotateY(0deg) rotateZ(0deg) translateZ(0px);
        }
        100% {
          opacity: 1;
          transform: translate(-50%, -50%) rotate(0deg) rotateY(0deg) rotateZ(0deg) translateZ(0px);
        }
      }
      @keyframes fly69 {
        0% {
          opacity: 0;
          transform: translate(-50%, -50%) rotateX(24deg) rotateY(-54deg) rotateZ(-444deg) translateZ(-1099px);
        }
        10% {
          opacity: 0;
          transform: translate(-50%, -50%) rotateX(24deg) rotateY(-54deg) rotateZ(-444deg) translateZ(-1099px);
        }
        90% {
          opacity: 1;
          transform: translate(-50%, -50%) rotate(0deg) rotateY(0deg) rotateZ(0deg) translateZ(0px);
        }
        100% {
          opacity: 1;
          transform: translate(-50%, -50%) rotate(0deg) rotateY(0deg) rotateZ(0deg) translateZ(0px);
        }
      }
      #websitelogo .text:nth-child(70) {
        color: #730fd2;
        -webkit-clip-path: polygon(40% 150%, 50% 150%, 50% 200%);
                clip-path: polygon(40% 150%, 50% 150%, 50% 200%);
        transform-origin: 77% 146%;
        -webkit-animation: fly70 5000ms 2760ms cubic-bezier(0.36, 0.1, 0.16, 1) infinite alternate;
                animation: fly70 5000ms 2760ms cubic-bezier(0.36, 0.1, 0.16, 1) infinite alternate;
      }
      @-webkit-keyframes fly70 {
        0% {
          opacity: 0;
          transform: translate(-50%, -50%) rotateX(37deg) rotateY(313deg) rotateZ(-186deg) translateZ(-605px);
        }
        10% {
          opacity: 0;
          transform: translate(-50%, -50%) rotateX(37deg) rotateY(313deg) rotateZ(-186deg) translateZ(-605px);
        }
        90% {
          opacity: 1;
          transform: translate(-50%, -50%) rotate(0deg) rotateY(0deg) rotateZ(0deg) translateZ(0px);
        }
        100% {
          opacity: 1;
          transform: translate(-50%, -50%) rotate(0deg) rotateY(0deg) rotateZ(0deg) translateZ(0px);
        }
      }
      @keyframes fly70 {
        0% {
          opacity: 0;
          transform: translate(-50%, -50%) rotateX(37deg) rotateY(313deg) rotateZ(-186deg) translateZ(-605px);
        }
        10% {
          opacity: 0;
          transform: translate(-50%, -50%) rotateX(37deg) rotateY(313deg) rotateZ(-186deg) translateZ(-605px);
        }
        90% {
          opacity: 1;
          transform: translate(-50%, -50%) rotate(0deg) rotateY(0deg) rotateZ(0deg) translateZ(0px);
        }
        100% {
          opacity: 1;
          transform: translate(-50%, -50%) rotate(0deg) rotateY(0deg) rotateZ(0deg) translateZ(0px);
        }
      }
      #websitelogo .text:nth-child(71) {
        color: #710fd5;
        -webkit-clip-path: polygon(50% 150%, 50% 200%, 60% 200%);
                clip-path: polygon(50% 150%, 50% 200%, 60% 200%);
        transform-origin: 28% 134%;
        -webkit-animation: fly71 5000ms 2800ms cubic-bezier(0.36, 0.1, 0.16, 1) infinite alternate;
                animation: fly71 5000ms 2800ms cubic-bezier(0.36, 0.1, 0.16, 1) infinite alternate;
      }
      @-webkit-keyframes fly71 {
        0% {
          opacity: 0;
          transform: translate(-50%, -50%) rotateX(477deg) rotateY(84deg) rotateZ(9deg) translateZ(231px);
        }
        10% {
          opacity: 0;
          transform: translate(-50%, -50%) rotateX(477deg) rotateY(84deg) rotateZ(9deg) translateZ(231px);
        }
        90% {
          opacity: 1;
          transform: translate(-50%, -50%) rotate(0deg) rotateY(0deg) rotateZ(0deg) translateZ(0px);
        }
        100% {
          opacity: 1;
          transform: translate(-50%, -50%) rotate(0deg) rotateY(0deg) rotateZ(0deg) translateZ(0px);
        }
      }
      @keyframes fly71 {
        0% {
          opacity: 0;
          transform: translate(-50%, -50%) rotateX(477deg) rotateY(84deg) rotateZ(9deg) translateZ(231px);
        }
        10% {
          opacity: 0;
          transform: translate(-50%, -50%) rotateX(477deg) rotateY(84deg) rotateZ(9deg) translateZ(231px);
        }
        90% {
          opacity: 1;
          transform: translate(-50%, -50%) rotate(0deg) rotateY(0deg) rotateZ(0deg) translateZ(0px);
        }
        100% {
          opacity: 1;
          transform: translate(-50%, -50%) rotate(0deg) rotateY(0deg) rotateZ(0deg) translateZ(0px);
        }
      }
      #websitelogo .text:nth-child(72) {
        color: #6f0ed8;
        -webkit-clip-path: polygon(50% 150%, 60% 150%, 60% 200%);
                clip-path: polygon(50% 150%, 60% 150%, 60% 200%);
        transform-origin: 74% 125%;
        -webkit-animation: fly72 5000ms 2840ms cubic-bezier(0.36, 0.1, 0.16, 1) infinite alternate;
                animation: fly72 5000ms 2840ms cubic-bezier(0.36, 0.1, 0.16, 1) infinite alternate;
      }
      @-webkit-keyframes fly72 {
        0% {
          opacity: 0;
          transform: translate(-50%, -50%) rotateX(343deg) rotateY(-264deg) rotateZ(-96deg) translateZ(-2475px);
        }
        10% {
          opacity: 0;
          transform: translate(-50%, -50%) rotateX(343deg) rotateY(-264deg) rotateZ(-96deg) translateZ(-2475px);
        }
        90% {
          opacity: 1;
          transform: translate(-50%, -50%) rotate(0deg) rotateY(0deg) rotateZ(0deg) translateZ(0px);
        }
        100% {
          opacity: 1;
          transform: translate(-50%, -50%) rotate(0deg) rotateY(0deg) rotateZ(0deg) translateZ(0px);
        }
      }
      @keyframes fly72 {
        0% {
          opacity: 0;
          transform: translate(-50%, -50%) rotateX(343deg) rotateY(-264deg) rotateZ(-96deg) translateZ(-2475px);
        }
        10% {
          opacity: 0;
          transform: translate(-50%, -50%) rotateX(343deg) rotateY(-264deg) rotateZ(-96deg) translateZ(-2475px);
        }
        90% {
          opacity: 1;
          transform: translate(-50%, -50%) rotate(0deg) rotateY(0deg) rotateZ(0deg) translateZ(0px);
        }
        100% {
          opacity: 1;
          transform: translate(-50%, -50%) rotate(0deg) rotateY(0deg) rotateZ(0deg) translateZ(0px);
        }
      }
      #websitelogo .text:nth-child(73) {
        color: #6d0edb;
        -webkit-clip-path: polygon(60% 150%, 60% 200%, 70% 200%);
                clip-path: polygon(60% 150%, 60% 200%, 70% 200%);
        transform-origin: 58% 103%;
        -webkit-animation: fly73 5000ms 2880ms cubic-bezier(0.36, 0.1, 0.16, 1) infinite alternate;
                animation: fly73 5000ms 2880ms cubic-bezier(0.36, 0.1, 0.16, 1) infinite alternate;
      }
      @-webkit-keyframes fly73 {
        0% {
          opacity: 0;
          transform: translate(-50%, -50%) rotateX(254deg) rotateY(-305deg) rotateZ(-337deg) translateZ(149px);
        }
        10% {
          opacity: 0;
          transform: translate(-50%, -50%) rotateX(254deg) rotateY(-305deg) rotateZ(-337deg) translateZ(149px);
        }
        90% {
          opacity: 1;
          transform: translate(-50%, -50%) rotate(0deg) rotateY(0deg) rotateZ(0deg) translateZ(0px);
        }
        100% {
          opacity: 1;
          transform: translate(-50%, -50%) rotate(0deg) rotateY(0deg) rotateZ(0deg) translateZ(0px);
        }
      }
      @keyframes fly73 {
        0% {
          opacity: 0;
          transform: translate(-50%, -50%) rotateX(254deg) rotateY(-305deg) rotateZ(-337deg) translateZ(149px);
        }
        10% {
          opacity: 0;
          transform: translate(-50%, -50%) rotateX(254deg) rotateY(-305deg) rotateZ(-337deg) translateZ(149px);
        }
        90% {
          opacity: 1;
          transform: translate(-50%, -50%) rotate(0deg) rotateY(0deg) rotateZ(0deg) translateZ(0px);
        }
        100% {
          opacity: 1;
          transform: translate(-50%, -50%) rotate(0deg) rotateY(0deg) rotateZ(0deg) translateZ(0px);
        }
      }
      #websitelogo .text:nth-child(74) {
        color: #6b0dde;
        -webkit-clip-path: polygon(60% 150%, 70% 150%, 70% 200%);
                clip-path: polygon(60% 150%, 70% 150%, 70% 200%);
        transform-origin: 41% 183%;
        -webkit-animation: fly74 5000ms 2920ms cubic-bezier(0.36, 0.1, 0.16, 1) infinite alternate;
                animation: fly74 5000ms 2920ms cubic-bezier(0.36, 0.1, 0.16, 1) infinite alternate;
      }
      @-webkit-keyframes fly74 {
        0% {
          opacity: 0;
          transform: translate(-50%, -50%) rotateX(-388deg) rotateY(-476deg) rotateZ(380deg) translateZ(-1880px);
        }
        10% {
          opacity: 0;
          transform: translate(-50%, -50%) rotateX(-388deg) rotateY(-476deg) rotateZ(380deg) translateZ(-1880px);
        }
        90% {
          opacity: 1;
          transform: translate(-50%, -50%) rotate(0deg) rotateY(0deg) rotateZ(0deg) translateZ(0px);
        }
        100% {
          opacity: 1;
          transform: translate(-50%, -50%) rotate(0deg) rotateY(0deg) rotateZ(0deg) translateZ(0px);
        }
      }
      @keyframes fly74 {
        0% {
          opacity: 0;
          transform: translate(-50%, -50%) rotateX(-388deg) rotateY(-476deg) rotateZ(380deg) translateZ(-1880px);
        }
        10% {
          opacity: 0;
          transform: translate(-50%, -50%) rotateX(-388deg) rotateY(-476deg) rotateZ(380deg) translateZ(-1880px);
        }
        90% {
          opacity: 1;
          transform: translate(-50%, -50%) rotate(0deg) rotateY(0deg) rotateZ(0deg) translateZ(0px);
        }
        100% {
          opacity: 1;
          transform: translate(-50%, -50%) rotate(0deg) rotateY(0deg) rotateZ(0deg) translateZ(0px);
        }
      }
      #websitelogo .text:nth-child(75) {
        color: #690de1;
        -webkit-clip-path: polygon(70% 150%, 70% 200%, 80% 200%);
                clip-path: polygon(70% 150%, 70% 200%, 80% 200%);
        transform-origin: 63% 108%;
        -webkit-animation: fly75 5000ms 2960ms cubic-bezier(0.36, 0.1, 0.16, 1) infinite alternate;
                animation: fly75 5000ms 2960ms cubic-bezier(0.36, 0.1, 0.16, 1) infinite alternate;
      }
      @-webkit-keyframes fly75 {
        0% {
          opacity: 0;
          transform: translate(-50%, -50%) rotateX(361deg) rotateY(393deg) rotateZ(-123deg) translateZ(-1793px);
        }
        10% {
          opacity: 0;
          transform: translate(-50%, -50%) rotateX(361deg) rotateY(393deg) rotateZ(-123deg) translateZ(-1793px);
        }
        90% {
          opacity: 1;
          transform: translate(-50%, -50%) rotate(0deg) rotateY(0deg) rotateZ(0deg) translateZ(0px);
        }
        100% {
          opacity: 1;
          transform: translate(-50%, -50%) rotate(0deg) rotateY(0deg) rotateZ(0deg) translateZ(0px);
        }
      }
      @keyframes fly75 {
        0% {
          opacity: 0;
          transform: translate(-50%, -50%) rotateX(361deg) rotateY(393deg) rotateZ(-123deg) translateZ(-1793px);
        }
        10% {
          opacity: 0;
          transform: translate(-50%, -50%) rotateX(361deg) rotateY(393deg) rotateZ(-123deg) translateZ(-1793px);
        }
        90% {
          opacity: 1;
          transform: translate(-50%, -50%) rotate(0deg) rotateY(0deg) rotateZ(0deg) translateZ(0px);
        }
        100% {
          opacity: 1;
          transform: translate(-50%, -50%) rotate(0deg) rotateY(0deg) rotateZ(0deg) translateZ(0px);
        }
      }
      #websitelogo .text:nth-child(76) {
        color: #670ce4;
        -webkit-clip-path: polygon(70% 150%, 80% 150%, 80% 200%);
                clip-path: polygon(70% 150%, 80% 150%, 80% 200%);
        transform-origin: 31% 129%;
        -webkit-animation: fly76 5000ms 3000ms cubic-bezier(0.36, 0.1, 0.16, 1) infinite alternate;
                animation: fly76 5000ms 3000ms cubic-bezier(0.36, 0.1, 0.16, 1) infinite alternate;
      }
      @-webkit-keyframes fly76 {
        0% {
          opacity: 0;
          transform: translate(-50%, -50%) rotateX(-308deg) rotateY(470deg) rotateZ(-287deg) translateZ(362px);
        }
        10% {
          opacity: 0;
          transform: translate(-50%, -50%) rotateX(-308deg) rotateY(470deg) rotateZ(-287deg) translateZ(362px);
        }
        90% {
          opacity: 1;
          transform: translate(-50%, -50%) rotate(0deg) rotateY(0deg) rotateZ(0deg) translateZ(0px);
        }
        100% {
          opacity: 1;
          transform: translate(-50%, -50%) rotate(0deg) rotateY(0deg) rotateZ(0deg) translateZ(0px);
        }
      }
      @keyframes fly76 {
        0% {
          opacity: 0;
          transform: translate(-50%, -50%) rotateX(-308deg) rotateY(470deg) rotateZ(-287deg) translateZ(362px);
        }
        10% {
          opacity: 0;
          transform: translate(-50%, -50%) rotateX(-308deg) rotateY(470deg) rotateZ(-287deg) translateZ(362px);
        }
        90% {
          opacity: 1;
          transform: translate(-50%, -50%) rotate(0deg) rotateY(0deg) rotateZ(0deg) translateZ(0px);
        }
        100% {
          opacity: 1;
          transform: translate(-50%, -50%) rotate(0deg) rotateY(0deg) rotateZ(0deg) translateZ(0px);
        }
      }
      #websitelogo .text:nth-child(77) {
        color: #650ce7;
        -webkit-clip-path: polygon(80% 150%, 80% 200%, 90% 200%);
                clip-path: polygon(80% 150%, 80% 200%, 90% 200%);
        transform-origin: 53% 124%;
        -webkit-animation: fly77 5000ms 3040ms cubic-bezier(0.36, 0.1, 0.16, 1) infinite alternate;
                animation: fly77 5000ms 3040ms cubic-bezier(0.36, 0.1, 0.16, 1) infinite alternate;
      }
      @-webkit-keyframes fly77 {
        0% {
          opacity: 0;
          transform: translate(-50%, -50%) rotateX(83deg) rotateY(-89deg) rotateZ(91deg) translateZ(-632px);
        }
        10% {
          opacity: 0;
          transform: translate(-50%, -50%) rotateX(83deg) rotateY(-89deg) rotateZ(91deg) translateZ(-632px);
        }
        90% {
          opacity: 1;
          transform: translate(-50%, -50%) rotate(0deg) rotateY(0deg) rotateZ(0deg) translateZ(0px);
        }
        100% {
          opacity: 1;
          transform: translate(-50%, -50%) rotate(0deg) rotateY(0deg) rotateZ(0deg) translateZ(0px);
        }
      }
      @keyframes fly77 {
        0% {
          opacity: 0;
          transform: translate(-50%, -50%) rotateX(83deg) rotateY(-89deg) rotateZ(91deg) translateZ(-632px);
        }
        10% {
          opacity: 0;
          transform: translate(-50%, -50%) rotateX(83deg) rotateY(-89deg) rotateZ(91deg) translateZ(-632px);
        }
        90% {
          opacity: 1;
          transform: translate(-50%, -50%) rotate(0deg) rotateY(0deg) rotateZ(0deg) translateZ(0px);
        }
        100% {
          opacity: 1;
          transform: translate(-50%, -50%) rotate(0deg) rotateY(0deg) rotateZ(0deg) translateZ(0px);
        }
      }
      #websitelogo .text:nth-child(78) {
        color: #630bea;
        -webkit-clip-path: polygon(80% 150%, 90% 150%, 90% 200%);
                clip-path: polygon(80% 150%, 90% 150%, 90% 200%);
        transform-origin: 53% 187%;
        -webkit-animation: fly78 5000ms 3080ms cubic-bezier(0.36, 0.1, 0.16, 1) infinite alternate;
                animation: fly78 5000ms 3080ms cubic-bezier(0.36, 0.1, 0.16, 1) infinite alternate;
      }
      @-webkit-keyframes fly78 {
        0% {
          opacity: 0;
          transform: translate(-50%, -50%) rotateX(72deg) rotateY(-433deg) rotateZ(416deg) translateZ(-2179px);
        }
        10% {
          opacity: 0;
          transform: translate(-50%, -50%) rotateX(72deg) rotateY(-433deg) rotateZ(416deg) translateZ(-2179px);
        }
        90% {
          opacity: 1;
          transform: translate(-50%, -50%) rotate(0deg) rotateY(0deg) rotateZ(0deg) translateZ(0px);
        }
        100% {
          opacity: 1;
          transform: translate(-50%, -50%) rotate(0deg) rotateY(0deg) rotateZ(0deg) translateZ(0px);
        }
      }
      @keyframes fly78 {
        0% {
          opacity: 0;
          transform: translate(-50%, -50%) rotateX(72deg) rotateY(-433deg) rotateZ(416deg) translateZ(-2179px);
        }
        10% {
          opacity: 0;
          transform: translate(-50%, -50%) rotateX(72deg) rotateY(-433deg) rotateZ(416deg) translateZ(-2179px);
        }
        90% {
          opacity: 1;
          transform: translate(-50%, -50%) rotate(0deg) rotateY(0deg) rotateZ(0deg) translateZ(0px);
        }
        100% {
          opacity: 1;
          transform: translate(-50%, -50%) rotate(0deg) rotateY(0deg) rotateZ(0deg) translateZ(0px);
        }
      }
      #websitelogo .text:nth-child(79) {
        color: #610bed;
        -webkit-clip-path: polygon(90% 150%, 90% 200%, 100% 200%);
                clip-path: polygon(90% 150%, 90% 200%, 100% 200%);
        transform-origin: 60% 114%;
        -webkit-animation: fly79 5000ms 3120ms cubic-bezier(0.36, 0.1, 0.16, 1) infinite alternate;
                animation: fly79 5000ms 3120ms cubic-bezier(0.36, 0.1, 0.16, 1) infinite alternate;
      }
      @-webkit-keyframes fly79 {
        0% {
          opacity: 0;
          transform: translate(-50%, -50%) rotateX(355deg) rotateY(476deg) rotateZ(412deg) translateZ(-451px);
        }
        10% {
          opacity: 0;
          transform: translate(-50%, -50%) rotateX(355deg) rotateY(476deg) rotateZ(412deg) translateZ(-451px);
        }
        90% {
          opacity: 1;
          transform: translate(-50%, -50%) rotate(0deg) rotateY(0deg) rotateZ(0deg) translateZ(0px);
        }
        100% {
          opacity: 1;
          transform: translate(-50%, -50%) rotate(0deg) rotateY(0deg) rotateZ(0deg) translateZ(0px);
        }
      }
      @keyframes fly79 {
        0% {
          opacity: 0;
          transform: translate(-50%, -50%) rotateX(355deg) rotateY(476deg) rotateZ(412deg) translateZ(-451px);
        }
        10% {
          opacity: 0;
          transform: translate(-50%, -50%) rotateX(355deg) rotateY(476deg) rotateZ(412deg) translateZ(-451px);
        }
        90% {
          opacity: 1;
          transform: translate(-50%, -50%) rotate(0deg) rotateY(0deg) rotateZ(0deg) translateZ(0px);
        }
        100% {
          opacity: 1;
          transform: translate(-50%, -50%) rotate(0deg) rotateY(0deg) rotateZ(0deg) translateZ(0px);
        }
      }
      #websitelogo .text:nth-child(80) {
        color: #5f0af0;
        -webkit-clip-path: polygon(90% 150%, 100% 150%, 100% 200%);
                clip-path: polygon(90% 150%, 100% 150%, 100% 200%);
        transform-origin: 88% 167%;
        -webkit-animation: fly80 5000ms 3160ms cubic-bezier(0.36, 0.1, 0.16, 1) infinite alternate;
                animation: fly80 5000ms 3160ms cubic-bezier(0.36, 0.1, 0.16, 1) infinite alternate;
      }
      @-webkit-keyframes fly80 {
        0% {
          opacity: 0;
          transform: translate(-50%, -50%) rotateX(250deg) rotateY(-493deg) rotateZ(-394deg) translateZ(-2275px);
        }
        10% {
          opacity: 0;
          transform: translate(-50%, -50%) rotateX(250deg) rotateY(-493deg) rotateZ(-394deg) translateZ(-2275px);
        }
        90% {
          opacity: 1;
          transform: translate(-50%, -50%) rotate(0deg) rotateY(0deg) rotateZ(0deg) translateZ(0px);
        }
        100% {
          opacity: 1;
          transform: translate(-50%, -50%) rotate(0deg) rotateY(0deg) rotateZ(0deg) translateZ(0px);
        }
      }
      @keyframes fly80 {
        0% {
          opacity: 0;
          transform: translate(-50%, -50%) rotateX(250deg) rotateY(-493deg) rotateZ(-394deg) translateZ(-2275px);
        }
        10% {
          opacity: 0;
          transform: translate(-50%, -50%) rotateX(250deg) rotateY(-493deg) rotateZ(-394deg) translateZ(-2275px);
        }
        90% {
          opacity: 1;
          transform: translate(-50%, -50%) rotate(0deg) rotateY(0deg) rotateZ(0deg) translateZ(0px);
        }
        100% {
          opacity: 1;
          transform: translate(-50%, -50%) rotate(0deg) rotateY(0deg) rotateZ(0deg) translateZ(0px);
        }
      }
      #websitelogo .text:nth-child(81) {
        color: #5d0af3;
        -webkit-clip-path: polygon(0% 200%, 0% 250%, 10% 250%);
                clip-path: polygon(0% 200%, 0% 250%, 10% 250%);
        transform-origin: -21% 224%;
        -webkit-animation: fly81 5000ms 3200ms cubic-bezier(0.36, 0.1, 0.16, 1) infinite alternate;
                animation: fly81 5000ms 3200ms cubic-bezier(0.36, 0.1, 0.16, 1) infinite alternate;
      }
      @-webkit-keyframes fly81 {
        0% {
          opacity: 0;
          transform: translate(-50%, -50%) rotateX(-189deg) rotateY(262deg) rotateZ(153deg) translateZ(-1608px);
        }
        10% {
          opacity: 0;
          transform: translate(-50%, -50%) rotateX(-189deg) rotateY(262deg) rotateZ(153deg) translateZ(-1608px);
        }
        90% {
          opacity: 1;
          transform: translate(-50%, -50%) rotate(0deg) rotateY(0deg) rotateZ(0deg) translateZ(0px);
        }
        100% {
          opacity: 1;
          transform: translate(-50%, -50%) rotate(0deg) rotateY(0deg) rotateZ(0deg) translateZ(0px);
        }
      }
      @keyframes fly81 {
        0% {
          opacity: 0;
          transform: translate(-50%, -50%) rotateX(-189deg) rotateY(262deg) rotateZ(153deg) translateZ(-1608px);
        }
        10% {
          opacity: 0;
          transform: translate(-50%, -50%) rotateX(-189deg) rotateY(262deg) rotateZ(153deg) translateZ(-1608px);
        }
        90% {
          opacity: 1;
          transform: translate(-50%, -50%) rotate(0deg) rotateY(0deg) rotateZ(0deg) translateZ(0px);
        }
        100% {
          opacity: 1;
          transform: translate(-50%, -50%) rotate(0deg) rotateY(0deg) rotateZ(0deg) translateZ(0px);
        }
      }
      #websitelogo .text:nth-child(82) {
        color: #5b09f6;
        -webkit-clip-path: polygon(0% 200%, 10% 200%, 10% 250%);
                clip-path: polygon(0% 200%, 10% 200%, 10% 250%);
        transform-origin: 19% 240%;
        -webkit-animation: fly82 5000ms 3240ms cubic-bezier(0.36, 0.1, 0.16, 1) infinite alternate;
                animation: fly82 5000ms 3240ms cubic-bezier(0.36, 0.1, 0.16, 1) infinite alternate;
      }
      @-webkit-keyframes fly82 {
        0% {
          opacity: 0;
          transform: translate(-50%, -50%) rotateX(-304deg) rotateY(-26deg) rotateZ(26deg) translateZ(-2373px);
        }
        10% {
          opacity: 0;
          transform: translate(-50%, -50%) rotateX(-304deg) rotateY(-26deg) rotateZ(26deg) translateZ(-2373px);
        }
        90% {
          opacity: 1;
          transform: translate(-50%, -50%) rotate(0deg) rotateY(0deg) rotateZ(0deg) translateZ(0px);
        }
        100% {
          opacity: 1;
          transform: translate(-50%, -50%) rotate(0deg) rotateY(0deg) rotateZ(0deg) translateZ(0px);
        }
      }
      @keyframes fly82 {
        0% {
          opacity: 0;
          transform: translate(-50%, -50%) rotateX(-304deg) rotateY(-26deg) rotateZ(26deg) translateZ(-2373px);
        }
        10% {
          opacity: 0;
          transform: translate(-50%, -50%) rotateX(-304deg) rotateY(-26deg) rotateZ(26deg) translateZ(-2373px);
        }
        90% {
          opacity: 1;
          transform: translate(-50%, -50%) rotate(0deg) rotateY(0deg) rotateZ(0deg) translateZ(0px);
        }
        100% {
          opacity: 1;
          transform: translate(-50%, -50%) rotate(0deg) rotateY(0deg) rotateZ(0deg) translateZ(0px);
        }
      }
      #websitelogo .text:nth-child(83) {
        color: #5909f9;
        -webkit-clip-path: polygon(10% 200%, 10% 250%, 20% 250%);
                clip-path: polygon(10% 200%, 10% 250%, 20% 250%);
        transform-origin: -7% 189%;
        -webkit-animation: fly83 5000ms 3280ms cubic-bezier(0.36, 0.1, 0.16, 1) infinite alternate;
                animation: fly83 5000ms 3280ms cubic-bezier(0.36, 0.1, 0.16, 1) infinite alternate;
      }
      @-webkit-keyframes fly83 {
        0% {
          opacity: 0;
          transform: translate(-50%, -50%) rotateX(-414deg) rotateY(-387deg) rotateZ(-360deg) translateZ(-689px);
        }
        10% {
          opacity: 0;
          transform: translate(-50%, -50%) rotateX(-414deg) rotateY(-387deg) rotateZ(-360deg) translateZ(-689px);
        }
        90% {
          opacity: 1;
          transform: translate(-50%, -50%) rotate(0deg) rotateY(0deg) rotateZ(0deg) translateZ(0px);
        }
        100% {
          opacity: 1;
          transform: translate(-50%, -50%) rotate(0deg) rotateY(0deg) rotateZ(0deg) translateZ(0px);
        }
      }
      @keyframes fly83 {
        0% {
          opacity: 0;
          transform: translate(-50%, -50%) rotateX(-414deg) rotateY(-387deg) rotateZ(-360deg) translateZ(-689px);
        }
        10% {
          opacity: 0;
          transform: translate(-50%, -50%) rotateX(-414deg) rotateY(-387deg) rotateZ(-360deg) translateZ(-689px);
        }
        90% {
          opacity: 1;
          transform: translate(-50%, -50%) rotate(0deg) rotateY(0deg) rotateZ(0deg) translateZ(0px);
        }
        100% {
          opacity: 1;
          transform: translate(-50%, -50%) rotate(0deg) rotateY(0deg) rotateZ(0deg) translateZ(0px);
        }
      }
      #websitelogo .text:nth-child(84) {
        color: #5708fc;
        -webkit-clip-path: polygon(10% 200%, 20% 200%, 20% 250%);
                clip-path: polygon(10% 200%, 20% 200%, 20% 250%);
        transform-origin: 46% 211%;
        -webkit-animation: fly84 5000ms 3320ms cubic-bezier(0.36, 0.1, 0.16, 1) infinite alternate;
                animation: fly84 5000ms 3320ms cubic-bezier(0.36, 0.1, 0.16, 1) infinite alternate;
      }
      @-webkit-keyframes fly84 {
        0% {
          opacity: 0;
          transform: translate(-50%, -50%) rotateX(355deg) rotateY(111deg) rotateZ(-199deg) translateZ(-1187px);
        }
        10% {
          opacity: 0;
          transform: translate(-50%, -50%) rotateX(355deg) rotateY(111deg) rotateZ(-199deg) translateZ(-1187px);
        }
        90% {
          opacity: 1;
          transform: translate(-50%, -50%) rotate(0deg) rotateY(0deg) rotateZ(0deg) translateZ(0px);
        }
        100% {
          opacity: 1;
          transform: translate(-50%, -50%) rotate(0deg) rotateY(0deg) rotateZ(0deg) translateZ(0px);
        }
      }
      @keyframes fly84 {
        0% {
          opacity: 0;
          transform: translate(-50%, -50%) rotateX(355deg) rotateY(111deg) rotateZ(-199deg) translateZ(-1187px);
        }
        10% {
          opacity: 0;
          transform: translate(-50%, -50%) rotateX(355deg) rotateY(111deg) rotateZ(-199deg) translateZ(-1187px);
        }
        90% {
          opacity: 1;
          transform: translate(-50%, -50%) rotate(0deg) rotateY(0deg) rotateZ(0deg) translateZ(0px);
        }
        100% {
          opacity: 1;
          transform: translate(-50%, -50%) rotate(0deg) rotateY(0deg) rotateZ(0deg) translateZ(0px);
        }
      }
      #websitelogo .text:nth-child(85) {
        color: #5508ff;
        -webkit-clip-path: polygon(20% 200%, 20% 250%, 30% 250%);
                clip-path: polygon(20% 200%, 20% 250%, 30% 250%);
        transform-origin: 4% 153%;
        -webkit-animation: fly85 5000ms 3360ms cubic-bezier(0.36, 0.1, 0.16, 1) infinite alternate;
                animation: fly85 5000ms 3360ms cubic-bezier(0.36, 0.1, 0.16, 1) infinite alternate;
      }
      @-webkit-keyframes fly85 {
        0% {
          opacity: 0;
          transform: translate(-50%, -50%) rotateX(-310deg) rotateY(340deg) rotateZ(60deg) translateZ(-1584px);
        }
        10% {
          opacity: 0;
          transform: translate(-50%, -50%) rotateX(-310deg) rotateY(340deg) rotateZ(60deg) translateZ(-1584px);
        }
        90% {
          opacity: 1;
          transform: translate(-50%, -50%) rotate(0deg) rotateY(0deg) rotateZ(0deg) translateZ(0px);
        }
        100% {
          opacity: 1;
          transform: translate(-50%, -50%) rotate(0deg) rotateY(0deg) rotateZ(0deg) translateZ(0px);
        }
      }
      @keyframes fly85 {
        0% {
          opacity: 0;
          transform: translate(-50%, -50%) rotateX(-310deg) rotateY(340deg) rotateZ(60deg) translateZ(-1584px);
        }
        10% {
          opacity: 0;
          transform: translate(-50%, -50%) rotateX(-310deg) rotateY(340deg) rotateZ(60deg) translateZ(-1584px);
        }
        90% {
          opacity: 1;
          transform: translate(-50%, -50%) rotate(0deg) rotateY(0deg) rotateZ(0deg) translateZ(0px);
        }
        100% {
          opacity: 1;
          transform: translate(-50%, -50%) rotate(0deg) rotateY(0deg) rotateZ(0deg) translateZ(0px);
        }
      }
      #websitelogo .text:nth-child(86) {
        color: #5307ff;
        -webkit-clip-path: polygon(20% 200%, 30% 200%, 30% 250%);
                clip-path: polygon(20% 200%, 30% 200%, 30% 250%);
        transform-origin: -15% 228%;
        -webkit-animation: fly86 5000ms 3400ms cubic-bezier(0.36, 0.1, 0.16, 1) infinite alternate;
                animation: fly86 5000ms 3400ms cubic-bezier(0.36, 0.1, 0.16, 1) infinite alternate;
      }
      @-webkit-keyframes fly86 {
        0% {
          opacity: 0;
          transform: translate(-50%, -50%) rotateX(470deg) rotateY(-306deg) rotateZ(-304deg) translateZ(-802px);
        }
        10% {
          opacity: 0;
          transform: translate(-50%, -50%) rotateX(470deg) rotateY(-306deg) rotateZ(-304deg) translateZ(-802px);
        }
        90% {
          opacity: 1;
          transform: translate(-50%, -50%) rotate(0deg) rotateY(0deg) rotateZ(0deg) translateZ(0px);
        }
        100% {
          opacity: 1;
          transform: translate(-50%, -50%) rotate(0deg) rotateY(0deg) rotateZ(0deg) translateZ(0px);
        }
      }
      @keyframes fly86 {
        0% {
          opacity: 0;
          transform: translate(-50%, -50%) rotateX(470deg) rotateY(-306deg) rotateZ(-304deg) translateZ(-802px);
        }
        10% {
          opacity: 0;
          transform: translate(-50%, -50%) rotateX(470deg) rotateY(-306deg) rotateZ(-304deg) translateZ(-802px);
        }
        90% {
          opacity: 1;
          transform: translate(-50%, -50%) rotate(0deg) rotateY(0deg) rotateZ(0deg) translateZ(0px);
        }
        100% {
          opacity: 1;
          transform: translate(-50%, -50%) rotate(0deg) rotateY(0deg) rotateZ(0deg) translateZ(0px);
        }
      }
      #websitelogo .text:nth-child(87) {
        color: #5107ff;
        -webkit-clip-path: polygon(30% 200%, 30% 250%, 40% 250%);
                clip-path: polygon(30% 200%, 30% 250%, 40% 250%);
        transform-origin: 48% 223%;
        -webkit-animation: fly87 5000ms 3440ms cubic-bezier(0.36, 0.1, 0.16, 1) infinite alternate;
                animation: fly87 5000ms 3440ms cubic-bezier(0.36, 0.1, 0.16, 1) infinite alternate;
      }
      @-webkit-keyframes fly87 {
        0% {
          opacity: 0;
          transform: translate(-50%, -50%) rotateX(-116deg) rotateY(-215deg) rotateZ(-296deg) translateZ(-800px);
        }
        10% {
          opacity: 0;
          transform: translate(-50%, -50%) rotateX(-116deg) rotateY(-215deg) rotateZ(-296deg) translateZ(-800px);
        }
        90% {
          opacity: 1;
          transform: translate(-50%, -50%) rotate(0deg) rotateY(0deg) rotateZ(0deg) translateZ(0px);
        }
        100% {
          opacity: 1;
          transform: translate(-50%, -50%) rotate(0deg) rotateY(0deg) rotateZ(0deg) translateZ(0px);
        }
      }
      @keyframes fly87 {
        0% {
          opacity: 0;
          transform: translate(-50%, -50%) rotateX(-116deg) rotateY(-215deg) rotateZ(-296deg) translateZ(-800px);
        }
        10% {
          opacity: 0;
          transform: translate(-50%, -50%) rotateX(-116deg) rotateY(-215deg) rotateZ(-296deg) translateZ(-800px);
        }
        90% {
          opacity: 1;
          transform: translate(-50%, -50%) rotate(0deg) rotateY(0deg) rotateZ(0deg) translateZ(0px);
        }
        100% {
          opacity: 1;
          transform: translate(-50%, -50%) rotate(0deg) rotateY(0deg) rotateZ(0deg) translateZ(0px);
        }
      }
      #websitelogo .text:nth-child(88) {
        color: #4f06ff;
        -webkit-clip-path: polygon(30% 200%, 40% 200%, 40% 250%);
                clip-path: polygon(30% 200%, 40% 200%, 40% 250%);
        transform-origin: 29% 217%;
        -webkit-animation: fly88 5000ms 3480ms cubic-bezier(0.36, 0.1, 0.16, 1) infinite alternate;
                animation: fly88 5000ms 3480ms cubic-bezier(0.36, 0.1, 0.16, 1) infinite alternate;
      }
      @-webkit-keyframes fly88 {
        0% {
          opacity: 0;
          transform: translate(-50%, -50%) rotateX(456deg) rotateY(-358deg) rotateZ(339deg) translateZ(-606px);
        }
        10% {
          opacity: 0;
          transform: translate(-50%, -50%) rotateX(456deg) rotateY(-358deg) rotateZ(339deg) translateZ(-606px);
        }
        90% {
          opacity: 1;
          transform: translate(-50%, -50%) rotate(0deg) rotateY(0deg) rotateZ(0deg) translateZ(0px);
        }
        100% {
          opacity: 1;
          transform: translate(-50%, -50%) rotate(0deg) rotateY(0deg) rotateZ(0deg) translateZ(0px);
        }
      }
      @keyframes fly88 {
        0% {
          opacity: 0;
          transform: translate(-50%, -50%) rotateX(456deg) rotateY(-358deg) rotateZ(339deg) translateZ(-606px);
        }
        10% {
          opacity: 0;
          transform: translate(-50%, -50%) rotateX(456deg) rotateY(-358deg) rotateZ(339deg) translateZ(-606px);
        }
        90% {
          opacity: 1;
          transform: translate(-50%, -50%) rotate(0deg) rotateY(0deg) rotateZ(0deg) translateZ(0px);
        }
        100% {
          opacity: 1;
          transform: translate(-50%, -50%) rotate(0deg) rotateY(0deg) rotateZ(0deg) translateZ(0px);
        }
      }
      #websitelogo .text:nth-child(89) {
        color: #4d06ff;
        -webkit-clip-path: polygon(40% 200%, 40% 250%, 50% 250%);
                clip-path: polygon(40% 200%, 40% 250%, 50% 250%);
        transform-origin: -1% 183%;
        -webkit-animation: fly89 5000ms 3520ms cubic-bezier(0.36, 0.1, 0.16, 1) infinite alternate;
                animation: fly89 5000ms 3520ms cubic-bezier(0.36, 0.1, 0.16, 1) infinite alternate;
      }
      @-webkit-keyframes fly89 {
        0% {
          opacity: 0;
          transform: translate(-50%, -50%) rotateX(-387deg) rotateY(464deg) rotateZ(-77deg) translateZ(-1165px);
        }
        10% {
          opacity: 0;
          transform: translate(-50%, -50%) rotateX(-387deg) rotateY(464deg) rotateZ(-77deg) translateZ(-1165px);
        }
        90% {
          opacity: 1;
          transform: translate(-50%, -50%) rotate(0deg) rotateY(0deg) rotateZ(0deg) translateZ(0px);
        }
        100% {
          opacity: 1;
          transform: translate(-50%, -50%) rotate(0deg) rotateY(0deg) rotateZ(0deg) translateZ(0px);
        }
      }
      @keyframes fly89 {
        0% {
          opacity: 0;
          transform: translate(-50%, -50%) rotateX(-387deg) rotateY(464deg) rotateZ(-77deg) translateZ(-1165px);
        }
        10% {
          opacity: 0;
          transform: translate(-50%, -50%) rotateX(-387deg) rotateY(464deg) rotateZ(-77deg) translateZ(-1165px);
        }
        90% {
          opacity: 1;
          transform: translate(-50%, -50%) rotate(0deg) rotateY(0deg) rotateZ(0deg) translateZ(0px);
        }
        100% {
          opacity: 1;
          transform: translate(-50%, -50%) rotate(0deg) rotateY(0deg) rotateZ(0deg) translateZ(0px);
        }
      }
      #websitelogo .text:nth-child(90) {
        color: #4b05ff;
        -webkit-clip-path: polygon(40% 200%, 50% 200%, 50% 250%);
                clip-path: polygon(40% 200%, 50% 200%, 50% 250%);
        transform-origin: 36% 197%;
        -webkit-animation: fly90 5000ms 3560ms cubic-bezier(0.36, 0.1, 0.16, 1) infinite alternate;
                animation: fly90 5000ms 3560ms cubic-bezier(0.36, 0.1, 0.16, 1) infinite alternate;
      }
      @-webkit-keyframes fly90 {
        0% {
          opacity: 0;
          transform: translate(-50%, -50%) rotateX(-434deg) rotateY(248deg) rotateZ(-288deg) translateZ(-1244px);
        }
        10% {
          opacity: 0;
          transform: translate(-50%, -50%) rotateX(-434deg) rotateY(248deg) rotateZ(-288deg) translateZ(-1244px);
        }
        90% {
          opacity: 1;
          transform: translate(-50%, -50%) rotate(0deg) rotateY(0deg) rotateZ(0deg) translateZ(0px);
        }
        100% {
          opacity: 1;
          transform: translate(-50%, -50%) rotate(0deg) rotateY(0deg) rotateZ(0deg) translateZ(0px);
        }
      }
      @keyframes fly90 {
        0% {
          opacity: 0;
          transform: translate(-50%, -50%) rotateX(-434deg) rotateY(248deg) rotateZ(-288deg) translateZ(-1244px);
        }
        10% {
          opacity: 0;
          transform: translate(-50%, -50%) rotateX(-434deg) rotateY(248deg) rotateZ(-288deg) translateZ(-1244px);
        }
        90% {
          opacity: 1;
          transform: translate(-50%, -50%) rotate(0deg) rotateY(0deg) rotateZ(0deg) translateZ(0px);
        }
        100% {
          opacity: 1;
          transform: translate(-50%, -50%) rotate(0deg) rotateY(0deg) rotateZ(0deg) translateZ(0px);
        }
      }
      #websitelogo .text:nth-child(91) {
        color: #4905ff;
        -webkit-clip-path: polygon(50% 200%, 50% 250%, 60% 250%);
                clip-path: polygon(50% 200%, 50% 250%, 60% 250%);
        transform-origin: 80% 212%;
        -webkit-animation: fly91 5000ms 3600ms cubic-bezier(0.36, 0.1, 0.16, 1) infinite alternate;
                animation: fly91 5000ms 3600ms cubic-bezier(0.36, 0.1, 0.16, 1) infinite alternate;
      }
      @-webkit-keyframes fly91 {
        0% {
          opacity: 0;
          transform: translate(-50%, -50%) rotateX(-193deg) rotateY(490deg) rotateZ(104deg) translateZ(-2300px);
        }
        10% {
          opacity: 0;
          transform: translate(-50%, -50%) rotateX(-193deg) rotateY(490deg) rotateZ(104deg) translateZ(-2300px);
        }
        90% {
          opacity: 1;
          transform: translate(-50%, -50%) rotate(0deg) rotateY(0deg) rotateZ(0deg) translateZ(0px);
        }
        100% {
          opacity: 1;
          transform: translate(-50%, -50%) rotate(0deg) rotateY(0deg) rotateZ(0deg) translateZ(0px);
        }
      }
      @keyframes fly91 {
        0% {
          opacity: 0;
          transform: translate(-50%, -50%) rotateX(-193deg) rotateY(490deg) rotateZ(104deg) translateZ(-2300px);
        }
        10% {
          opacity: 0;
          transform: translate(-50%, -50%) rotateX(-193deg) rotateY(490deg) rotateZ(104deg) translateZ(-2300px);
        }
        90% {
          opacity: 1;
          transform: translate(-50%, -50%) rotate(0deg) rotateY(0deg) rotateZ(0deg) translateZ(0px);
        }
        100% {
          opacity: 1;
          transform: translate(-50%, -50%) rotate(0deg) rotateY(0deg) rotateZ(0deg) translateZ(0px);
        }
      }
      #websitelogo .text:nth-child(92) {
        color: #4704ff;
        -webkit-clip-path: polygon(50% 200%, 60% 200%, 60% 250%);
                clip-path: polygon(50% 200%, 60% 200%, 60% 250%);
        transform-origin: 14% 242%;
        -webkit-animation: fly92 5000ms 3640ms cubic-bezier(0.36, 0.1, 0.16, 1) infinite alternate;
                animation: fly92 5000ms 3640ms cubic-bezier(0.36, 0.1, 0.16, 1) infinite alternate;
      }
      @-webkit-keyframes fly92 {
        0% {
          opacity: 0;
          transform: translate(-50%, -50%) rotateX(-65deg) rotateY(371deg) rotateZ(330deg) translateZ(-502px);
        }
        10% {
          opacity: 0;
          transform: translate(-50%, -50%) rotateX(-65deg) rotateY(371deg) rotateZ(330deg) translateZ(-502px);
        }
        90% {
          opacity: 1;
          transform: translate(-50%, -50%) rotate(0deg) rotateY(0deg) rotateZ(0deg) translateZ(0px);
        }
        100% {
          opacity: 1;
          transform: translate(-50%, -50%) rotate(0deg) rotateY(0deg) rotateZ(0deg) translateZ(0px);
        }
      }
      @keyframes fly92 {
        0% {
          opacity: 0;
          transform: translate(-50%, -50%) rotateX(-65deg) rotateY(371deg) rotateZ(330deg) translateZ(-502px);
        }
        10% {
          opacity: 0;
          transform: translate(-50%, -50%) rotateX(-65deg) rotateY(371deg) rotateZ(330deg) translateZ(-502px);
        }
        90% {
          opacity: 1;
          transform: translate(-50%, -50%) rotate(0deg) rotateY(0deg) rotateZ(0deg) translateZ(0px);
        }
        100% {
          opacity: 1;
          transform: translate(-50%, -50%) rotate(0deg) rotateY(0deg) rotateZ(0deg) translateZ(0px);
        }
      }
      #websitelogo .text:nth-child(93) {
        color: #4504ff;
        -webkit-clip-path: polygon(60% 200%, 60% 250%, 70% 250%);
                clip-path: polygon(60% 200%, 60% 250%, 70% 250%);
        transform-origin: 106% 159%;
        -webkit-animation: fly93 5000ms 3680ms cubic-bezier(0.36, 0.1, 0.16, 1) infinite alternate;
                animation: fly93 5000ms 3680ms cubic-bezier(0.36, 0.1, 0.16, 1) infinite alternate;
      }
      @-webkit-keyframes fly93 {
        0% {
          opacity: 0;
          transform: translate(-50%, -50%) rotateX(-339deg) rotateY(352deg) rotateZ(-7deg) translateZ(-595px);
        }
        10% {
          opacity: 0;
          transform: translate(-50%, -50%) rotateX(-339deg) rotateY(352deg) rotateZ(-7deg) translateZ(-595px);
        }
        90% {
          opacity: 1;
          transform: translate(-50%, -50%) rotate(0deg) rotateY(0deg) rotateZ(0deg) translateZ(0px);
        }
        100% {
          opacity: 1;
          transform: translate(-50%, -50%) rotate(0deg) rotateY(0deg) rotateZ(0deg) translateZ(0px);
        }
      }
      @keyframes fly93 {
        0% {
          opacity: 0;
          transform: translate(-50%, -50%) rotateX(-339deg) rotateY(352deg) rotateZ(-7deg) translateZ(-595px);
        }
        10% {
          opacity: 0;
          transform: translate(-50%, -50%) rotateX(-339deg) rotateY(352deg) rotateZ(-7deg) translateZ(-595px);
        }
        90% {
          opacity: 1;
          transform: translate(-50%, -50%) rotate(0deg) rotateY(0deg) rotateZ(0deg) translateZ(0px);
        }
        100% {
          opacity: 1;
          transform: translate(-50%, -50%) rotate(0deg) rotateY(0deg) rotateZ(0deg) translateZ(0px);
        }
      }
      #websitelogo .text:nth-child(94) {
        color: #4303ff;
        -webkit-clip-path: polygon(60% 200%, 70% 200%, 70% 250%);
                clip-path: polygon(60% 200%, 70% 200%, 70% 250%);
        transform-origin: 48% 202%;
        -webkit-animation: fly94 5000ms 3720ms cubic-bezier(0.36, 0.1, 0.16, 1) infinite alternate;
                animation: fly94 5000ms 3720ms cubic-bezier(0.36, 0.1, 0.16, 1) infinite alternate;
      }
      @-webkit-keyframes fly94 {
        0% {
          opacity: 0;
          transform: translate(-50%, -50%) rotateX(144deg) rotateY(148deg) rotateZ(168deg) translateZ(209px);
        }
        10% {
          opacity: 0;
          transform: translate(-50%, -50%) rotateX(144deg) rotateY(148deg) rotateZ(168deg) translateZ(209px);
        }
        90% {
          opacity: 1;
          transform: translate(-50%, -50%) rotate(0deg) rotateY(0deg) rotateZ(0deg) translateZ(0px);
        }
        100% {
          opacity: 1;
          transform: translate(-50%, -50%) rotate(0deg) rotateY(0deg) rotateZ(0deg) translateZ(0px);
        }
      }
      @keyframes fly94 {
        0% {
          opacity: 0;
          transform: translate(-50%, -50%) rotateX(144deg) rotateY(148deg) rotateZ(168deg) translateZ(209px);
        }
        10% {
          opacity: 0;
          transform: translate(-50%, -50%) rotateX(144deg) rotateY(148deg) rotateZ(168deg) translateZ(209px);
        }
        90% {
          opacity: 1;
          transform: translate(-50%, -50%) rotate(0deg) rotateY(0deg) rotateZ(0deg) translateZ(0px);
        }
        100% {
          opacity: 1;
          transform: translate(-50%, -50%) rotate(0deg) rotateY(0deg) rotateZ(0deg) translateZ(0px);
        }
      }
      #websitelogo .text:nth-child(95) {
        color: #4103ff;
        -webkit-clip-path: polygon(70% 200%, 70% 250%, 80% 250%);
                clip-path: polygon(70% 200%, 70% 250%, 80% 250%);
        transform-origin: 88% 228%;
        -webkit-animation: fly95 5000ms 3760ms cubic-bezier(0.36, 0.1, 0.16, 1) infinite alternate;
                animation: fly95 5000ms 3760ms cubic-bezier(0.36, 0.1, 0.16, 1) infinite alternate;
      }
      @-webkit-keyframes fly95 {
        0% {
          opacity: 0;
          transform: translate(-50%, -50%) rotateX(-40deg) rotateY(309deg) rotateZ(162deg) translateZ(-1629px);
        }
        10% {
          opacity: 0;
          transform: translate(-50%, -50%) rotateX(-40deg) rotateY(309deg) rotateZ(162deg) translateZ(-1629px);
        }
        90% {
          opacity: 1;
          transform: translate(-50%, -50%) rotate(0deg) rotateY(0deg) rotateZ(0deg) translateZ(0px);
        }
        100% {
          opacity: 1;
          transform: translate(-50%, -50%) rotate(0deg) rotateY(0deg) rotateZ(0deg) translateZ(0px);
        }
      }
      @keyframes fly95 {
        0% {
          opacity: 0;
          transform: translate(-50%, -50%) rotateX(-40deg) rotateY(309deg) rotateZ(162deg) translateZ(-1629px);
        }
        10% {
          opacity: 0;
          transform: translate(-50%, -50%) rotateX(-40deg) rotateY(309deg) rotateZ(162deg) translateZ(-1629px);
        }
        90% {
          opacity: 1;
          transform: translate(-50%, -50%) rotate(0deg) rotateY(0deg) rotateZ(0deg) translateZ(0px);
        }
        100% {
          opacity: 1;
          transform: translate(-50%, -50%) rotate(0deg) rotateY(0deg) rotateZ(0deg) translateZ(0px);
        }
      }
      #websitelogo .text:nth-child(96) {
        color: #3f02ff;
        -webkit-clip-path: polygon(70% 200%, 80% 200%, 80% 250%);
                clip-path: polygon(70% 200%, 80% 200%, 80% 250%);
        transform-origin: 32% 217%;
        -webkit-animation: fly96 5000ms 3800ms cubic-bezier(0.36, 0.1, 0.16, 1) infinite alternate;
                animation: fly96 5000ms 3800ms cubic-bezier(0.36, 0.1, 0.16, 1) infinite alternate;
      }
      @-webkit-keyframes fly96 {
        0% {
          opacity: 0;
          transform: translate(-50%, -50%) rotateX(214deg) rotateY(-331deg) rotateZ(-364deg) translateZ(-2230px);
        }
        10% {
          opacity: 0;
          transform: translate(-50%, -50%) rotateX(214deg) rotateY(-331deg) rotateZ(-364deg) translateZ(-2230px);
        }
        90% {
          opacity: 1;
          transform: translate(-50%, -50%) rotate(0deg) rotateY(0deg) rotateZ(0deg) translateZ(0px);
        }
        100% {
          opacity: 1;
          transform: translate(-50%, -50%) rotate(0deg) rotateY(0deg) rotateZ(0deg) translateZ(0px);
        }
      }
      @keyframes fly96 {
        0% {
          opacity: 0;
          transform: translate(-50%, -50%) rotateX(214deg) rotateY(-331deg) rotateZ(-364deg) translateZ(-2230px);
        }
        10% {
          opacity: 0;
          transform: translate(-50%, -50%) rotateX(214deg) rotateY(-331deg) rotateZ(-364deg) translateZ(-2230px);
        }
        90% {
          opacity: 1;
          transform: translate(-50%, -50%) rotate(0deg) rotateY(0deg) rotateZ(0deg) translateZ(0px);
        }
        100% {
          opacity: 1;
          transform: translate(-50%, -50%) rotate(0deg) rotateY(0deg) rotateZ(0deg) translateZ(0px);
        }
      }
      #websitelogo .text:nth-child(97) {
        color: #3d02ff;
        -webkit-clip-path: polygon(80% 200%, 80% 250%, 90% 250%);
                clip-path: polygon(80% 200%, 80% 250%, 90% 250%);
        transform-origin: 80% 180%;
        -webkit-animation: fly97 5000ms 3840ms cubic-bezier(0.36, 0.1, 0.16, 1) infinite alternate;
                animation: fly97 5000ms 3840ms cubic-bezier(0.36, 0.1, 0.16, 1) infinite alternate;
      }
      @-webkit-keyframes fly97 {
        0% {
          opacity: 0;
          transform: translate(-50%, -50%) rotateX(-274deg) rotateY(432deg) rotateZ(479deg) translateZ(303px);
        }
        10% {
          opacity: 0;
          transform: translate(-50%, -50%) rotateX(-274deg) rotateY(432deg) rotateZ(479deg) translateZ(303px);
        }
        90% {
          opacity: 1;
          transform: translate(-50%, -50%) rotate(0deg) rotateY(0deg) rotateZ(0deg) translateZ(0px);
        }
        100% {
          opacity: 1;
          transform: translate(-50%, -50%) rotate(0deg) rotateY(0deg) rotateZ(0deg) translateZ(0px);
        }
      }
      @keyframes fly97 {
        0% {
          opacity: 0;
          transform: translate(-50%, -50%) rotateX(-274deg) rotateY(432deg) rotateZ(479deg) translateZ(303px);
        }
        10% {
          opacity: 0;
          transform: translate(-50%, -50%) rotateX(-274deg) rotateY(432deg) rotateZ(479deg) translateZ(303px);
        }
        90% {
          opacity: 1;
          transform: translate(-50%, -50%) rotate(0deg) rotateY(0deg) rotateZ(0deg) translateZ(0px);
        }
        100% {
          opacity: 1;
          transform: translate(-50%, -50%) rotate(0deg) rotateY(0deg) rotateZ(0deg) translateZ(0px);
        }
      }
      #websitelogo .text:nth-child(98) {
        color: #3b01ff;
        -webkit-clip-path: polygon(80% 200%, 90% 200%, 90% 250%);
                clip-path: polygon(80% 200%, 90% 200%, 90% 250%);
        transform-origin: 82% 167%;
        -webkit-animation: fly98 5000ms 3880ms cubic-bezier(0.36, 0.1, 0.16, 1) infinite alternate;
                animation: fly98 5000ms 3880ms cubic-bezier(0.36, 0.1, 0.16, 1) infinite alternate;
      }
      @-webkit-keyframes fly98 {
        0% {
          opacity: 0;
          transform: translate(-50%, -50%) rotateX(438deg) rotateY(428deg) rotateZ(160deg) translateZ(-1697px);
        }
        10% {
          opacity: 0;
          transform: translate(-50%, -50%) rotateX(438deg) rotateY(428deg) rotateZ(160deg) translateZ(-1697px);
        }
        90% {
          opacity: 1;
          transform: translate(-50%, -50%) rotate(0deg) rotateY(0deg) rotateZ(0deg) translateZ(0px);
        }
        100% {
          opacity: 1;
          transform: translate(-50%, -50%) rotate(0deg) rotateY(0deg) rotateZ(0deg) translateZ(0px);
        }
      }
      @keyframes fly98 {
        0% {
          opacity: 0;
          transform: translate(-50%, -50%) rotateX(438deg) rotateY(428deg) rotateZ(160deg) translateZ(-1697px);
        }
        10% {
          opacity: 0;
          transform: translate(-50%, -50%) rotateX(438deg) rotateY(428deg) rotateZ(160deg) translateZ(-1697px);
        }
        90% {
          opacity: 1;
          transform: translate(-50%, -50%) rotate(0deg) rotateY(0deg) rotateZ(0deg) translateZ(0px);
        }
        100% {
          opacity: 1;
          transform: translate(-50%, -50%) rotate(0deg) rotateY(0deg) rotateZ(0deg) translateZ(0px);
        }
      }
      #websitelogo .text:nth-child(99) {
        color: #3901ff;
        -webkit-clip-path: polygon(90% 200%, 90% 250%, 100% 250%);
                clip-path: polygon(90% 200%, 90% 250%, 100% 250%);
        transform-origin: 86% 186%;
        -webkit-animation: fly99 5000ms 3920ms cubic-bezier(0.36, 0.1, 0.16, 1) infinite alternate;
                animation: fly99 5000ms 3920ms cubic-bezier(0.36, 0.1, 0.16, 1) infinite alternate;
      }
      @-webkit-keyframes fly99 {
        0% {
          opacity: 0;
          transform: translate(-50%, -50%) rotateX(-109deg) rotateY(-307deg) rotateZ(-232deg) translateZ(-1675px);
        }
        10% {
          opacity: 0;
          transform: translate(-50%, -50%) rotateX(-109deg) rotateY(-307deg) rotateZ(-232deg) translateZ(-1675px);
        }
        90% {
          opacity: 1;
          transform: translate(-50%, -50%) rotate(0deg) rotateY(0deg) rotateZ(0deg) translateZ(0px);
        }
        100% {
          opacity: 1;
          transform: translate(-50%, -50%) rotate(0deg) rotateY(0deg) rotateZ(0deg) translateZ(0px);
        }
      }
      @keyframes fly99 {
        0% {
          opacity: 0;
          transform: translate(-50%, -50%) rotateX(-109deg) rotateY(-307deg) rotateZ(-232deg) translateZ(-1675px);
        }
        10% {
          opacity: 0;
          transform: translate(-50%, -50%) rotateX(-109deg) rotateY(-307deg) rotateZ(-232deg) translateZ(-1675px);
        }
        90% {
          opacity: 1;
          transform: translate(-50%, -50%) rotate(0deg) rotateY(0deg) rotateZ(0deg) translateZ(0px);
        }
        100% {
          opacity: 1;
          transform: translate(-50%, -50%) rotate(0deg) rotateY(0deg) rotateZ(0deg) translateZ(0px);
        }
      }
      #websitelogo .text:nth-child(100) {
        color: #3700ff;
        -webkit-clip-path: polygon(90% 200%, 100% 200%, 100% 250%);
                clip-path: polygon(90% 200%, 100% 200%, 100% 250%);
        transform-origin: 98% 166%;
        -webkit-animation: fly100 5000ms 3960ms cubic-bezier(0.36, 0.1, 0.16, 1) infinite alternate;
                animation: fly100 5000ms 3960ms cubic-bezier(0.36, 0.1, 0.16, 1) infinite alternate;
      }
      @-webkit-keyframes fly100 {
        0% {
          opacity: 0;
          transform: translate(-50%, -50%) rotateX(-38deg) rotateY(-306deg) rotateZ(222deg) translateZ(-1472px);
        }
        10% {
          opacity: 0;
          transform: translate(-50%, -50%) rotateX(-38deg) rotateY(-306deg) rotateZ(222deg) translateZ(-1472px);
        }
        90% {
          opacity: 1;
          transform: translate(-50%, -50%) rotate(0deg) rotateY(0deg) rotateZ(0deg) translateZ(0px);
        }
        100% {
          opacity: 1;
          transform: translate(-50%, -50%) rotate(0deg) rotateY(0deg) rotateZ(0deg) translateZ(0px);
        }
      }
      @keyframes fly100 {
        0% {
          opacity: 0;
          transform: translate(-50%, -50%) rotateX(-38deg) rotateY(-306deg) rotateZ(222deg) translateZ(-1472px);
        }
        10% {
          opacity: 0;
          transform: translate(-50%, -50%) rotateX(-38deg) rotateY(-306deg) rotateZ(222deg) translateZ(-1472px);
        }
        90% {
          opacity: 1;
          transform: translate(-50%, -50%) rotate(0deg) rotateY(0deg) rotateZ(0deg) translateZ(0px);
        }
        100% {
          opacity: 1;
          transform: translate(-50%, -50%) rotate(0deg) rotateY(0deg) rotateZ(0deg) translateZ(0px);
        }
      }
      #websitelogo .text:nth-child(101) {
        color: #3500ff;
        -webkit-clip-path: polygon(0% 250%, 0% 300%, 10% 300%);
                clip-path: polygon(0% 250%, 0% 300%, 10% 300%);
        transform-origin: -24% 277%;
        -webkit-animation: fly101 5000ms 4000ms cubic-bezier(0.36, 0.1, 0.16, 1) infinite alternate;
                animation: fly101 5000ms 4000ms cubic-bezier(0.36, 0.1, 0.16, 1) infinite alternate;
      }
      @-webkit-keyframes fly101 {
        0% {
          opacity: 0;
          transform: translate(-50%, -50%) rotateX(9deg) rotateY(68deg) rotateZ(442deg) translateZ(-1215px);
        }
        10% {
          opacity: 0;
          transform: translate(-50%, -50%) rotateX(9deg) rotateY(68deg) rotateZ(442deg) translateZ(-1215px);
        }
        90% {
          opacity: 1;
          transform: translate(-50%, -50%) rotate(0deg) rotateY(0deg) rotateZ(0deg) translateZ(0px);
        }
        100% {
          opacity: 1;
          transform: translate(-50%, -50%) rotate(0deg) rotateY(0deg) rotateZ(0deg) translateZ(0px);
        }
      }
      @keyframes fly101 {
        0% {
          opacity: 0;
          transform: translate(-50%, -50%) rotateX(9deg) rotateY(68deg) rotateZ(442deg) translateZ(-1215px);
        }
        10% {
          opacity: 0;
          transform: translate(-50%, -50%) rotateX(9deg) rotateY(68deg) rotateZ(442deg) translateZ(-1215px);
        }
        90% {
          opacity: 1;
          transform: translate(-50%, -50%) rotate(0deg) rotateY(0deg) rotateZ(0deg) translateZ(0px);
        }
        100% {
          opacity: 1;
          transform: translate(-50%, -50%) rotate(0deg) rotateY(0deg) rotateZ(0deg) translateZ(0px);
        }
      }
      /*for mobile */
      @media (min-width: 50px) and (max-width: 480px) {
    
      
      }
      
      /*For tablets */
      @media (min-width: 481px) and (max-width: 1024px) {
       
      
      }
      
      /* For desktop devices */
      @media (min-width: 1025px) {
      }
      
      @media screen and (-webkit-min-device-pixel-ratio: 2) {
       
      
      }
      
      @media screen and (min-width: 426px) {
      
      
      
      }
      
      @media screen and (max-width: 426px) {
     
      
      
       }
      @media screen and (min-width: 769px) {
      
      
      }
      

      .hello {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        font-size: 5vw;
        -moz-animation-name: wave;
        -o-animation-name: wave;         
        -webkit-animation-name: wave;
                animation-name: wave;

        -moz-animation-iteration-count: infinite;
        -o-animation-iteration-count: infinite; 
        -webkit-animation-iteration-count: infinite;
                animation-iteration-count: infinite;

        -moz-animation-timing-function: ease-in-out;
        -o-animation-timing-function: ease-in-out;
        -webkit-animation-timing-function: ease-in-out;
                animation-timing-function: ease-in-out;
        font-family: "Righteous", cursive;
        -webkit-text-stroke-width: 3px;
        -webkit-text-stroke-color: black;
      }
      
      .hello:nth-of-type(1) {
        -moz-animation-duration: 2s;
        -o-animation-duration: 2s;
        -webkit-animation-duration: 2s;
                animation-duration: 2s;

        -moz-animation-delay: 0.55s;
        -o-animation-delay: 0.55s;
        -webkit-animation-delay: 0.55s;
                animation-delay: 0.55s;
      }
      
      .hello:nth-of-type(2) {
        -moz-animation-duration: 2s;
        -o-animation-duration: 2s;
        -webkit-animation-duration: 2s;
                animation-duration: 2s;
        -moz-animation-delay: 0.3s;
        -o-animation-delay: 0.3s;
        -webkit-animation-delay: 0.3s;
                animation-delay: 0.3s;
      }
      
      .hello:nth-of-type(3) {
        moz-animation-duration: 2s;
        -o-animation-duration: 2s;
        -webkit-animation-duration: 2s;
                animation-duration: 2s;
        -moz-animation-delay: 0.05s;
        -o-animation-delay: 0.05s;
        -webkit-animation-delay: 0.05s;
                animation-delay: 0.05s;
      }
      
      .hello:nth-of-type(4) {
        -moz-animation-duration: 2s;
        -o-animation-duration: 2s;
        -webkit-animation-duration: 2s;
                animation-duration: 2s;
        -moz-animation-delay: -0.2s;
        -o-animation-delay: -0.2s;
        -webkit-animation-delay: -0.2s;
                animation-delay: -0.2s;
      }
      
      @-webkit-keyframes wave {
        40%, 50% {
          transform: translate(-50%, -65%) scale(1.05);
        }
        0%, 90%, 100% {
          transform: translate(-50%, -45%) scale(0.95);
        }
      }
      
      @keyframes wave {
        40%, 50% {
          transform: translate(-50%, -65%) scale(1.05);
        }
        0%, 90%, 100% {
          transform: translate(-50%, -45%) scale(0.95);
        }
      }

      @-moz-keyframes wave {
        40%, 50% {
          transform: translate(-50%, -65%) scale(1.05);
        }
        0%, 90%, 100% {
          transform: translate(-50%, -45%) scale(0.95);
        }
      }

      @-o-keyframes wave {
        40%, 50% {
          transform: translate(-50%, -65%) scale(1.05);
        }
        0%, 90%, 100% {
          transform: translate(-50%, -45%) scale(0.95);
        }
      }

      body .content {
        display: flex;
        height: 100vh;
      }
      body .content h1 {
        justify-content: center;
        align-items: center;
   
      }
      
      .watername {
        position: absolute;
          top: 20%;
          left:10%;
        background-color:white;
        
        z-index: 8888;
        
      
      }
      

      .whiteflower {
        position: absolute;
        top: 40%;
        left:50%;
        
        z-index: 777;
      
      
      }

      h1 {
        text-shadow: 0 1px 0 #ccc,
                     0 2px 0 #c9c9c9,
                     0 3px 0 #bbb,
                     0 4px 0 #b9b9b9,
                     0 5px 0 #aaa,
                     0 6px 1px rgba(0,0,0,.1),
                     0 0 5px rgba(0,0,0,.1),
                     0 1px 3px rgba(0,0,0,.3),
                     0 3px 5px rgba(0,0,0,.2),
                     0 5px 10px rgba(0,0,0,.25),
                     0 10px 10px rgba(0,0,0,.2),
                     0 20px 20px rgba(0,0,0,.15);
               
        color: transparent
        text-transform: uppercase;;
        position: absolute;
        opacity: 0;
        margin-top: 3em;
        margin-left: -0.5em;
        animation: cinematicInOut infinite ease-in-out 5s;
      }
      
      h1:nth-child(2) {
        animation-delay: 8s;
      }
      
      h1:nth-child(3) {
        animation-delay: 11s;
      }
      h1:nth-child(4) {
        animation-delay: 14s;
      }
      h1:nth-child(5) {
        animation-delay: 17s;
      }
      h1:nth-child(6) {
        animation-delay: 20s;
      }
      h1:nth-child(7) {
        animation-delay: 23s;
      }
      h1:nth-child(8) {
        animation-delay: 26s;
      }
      h1:nth-child(9) {
        animation-delay: 29s;
      }
      h1:nth-child(10) {
        animation-delay: 32s;
      }
      @keyframes cinematicInOut {
        0% {
          text-shadow: 0 0 1em white;
          opacity: 0;
          margin-left: -0.5em;
        }
        25% {
          text-shadow: 0 0 0 white;
          opacity: 1;
          margin-left: 0;
        }
        75% {
          text-shadow: 0 0 0 white;
          opacity: 1;
          margin-left: 0;
        }
        100% {
          text-shadow: 0 0 1em white;
          opacity: 0;
          margin-left: 0;
        }
      }


      .hello {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        font-size: 5vw;
        -moz-animation-name: wave;
        -o-animation-name: wave;         
        -webkit-animation-name: wave;
                animation-name: wave;

        -moz-animation-iteration-count: infinite;
        -o-animation-iteration-count: infinite; 
        -webkit-animation-iteration-count: infinite;
                animation-iteration-count: infinite;

        -moz-animation-timing-function: ease-in-out;
        -o-animation-timing-function: ease-in-out;
        -webkit-animation-timing-function: ease-in-out;
                animation-timing-function: ease-in-out;
        font-family: "Righteous", cursive;
        -webkit-text-stroke-width: 3px;
        -webkit-text-stroke-color: black;
      }
      
      .hello:nth-of-type(1) {
        -moz-animation-duration: 2s;
        -o-animation-duration: 2s;
        -webkit-animation-duration: 2s;
                animation-duration: 2s;

        -moz-animation-delay: 0.55s;
        -o-animation-delay: 0.55s;
        -webkit-animation-delay: 0.55s;
                animation-delay: 0.55s;
      }
      
      .hello:nth-of-type(2) {
        -moz-animation-duration: 2s;
        -o-animation-duration: 2s;
        -webkit-animation-duration: 2s;
                animation-duration: 2s;
        -moz-animation-delay: 0.3s;
        -o-animation-delay: 0.3s;
        -webkit-animation-delay: 0.3s;
                animation-delay: 0.3s;
      }
      
      .hello:nth-of-type(3) {
        moz-animation-duration: 2s;
        -o-animation-duration: 2s;
        -webkit-animation-duration: 2s;
                animation-duration: 2s;
        -moz-animation-delay: 0.05s;
        -o-animation-delay: 0.05s;
        -webkit-animation-delay: 0.05s;
                animation-delay: 0.05s;
      }
      
      .hello:nth-of-type(4) {
        -moz-animation-duration: 2s;
        -o-animation-duration: 2s;
        -webkit-animation-duration: 2s;
                animation-duration: 2s;
        -moz-animation-delay: -0.2s;
        -o-animation-delay: -0.2s;
        -webkit-animation-delay: -0.2s;
                animation-delay: -0.2s;
      }

       
      @-webkit-keyframes wave {
        40%, 50% {
          transform: translate(-50%, -65%) scale(1.05);
        }
        0%, 90%, 100% {
          transform: translate(-50%, -45%) scale(0.95);
        }
      }
      
      @keyframes wave {
        40%, 50% {
          transform: translate(-50%, -65%) scale(1.05);
        }
        0%, 90%, 100% {
          transform: translate(-50%, -45%) scale(0.95);
        }
      }

      @-moz-keyframes wave {
        40%, 50% {
          transform: translate(-50%, -65%) scale(1.05);
        }
        0%, 90%, 100% {
          transform: translate(-50%, -45%) scale(0.95);
        }
      }

      @-o-keyframes wave {
        40%, 50% {
          transform: translate(-50%, -65%) scale(1.05);
        }
        0%, 90%, 100% {
          transform: translate(-50%, -45%) scale(0.95);
        }
      }

  

          `}</style>

<form onSubmit={this.loginClicked} >

                  <h1 className="watername">
                  <div  style={{zindex:"999",marginLeft:"5em" ,position: "absolute",bottom: "0",left: "0"}}>
                    <p style={{top:"1"}}> &nbsp;</p>
                        <p className="hollywoodtext" >
                            <span className="LoginMenutextbrand" >
                                <button style={{color:'red'}}  >
                                <div className='hello' style={{color: '#0091ea'}}>Explore</div>
                                    <div className='hello' style={{color: '#ff9100'}}>Explore</div>
                                    <div className='hello' style={{color: '#ff1744'}}>Explore</div>
                                    <span className="icon-right">
                                        </span>
                                        <span className="icon-right after">
                                        </span>
                                    </button>
                                </span> 
                            </p>

                  </div> 
                  
                  </h1>
              
              
                  
<Carousel variant="outline-danger" autoPlay interval="30000"  indicators={true} fade={true}>

                <Carousel.Item  >
              <section >
                 
                 <Navbar bg="bg-transparent" variant="light" expand="lg" id="welcome-3d">
                     
                 
                                      <Navbar.Brand href="#" style={{zindex:"1"}} >
                                      <div style={{marginLeft:"10vw",bottom:"5vw",zindex:"999"}}> 
                                                                          {/* <ul >
                                                                          <li >
                                                                      <a className='list-item' >
                                                                          <Nav.Link href="#" onClick={this.loginClicked.bind(this)} style={{color:'white', zindex: '99',top:'1'} }  className="LoginMenuLinksText LoginButton-availability">  
                                                                                  <i className='icon-th-large L'><span >Next</span></i>
                                                                          </Nav.Link>
                                                                      </a>
                                                                      </li>

                  
                                                                    
                                                                  </ul>   */}
                                                                  </div> 
                                    
                                     <div style={{display:"flex"}} >
                                     
                                      {/* <div id="circle"  >
                                                 <h1 style={{ padding: "1.5em"}}></h1>
                                             </div> */}
                                                 {/* <div style={{width:"20%",zindex:"1"}} id="walkinglioncircle">
                                                 
                                                  <h1 style={{ padding: "5em"}}></h1>
                                                 
                                                 </div> */}
                                         
                                                 {/* <div id="officescreen-rupa" style={{zindex:"1"}} >
                                                 <h1 style={{ padding: "4em"}}></h1>
                                                       
                                                  </div> */}
                                                 
     
                                         </div>   
                                      </Navbar.Brand>
                                      {/* <Navbar.Toggle aria-controls="basic-navbar-nav" /> */}
                                      {/* <Navbar.Collapse id="basic-navbar-nav"> */}
                                      <Nav className="mr-auto "  style={{zindex:"90"}}>                                      
                                        
{/*                                                        
                                      <div id="humming-bird"  >
                                                          <h1 style={{ padding: "3em"}}></h1>
                                                          </div>        */}
                                                 
                                              <Nav.Link href="#"> 
                                                 </Nav.Link>
                                                
                                         </Nav>                  
                                         <Nav className="mr-auto" > 
                                         {/* <img  style={{height: '100%',width: '100%'}} src={jpg6}  alt="First slide" />
        <img  style={{height: '100%',width: '100%'}} src={jpg12}  alt="First slide" />
        <img  style={{height: '100%',width: '100%'}} src={jpg6}  alt="First slide" />
        <img  style={{height: '100%',width: '100%'}} src={jpg12}  alt="First slide" />                   */}
          
          
      
    <div id="websitelogo" style={{zindex: "99"}}>
                                       
  <div className="text">NIRUPANAIDU.COM </div>
  <div className="text">NIRUPANAIDU.COM</div>
  <div className="text">NIRUPANAIDU.COM</div>
  <div className="text">NIRUPANAIDU.COM</div>
  <div className="text">NIRUPANAIDU.COM</div>
  <div className="text">NIRUPANAIDU.COM</div>
  <div className="text">NIRUPANAIDU.COM</div>
  <div className="text">NIRUPANAIDU.COM</div>
  <div className="text">NIRUPANAIDU.COM</div>
  <div className="text">NIRUPANAIDU.COM</div>
  <div className="text">NIRUPANAIDU.COM</div>
  <div className="text">NIRUPANAIDU.COM</div>
  <div className="text">NIRUPANAIDU.COM</div>
  <div className="text">NIRUPANAIDU.COM</div>
  <div className="text">NIRUPANAIDU.COM</div>
  <div className="text">NIRUPANAIDU.COM</div>
  <div className="text">NIRUPANAIDU.COM</div>
  <div className="text">NIRUPANAIDU.COM</div>
  <div className="text">NIRUPANAIDU.COM</div>
  <div className="text">NIRUPANAIDU.COM</div>
  <div className="text">NIRUPANAIDU.COM</div>
  <div className="text">NIRUPANAIDU.COM</div>
  <div className="text">NIRUPANAIDU.COM</div>
  <div className="text">NIRUPANAIDU.COM</div>
  <div className="text">NIRUPANAIDU.COM</div>
  <div className="text">NIRUPANAIDU.COM</div>
  <div className="text">NIRUPANAIDU.COM</div>
  <div className="text">NIRUPANAIDU.COM</div>
  <div className="text">NIRUPANAIDU.COM</div>
  <div className="text">NIRUPANAIDU.COM</div>
  <div className="text">NIRUPANAIDU.COM</div>
  <div className="text">NIRUPANAIDU.COM</div>
  <div className="text">NIRUPANAIDU.COM</div>
  <div className="text">NIRUPANAIDU.COM</div>
  <div className="text">NIRUPANAIDU.COM</div>
  <div className="text">NIRUPANAIDU.COM</div>
  <div className="text">NIRUPANAIDU.COM</div>
  <div className="text">NIRUPANAIDU.COM</div>
  <div className="text">NIRUPANAIDU.COM</div>
  <div className="text">NIRUPANAIDU.COM</div>
</div>

                                                          
                                                 <div >  
                                                 <div style={{marginLeft:"30vw"}}> 
                                                                          <ul >
                                                                          {/* <li >
                                                                      <a className='list-item' >
                                                                          <Nav.Link href="#" onClick={this.loginClicked.bind(this)} style={{color:'white', zindex: '99'} }  className="LoginMenuLinksText LoginButton-availability">  
                                                                                  <i className='icon-th-large L'><span >Next</span></i>
                                                                          </Nav.Link>
                                                                      </a>
                                                                      </li> */}

                  
                                                                    
                                                                  </ul>  
                                                                  </div>    
                                                          <div style={{height:"10%",zindex:"1"}} id="officescreen-row">
      
                                                          <label id="offsetbrand" className="LoginMenutextbrand  spinbrand">
                                                            <span style={{fontSize: "25px"}}> Nirupa Naidu <br/>Enterprises</span>
                                                        </label>  
                                                                       
                                                              </div>
                                                             
                                                                    
                                                      </div>
     
                                                       
     
                                                 
                                         </Nav>
                                                         
                                         
                                         
                                     {/* </Navbar.Collapse> */}
                                  
                 </Navbar>

                  
             </section>
             <Carousel.Caption>
             <span ><img  src={jpg15rm} style={{width:"100%"}} alt="First slide" /> </span>
              </Carousel.Caption>
               
           </Carousel.Item>
        
           </Carousel>
        <section>
 




        </section>

        <section>
    
        <div className="fixed-bottom">
        
        <SocialFollowUp/>
        </div>  
          
        </section >
         


         

    </form>  
        </>
        );
    }
}

