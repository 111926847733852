import React, { Component } from 'react'

export class AboutUs extends Component {
    render() {
        return (
            <React.Fragment>
                Page under construction .. revisit us .  Thanks for your patiency .
             
            <div>
                <p>&nbsp;</p>
                <p>&nbsp;</p>
                <p>&nbsp;</p>
                <p>&nbsp;</p>
                <p>&nbsp;</p>
                 <p>&nbsp;</p>
                <p>&nbsp;</p>
                <p>&nbsp;</p>
                 <p>&nbsp;</p>
                  <p>&nbsp;</p>
                   
            </div>
            </React.Fragment>
        )
    }
}

export default AboutUs
