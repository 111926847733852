import React, { Component } from "react";
import { Carousel } from 'react-bootstrap';
import {Navbar,Nav,NavDropdown,Form,FormControl,Modal,Button} from 'react-bootstrap';

import PropTypes from "prop-types";
import Axios from 'axios';
import auth from "../Auth.js";
import { useState } from 'react';


import AuthenticationService from "../service/AuthenticateService";
import AuthenticateService from "../service/AuthenticateService";
import LoginModal from '../login/LoginModal';
import useWindowDimensions from '../useWindowDimensions'
import { GoogleLogin } from 'react-google-login';
import { refreshTokenSetup } from '../googleutils/refreshToken';
import LoginBackgroundImage from '../LoginBackgroundImage'; 
import desktopImage from '../images/test15.jpeg';
import mobileImage from '../images/test15.jpeg';
import SocialFollowUp from '../SocialFollowUp';
import CommentBox from '../comments/CommentBox.js';
import NiruChatBot from '../chatbot/NiruChatBot.js';
import SimpleChatBot from '../chatbot/SimpleForm.js';



import "../css/login.css";
import "../css/book.css";
import "../css/poster.css";
import "../css/galaxy.css";
import jpg15rm from "../images/test15-rm.jpeg"
import jpgtest from '../images/test.jpeg';
import jpg1 from '../images/test1.jpeg';
import jpg2 from '../images/test2.jpeg';
import jpg3 from '../images/test3.jpeg';
import jpg4 from '../images/test4.jpeg';
import jpg5 from '../images/test5.jpeg';
import jpg6 from '../images/test6.jpeg';
import jpg7 from '../images/test7.jpeg';
import jpg8 from '../images/test8.jpeg';
import jpg9 from '../images/test9.jpeg';
import jpg10 from '../images/test10.jpeg';
import jpg11 from '../images/test11.jpeg';
import jpg12 from '../images/test12.jpeg';
import jpg13 from '../images/test13.jpeg';
import jpg14 from '../images/test14.jpeg';
import jpg15 from '../images/test15.jpeg';
import jpg16 from '../images/test16.jpeg';
import jpg17 from '../images/test17.jpeg';
import jpg22 from '../images/test22.jpeg';
import jpg23 from '../images/test23-2.png';
import jpg23_2 from '../images/test23-2.png';
import jpg26 from '../images/test26.jpeg';
import jpg27 from '../images/test27.jpeg';
import jpg28 from '../images/test28.jpeg';
import jpg29 from '../images/test29.jpeg';
import jpg30 from '../images/test30.jpeg';
import jpg31 from '../images/test31.jpeg';
import gifBrandLion from '../images/Lion-walk.gif';


export default class LoginCompanyGlimpse extends Component {

  constructor(props){
        super(props);
        this.state = { time: new Date() }; // initialise the state
        this.ceoDeskClicked=this.ceoDeskClicked.bind(this);
        this.loginClicked=this.loginClicked.bind(this);

        
    }

    componentDidMount = (props) => {
            // //console.log(this.props);
            //console.log("login component didmount:::");           
              document.addEventListener('contextmenu', (e) => {
                e.preventDefault();
            });
        AuthenticateService.ClearFlagsforLogin();
        this.update = setInterval(() => {
            this.setState({ time: new Date() });
        }, 1 * 1000); // every 1 seconds
        this.timer = setInterval(function(){  this.props.history.push(`/login`); }.bind(this), 30000);
          
          this.displayBanner = setInterval(() => {
            document.querySelector(".galleryName").style.display="block";
            
        },1 * 42000)
      

        };
     

    ceoDeskClicked(event){
        event.preventDefault();
     
        this.props.history.push(`/login3`);       

    }
     loginClicked(event){
        event.preventDefault();
     
        this.props.history.push(`/login`);       

    }
    
     componentWillUnmount() {
        clearInterval(this.timer);
        clearInterval(this.update);
         clearInterval(this.displayBanner);
      }

    render() {
  
        return (
                <>
                
                    <style>{`
                       .ap {
                            position: fixed;
                            right: 0;
                            bottom: 0;
                            left: 0;
                            height: 40px;
                            margin: auto;
                            font-family: Arial, sans-serif;
                            font-size: 14px;
                            -webkit-user-select: none;
                            -moz-user-select: none;
                            -ms-user-select: none;
                            user-select: none;
                            color: #333;
                            background: #222;
                            border-top: 2px solid #f0f;
                            z-index: 9999;
                            }  
                            /*END*/

                            @import url(https://fonts.googleapis.com/css?family=Open+Sans+Condensed:300);
                            * {
                            margin: 0;
                            padding: 0;
                            box-sizing: border-box;
                            }



                            .wrapper {
                            position: absolute;
                            top: 100%;
                            left: 50%;
                            width: 400px;
                            margin-left: -200px;
                            font: 300 30px/1 'Open Sans Condensed', sans-serif;
                            text-align: center;
                            text-transform: uppercase;
                            color: #fff;
                            animation: 60s credits linear infinite;
                            }

                            .movie {
                            margin-bottom: 50px;
                            font-size: 50px;
                            }

                            .job {
                            margin-bottom: 5px;
                            font-size: 18px;
                            }

                            .name {
                            margin-bottom: 50px;
                            font-size: 35px;
                            }

                            @keyframes credits {
                            0% {
                                top: 100%;
                            }
                            100% {
                                top: -500%;
                            }
                            }

                            section>div>h1 {
                                text-shadow: 0 1px 0 #ccc,
                                    0 2px 0 #c9c9c9,
                                    0 3px 0 #bbb,
                                    0 4px 0 #b9b9b9,
                                    0 5px 0 #aaa,
                                    0 6px 1px rgba(0,0,0,.1),
                                    0 0 5px rgba(0,0,0,.1),
                                    0 1px 3px rgba(0,0,0,.3),
                                    0 3px 5px rgba(0,0,0,.2),
                                    0 5px 10px rgba(0,0,0,.25),
                                    0 10px 10px rgba(0,0,0,.2),
                                    0 20px 20px rgba(0,0,0,.15);
                                    
                                    color: gold;
                                    text-transform: uppercase;;
                                    position: absolute;
                                    opacity: 0;
                                    top: 4vh;
                                    left: 20vw;
                                    animation: cinematicInOut 5s ease-in-out 2s;
                                    }

                                    h1:nth-child(2) {
                                    animation-delay: 8s;
                                    }

                                    h1:nth-child(3) {
                                    animation-delay: 11s;
                                    }
                                    h1:nth-child(4) {
                                    animation-delay: 14s;
                                    }
                                    h1:nth-child(5) {
                                    animation-delay: 17s;
                                    }
                                    h1:nth-child(6) {
                                    animation-delay: 20s;
                                    }
                                    h1:nth-child(7) {
                                    animation-delay: 23s;
                                    }
                                    h1:nth-child(8) {
                                    animation-delay: 26s;
                                    }
                                    h1:nth-child(9) {
                                    animation-delay: 29s;
                                    }
                                    h1:nth-child(10) {
                                    animation-delay: 32s;
                                    }
                                    h1:nth-child(11) {
                                    animation-delay: 35s;
                                    }
                                    h1:nth-child(12) {
                                    animation-delay: 38s;
                                    }
                                    @keyframes cinematicInOut {
                                    0% {
                                        text-shadow: 0 0 1em white;
                                        opacity: 0;
                                        margin-left: -0.5em;
                                    }
                                    25% {
                                        text-shadow: 0 0 0 white;
                                        opacity: 1;
                                        margin-left: 0;
                                    }
                                    75% {
                                        text-shadow: 0 0 0 white;
                                        opacity: 1;
                                        margin-left: 0;
                                    }
                                    100% {
                                        text-shadow: 0 0 1em white;
                                        opacity: 0;
                                        margin-left: 0;
                                    }
                                    }

                                    #offset {
                                    animation: letter-flicker 2s linear infinite;
                                    }
                                    @keyframes letter-flicker {
                                    0% {
                                        opacity:0.1;
                                        text-shadow: 0px 0px 29px rgba(242, 22, 22, 1);
                                    }
                                    2% {
                                        opacity:0.1;
                                        text-shadow: 0px 0px 29px rgba(242, 22, 22, 1);
                                    }
                                    4% {
                                        opacity:1;
                                        text-shadow: 0px 0px 29px rgba(242, 22, 22, 1);
                                    }
                                    
                                    
                                    19% {
                                        opacity:1;
                                        text-shadow: 0px 0px 29px rgba(242, 22, 22, 1);
                                    }
                                    21% {
                                        opacity:0.1;
                                        text-shadow: 0px 0px 29px rgba(242, 22, 22, 1);
                                    }
                                    23% {
                                        opacity:1;
                                        text-shadow: 0px 0px 29px rgba(242, 22, 22, 1);
                                    }
                                    
                                    80% {
                                        opacity:1;
                                        text-shadow: 0px 0px 29px rgba(242, 22, 22, 1);
                                    }
                                    83% {
                                        opacity:0.4;
                                        text-shadow: 0px 0px 29px rgba(242, 22, 22, 1);
                                    }
                                    87% {
                                        opacity:1;
                                        text-shadow: 0px 0px 29px rgba(242, 22, 22, 1);
                                    }
                                    }


                                h2 {
                                    text-transform: uppercase;
                                    font: 700 42px/1 "Arvo", sans-serif;
                                    text-align: center;
                                    -webkit-animation: in 10s ease-in-out forwards infinite;
                                            animation: in 10s ease-in-out forwards infinite;
                                    -webkit-animation-delay: 1s;
                                            animation-delay: 1s;
                                    text-shadow: 1px 1px 5px rgba(0, 0, 0, 0.8);
                                }

                                @-webkit-keyframes in {
                                    0% {
                                        letter-spacing: 20px;
                                    }
                                    100% {
                                        letter-spacing: 5px;
                                    }
                                }

                                @keyframes in {
                                    0% {
                                        letter-spacing: 20px;
                                    }
                                    100% {
                                        letter-spacing: 5px;
                                    }
                                }

                                .watername {
                                        position: absolute;
                                        top: 1%;
                                        bottom: 0;
                                        left: 0;
                                        right: 0;
                                        
                                    
                                        z-index: 8888;
                                    

                                }
                                .galleryName {
                                        position: absolute;
                                        top: 1%;
                                        bottom: 0;
                                        left: 0;
                                        right: 0;
                                        
                                    
                                        z-index: 8888;
                                    

                                }
                                
                                .whiteflower {
                                    position: relative;
                                    top: 10%;
                                    bottom: 0;
                                    left: 0;
                                    right: 0;
                                    
                                    z-index: 777;


                                }


                `}</style>


                {/* <section >
                    
                    <Carousel variant="outline-danger" autoPlay interval="15000"  indicators={true} fade={true}>
                        <Carousel.Item  >				  
                            <section >    
                                         
                                <Navbar bg="bg-transparent" variant="light" expand="lg" id="loginIndex1">                                                               
                                        <h1 className="welcometext" style={{color:"gold"}}> 
                                         <p>{this.state.time.toLocaleDateString('en-US',{ year: 'numeric', month: 'long', day: 'numeric' })} </p>
                                          <p>{this.state.time.toLocaleTimeString()} </p>
                                       </h1>
                                </Navbar>                        
                            </section>                          
                            <Carousel.Caption> 
                            
                              <div style={{height:"10%",zindex:"1"}} id="officescreen-coloumn">
                                        <label id="offsetbrand" className="LoginMenutextbrand  spinbrand">
                                             <span style={{fontSize: "25px"}}> Nirupa Naidu <br/>Enterprises</span>
                                        </label>  
                                    </div>                                   
                            </Carousel.Caption>
                                    
                        </Carousel.Item>
                        <Carousel.Item  >		  
                            <section >                     
                                <Navbar bg="bg-transparent" variant="light" expand="lg" id="loginIndex2"> 
                                     <h1 className="welcometext" style={{color:"gold"}}> 
                                         <p>{this.state.time.toLocaleDateString('en-US',{ year: 'numeric', month: 'long', day: 'numeric' })} </p>
                                          <p>{this.state.time.toLocaleTimeString()} </p>
                                       </h1>                        
                                </Navbar>
                            </section>
                            <Carousel.Caption>
                              <div style={{height:"10%",zindex:"1"}} id="officescreen-coloumn">
                                        <label id="offsetbrand" className="LoginMenutextbrand  spinbrand">
                                             <span style={{fontSize: "25px"}}> Nirupa Naidu <br/>Enterprises</span>
                                        </label>  
                                    </div>  
                            </Carousel.Caption>                               
                        </Carousel.Item>

                        <Carousel.Item  >
                            <section>
                            <Navbar bg="bg-transparent" variant="light" expand="lg" id="loginIndex3">  
                                    <h1 className="welcometext" style={{color:"gold"}}> 
                                         <p>{this.state.time.toLocaleDateString('en-US',{ year: 'numeric', month: 'long', day: 'numeric' })} </p>
                                          <p>{this.state.time.toLocaleTimeString()} </p>
                                       </h1>
                            </Navbar>
                        </section>
                                
                            <Carousel.Caption>
                                  <div style={{height:"10%",zindex:"1"}} id="officescreen-coloumn">
                                        <label id="offsetbrand" className="LoginMenutextbrand  spinbrand">
                                             <span style={{fontSize: "25px"}}> Nirupa Naidu <br/>Enterprises</span>
                                        </label>  
                                    </div>  
                            </Carousel.Caption>
                                    
                        </Carousel.Item>
                        <Carousel.Item  >
                            <section>
                                    <Navbar bg="bg-transparent" variant="light" expand="lg" id="loginIndex4">
                                      <h1 className="welcometext" style={{color:"gold"}}> 
                                         <p>{this.state.time.toLocaleDateString('en-US',{ year: 'numeric', month: 'long', day: 'numeric' })} </p>
                                          <p>{this.state.time.toLocaleTimeString()} </p>
                                       </h1>
                                    </Navbar>
                            </section>
                            <Carousel.Caption>
                           
                              <div style={{height:"10%",zindex:"1"}} id="officescreen-coloumn">
                                        <label id="offsetbrand" className="LoginMenutextbrand  spinbrand">
                                             <span style={{fontSize: "25px"}}> Nirupa Naidu <br/>Enterprises</span>
                                        </label>  
                                    </div>  
                                   
                            </Carousel.Caption>
                        </Carousel.Item>>
                        
                        
                    </Carousel>

                </section> */}

              {/* <section id="galaxyIndex1">
                    <h1 >
                        <span style={{fontSize:'75'}}><br/> Creative <br/><br/>
                            <img className='whiteflower' id='flower6' style={{left:'35%'}} src={jpg9} width='300' height='300' alt='Girl'></img>
                            </span>
                    </h1>
                    <h1><span style={{fontSize:'75'}}><br/>An Innovative<br/><br/>
                        <img className='whiteflower' id='flower6' style={{left:'35%'}} src={jpg17} width='300' height='300' alt='Girl'></img>
                        </span>
                    </h1>
                    <h1><span style={{fontSize:'75'}}><br/>Humble<br/><br/>
                            <img className='whiteflower' id='flower6' style={{left:'35%'}} src={jpg8} width='300' height='300' alt='Girl'></img>
                            </span>
                    </h1>
                    <h1><span style={{fontSize:'75'}}><br/>Intelligent<br/><br/>
                            <img className='whiteflower' id='flower6' style={{left:'35%'}} src={jpg15} width='300' height='300' alt='Girl'></img>
                        </span>
                    </h1>
                    <h1><span style={{fontSize:'75'}}><br/>Gorgeous<br/><br/>
                            <img className='whiteflower' id='flower6' style={{left:'35%'}} src={jpg12} width='300' height='300' alt='Girl'></img>
                        </span>
                    </h1>
                    <h1><span style={{fontSize:'75'}}><br/>Stunning<br/><br/>
                            <img className='whiteflower' id='flower6' style={{left:'35%'}} src={jpg14} width='250' height='250' alt='Girl'></img>
                        </span>
                    </h1>
                    <h1><span style={{fontSize:'75'}}><br/><br/><br/>And <br/></span></h1>
                    <h1><span style={{fontSize:'75'}}><br/>Novice Hollywood Model <br/><br/>
                            <img className='whiteflower' id='flower6' style={{left:'25%'}} src={jpg31} width='300' height='300' alt='Girl'></img>
                        </span>
                    </h1> 
                      <h1><span style={{fontSize:'75'}}><br/>forbes most powerful <br/>woman in the world <br/><br/>
                            <img className='whiteflower' id='flower6' style={{left:'15%'}} src={jpg28} width='300' height='300' alt='Girl'></img>
                        </span>
                    </h1> 
                     <h1><span style={{fontSize:'75'}}><br/>Best of CEO Award All Time  <br/><br/>
                            <img className='whiteflower' id='flower6' style={{left:'25%'}} src={jpg30} width='300' height='300' alt='Girl'></img>
                        </span>
                    </h1>
                    <h1 > 
                        <span style={{fontSize:'75'}}><br/>CEO Nirupa Naidu  <br/><br/>
                             <img className='whiteflower' id='flower6' style={{left:'25%'}} src={jpg22} width='300' height='300' alt='Girl'></img>
                         </span>
                    </h1>
                    <h1 >
                        <span style={{fontSize:'75'}}><br/> CEO of Nirupa Naidu  Enterprises<br/><br/>
                            <img className='whiteflower' id='flower6' style={{left:'25%'}} src={jpg9} width='300' height='300' alt='Girl'></img>
                         </span>
                    </h1>
                    





                    <br/>
                    <br/>
                    <br/>
                    <br/>
                    <div className='galleryName' id='gallery' style={{display:'none'}}>
                        <h2 > 
                            <span style={{fontSize:'100px',color:'red'}}> <br/><br/>CEO Nirupa  
                            <img className='watername' id='flower6' style={{left:'15%'}} src={gifBrandLion} width='200' height='200' alt='Girl'></img>
                            Naidu </span> 
                            <br/><span style={{fontSize:'50px'}}>&#128522;&#128512;&#128512;&#128514;&#128523;
                            <br/>
                            
                            </span>
                            <br/>
                    
                        </h2>
                    </div>
                  
                   
               </section> */}
               <section id="companyglimpse">
                <section >
                    <div className='wrapper'>
                            <div className='movie'>Welcome </div>
                            <div className='job'>To Our World class organization</div>
                            <br/>
                            <br/>
                            <br/>
                            <br/>
                            <br/>

                            <div className='name'>Above 500,000 Franchises</div>
                            <div className='job'></div>
                              <br/>
                            <br/>
                            <br/>
                            <br/>
                            <br/>
                            <div className='name'>Above 100,000,000 Customers World Wide</div>
                            <div className='job'> </div>
                              <br/>
                            <br/>
                            <br/>
                            <br/>
                            <br/>
                            <div className='name'>We are at almost in all business </div>
                            <br/>
                            <br/>
                            <br/>
                            <br/>
                            <br/>
                             <div className='name'>Software</div>
                            <div className='job'>Topper in Software solutions</div>
                            <div className='job'>AI Technologies</div>
                            <div className='job'>Block chain</div>
                            <div className='job'>Robotics</div>
                            <div className='job'>Quantum computing</div>
                            <br/>
                            <br/>
                            <br/>
                            <br/>
                            <div className='name'>Medicine</div>
                            <div className='job'>Scientific Reasearch </div>
                            <div className='job'>Hospitals</div>
                            <div className='job'>top in Bio-Technology</div> 
                            <div className='job'>producing top medicines</div>
                            <br/>
                            <br/>
                            <br/>
                            <br/>
                            <br/>
                            <div className='name'>Flights</div>
                            <div className='job'>our Fligts are available all world famous cities </div>
                            <br/>
                            <br/>
                            <br/>
                            <br/>
                            <br/>

                            <div className='name'>Construction</div>
                            <div className='job'>Top class cities build experience</div>
                            <div className='job'>Flyovers,road,buildings etc</div>
                            <br/>
                            <br/>
                            <br/>
                            <br/>
                            <br/>
                            <div className='name'>Travel </div>
                            <div className='job'>Manufacturing world class busses </div>
                            <div className='job'>Manufacturing Trains </div>
                            <div className='job'>flights </div>
                             <br/>
                            <br/>
                            <br/>
                            <br/>
                            <br/>
                            <div className='name'>Tourisim </div>
                            <div> Charted filghts</div>
                            <div>Choppers</div>
                            <div>Ships</div>
                            <div className='job'>World best rank in tourisim to all major cities in the world </div>
                              <br/>
                            <br/>
                            <br/>
                            <br/>
                            <br/>
                            <div className='movie'>Welcome </div>
                            <div className='job'>To Our World class organization</div>
                            <br/>
                            <br/>
                            <br/>
                            <br/>
                            <br/>

                            <div className='name'>Above 500,000 Franchises</div>
                            <div className='job'></div>
                              <br/>
                            <br/>
                            <br/>
                            <br/>
                            <br/>
                            <div className='name'>Above 100,000,000 Customers World Wide</div>
                            <div className='job'> </div>
                              <br/>
                            <br/>
                            <br/>
                            <br/>
                            <br/>
                            <div className='name'>We are at almost in all business </div>
                            <br/>
                            <br/>
                            <br/>
                            <br/>
                            <br/>
                             <div className='name'>Software</div>
                            <div className='job'>Topper in Software solutions</div>
                            <div className='job'>AI Technologies</div>
                            <div className='job'>Block chain</div>
                            <div className='job'>Robotics</div>
                            <div className='job'>Quantum computing</div>
                            <br/>
                            <br/>
                            <br/>
                            <br/>
                            <div className='name'>Medicine</div>
                            <div className='job'>Scientific Reasearch </div>
                            <div className='job'>Hospitals</div>
                            <div className='job'>top in Bio-Technology</div> 
                            <div className='job'>producing top medicines</div>
                            <br/>
                            <br/>
                            <br/>
                            <br/>
                            <br/>
                            <div className='name'>Flights</div>
                            <div className='job'>our Fligts are available all world famous cities </div>
                            <br/>
                            <br/>
                            <br/>
                            <br/>
                            <br/>

                            <div className='name'>Construction</div>
                            <div className='job'>Top class cities build experience</div>
                            <div className='job'>Flyovers,road,buildings etc</div>
                            <br/>
                            <br/>
                            <br/>
                            <br/>
                            <br/>
                            <div className='name'>Travel </div>
                            <div className='job'>Manufacturing world class busses </div>
                            <div className='job'>Manufacturing Trains </div>
                            <div className='job'>flights </div>
                             <br/>
                            <br/>
                            <br/>
                            <br/>
                            <br/>
                            <div className='name'>Tourisim </div>
                            <div> Charted filghts</div>
                            <div>Choppers</div>
                            <div>Ships</div>
                            <div className='job'>World best rank in tourisim to all major cities in the world </div>
                              <br/>
                            <br/>
                            <br/>
                            <br/>
                            <br/>
                            
                    </div>	

                   </section> 
                   
                      <div className='galleryName' id='gallery' style={{display:'block'}}>
                           
                            <h2 > 
                             <br/>
                            <br/>
                            <br/>
                                <span style={{fontSize:'50px',color:'red'}}> <br/><br/>Nirupa Naidu 
                                
                                 <img  style={{left:'15%'}} src={gifBrandLion} width='200' height='200' alt='Girl'></img>
                                Enterprises
                                </span> 
                                <br/>
                                <br/>
                    
                            </h2>
                         </div>
                </section>
               
                <section>
                  <SimpleChatBot />  
                 
                </section>
                <h1 style={{ padding: '1em'}}></h1>
                <section className="fixed-bottom">
                    <SocialFollowUp/>
                </section>
                    
            </>
        );
    }
}

