import React, { Component } from "react";
import { Carousel } from 'react-bootstrap';
import {Navbar,Nav,NavDropdown,Form,FormControl,Modal,Button} from 'react-bootstrap';

import PropTypes from "prop-types";
import Axios from 'axios';
import SimpleChatBot from '../chatbot/SimpleForm.js';
import SocialFollowUp from '../SocialFollowUp';

import "../css/galaxy.css";
import jpg9 from '../images/test26-2.jpeg';

export default class NewYear2023 extends Component {

  constructor(props){
        super(props);
        this.state = { time: new Date() }; // initialise the state
        this.ceoDeskClicked=this.ceoDeskClicked.bind(this);
        this.loginClicked=this.loginClicked.bind(this);

        
    }

    componentDidMount = (props) => {
          
              document.addEventListener('contextmenu', (e) => {
                e.preventDefault();
            });        
        this.update = setInterval(() => {
            this.setState({ time: new Date() });
        }, 1 * 1000); // every 1 seconds
        
        // this.removeWishes = setInterval(() => {
        //     document.querySelector("#newyear2023wishes").style.display="none";
        //      document.querySelector("#galaxyIndex1").style.display="block";
        // }, 5000); // 

        this.timer = setInterval(function(){  this.props.history.push(`/ceodesk1`); }.bind(this), 15000);
          

        };
     

    ceoDeskClicked(event){
        event.preventDefault();
     
        this.props.history.push(`/login3`);       

    }
     loginClicked(event){
        event.preventDefault();
     
        this.props.history.push(`/login`);       

    }
    
     componentWillUnmount() {
        clearInterval(this.timer);
        clearInterval(this.update);
        clearInterval(this.displayBanner);
        clearInterval(this.removeWishes);
         
      }

    render() {
  
        return (
                <>
                
                    <style>{`
                    .line{
                        background-color:white; 
                        padding-left:20px; 
                        padding-right:20px; 
                        max-width:550px; 
                        margin:auto; 
                        border-radius:5px;
                        padding-bottom:5px; 
                        text-align:left; 
                        margin-bottom:40px;
                        width:35%;
                    }
                    .center {
                        display: flex;
                        justify-content: center;
                        align-items: center;
                    }
                    ul{
                        display: flex;
                        justify-content: center;
                    }
                   ul > li{
                       
                        display:inline-block;
                        padding: 0px 40px;
                        first-child{padding-left:0;}
                        last-child{padding-right:0;}
                    }
                    h1 {
                        text-shadow: 0 1px 0 #ccc,
                                    0 2px 0 #c9c9c9,
                                    0 3px 0 #bbb,
                                    0 4px 0 #b9b9b9,
                                    0 5px 0 #aaa,
                                    0 6px 1px rgba(0,0,0,.1),
                                    0 0 5px rgba(0,0,0,.1),
                                    0 1px 3px rgba(0,0,0,.3),
                                    0 3px 5px rgba(0,0,0,.2),
                                    0 5px 10px rgba(0,0,0,.25),
                                    0 10px 10px rgba(0,0,0,.2),
                                    0 20px 20px rgba(0,0,0,.15);
                                    
                        color: gold;
                        text-transform: uppercase;;
                        position: absolute;
                        opacity: 0;
                        top: 4vh;
                        left: 20vw;
                        animation: cinematicInOut 5s ease-in-out 2s;
                        }

                        h1:nth-child(2) {
                        animation-delay: 8s;
                        }

                        h1:nth-child(3) {
                        animation-delay: 11s;
                        }
                        h1:nth-child(4) {
                        animation-delay: 14s;
                        }
                        h1:nth-child(5) {
                        animation-delay: 17s;
                        }
                        h1:nth-child(6) {
                        animation-delay: 20s;
                        }
                        h1:nth-child(7) {
                        animation-delay: 23s;
                        }
                        h1:nth-child(8) {
                        animation-delay: 26s;
                        }
                        h1:nth-child(9) {
                        animation-delay: 29s;
                        }
                        h1:nth-child(10) {
                        animation-delay: 32s;
                        }
                        h1:nth-child(11) {
                        animation-delay: 38s;
                        }
                        h1:nth-child(12) {
                        animation-delay: 42s;
                        }
                        @keyframes cinematicInOut {
                        0% {
                            text-shadow: 0 0 1em white;
                            opacity: 0;
                            margin-left: -0.5em;
                        }
                        25% {
                            text-shadow: 0 0 0 white;
                            opacity: 1;
                            margin-left: 0;
                        }
                        75% {
                            text-shadow: 0 0 0 white;
                            opacity: 1;
                            margin-left: 0;
                        }
                        100% {
                            text-shadow: 0 0 1em white;
                            opacity: 0;
                            margin-left: 0;
                        }
                        }

                        #offset {
                        animation: letter-flicker 2s linear infinite;
                        }
                        @keyframes letter-flicker {
                        0% {
                            opacity:0.1;
                            text-shadow: 0px 0px 29px rgba(242, 22, 22, 1);
                        }
                        2% {
                            opacity:0.1;
                            text-shadow: 0px 0px 29px rgba(242, 22, 22, 1);
                        }
                        4% {
                            opacity:1;
                            text-shadow: 0px 0px 29px rgba(242, 22, 22, 1);
                        }
                        
                        
                        19% {
                            opacity:1;
                            text-shadow: 0px 0px 29px rgba(242, 22, 22, 1);
                        }
                        21% {
                            opacity:0.1;
                            text-shadow: 0px 0px 29px rgba(242, 22, 22, 1);
                        }
                        23% {
                            opacity:1;
                            text-shadow: 0px 0px 29px rgba(242, 22, 22, 1);
                        }
                        
                        80% {
                            opacity:1;
                            text-shadow: 0px 0px 29px rgba(242, 22, 22, 1);
                        }
                        83% {
                            opacity:0.4;
                            text-shadow: 0px 0px 29px rgba(242, 22, 22, 1);
                        }
                        87% {
                            opacity:1;
                            text-shadow: 0px 0px 29px rgba(242, 22, 22, 1);
                        }
                        }


                    h2 {
                        text-transform: uppercase;
                        font: 700 42px/1 "Arvo", sans-serif;
                        text-align: center;
                        -webkit-animation: in 10s ease-in-out forwards infinite;
                                animation: in 10s ease-in-out forwards infinite;
                        -webkit-animation-delay: 1s;
                                animation-delay: 1s;
                        text-shadow: 1px 1px 5px rgba(0, 0, 0, 0.8);
                    }

                    @-webkit-keyframes in {
                        0% {
                            letter-spacing: 20px;
                        }
                        100% {
                            letter-spacing: 5px;
                        }
                    }

                    @keyframes in {
                        0% {
                            letter-spacing: 20px;
                        }
                        100% {
                            letter-spacing: 5px;
                        }
                    }

                    .watername {
                            position: absolute;
                            top: 1%;
                            bottom: 0;
                            left: 0;
                            right: 0;
                            
                        
                            z-index: 8888;
                        

                    }
                    .galleryName {
                            position: absolute;
                            top: 1%;
                            bottom: 0;
                            left: 0;
                            right: 0;
                            
                        
                            z-index: 8888;
                        

                    }
                    
                    .whiteflower {
                        position: relative;
                         top: 10%;
                        bottom: 0;
                        left: 0;
                        right: 0;
                        
                        z-index: 777;


                    }
                    .welcomeimgclass{
                         position: relative;
                         top: 1%;
                        bottom: 0;
                        left: 0;
                        right: 0;
                        word-break: normal;
                        z-index: 777;
                    }

                `}</style>


              <section id="newyear2023wishes" style={{display:'block'}}>
                <div className="welcometext" style={{color:'gold',top:'1%'}}> 
                        <p >  
                        
                            <span>
                              <h4 >
                        <span style={{fontSize:'5vh',color:'white'}}> CEO oF Nirupa Naidu  Enterprises                       
                             <img  className='welcomeimgclass' id='flower6' src={jpg9}  alt='Girl'></img>
                         </span>
                    </h4>
                           
                            </span>  
                                                
                      </p>
                        
                        
                    </div>
                   
                   
               </section>

               
                <section>
                  <SimpleChatBot />  
                 
                </section>
                <h1 style={{ padding: '1em'}}></h1>
                <section >
                    <SocialFollowUp/>
                </section>
                    
            </>
        );
    }
}

