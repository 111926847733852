import React,{Component,useState,useEffect} from 'react';
import ReactDOM from "react-dom";
import HelloWorldApp from '../component/HelloWorldApp'
import {Navbar,Nav,NavDropdown,Form,FormControl,Button} from 'react-bootstrap';
import { Carousel } from 'react-bootstrap';
import AuthenticateService from '../service/AuthenticateService';

import jpgtest from '../images/test.jpeg';
import jpg1 from '../images/test1.jpeg';
import jpg2 from '../images/test2.jpeg';
import jpg3 from '../images/test3.jpeg';
import jpg4 from '../images/test4.jpeg';
import jpg5 from '../images/test5.jpeg';
import jpg6 from '../images/test6.jpeg';
import jpg7 from '../images/test7.jpeg';
import jpg8 from '../images/test8.jpeg';
import jpg9 from '../images/test9.jpeg';
import jpg10 from '../images/test10.jpeg';
import jpg11 from '../images/test11.jpeg';
import jpg12 from '../images/test12.jpeg';
import jpg13 from '../images/test13.jpeg';
import jpg14 from '../images/test14.jpeg';
import jpg15 from '../images/test15.jpeg';
import jpg16 from '../images/test16.jpeg';
import jpg17 from '../images/test17.jpeg';
import jpg23 from '../images/test23.jpeg';
import jpg26 from '../images/test26.jpeg';
import jpg27 from '../images/test27.jpeg';
import "../css/homepage.css";

import CommentBox from '../comments/CommentBox.js';




export class Home extends Component {
      constructor(props){
        super(props);
       // this.handleLogout=this.handleLogout.bind(this);
        //this.isThirdPartyUser = this.isThirdPartyUser.bind();
        
    }
 





    render() {
        //console.log("homejs-userobj-"+this.props.state);
        var userObj ={...this.props.state} ;
        var authObj = AuthenticateService.getUser();
        
        if( authObj ){
           // console.log("authservice user authObj ::"+authObj);
           //Object.assign(userObj,authObj); const case
         userObj=JSON.parse(authObj);
           // console.log("authservice user userObj ::"+userObj);

        }
         let googleuserFistName = userObj.googleuserFistName;
        let googleUserLastName = userObj.googleUserLastName;
        let googleUserImage = userObj.googleUserImage;
        let usermailid = userObj.googleUserMailId;
        //console.log("homejs userObj-"+userObj.googleuserFistName);
        //console.log("homejs userObj-"+userObj.googleUserImage);
        //console.log("homejs userObj-"+userObj.googleUserLastName);

        return (
           <>
           <div>
                <div className='container-fluid' >
                      
                            <h1 className="welcometext" style={{color:"blue"}}> 
                                {`${new Date().toLocaleString()}`}
                            
                            </h1>
                             <h1 className="welcometext" style={{color:"blue"}}> 
                              
                            
                            </h1>
                             <p><label  className="welcometext" >welcome  : </label>
                                <span className="welcomeusertxt">{googleuserFistName} {googleUserLastName} </span>
                                <label  className="welcometext" >logged in using   : </label>
                                    <span className="welcomeusertxt">{usermailid} </span>
                                    </p>
                           <p>&nbsp;</p>
                        <p>&nbsp;</p>
                        <p>&nbsp;</p>            
                       <div className="row">
                            <div className="col-12 d-flex justify-content-center">
                           
                                <Carousel variant="outline-danger" autoPlay interval="35000" >
                                 <Carousel.Item  >
                                        {/* <img
                                                className="d-block w-70 "
                                                src={jpg6}
                                                alt="First slide"
                                                />
                                            */} 
                                        <section >

                                            <img
                                            
                                                src={jpg26}
                                                alt="First slide"
                                               className="imageClass" />
                                        </section>
                                            <Carousel.Caption>
                                            {/*<h5>First slide label</h5>
                                            <p>Nulla vitae elit libero, a pharetra augue mollis interdum.</p>*/}
                                            </Carousel.Caption>
                                        </Carousel.Item>
                                     <Carousel.Item  >
                                        {/* <img
                                                className="d-block w-70 "
                                                src={jpg6}
                                                alt="First slide"
                                                />
                                            */} 
                                        <section >

                                            <img
                                            
                                                src={jpg27}
                                                alt="First slide"
                                               className="imageClass" />
                                        </section>
                                            <Carousel.Caption>
                                            {/*<h5>First slide label</h5>
                                            <p>Nulla vitae elit libero, a pharetra augue mollis interdum.</p>*/}
                                            </Carousel.Caption>
                                        </Carousel.Item>

                                    <Carousel.Item  >
                                        {/* <img
                                                className="d-block w-70 "
                                                src={jpg6}
                                                alt="First slide"
                                                />
                                            */} 
                                        <section >

                                            <img
                                            
                                                src={jpg23}
                                                alt="First slide"
                                              className="imageClass"  />
                                        </section>
                                            <Carousel.Caption>
                                            {/*<h5>First slide label</h5>
                                            <p>Nulla vitae elit libero, a pharetra augue mollis interdum.</p>*/}
                                            </Carousel.Caption>
                                        </Carousel.Item>
                                    
                                        <Carousel.Item  >
                                    {/* <img
                                            className="d-block w-70 "
                                            src={jpg6}
                                            alt="First slide"
                                            />
                                        */} 
                                    <section >

                                        <img
                                        
                                            src={jpg6}
                                            alt="First slide"
                                           className="imageClass" />
                                    </section>
                                            <Carousel.Caption>
                                            {/*<h5>First slide label</h5>
                                            <p>Nulla vitae elit libero, a pharetra augue mollis interdum.</p>*/}
                                            </Carousel.Caption>
                                        </Carousel.Item>
                                        <Carousel.Item>
                                            <section >
                                                <img
                                                
                                                src={jpg7}
                                                alt="Second slide"
                                                className="imageClass"/>
                                            </section>
                                            <Carousel.Caption>
                                            {/*<h5>Second slide label</h5>
                                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>*/}
                                            </Carousel.Caption>
                                        </Carousel.Item>
                                    <Carousel.Item>
                                    <section >
                                        <img
                                         
                                        src={jpg8}
                                        alt="Third slide"
                                       className="imageClass" />
                                         </section>
                                        <Carousel.Caption>
                                        {/*<h5>Third slide label</h5>
                                        <p>Praesent commodo cursus magna, vel scelerisque nisl consectetur.</p>*/}
                                        </Carousel.Caption>
                                    </Carousel.Item>
                                    <Carousel.Item>
                                    <section >
                                        <img
                                         
                                        src={jpg9}
                                        alt="First slide"
                                        className="imageClass"/>
                                         </section>
                                        <Carousel.Caption>
                                    {/* <h5>First slide label</h5>
                                        <p>Nulla vitae elit libero, a pharetra augue mollis interdum.</p>*/}
                                        </Carousel.Caption>
                                    </Carousel.Item>
                                    <Carousel.Item>
                                    <section >
                                        <img
                                         
                                        src={jpg10}
                                        alt="Second slide"
                                        className="imageClass"/>
                                     </section>
                                        <Carousel.Caption>
                                        {/*<h5>Second slide label</h5>
                                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>*/}
                                        </Carousel.Caption>
                                    </Carousel.Item>
                                    <Carousel.Item>
                                    <section >
                                        <img
                                         
                                        src={jpg11}
                                        alt="Third slide"
                                       className="imageClass" />
                                     </section>
                                        <Carousel.Caption>
                                        {/*<h5>Third slide label</h5>
                                        <p>Praesent commodo cursus magna, vel scelerisque nisl consectetur.</p>*/}
                                        </Carousel.Caption>
                                    </Carousel.Item>
                                    <Carousel.Item>
                                    <section >
                                        <img
                                         
                                        src={jpg12}
                                        alt="First slide"
                                       className="imageClass" />
                                     </section>
                                        <Carousel.Caption>
                                        {/*<h5>First slide label</h5>
                                        <p>Nulla vitae elit libero, a pharetra augue mollis interdum.</p>*/}
                                        </Carousel.Caption>
                                    </Carousel.Item>
                                    <Carousel.Item>
                                    <section >
                                        <img
                                         
                                        src={jpg13}
                                        alt="Second slide"
                                       className="imageClass" />
                                        </section>
                                        <Carousel.Caption>
                                        {/*<h5>Second slide label</h5>
                                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>*/}
                                        </Carousel.Caption>
                                    </Carousel.Item>
                                    <Carousel.Item>
                                    <section >
                                        <img
                                         
                                        src={jpg14}
                                        alt="Third slide"
                                       className="imageClass" />
                                     </section>
                                        <Carousel.Caption>
                                        {/*<h5>Third slide label</h5>
                                        <p>Praesent commodo cursus magna, vel scelerisque nisl consectetur.</p>*/}
                                        </Carousel.Caption>
                                    </Carousel.Item>
                                    <Carousel.Item>
                                    <section >
                                        <img
                                         
                                        src={jpg15}
                                        alt="First slide"
                                       className="imageClass" />
                                     </section>
                                        <Carousel.Caption>
                                        {/*<h5>First slide label</h5>
                                        <p>Nulla vitae elit libero, a pharetra augue mollis interdum.</p>*/}
                                        </Carousel.Caption>
                                    </Carousel.Item>
                                    <Carousel.Item>
                                    <section >
                                        <img
                                         
                                        src={jpg16}
                                        alt="Second slide"
                                       className="imageClass" />
                                     </section>
                                        <Carousel.Caption>
                                        {/*<h5>Second slide label</h5>
                                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>*/}
                                        </Carousel.Caption>
                                    </Carousel.Item>
                                    <Carousel.Item>
                                    <section >
                                        <img
                                         
                                        src={jpg17}
                                        alt="Third slide"
                                        className="imageClass"/>
                                     </section>
                                        <Carousel.Caption>
                                        {/*<h5>Third slide label</h5>
                                        <p>Praesent commodo cursus magna, vel scelerisque nisl consectetur.</p>*/}
                                        </Carousel.Caption>
                                    </Carousel.Item>
                                    <Carousel.Item>
                                    <section >
                                        <img
                                        
                                        src={jpg1}
                                        alt="First slide"
                                       className="imageClass" />
                                     </section>
                                        <Carousel.Caption>
                                        {/*<h5>First slide label</h5>
                                        <p>Nulla vitae elit libero, a pharetra augue mollis interdum.</p>*/}
                                        </Carousel.Caption>
                                    </Carousel.Item>
                                    <Carousel.Item>
                                    <section >
                                        <img
                                        style={{maxHeight: '100%',maxWidth: '100%'}}
                                        src={jpg2}
                                        alt="Second slide"
                                       className="imageClass" />
                                     </section>
                                        <Carousel.Caption>
                                        {/*<h5>Second slide label</h5>
                                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>*/}
                                        </Carousel.Caption>
                                    </Carousel.Item>
                                    <Carousel.Item>
                                    <section >
                                        <img 
                                         
                                        src={jpg3}
                                        alt="Third slide"
                                       className="imageClass" />
                                     </section>
                                        <Carousel.Caption>
                                        {/*<h5>Third slide label</h5>
                                        <p>Praesent commodo cursus magna, vel scelerisque nisl consectetur.</p>*/}
                                        </Carousel.Caption>
                                    </Carousel.Item>
                                    <Carousel.Item>
                                    <section >
                                        <img
                                        
                                        src={jpg4}
                                        alt="First slide"
                                        className="imageClass"/>
                                     </section>
                                        <Carousel.Caption>
                                        {/*<h5>First slide label</h5>
                                        <p>Nulla vitae elit libero, a pharetra augue mollis interdum.</p>*/}
                                        </Carousel.Caption>
                                    </Carousel.Item>
                                    <Carousel.Item>
                                    <section >
                                        <img
                                       
                                        src={jpg5}
                                        alt="Second slide"
                                        className="imageClass"/>
                                        </section>
                                        <Carousel.Caption>
                                        {/*<h5>Second slide label</h5>
                                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>*/}
                                        </Carousel.Caption>
                                    </Carousel.Item>
                                    <Carousel.Item>
                                    <section >
                                        <img
                                        
                                        src={jpgtest}
                                        alt="Third slide"
                                        className="imageClass"/>
                                     </section>
                                        <Carousel.Caption>
                                        {/*<h5>Third slide label</h5>
                                        <p>Praesent commodo cursus magna, vel scelerisque nisl consectetur.</p>*/}
                                        </Carousel.Caption>
                                    </Carousel.Item>
                                </Carousel>
                            
                            </div>
                        </div>
                    </div>
                <div>
                
                
                </div>
        </div>
        <div >
            
        </div>

           <p>&nbsp;</p>
            <p>&nbsp;</p>
            <p>&nbsp;</p> 
         <CommentBox  {...this.props} />
        </>
        )
    }
}

export default Home
