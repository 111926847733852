import React, { Component } from 'react'
import {Navbar,Nav,NavDropdown,Form,FormControl,Button} from 'react-bootstrap';
import { Carousel } from 'react-bootstrap';


import HelloWorldApp from '../component/HelloWorldApp'
import AuthenticateService from "../service/AuthenticateService";
import jpgtest from '../images/test.jpeg';
import jpg1 from '../images/test1.jpeg';
import jpg2 from '../images/test2.jpeg';
import jpg3 from '../images/test3.jpeg';
import jpg4 from '../images/test4.jpeg';
import jpg5 from '../images/test5.jpeg';
import jpg6 from '../images/test6.jpeg';
import jpg7 from '../images/test7.jpeg';
import jpg8 from '../images/test8.jpeg';
import jpg9 from '../images/test9.jpeg';
import jpg10 from '../images/test10.jpeg';
import jpg11 from '../images/test11.jpeg';
import jpg12 from '../images/test12.jpeg';
import jpg13 from '../images/test13.jpeg';
import jpg14 from '../images/test14.jpeg';
import jpg15 from '../images/test15.jpeg';
import jpg16 from '../images/test16.jpeg';
import jpg17 from '../images/test17.jpeg';

import jpg23 from '../images/test23.jpeg';
import "../css/homepage.css";
export class Dashboard extends Component {
      constructor(props){
        super(props);
       // this.handleLogout=this.handleLogout.bind(this);
        //this.isThirdPartyUser = this.isThirdPartyUser.bind();
       // var currentDateTime: new Date().toLocaleString();
        this.state = {
         currentDate: Date().toLocaleString()

        }
    }
       componentDidMount = (props) => {
        this.timer = setInterval(function(){  this.props.history.push(`/dashboard/home`); }.bind(this), 10000);
         // //console.log(this.props);
          document.addEventListener('contextmenu', (e) => {
              e.preventDefault();
          });
      };

     componentWillUnmount() {
         clearInterval(this.timer);
      }

    render() {
        //console.log("homejs-userobj-"+this.props.state);
        
        var userObj ={...this.props.state} ;
        //const userObj ={} ;
        var authObj = AuthenticateService.getUser();
        
        if( authObj ){
           // console.log("authservice user authObj ::"+authObj);
           //Object.assign(userObj,authObj); const case
         userObj=JSON.parse(authObj);
           // console.log("authservice user userObj ::"+userObj);

        }
        let googleuserFistName = userObj.googleuserFistName;
        let googleUserLastName = userObj.googleUserLastName;
        let googleUserImage = userObj.googleUserImage;
        let usermailid = userObj.googleUserMailId;
        //console.log("homejs userObj-"+userObj);
        //console.log("homejs userObj-"+userObj.googleuserFistName);
                                        
        //console.log("homejs userObj-"+userObj.googleUserImage);
        //console.log("homejs userObj-"+userObj.googleUserLastName);
        return (
           <>
           <div>
                <div className='container-fluid' >
                    
                    <div className="row">
                        <div className="col-12 d-flex justify-content-center">
                            <h1> 
                                <h2 className="welcometext" style={{color:"blue"}}> 
                                    {/*{`${new Date().toLocaleString()}`}*/}
                                    {this.state.currentDate}
                                </h2>
                                <h2>
                                <label  className="welcometext" >welcome  : </label>
                                    <span className="welcomeusertxt">{googleuserFistName} {googleUserLastName} </span>
                                    <label  className="welcometext" >logged in using   : </label>
                                        <span className="welcomeusertxt">{usermailid} </span>
                                </h2>               
                            </h1>
                        </div>
                    </div>
                </div>
            </div>
    
            <div>
                <p>&nbsp;</p>
                <p>&nbsp;</p>
                <p>&nbsp;</p>
                <p>&nbsp;</p>
                <p>&nbsp;</p>
                 <p>&nbsp;</p>
                <p>&nbsp;</p>
            </div>
                 <section >
            <div className="cutecontainer">
                <div className="alignleftclass">
                         <div id="hero-8" > 
                            <img
                        style={{height: '70%',width: '70%'}}
                            src={jpg23}
                            alt="First slide"
                            className="imageClass" 
                            />
                         </div>
                    </div>
                    <div className="cutecontainer-text-block">
                    <p>&nbsp;</p>
                        <p class="hollywoodtext"><span> &#34;awesome hollywood debut&#34;<br/><span style={{color:'pink'}}>— hollywood magazine cafe</span></span> </p>
                       {/* <p > <span style={{color:'cyan'}}>
                                                &#34;awesome hollywood debut&#34;
                                            </span>
                                                <br/>
                                            <span style={{color:'pink'}}>— hollywood magazine cafe</span></p>*/}
                    </div>
            </div>
             <h1 style={{ padding: "3em"}}></h1>
         {/** 
             <div className="alignleftclass">
                <img
                style={{height: '100%',width: '100%'}}
                    src={jpg23}
                    alt="First slide"
                    />
                    <div className="hero-imagepostertext">
                        
                    </div>
             </div>
              <div class="parallexcontainer">
                  <label className="loginMoving-text">
                                        <span style={{color:'cyan'}}>
                                                &#34;awesome hollywood debut&#34;
                                            </span>
                                                <br/>
                                            <span style={{color:'pink'}}>— hollywood magazine cafe</span>
                                </label>
                       
                                </div>
            */}
            
        </section>
        
  
        </>
        )
    }
}

export default Dashboard

export const useDate = () => {
  const locale = 'en';
  const [today, setDate] = React.useState(new Date()); // Save the current date to be able to trigger an update

  React.useEffect(() => {
      const timer = setInterval(() => { // Creates an interval which will update the current data every minute
      // This will trigger a rerender every component that uses the useDate hook.
      setDate(new Date());
    }, 60 * 1000);
    return () => {
      clearInterval(timer); // Return a funtion to clear the timer so that it will stop being called on unmount
    }
  }, []);

  const day = today.toLocaleDateString(locale, { weekday: 'long' });
  const date = `${day}, ${today.getDate()} ${today.toLocaleDateString(locale, { month: 'long' })}\n\n`;

  const hour = today.getHours();
  const wish = `Good ${(hour < 12 && 'Morning') || (hour < 17 && 'Afternoon') || 'Evening'}, `;

  const time = today.toLocaleTimeString(locale, { hour: 'numeric', hour12: true, minute: 'numeric' });

  return {
    date,
    time,
    wish,
  };
};