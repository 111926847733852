import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import Login from './login/Login'
import {HashRouter as Router,BrowserRouter ,Route,Switch} from 'react-router-dom';
import BootStrapNavBar from './BootstrapNavbar';
import { ProtectedRoute } from "./Protected.Route";

import AboutUS from './bootstrapsupportfiles/AboutUs';
import ContactUs from './bootstrapsupportfiles/ContactUs';
import Home from './bootstrapsupportfiles/Home';
import HelloWorldBeanComponent from './component/HelloWorldBeanComponent';
import HelloWorldStringComponent from './component/HelloWorldStringComponent';
import FetchAllEmployees from './employee/FetchAllEmployees';
import AddEmployee from './employee/AddEmployee';
import LoginComponent from './login/Login';
import LogoutComponent from './login/LogoutComponent';
import AuthenticatedRoute from './AuthenticatedRoute';
import "../node_modules/bootstrap/dist/css/bootstrap.css"
const rootElement = document.getElementById("root");
ReactDOM.render(
 <Router><App/></Router>,
  rootElement
);



// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
